import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import TimelineItem from "defaultComponents/Timeline/TimelineItem";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";

// React icons
import { IoEllipse } from "react-icons/io5"
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IoLogoTiktok as TikTokIcon } from "react-icons/io5";
import { IoArrowUp } from "react-icons/io5";
// Data
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import mockPhoto from "assets/images/avatar1.png"
import * as S from "./style"
import Modal from "components/Modal/Modal";
import LinkModalContent from "./components/LinkModalContent/LinkModalContent";
import { getHomeData } from "utils/requests/other";
import moment from 'moment';
import 'moment/locale/pt-br';
import InfluencersRanking from "./components/InfluencersRanking/InfluencersRanking";
import EventDetails from "pages/Calendar/ModalContent/EventDetails";
import { TokenContext } from "context/TokenContext";
import Notifications from "./components/Notifications/Notifications";
import PublicationDetails from "pages/Calendar/ModalContent/PublicationDetails";

function Dashboard() {
  moment.locale("pt-br")
  const { gradients } = colors;
  const { cardContent } = gradients;

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  //facebook modal
  const [openFacebookModal, setOpenFacebookModal] = React.useState(false);
  const [openEventDetails, setOpenEventDetails] = React.useState(false);
  const [openPublicationDetails, setOpenPublicationDetails] = React.useState(false);
  const [eventToShow, setEventToShow] = React.useState();
  const [homeData, setHomeData] = React.useState();

  const handleClose = () => {
    setOpenFacebookModal(false);
  };

  const fetchData = async () => {
    const data = await getHomeData();
    if (!data.statusCode) {
      setHomeData(data);
    }
  };

  React.useEffect(() => {
    fetchData();
    if(decodedToken?.username === 'orine') {
      setOpenFacebookModal(true);
    }
  }, []);

  const handleOpenCalendarDetails = (event) => {
    if(event?.extendedProps?.type === "PUBLICATION") {
      setOpenPublicationDetails(true);
      return
    }
    setOpenEventDetails(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={3}>
              <WelcomeMark />
            </Grid>
            {role === 'ADMINISTRATOR' &&
              <Grid item xs={12} lg={6} xl={3}>
                <Notifications />
              </Grid>
            }
            {role !== 'ADMINISTRATOR' &&
              <Grid item xs={12} lg={6} xl={3}>
                <SatisfactionRate rating={homeData?.approval} />
              </Grid>
            }
            <Grid item xs={12} lg={6} xl={6}>
              <S.ScheduleList title="Agenda" maxHeight={"335px"} sx={{ paddingBottom: "0" }}>
                {homeData?.calendar?.length === 0 && <p style={{ color: "#fff" }}>Sem informações</p>}
                {homeData?.calendar?.length > 0 && homeData?.calendar?.map((event, index) => (
                  <Grid key={index} onClick={() => { setEventToShow(event); handleOpenCalendarDetails(event); }}>
                    <TimelineItem
                      color="viuzz"
                      icon={<IoEllipse />}
                      title={event?.title}
                      dateTimeStart={event?.start ? (moment(event?.start).format("D MMM HH:mm").toUpperCase()) : ''}
                      dateTimeEnd={event?.end ? (moment(event?.end).format("D MMM HH:mm").toUpperCase()) : ''}
                      badges={event?.extendedProps?.usersRelated?.map(category => category.name)}
                      location={event?.extendedProps?.place}
                    />
                  </Grid>
                ))
                }
              </S.ScheduleList>
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={7}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Seguidores Gerais
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      (+{homeData?.lastDayChanges?.changes ?? 0})&nbsp;
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        nas últimas 24h
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                    /*                       lineChartData={lineChartDataDashboard}
                                          lineChartOptions={lineChartOptionsDashboard} */
                    />
                  </VuiBox>
                  <Grid container spacing="50px" justifyContent='space-around' sx={{ marginTop: "0" }}>
                    <S.SocialsGrid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          variant="gradient"
                          bgColor="viuzzPag"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "35px", height: "35px" }}
                        >
                          <InstagramIcon color="white" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium" sx={{ alignSelf: "center" }}>
                          Instagram
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        {homeData?.generalInfo?.instagram ? (homeData?.generalInfo?.instagram).toLocaleString("pt-BR") : 0}
                      </VuiTypography>
                      <VuiProgress value={0} color="viuzz" sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
                    </S.SocialsGrid>
                    <S.SocialsGrid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          variant="gradient"
                          bgColor="tiktok"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "35px", height: "35px" }}
                        >
                          <TikTokIcon color="#fff" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium" sx={{ alignSelf: "center" }}>
                          TikTok
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        {homeData?.generalInfo?.tiktok ? (homeData?.generalInfo?.tiktok).toLocaleString("pt-BR") : 0}
                      </VuiTypography>
                      <VuiProgress value={0} color="tiktok" sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
                    </S.SocialsGrid>
                    <S.SocialsGrid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          variant="gradient"
                          bgColor="twitter"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "35px", height: "35px" }}
                        >
                          <TwitterIcon color="white" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium" sx={{ alignSelf: "center" }}>
                          Twitter
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        {homeData?.generalInfo?.twitter ? (homeData?.generalInfo?.twitter).toLocaleString("pt-BR") : 0}
                      </VuiTypography>
                      <VuiProgress value={0} color="twitter" sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
                    </S.SocialsGrid>
                  </Grid>
                </VuiBox>
              </Card>
            </Grid>
            <InfluencersRanking influencerList={homeData?.influencers} rankingType="followers" />
          </Grid>
        </VuiBox>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={12} lg={12}>
            <Projects projects={homeData?.projects ? homeData?.projects?.slice(0, 5) : false} routing='/comercial/negocios' />
          </Grid>
        </Grid>
      </VuiBox>
      <Modal open={openFacebookModal} onClose={handleClose} contentProps={{ paddingBottom: '0', paddingRight: '0', paddingLeft: '0' }}>
        <LinkModalContent closeModal={handleClose} />
      </Modal>
      <Modal open={openEventDetails} onClose={() => { setOpenEventDetails(false); setEventToShow(); }}>
        <EventDetails event={eventToShow} />
      </Modal>
      <Modal open={openPublicationDetails} onClose={() => { setOpenPublicationDetails(false); setEventToShow(); }}>
        <PublicationDetails event={eventToShow} />
      </Modal>
    </DashboardLayout>
  );
}

export default Dashboard;
