import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import Tabs from "@mui/material/Tabs";
import InputAdornment from '@mui/material/InputAdornment';
import { RiArrowLeftLine, RiCalendarEventFill } from 'react-icons/ri';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import * as S from './style'
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import moment from 'moment';
import 'moment/locale/pt-br';
import { MdCloudUpload } from "react-icons/md";
import { useFilePicker } from 'react-sage';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams, useNavigate } from 'react-router-dom';
import { editProjectGeneral } from 'utils/requests/project';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import * as T from "components/Toast/style";
import showToast from 'components/Toast/Toast';
import { TokenContext } from "context/TokenContext";

const BriefingProjectDetails = ({ project, influencerList }) => {
  moment.locale("pt-br")

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const navigate = useNavigate();
  const params = useParams();

  const [tabValue, setTabValue] = React.useState("community");
  const [postAvatar, setPostAvatar] = React.useState()
  const [previewUrl, setPreviewUrl] = React.useState(null);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: project?.title ?? '',
      business: project?.pipeline?.leadsRelated?.businessName ?? '',
      description: project?.description ?? '',
      influencers: project?.influencers ?? [],
      start: project?.startDate ? moment(project?.startDate).format('DD/MM/YYYY HH:mm') : '',
      end: project?.endDate ? moment(project?.endDate).format('DD/MM/YYYY HH:mm') : '',
      currency: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4, 'Título deve conter pelo menos 4 caracteres').required('Nome do projeto é obrigatório'),
      business: Yup.string().min(4, 'Título deve conter pelo menos 4 caracteres'),
      description: Yup.string(),
      influencers: Yup.array(),
      start: Yup.string(),
      end: Yup.string(),
      currency: Yup.string(),
    }),
    onSubmit: async (values) => {
      const startProjectValues = formik.initialValues;
      const projectToEdit = {
        name: values.name !== startProjectValues.name ? values.name : null,
        business: values.business !== startProjectValues.business ? values.business : null,
        description: values.description !== startProjectValues.description ? values.description : null,
        influencers: values.influencers !== startProjectValues.influencers ? values.influencers.map((influencer) => influencer.id) : null,
        startDate: values.start !== startProjectValues.start ? unixDate(values.start) : null,
        endDate: values.end !== startProjectValues.end ? unixDate(values.end) : null,
        currency: values.currency !== startProjectValues.currency ? values.currency : null
      };
      Object.keys(projectToEdit).forEach(key => {
        if (projectToEdit[key] === null) {
          delete projectToEdit[key];
        };
      });
      if (Object.keys(projectToEdit).length > 0) await editProjectGeneral(params?.id, projectToEdit).then((res) => {
        if (res.message) {
          showToast('Falha na edição do evento', 'error');
        };
        if (!res.message) {
          showToast('Evento editado com sucesso!', 'success');
        };
      });
      if (Object.keys(projectToEdit).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr(".startDate", flatpickrOptions('start', formik.setFieldValue));

  flatpickr(".endDate", flatpickrOptions('end', formik.setFieldValue));

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  React.useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const deleteAvatar = () => {
    setPostAvatar(null);
    setPreviewUrl(null);
  };

  const redirects = {
    ADMINISTRATOR: '/marketing/projetos',
    H_MARKETING: '/marketing/projetos',
    MARKETING: '/marketing/projetos'
  };
    
  return (
    <DashboardLayout>
      <DashboardNavbar customTitle={project?.title} />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container spacing={3}>
        <Grid item md={3} xl={3}>
          <S.ProjectCard sx={{ width: "100%" }}>
            <S.ReturnPageGrid container gap='10px' onClick={() => navigate(redirects[role])}>
              <RiArrowLeftLine size='20px' style={{ alignSelf: "center" }} />
              <p>Voltar</p>
            </S.ReturnPageGrid>
            <S.TabsGrid>
              <Tabs
                orientation='vertical'
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                TabIndicatorProps={{ style: { borderRadius: "5px 5px 15px 5px", backgroundColor: "rgba(239, 241, 246, 0.10)", backdropFilter: "blur(15px)" } }}
              >
                <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Geral" value="community" />
              </Tabs>
            </S.TabsGrid>
          </S.ProjectCard>
        </Grid>
        <Grid item md={9} xl={9}>
          <S.ProjectCard>
            <Grid container justifyContent='space-between'>
              <S.FormTitleGrid flexDirection='column' item md={9}>
                <p>Informações gerais</p>
                <p className='subTitle'>Gerenciar informações básicas do projeto</p>
              </S.FormTitleGrid>
              <Grid display='flex' flexDirection='column' alignItems='flex-end' item md={3}>
                <S.BadgeGrid item>
                  <p>Briefing</p>
                </S.BadgeGrid>
              </Grid>
            </Grid>
            <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
              <Form>
                <S.FormGrid container flexDirection='column' gap='30px'>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Foto de Capa</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <S.ImageUploadContainer container onClick={imageInput.onClick}>
                        {!postAvatar &&
                          <S.ImageUploadGrid container justifyContent='center' flexDirection='column' alignItems='center'>
                            <MdCloudUpload color='white' size='60px' />
                            <p>Enviar vídeo ou Imagem</p>
                            <p>Tipos de arquivos compatíveis: .jpg, .jpeg, .png</p>
                            <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png, .mp4" />
                          </S.ImageUploadGrid>
                        }
                        {postAvatar &&
                          <>
                            <img src={previewUrl} className='previewImg' alt="Preview da imagem" />
                            <S.DeleteFab size="small" onClick={() => deleteAvatar()}>
                              <DeleteIcon />
                            </S.DeleteFab>
                          </>
                        }
                      </S.ImageUploadContainer>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Nome do Projeto</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <S.InputGrid>
                        <S.Input
                          name='name'
                          id='name'
                          placeholder='Nome do Projeto'
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={Boolean(formik.errors.name)}
                          helperText={formik.errors.name}
                        />
                      </S.InputGrid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Nome da Empresa</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <S.InputGrid>
                        <S.Input
                          name='business'
                          id='business'
                          placeholder='Nome da Empresa'
                          onChange={formik.handleChange}
                          value={formik.values.business}
                          error={Boolean(formik.errors.business)}
                          helperText={formik.errors.business}
                        />
                      </S.InputGrid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Descrição</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <S.InputGrid>
                        <S.Input
                          placeholder='Descrição do projeto'
                          name='description'
                          id='description'
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                      </S.InputGrid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Influencers</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <S.InputGrid>
                        <Autocomplete
                          disablePortal
                          disableCloseOnSelect
                          multiple
                          id="influencers"
                          name="influencers"
                          value={formik.values.influencers ? formik.values.influencers : []}
                          onChange={(e, value) => {
                            formik.setFieldValue('influencers', value)
                          }}
                          options={influencerList ? influencerList[0] : []}
                          sx={{ width: "100%" }}
                          renderInput={(params) => <S.Input {...params} placeholder='Selecione os influencers que terão LUCRO com o projeto' />}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                        />
                      </S.InputGrid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Datas</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <Grid container justifyContent='space-between'>
                        <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='startDate'>
                          <S.Input
                            hiddenLabel
                            type='text'
                            placeholder="Data Inicial"
                            fullWidth
                            value={formik.values.start}
                            data-input
                            autoComplete='off'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <RiCalendarEventFill />
                                </InputAdornment>
                              )
                            }}
                          />
                        </S.DateInputGrid>
                        <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='endDate'>
                          <S.Input
                            hiddenLabel
                            type='text'
                            placeholder="Data Final"
                            fullWidth
                            value={formik.values.end}
                            data-input
                            autoComplete='off'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <RiCalendarEventFill />
                                </InputAdornment>
                              )
                            }}
                          />
                        </S.DateInputGrid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2.5} xl={2.5}>
                      <p>Moeda Corrente</p>
                    </Grid>
                    <Grid item md={9} xl={9}>
                      <S.InputGrid>
                        <S.Input
                          placeholder='BRL'
                          name='currency'
                          id='currency'
                          value={formik.values.currency}
                          onChange={formik.handleChange}
                        />
                      </S.InputGrid>
                    </Grid>
                  </Grid>
                </S.FormGrid>
                <Grid container justifyContent='flex-end'>
                  <ButtonCustom label='Salvar Alterações' sx={{ width: "230px", fontSize: "18px", fontWeight: "500" }} type='submit' />
                </Grid>
              </Form>
            </Formik>
          </S.ProjectCard>
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default BriefingProjectDetails