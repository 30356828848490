  

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { RiCalendarLine, RiMapPin2Fill } from "react-icons/ri";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";

// Timeline context
import { useTimeline } from "defaultComponents/Timeline/context";

// Custom styles for the TimelineItem
import { timelineItem } from "defaultComponents/Timeline/TimelineItem/styles";
import { Grid } from "@mui/material";

function TimelineItem({ color, icon, title, dateTimeStart, dateTimeEnd, description, badges, lastItem, location }) {
  const isDark = useTimeline();

  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
        const badgeKey = `badge-${key}`;

        return (
          <VuiBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
            <VuiBadge color={color} size="xs" badgeContent={badge} container />
          </VuiBox>
        );
      })
      : null;

  return (
    <VuiBox position="relative" mb="10px" sx={(theme) => timelineItem(theme, { color })}>
      <VuiBox
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="-9px"
        zIndex={2}
        color="#E22B00"
      >
        {icon}
      </VuiBox>
      <VuiBox ml="30px" pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <VuiTypography variant="button" fontWeight="medium" color="white">
          {title}
        </VuiTypography>
        <VuiBox mt={0.5} container="true" sx={{ gap: "50px", display: "flex" }}>
          <Grid display='flex' gap='5px'>
            <RiCalendarLine color="#a0aec0" size='14px' />
            <VuiTypography variant="caption" fontWeight="medium" color="text">
              {dateTimeStart}{dateTimeEnd ? ` - ${dateTimeEnd}` : ''}
            </VuiTypography>
          </Grid>
          {location &&
          <Grid display='flex' gap='5px'>
            <RiMapPin2Fill color="#a0aec0" size='14px' />
            <VuiTypography variant="caption" fontWeight="medium" color="text">
              {location}
            </VuiTypography>
          </Grid>
          }
        </VuiBox>
        <VuiBox mt={2} mb={1.5}>
          {description ? (
            <VuiTypography variant="button" fontWeight="regular" color="text">
              {description}
            </VuiTypography>
          ) : null}
        </VuiBox>
        {badges.length > 0 ? (
          <>
            <VuiBox display="flex" pb={lastItem ? 1 : 2}>
              {renderBadges}
              <VuiBox sx={() => ({
                minWidth: "2px",
                height: "70px",
                position: "absolute",
                top: "34px",
                left: "0",
                opacity: "1",
                background: "#E22B00",
              })}>
              </VuiBox>
            </VuiBox>
          </>

        ) : null}
      </VuiBox>
    </VuiBox >
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "viuzz",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTimeStart: PropTypes.string.isRequired,
  dateTimeEnd: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
};

export default TimelineItem;
