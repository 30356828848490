import React from 'react'
import { Grid } from '@mui/material'
import * as S from "./style"
import InputAdornment from '@mui/material/InputAdornment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailIcon from '@mui/icons-material/Mail';
import * as Yup from "yup";
import { useFormik } from "formik";
import Viraliza from '../../assets/viraliza.svg'
import Viuzz from '../../assets/viuzz.svg'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { forgottenPasswordChange } from 'utils/requests/auth';
import showToast from 'components/Toast/Toast';
import * as T from "components/Toast/style"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ForgottenPasswordChange = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [showPassword, setShowPassword] = React.useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Por favor, insira sua senha").min(6, 'Senha precisa ter pelo menos 6 dígitos'),
    }),
    onSubmit: async (values) => {
      await forgottenPasswordChange(params?.token, { newPassword: values.password}).then((res) => {
        if (!res.message) {
          showToast("Senha alterada com sucesso!", "success");
          setTimeout(() => navigate('/login'), 3000);
          return
        }
        showToast("Houve um erro na alteração de senha", "error");
      });
    }
  })

  return (
    <>
      <S.MainContainer container justifyContent='center'>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <S.BgGrid container>
          <Grid container flexDirection='column' justifyContent='space-evenly'>
            <img src={Viraliza} />
            <form
              onSubmit={validation.handleSubmit}
            >
              <Grid container flexDirection='column' sx={{ marginBottom: "100px" }}>
                <S.BoxGrid container flexDirection='column'>
                  <hr className='gradientBar'></hr>
                </S.BoxGrid>
                <S.InputGrid container flexDirection='column'>
                  <S.Input
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    hiddenLabel
                    placeholder="Nova Senha"
                    error={Boolean(validation.errors.password)}
                    helperText={validation.errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <LockOpenIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          {
                          showPassword 
                          ? 
                          <VisibilityIcon onClick={() => setShowPassword(false)} sx={{ cursor: 'pointer' }} /> 
                          : 
                          <VisibilityOffIcon onClick={() => setShowPassword(true)} sx={{ cursor: 'pointer' }} />
                          }
                        </InputAdornment>
                      )
                    }}
                  />
                </S.InputGrid>
              </Grid>
              <S.LoginButton
                type='submit'
                disabled={(validation.errors.password || !validation.values.password)}
              >
                Cadastrar Senha
              </S.LoginButton>
            </form>
            <img src={Viuzz} />
          </Grid>
          {/* <img src={img}></img> */}
        </S.BgGrid>
      </S.MainContainer>
    </>
  )
}

export default ForgottenPasswordChange