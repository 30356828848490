import React, { Component } from "react";
import Chart from "react-apexcharts";

class MixedChart extends Component {
  constructor(props) {
    super(props);
    this.state = {

      series: [
        {
          name: "Organic Search",
          type: "bar",
          data: [440, 505, 414, 671, 160, 550, 350, 138],
        },
        {
          name: "Referral",
          type: "line",
          data: [232, 421, 352, 273, 143, 222, 173, 311],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false
          },
        },
        tooltip: {
          theme: "dark",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          labels: {
            style: {
              colors: "#A0AEC0",
              fontSize: "10px",
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#A0AEC0",
              fontSize: "10px",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          borderColor: "#56577A",
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          type: "solid",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 0.8,
            opacityTo: 0.5,
            stops: [],
          },
          colors: ["#FC6009", "#83848A"],
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            columnWidth: "8px",
          },
        },
        colors: ["#FC6009", "#83848A"],
      },
    };
  }

  /*   componentDidMount() {
      const { mixedChartData, mixedChartOptions } = this.props;
  
      this.setState({
        chartData: mixedChartData,
        chartOptions: mixedChartOptions,
      });
    } */

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="line"
        width="100%"
        height="100%"
      />
    );
  }
}

export default MixedChart;
