import { Grid } from "@mui/material";
import styled from "styled-components";

export const NotificationGrid = styled(Grid)`
  overflow: auto;
  max-height: 250px;
  padding-bottom: 20px;

  .emptyMessage {
    font-size: 18px;
  }

  span:first-of-type {
    color: #fff;
  }
`