import styled from 'styled-components'
import { Grid, TextField } from '@mui/material'

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
  }
`

export const AvatarGrid = styled(Grid)`
  img {
    width: 112px;
    height: 112px;
    border-radius: 60px;
    margin-bottom: 5px;
  }
`

export const InfluencerPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: 100px;
    height: 140px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
      border-radius: 10px;
    }
  }
`

export const IndicatorGrid = styled(Grid)`
  justify-content: flex-end;
  display: flex;
  margin-top: -35px;
`

export const PhotoIndicator = styled.div`
  color: white;
  width: 35px;
  height: 35px;
  font-size: 18px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 50%;
  text-align: center;
  align-content: center;
  cursor: pointer;
  z-index: 1;

  svg {
    margin-top: 6px;
  }
`