import React from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Card from "@mui/material/Card";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import Modal from "components/Modal/Modal";
import moment from "moment";
import "moment/locale/pt-br";
import * as S from "./style";
import AddEvent from "./ModalContent/AddEvent";
import EditEvent from "./ModalContent/EditEvent";
import { getEvents, deleteEvent, getCalendarCategories } from "utils/requests/calendar";
import * as T from "components/Toast/style";
import showToast from "components/Toast/Toast";
import { useDebounce } from "use-debounce";
import { TokenContext } from "context/TokenContext";
import EventDetails from "./ModalContent/EventDetails";
import { getColors } from "utils/eventTagColors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddPublication from "./ModalContent/AddPublication";
import EditPublication from "./ModalContent/EditPublication";
import PublicationDetails from "./ModalContent/PublicationDetails";
import { deletePublication } from "utils/requests/calendar";

const Calendar = () => {
  moment.locale("pt-br");

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [checked, setChecked] = React.useState(true);
  const [eventToEdit, setEventToEdit] = React.useState();
  const [openAddEventModal, setOpenAddEventModal] = React.useState(false);
  const [openAddPublicationModal, setOpenAddPublicationModal] = React.useState(false);
  const [openEditEventModal, setOpenEditEventModal] = React.useState(false);
  const [openEditPublicationModal, setOpenEditPublicationModal] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState([]);
  const [filters, setFilters] = React.useState({ categories: null });
  const [openEventDetails, setOpenEventDetails] = React.useState(false);
  const [openPublicationDetails, setOpenPublicationDetails] = React.useState(false);
  const [categoryBooleans, setCategoryBooleans] = React.useState([]);
  const [categoryEntries, setCategoryEntries] = React.useState([]);
  const [categoriesByRole, setCategoriesByRole] = React.useState([]);

  const [debouncedFilters] = useDebounce(filters, 1000);

  const fetchCategories = async () => {
    const data = await getCalendarCategories();
    if (!data.message) {
      setCategories(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr);
      setCategoryEntries(Object.entries(data).map((entry) => entry[0]));
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  const handleRoleCategories = () => {
    const roleCategories = categoryEntries.map((entry) => categories[entry]);
    setCategoriesByRole(roleCategories);
    const checkboxBooleans = roleCategories.map((category) =>
      Array.from({ length: category?.length }, () => true)
    );
    setCategoryBooleans(checkboxBooleans);
  };

  React.useEffect(() => {
    handleRoleCategories();
  }, [categoryEntries]);

  const handleChange = (bool) => {
    setChecked(bool);
    const roleCategories = categoryEntries.map((entry) => categories[entry]);
    const checkboxBooleans = roleCategories.map((category) =>
      Array.from({ length: category?.length }, () => bool)
    );
    setCategoryBooleans(checkboxBooleans);
  };

  const calendarRef = React.useRef();

  const colors = getColors();

  const addColors = (event) => {
    if (event?.extendedProps && event?.extendedProps?.categories?.length > 0) {
      const entries = Object.entries(categories).map((entry) => entry[0]);
      const filterCategories = entries.map((entry) => categories[entry]);
      const index = filterCategories?.findIndex((userGroup) =>
        userGroup?.some((cat) => cat.name === event?.extendedProps?.categories[0]?.name)
      );
      return {
        ...event,
        backgroundColor: `rgba(${colors[index]}, 0.25)`,
        textColor: `rgb(${colors[index]})`,
        color: `rgb(${colors[index]})`,
      };
    }
    return event;
  };

  const getCheckboxColor = (name) => {
    const entries = Object.entries(categories).map((entry) => entry[0]);
    const filterCategories = entries.map((entry) => categories[entry]);
    const index = filterCategories?.findIndex((userGroup) =>
      userGroup?.some((cat) => cat.name === name)
    );
    return colors[index];
  };

  const fetchEvents = async () => {
    const data = await getEvents(checked ? null : filters);
    if (!data.message) {
      const updatedEvents = data.map((event) => addColors(event));
      setEvents(updatedEvents);
    }
  };

  React.useEffect(() => {
    if (categoryEntries.length > 0) {
      if (!checked && !categoryBooleans?.flat().includes(true)) {
        setEvents([]);
      } else {
        fetchEvents();
      }
    }
  }, [debouncedFilters, categories]);

  const deleteEventById = async (id) => {
    await deleteEvent(id).then((res) => {
      if (!res.message) showToast("Evento deletado com sucesso!", "success");
      setOpenConfirmDeletionModal(false);
      setOpenEditEventModal(false);
      fetchEvents();
      if (res.message) showToast("Falha ao deletar evento", "error");
    });
  };

  const deletePublicationById = async (id) => {
    await deletePublication(id).then((res) => {
      if (!res.message) showToast("Publi deletada com sucesso!", "success");
      setOpenConfirmDeletionModal(false);
      setOpenEditPublicationModal(false);
      fetchEvents();
      if (res.message) showToast("Falha ao deletar publi", "error");
    });
  };

  const closeEditModal = () => {
    setOpenEditEventModal(false);
    setOpenEditPublicationModal(false);
    setEventToEdit();
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const modalByRole = (event) => {
    if (event?.event?.extendedProps?.type === "EVENT") {
      if (decodedToken?.type !== "INFLUENCER") {
        setEventToEdit(event.event);
        setOpenEditEventModal(true);
      } else {
        setEventToEdit(event.event);
        setOpenEventDetails(true);
      }
    }
    if (event?.event?.extendedProps?.type === "PUBLICATION") {
      if (decodedToken?.type !== "INFLUENCER") {
        setEventToEdit(event.event);
        setOpenEditPublicationModal(true);
      } else {
        setEventToEdit(event.event);
        setOpenPublicationDetails(true);
      }
    }
  };

  const roleTranslation = {
    ADMINISTRATOR: "Administrador",
    INFLUENCER: "Influencer",
    H_COMERCIAL: "Head Comercial",
    COMERCIAL: "Comercial",
    H_MARKETING: "Head Marketing",
    MARKETING: "Marketing",
    TESTER: "Tester",
  };

  const handleCategoriesOnChange = (position, index) => {
    const updatedCheckedState = categoryBooleans[index]?.map((item, index) =>
      index === position ? !item : item
    );
    const arrayCopy = [...categoryBooleans];
    arrayCopy[index] = updatedCheckedState;
    setCategoryBooleans(arrayCopy);
    if (arrayCopy.flat().includes(true)) {
      setChecked(false);
    }
    if (arrayCopy.flat().every((el) => el === true)) {
      setChecked(true);
    }
  };

  const newCategoryFilter = () => {
    const checkedIndexes = categoryBooleans
      ?.flat()
      .map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const flatCategories = categoriesByRole.flat();
    const result = checkedIndexes?.map((index) => flatCategories[index].username);
    result.length > 0
      ? setFilters((prevData) => ({ ...prevData, categories: result }))
      : setFilters((prevData) => ({ ...prevData, categories: null }));
  };

  React.useEffect(() => {
    newCategoryFilter();
  }, [categoryBooleans]);

  return (
    <DashboardLayout>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar />
      <Card sx={{ maxHeight: "850px", overflow: "auto" }}>
        <S.MainContainer container flexDirection="row">
          <S.SidePanelGrid
            container
            item
            lg={3}
            xl={2.5}
            alignItems="flex-start"
            alignSelf="flex-start"
            gap="20px"
          >
            <Grid container flexDirection="column" gap="20px">
              <ButtonCustom
                label=" + Criar Evento"
                sx={{ fontSize: "14px", height: "35px !important" }}
                onClick={() => setOpenAddEventModal(true)}
              />
              {(role === "ADMINISTRATOR" || role === "INFLUENCER" || role === "H_MARKETING") && (
                <ButtonCustom
                  label=" + Criar Publi"
                  sx={{ fontSize: "14px", height: "35px !important" }}
                  onClick={() => setOpenAddPublicationModal(true)}
                />
              )}
            </Grid>
            <p style={{ fontSize: "16px" }}>CALENDÁRIOS</p>
            <Grid container gap="20px" sx={{ maxHeight: "650px", overflow: "auto" }}>
              <S.CheckboxGrid container onClick={() => handleChange(!checked)}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={checked}
                  sx={{
                    ".MuiSvgIcon-root": {
                      fill: `rgb(255, 255, 255) !important`,
                    },
                  }}
                />
                <p style={{ fontSize: "16px" }}>Todos</p>
              </S.CheckboxGrid>
              <Grid container>
                {categoryEntries?.map((entry, index) => (
                  <S.CheckboxAccordion sx={{ backgroundColor: "#000" }} key={index}>
                    <S.CheckboxAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ color: "#fff" }}
                    >
                      {roleTranslation[entry]}
                    </S.CheckboxAccordionSummary>
                    <S.CheckboxAccordionDetails sx={{ color: "#fff" }}>
                      {categories[entry]?.map((checkbox, checkIdx) => (
                        <S.CheckboxGrid
                          container
                          key={checkIdx}
                          onClick={() => handleCategoriesOnChange(checkIdx, index)}
                        >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              categoryBooleans && categoryBooleans?.length > 0
                                ? categoryBooleans[index][checkIdx]
                                : false
                            }
                            sx={{
                              ".MuiSvgIcon-root": {
                                fill: `rgb(${getCheckboxColor(checkbox?.name)}) !important`,
                              },
                            }}
                          />
                          <p style={{ fontSize: "16px" }}>{checkbox?.name}</p>
                        </S.CheckboxGrid>
                      ))}
                    </S.CheckboxAccordionDetails>
                  </S.CheckboxAccordion>
                ))}
              </Grid>
            </Grid>
          </S.SidePanelGrid>
          <S.CalendarGrid container item sm={12} md={12} lg={9} xl={9.5}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                start: "prev next title", // will normally be on the left. if RTL, will be on the right
                end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek", // will normally be on the right. if RTL, will be on the left
              }}
              events={events}
              locale={ptBrLocale}
              ref={calendarRef}
              displayEventTime={false}
              allDaySlot={false}
              dayMaxEventRows={4}
              eventClick={(eventInfo) => modalByRole(eventInfo)}
              eventBackgroundColor="rgba(252, 96, 9, 0.25)"
              eventTextColor="rgb(252, 96, 9)"
              eventColor="rgb(252, 96, 9)"
            />
          </S.CalendarGrid>
        </S.MainContainer>
      </Card>
      <Modal open={openAddEventModal} onClose={() => setOpenAddEventModal(false)}>
        <AddEvent
          refetch={fetchEvents}
          closeModal={() => setOpenAddEventModal(false)}
          token={decodedToken}
        />
      </Modal>
      <Modal
        open={openEditEventModal}
        onClose={() => closeEditModal()}
        deleteFunc={() => setOpenConfirmDeletionModal(true)}
      >
        <EditEvent
          event={eventToEdit ? eventToEdit : null}
          refetch={fetchEvents}
          closeModal={closeEditModal}
        />
      </Modal>
      <Modal open={openAddPublicationModal} onClose={() => setOpenAddPublicationModal(false)}>
        <AddPublication
          refetch={fetchEvents}
          closeModal={() => setOpenAddPublicationModal(false)}
        />
      </Modal>
      <Modal
        open={openEditPublicationModal}
        onClose={() => closeEditModal()}
        deleteFunc={() => setOpenConfirmDeletionModal(true)}
      >
        <EditPublication
          event={eventToEdit ? eventToEdit : null}
          refetch={fetchEvents}
          closeModal={closeEditModal}
        />
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir essa entrada? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => setOpenConfirmDeletionModal(false)}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => {
                  eventToEdit?.extendedProps?.type === "EVENT"
                    ? deleteEventById(eventToEdit?.id)
                    : deletePublicationById(eventToEdit?.id);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openEventDetails}
        onClose={() => {
          setOpenEventDetails(false);
          setEventToEdit();
        }}
      >
        <EventDetails event={eventToEdit ? eventToEdit : null} />
      </Modal>
      <Modal
        open={openPublicationDetails}
        onClose={() => {
          setOpenPublicationDetails(false);
          setEventToEdit();
        }}
      >
        <PublicationDetails event={eventToEdit ? eventToEdit : null} />
      </Modal>
    </DashboardLayout>
  );
};

export default Calendar;
