import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const MaskMoney = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

MaskMoney.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

MaskMoney.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  }),
}

export default MaskMoney

/* // Core
import React from 'react';

// Others
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const MaskMoney = ({ inputRef, placeholder, ...other }) => (
  <MaskedInput
    {...other}
    ref={ref => inputRef(ref ? ref.inputElement : null)}
    placeholder={placeholder}
    mask={createNumberMask({
      prefix: 'R$ ',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: '.',
      allowDecimal: true,
      decimalSymbol: ',',
      decimalLimit: 2, // how many digits allowed after the decimal
      integerLimit: 9, // limit length of integer numbers
      allowNegative: false,
      allowLeadingZeroes: false,
    })}
  />
);

MaskMoney.defaultProps = {
  placeholder: 'Valor (R$)',
};

MaskMoney.propTypes = {
  inputRef: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default MaskMoney; */
