import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import VuiAvatar from "components/VuiAvatar";

const AvatarGroup = ({ members }) => {
  return members.map(([image, name]) => (
    <Grid container>
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="sm"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",
            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    </Grid>
  ));
};

export default AvatarGroup;
