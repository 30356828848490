import React from "react";
import { Grid } from "@mui/material";
import * as S from "../style";
import InputAdornment from "@mui/material/InputAdornment";
import { RiCalendarEventFill } from "react-icons/ri";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import moment from "moment";
import "moment/locale/pt-br";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { editEvent, getCalendarCategories } from "utils/requests/calendar";
import showToast from "components/Toast/Toast";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from "utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { getProjectsFromLeads } from "utils/requests/project";
import { getAllClients } from "utils/requests/leads";
import { getAllInfluencers } from "utils/requests/auth";
import { editPublication } from "utils/requests/calendar";

const EditPublication = ({ event, refetch, closeModal }) => {
  moment.locale("pt-br");

  const [influencers, setInfluencers] = React.useState([]);
  const [projects, setProjects] = React.useState();
  const [businesses, setBusinesses] = React.useState();

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: event?.title,
      start: event?.start ? moment(event?.start).format("DD/MM/YYYY HH:mm") : "",
      end: event?.end ? moment(event?.end).format("DD/MM/YYYY HH:mm") : "",
      extendedProps: {
        socialMedia: event?.extendedProps?.socialMedia ?? [],
        format: event?.extendedProps?.format ?? "",
        categories: event?.extendedProps?.usersRelated ?? [],
        leadsRelated: event?.extendedProps?.leadsRelated ?? [],
        products: event?.extendedProps?.products ?? "",
        coupons: event?.extendedProps?.coupons ?? "",
        tagsAndHashtags: event?.extendedProps?.tagsAndHashtags ?? "",
        description: event?.extendedProps?.description ?? "",
      },
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(4, "Título deve conter pelo menos 4 caracteres")
        .required("Título é obrigatório"),
      start: Yup.string()
        .required("Data Inicial é obrigatório")
        .required("Data de Postagem é obrigatório"),
      end: Yup.string(),
      extendedProps: Yup.object().shape({
        socialMedia: Yup.array(),
        format: Yup.string().required("Formato é obrigatório"),
        userRelated: Yup.array(),
        leadsRelated: Yup.array(),
        products: Yup.string(),
        coupons: Yup.string(),
        tagsAndHashtags: Yup.string(),
        description: Yup.string(),
      }),
    }),
    onSubmit: async (values) => {
      const edit = {
        title: values.title !== formik.initialValues.title ? values.title : null,
        socialMedia:
          values.extendedProps.socialMedia !== formik.initialValues.extendedProps.socialMedia
            ? values.extendedProps.socialMedia
            : null,
        postDateMs: values.start !== formik.initialValues.start ? unixDate(values.start) : null,
        format:
          values.extendedProps.format !== formik.initialValues.extendedProps.format
            ? values.extendedProps.format
            : null,
        usersRelated:
          values.extendedProps.categories !== formik.initialValues.extendedProps.categories
            ? values.extendedProps.categories.map((category) => category.id)
            : null,
        leadsRelated:
          values.extendedProps.leadsRelated !== formik.initialValues.extendedProps.leadsRelated
            ? values.extendedProps.leadsRelated.map((lead) => lead.id)
            : null,
        products:
          values.extendedProps.products !== formik.initialValues.extendedProps.products
            ? values.extendedProps.products
            : null,
        coupons:
          values.extendedProps.coupons !== formik.initialValues.extendedProps.coupons
            ? values.extendedProps.coupons
            : null,
        description:
          values.extendedProps.description !== formik.initialValues.extendedProps.description
            ? values.extendedProps.description
            : null,
        tagsAndHashtags:
          values.extendedProps.tagsAndHashtags !==
          formik.initialValues.extendedProps.tagsAndHashtags
            ? values.extendedProps.tagsAndHashtags
            : null,
      };

      Object.keys(edit).forEach((key) => {
        if (edit[key] === null) {
          delete edit[key];
        }
      });

      if (Object.keys(edit).length > 0)
        await editPublication(event?.id, edit).then((res) => {
          if (res.message) {
            showToast("Falha na edição de publicação", "error");
          }
          if (!res.message) {
            showToast("Publicação editada com sucesso!", "success");
            refetch();
            closeModal();
          }
        });
      if (Object.keys(edit).length === 0) showToast("Sem dados alterados", "warning");
    },
  });

  const fetchInfluencers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setInfluencers(data[0]);
    }
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    }
  };

  React.useEffect(() => {
    fetchInfluencers();
    fetchBusinesses();
  }, []);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY HH:mm").valueOf());
  };

  flatpickr("#start", flatpickrOptions("start", formik.setFieldValue, formik.setFieldTouched));
  flatpickr("#end", flatpickrOptions("end", formik.setFieldValue, formik.setFieldTouched));

  const socials = ["Instagram", "Facebook", "Twitter", "Youtube", "Spotify", "TikTok"];

  return (
    <>
      <p>Editar Publi</p>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.handleSubmit}
      >
        <Form>
          <Grid container flexDirection="column" gap="20px" marginTop="30px" padding="15px">
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="title"
                  id="title"
                  type="text"
                  placeholder="Título"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  error={Boolean(formik.errors.title)}
                  helperText={formik.errors.title}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.socialMedia"
                  name="extendedProps.socialMedia"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.socialMedia
                      ? formik.values.extendedProps.socialMedia
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.socialMedia", value);
                  }}
                  options={socials}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Redes Sociais" />}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id="start">
                <S.Input
                  name="start"
                  hiddenLabel
                  type="text"
                  placeholder="Data de Postagem"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={formik.values.start}
                  error={Boolean(formik.errors.start)}
                  helperText={formik.errors.start}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCalendarEventFill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => formik.setFieldValue("start", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.format"
                  id="extendedProps.format"
                  hiddenLabel
                  type="text"
                  placeholder="Formato"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.format}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.categories"
                  name="extendedProps.categories"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.categories
                      ? formik.values.extendedProps.categories
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.categories", value);
                  }}
                  options={influencers ? influencers.flat() : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Influencers" />}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.name}
                    </li>
                  )}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.leadsRelated"
                  name="extendedProps.leadsRelated"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.leadsRelated
                      ? formik.values.extendedProps.leadsRelated
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.leadsRelated", value);
                  }}
                  options={businesses && businesses[0] ? businesses[0] : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Empresa" />}
                  getOptionLabel={(option) => option.businessName}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.businessName}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.products"
                  id="extendedProps.products"
                  hiddenLabel
                  type="text"
                  placeholder="Produtos"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.products}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.coupons"
                  id="extendedProps.coupons"
                  hiddenLabel
                  type="text"
                  placeholder="Cupom de Compra"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.coupons}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  name="extendedProps.tagsAndHashtags"
                  id="extendedProps.tagsAndHashtags"
                  hiddenLabel
                  type="text"
                  placeholder="Marcações e Hashtags"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.tagsAndHashtags}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  name="extendedProps.description"
                  id="extendedProps.description"
                  hiddenLabel
                  type="text"
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.description}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <ButtonCustom
                label="EDITAR PUBLI"
                sx={{ width: "180px", fontSize: "18px", fontWeight: "500" }}
                type="submit"
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default EditPublication;
