import styled from 'styled-components'
import { Button, Grid } from '@mui/material'

export const InfoBlock = styled(Grid)`
  background-color: #2A0B03;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
  color: #fff;
  min-width: 100px;

  .subTitle {
    opacity: 0.5;
    font-size: 12px;
  }
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
  position: absolute !important;
  right: 10px;
`

export const PieChartGrid = styled(Grid)`
  min-height: 240px;
  margin-top: 30px !important;
  .apexcharts-legend-text{
    color: rgba(255, 255, 255, 0.75) !important;
  }
  .apexcharts-legend-marker{
    border-radius: 3px !important;
  }
`

export const AgeChartGrid = styled(Grid)`
  height: 100%;
  margin-top: 30px;
`

export const BlocksGrid = styled(Grid)`
  margin-top: 20px !important;
  gap: 20px;
  justify-content: flex-end;

  @media (max-width: 992px) {
    justify-content: center;
  }
`

export const PostsBlock = styled(InfoBlock)`
  min-width: 120px;
`

export const TableGrid = styled(Grid)`
  p {
    color: #fff;
  }
`
