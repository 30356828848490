import React from 'react'
import * as S from './style'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card } from '@mui/material';

const LoadingScreen = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <S.Background container>
        <S.Loader />
      </S.Background>
    </DashboardLayout>
  )
}

export default LoadingScreen