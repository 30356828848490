import { Portuguese } from "flatpickr/dist/l10n/pt"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"
import moment from 'moment';
import 'moment/locale/pt-br';

export const flatpickrOptions = (name, setFormValue, setTouched, noTime) => {
  moment.locale("pt-br")

  return {
    onClose: (selectedDates, dateStr) => {
      if (dateStr) {
        const dateParse = noTime ? 'DD/MM/YYYY' : "DD/MM/YYYY HH:mm"
        if(setTouched) setTouched(name);
        setFormValue(name, moment(dateStr, "YYYY-MM-DD HH:mm").format(dateParse));
      } 
    },
    mode: "single",
    maxDate: new Date().fp_incr(365),
    locale: Portuguese,
    enableTime: !noTime,
    plugins: [new confirmDatePlugin({})],
    wrap: true,
    disableMobile: true
  };
};

export const flatpickrOptionsInfluencer = (name, setFormValue, setTouched, touched) => {
  moment.locale("pt-br")

  return {
    onClose: (selectedDates, dateStr) => {
      if (dateStr) {
        if(setTouched) setTouched(name);
        setFormValue(name, moment(dateStr, "YYYY-MM-DD").format("DD/MM/YYYY"));
      } 
    },
    mode: "single",
    maxDate: new Date().fp_incr(365),
    locale: Portuguese,
    wrap: true,
    disableMobile: true
  };
};

export const capitalize = (str) => {
  const newString = str.charAt(0).toUpperCase() + str.slice(1)
  return newString;
};

export const abbreviateNumbers = (number, decimals) => {
  if (number >= 1000000) {
      return (number / 1000000).toFixed(decimals ? decimals : 1) + 'M';
  } else if (number >= 1000) {
      return (number / 1000).toFixed(decimals ? decimals : 1) + 'K';
  } else {
      return number;
  };
};

export const abbreviateName = (name) => {
  const splittedName = name.split(' ');
  const cleanedName = `${splittedName[0]} ${splittedName[1]}`;
  return cleanedName
};

export const branches = [
  'Agência',
  'Alimentícias',
  'Aplicativos',
  'AudioVisual',
  'Automotivo',
  'Bebidas',
  'Calçados',
  'Cosméticos',
  'Entretenimento',
  'Farmacias',
  'Instituição de Ensino',
  'Instituição Financeira',
  'Limpeza',
  'Outros',
  'Politicos',
  'Saúde',
  'Supermercado',
  'Time',
  'Transporte'
];

export const curves = ['A', 'B', 'C'];

export const unixDate = (date) => {
  return Number(moment(date, 'YYYY/MM/DD HH:mm').valueOf());
};

export const ensureIntValue = (value) => {
  if((value) % 1 != 0) {
    return Math.round(value)
  }
  return parseInt(value)
};

export const sanitizeCurrencyValue = (val) => {
  const cleanValue = Number(val?.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, "."));
  return ensureIntValue(cleanValue * 100);
};