

import { useState } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import * as S from './style'
import arrowRightUp from 'assets/icons/arrow-right-up.svg'
import { capitalize } from "utils/utils";
import { RiEyeFill } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { Grid } from "@mui/material";
import { IoEllipsisVertical } from "react-icons/io5";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Modal from "components/Modal/Modal";
import showToast from "components/Toast/Toast";
import moment from 'moment';
import 'moment/locale/pt-br';

const RejectionsTable = ({ projects, refetch }) => {
  moment.locale("pt-br");
  const [openMenu, setOpenMenu] = useState([]);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [entryDetails, setEntryDetails] = useState();

  const avatars = (members) => {
    return (
      members?.map(([image, name]) => (
        <Tooltip key={name} title={name} placeholder="bottom">
          <VuiAvatar
            src={image}
            alt="name"
            size="sm"
            sx={{
              border: ({ borders: { borderWidth }, palette: { dark } }) =>
                `${borderWidth[2]} solid ${dark.focus}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: -1.25,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            }}
          />
        </Tooltip>
      ))
    );
  };

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDetailsModal = (project) => {
    setOpenDetailsModal(true);
    setSelectedProject(project);
  };

  const closeDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedProject();
  };

  const statusTranslation = {
    LEADS: 'Leads 🔥',
    RELATIONSHIP: 'Relacionamento 🎯',
    FOLLOW_UP: 'FUP - Leads 🆙',
    NEGOTIATION: 'Em Negociação 💰',
    CLOSED_CONTRACT: 'Contrato Fechado 🤝',
    REJECTED: 'Rejeitado',
    ONGOING: 'Em andamento',
    ENDED: 'Finalizado'
  };

  return (
    <Card
      sx={{
        height: "100% !important",
        paddingBottom: "40px"
      }}
    >
      <VuiBox
        sx={{
          paddingBottom: '10px',
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
        onClick={() => openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null}
      >
        <Table
          columns={[
            { name: "empresa", align: "left" },
            { name: "influencers", align: "left" },
            { name: "vendedor", align: "left" },
            { name: "projeto", align: "center" },
            { name: "budget", align: "center" },
            { name: "motivo", align: "center" },
          ]}
          rows={
            projects && projects?.map((project, index) => {
              return {
                empresa: (
                  <VuiBox display="flex" alignItems="center" key={project?.id}>
                    <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                      {project?.projectRelated?.pipeline?.leadsRelated?.businessName ?? ""}
                    </VuiTypography>
                  </VuiBox>
                ),
                influencers: (
                  <VuiBox display="flex" py={1}>
                    {avatars(project?.projectRelated?.influencers?.map((influencer) => [influencer.avatar, influencer.name]))}
                  </VuiBox>
                ),
                vendedor: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.projectRelated?.creator?.name ?? ""}
                  </VuiTypography>
                ),
                projeto: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.projectRelated?.title ?? ""}
                  </VuiTypography>
                ),
                budget: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    R${
                      project?.projectRelated?.budget?.value > 0
                        ?
                        Number((project?.projectRelated?.budget?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2, maximumFractionDigits: 2
                        })
                        :
                        0
                    }
                  </VuiTypography>
                ),
                motivo: (
                  <Grid container flexDirection='column'>
                    <Grid container justifyContent='space-between'>
                      <Grid item xs={11} xl={11}>
                        <VuiTypography variant="button" color="white" fontWeight="bold">
                          {project?.mainReason ?? ""}
                        </VuiTypography>
                      </Grid>
                      <Grid item xs={1} xl={1} onClick={() => toggleOpenMenu(index)}>
                        <IoEllipsisVertical size='20px' style={{ cursor: "pointer", zIndex: "9999" }} />
                      </Grid>
                    </Grid>
                    {openMenu[index] &&
                      <S.OptionsContainer container justifyContent='flex-end'>
                        <S.OptionsGrid display='flex' flexDirection='column'>
                          <p onClick={() => handleDetailsModal(project)}>Ver detalhes</p>
                        </S.OptionsGrid>
                      </S.OptionsContainer>
                    }
                  </Grid>
                ),
              }
            },)
          }
        />
      </VuiBox>
      <Modal size='md' open={openDetailsModal} onClose={() => closeDetailsModal()}>
        <Grid container flexDirection='column' gap='5px'>
          <p style={{ fontSize: "22px" }}>{selectedProject?.projectRelated?.title}</p>
          <p style={{ fontSize: "18px" }}>Status: {statusTranslation[selectedProject?.projectRelated?.status]}</p>
        </Grid>
        <Grid container flexDirection='column' gap='20px' mt='10px'>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Empresa:</p>
              <p>{selectedProject?.projectRelated?.pipeline?.leadsRelated?.businessName ?? ''}</p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Valor do Negócio:</p>
              <p>
                R$ {
                  selectedProject?.projectRelated?.budget?.value > 0
                    ?
                    Number((selectedProject?.projectRelated?.budget?.value / 100).toFixed(2)).toLocaleString("pt-BR",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    :
                    0
                }
              </p>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Fechamento:</p>
              <p>{selectedProject?.projectRelated?.endDate ? moment(selectedProject?.projectRelated?.endDate).format('DD/MM/YYYY HH:mm') : ''}</p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Pagamento:</p>
              <p>
                {
                  selectedProject?.projectRelated?.budget?.paymentDateExpected
                    ?
                    moment(selectedProject?.projectRelated?.budget?.paymentDateExpected).format('DD/MM/YYYY HH:mm')
                    :
                    ''
                }
              </p>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Influencers:</p>
              <p>
                {
                  selectedProject?.projectRelated?.influencers
                  &&
                  selectedProject?.projectRelated?.influencers.map((influencer) => (influencer.name)).join(", ")
                }
              </p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Status de Pagamento:</p>
              <p>{selectedProject?.projectRelated?.budget?.status ? capitalize(selectedProject?.projectRelated?.budget?.status?.toLowerCase()) : ''}</p>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <p>Descrição:</p>
            <p>{selectedProject?.projectRelated?.description}</p>
          </Grid>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Motivo da recusa:</p>
              <p>{selectedProject?.mainReason ?? ''}</p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Observações da recusa:</p>
              <p>{selectedProject?.notes ?? ''}</p>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Card>
  );
}

export default RejectionsTable;
