import { useState, useEffect } from 'react';
import { Grid, InputAdornment, FormHelperText } from '@mui/material'
import placeholder from 'assets/leadsPlaceholder.png'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import { RiInstagramLine, RiYoutubeFill, RiTiktokFill, RiTwitterFill, RiSpotifyFill, RiCake2Fill, RiCameraFill } from "react-icons/ri"
import { useFilePicker } from 'react-sage';
import showToast from 'components/Toast/Toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import VuiAvatar from "components/VuiAvatar";
import CloseIcon from '@mui/icons-material/Close';
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptionsInfluencer } from 'utils/utils';
import { createInfluencer, getInfluencerTags } from 'utils/requests/auth';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useNavigate } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Modal from 'components/Modal/Modal';
import { editUserPhoto } from 'utils/requests/auth';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      /*       border: `${borderWidth[2]} solid ${rgba(light.focus, 0.3)}`,
            cursor: "pointer", */
      position: "relative",
      /*       "&:hover, &:focus": {
              zIndex: "10",
            }, */
    })}
  >
    {children}
  </VuiAvatar>
)

const CreateInfluencer = () => {
  const [postAvatar, setPostAvatar] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [tags, setTags] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [newSearchTerm, setNewSearchTerm] = useState('');
  const [searchTermOptions, setSearchTermOptions] = useState([]);
  const [validateFields, setValidateFields] = useState(false);

  const navigate = useNavigate();
  const filter = createFilterOptions();

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.statusCode) setTags(data);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY').valueOf());
  };

  const validation = useFormik({
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      name: '',
      email: '',
      birthDate: '',
      bio: '',
      instagram: '',
      youtube: '',
      tiktok: '',
      twitter: '',
      spotify: '',
      userTags: [],
      searchTerms: []
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4).required("Nome é obrigatório"),
      email: Yup.string().min(4).email().required("Email é obrigatório"),
      birthDate: Yup.string(),
      bio: Yup.string(),
      instagram: Yup.string(),
      youtube: Yup.string(),
      tiktok: Yup.string(),
      twitter: Yup.string(),
      spotify: Yup.string(),
      userTags: Yup.array(),
      searchTerms: Yup.array()
    }),
    onSubmit: async (values) => {
      const influencerValues = {
        bio: values.bio ? values.bio : '',
        userTags: values.userTags ? values.userTags.map(tag => tag.id) : '',
        name: values.name ? values.name : '',
        email: values.email ? values.email : '',
        instagram: values.instagram ? { username: values.instagram } : '',
        spotify: values.spotify ? { username: values.spotify } : '',
        tiktok: values.tiktok ? { username: values.tiktok } : '',
        twitter: values.twitter ? { username: values.twitter } : '',
        youtube: values.youtube ? { username: values.youtube } : '',
        birthDateMs: values.birthDate ? unixDate(values.birthDate) : '',
        searchTerms: values.searchTerms
      };

      const avatarChange = {
        file: postAvatar ?? '',
      };

      Object.keys(influencerValues).forEach(key => {
        if (influencerValues[key] === '') {
          delete influencerValues[key];
        };
      });

      Object.keys(influencerValues).forEach(key => {
        if ((Array.isArray(influencerValues[key])) && (influencerValues[key].length === 0)) {
          delete influencerValues[key];
        };
      });

      if (Object.keys(influencerValues).length > 0) {
        await createInfluencer({ ...influencerValues, type: 'INFLUENCER' }).then((res) => {
          if (res?.message) {
            showToast(res?.message, 'error');
          };
          if (!res?.message && !postAvatar) {
            showToast('Influencer criado com sucesso! Redirecionando...', 'success');
            setTimeout(() => {
              navigate(`/influencers/${res.username ? res.username : ""}`);
            }, 3000);
          };
          if (!res?.message && postAvatar) {
            editUserPhoto({ ...avatarChange, username: res.username }).then((photoResponse) => {
              if (!photoResponse?.message) {
                showToast('Influencer criado com sucesso! Redirecionando...', 'success');
                setTimeout(() => {
                  navigate(`/influencers/${res.username ? res.username : ""}`);
                }, 3000);
              };
              if (photoResponse?.message) {
                showToast("Houve um erro ao armazenar a foto deste usuário, contate o suporte", "error")
              };
            });
          };
        });
      };
      if (Object.keys(influencerValues).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  useEffect(() => {
    if (validation?.values?.searchTerms?.includes('Criar nova palavra-chave +')) {
      setOpenModal(true);
      validation.setFieldValue('searchTerms', []);
    };
  }, [validation?.values?.searchTerms]);

  const addNewSearchTermToFormik = (value) => {
    const searchTermsArray = [...validation?.values?.searchTerms];
    searchTermsArray.push(value);
    validation.setFieldValue('searchTerms', searchTermsArray);
    setSearchTermOptions(searchTermsArray);
    setNewSearchTerm('');
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr("#birthDate", flatpickrOptionsInfluencer('birthDate', validation.setFieldValue, validation.setFieldTouched));

  return (
    <Grid container flexDirection='column'>
      <p>Criar Influencer</p>
      <Grid container justifyContent='center'>
        <S.InfluencerPhoto>
          <div>
            <Avatar img={previewUrl ?? placeholder} />
          </div>
          <S.IndicatorGrid onClick={imageInput.onClick}>
            <S.BranchIndicator>
              <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
              <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
            </S.BranchIndicator>
          </S.IndicatorGrid>
        </S.InfluencerPhoto>
      </Grid>
      <Formik initialValues={validation.initialValues} validationSchema={validation.validationSchema} onSubmit={validation.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' mt='15px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='name'
                  placeholder="Nome"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.errors.name}
                  helperText={validation.errors.name ? 'Nome inválido' : false}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='email'
                  placeholder="Email"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  error={validation.errors.email}
                  helperText={validation.errors.email ? 'Email inválido' : false}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name='userTags'
                  id='userTags'
                  value={validation.values.userTags ?? []}
                  onChange={(e, value) => {
                    validation.setFieldValue('userTags', value)
                  }}
                  options={tags ? tags : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Tags' />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id='birthDate'>
                <S.Input
                  name='birthDate'
                  hiddenLabel
                  type='text'
                  placeholder="Data de Nascimento"
                  fullWidth
                  data-input
                  autoComplete='off'
                  value={validation.values.birthDate}
                  error={Boolean(validation.errors.birthDate)}
                  helperText={validation.errors.birthDate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiCake2Fill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CloseIcon onClick={() => validation.setFieldValue('birthDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap='20px' justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  multiline
                  name='bio'
                  placeholder="Descrição"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.bio || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='instagram'
                  placeholder="Instagram"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.instagram || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiInstagramLine />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='youtube'
                  placeholder="Youtube"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.youtube || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiYoutubeFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='tiktok'
                  placeholder="TikTok"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.tiktok || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiTiktokFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='twitter'
                  placeholder="Twitter"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.twitter || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiTwitterFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='spotify'
                  placeholder="Spotify"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.spotify || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiSpotifyFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name='searchTerms'
                  id='searchTerms'
                  value={validation.values.searchTerms ?? []}
                  onChange={(e, value) => {
                    validation.setFieldValue('searchTerms', value)
                  }}
                  options={searchTermOptions}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Palavras chave' />}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      {option !== 'Criar nova palavra-chave +' &&
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                      }
                      {option}
                    </li>
                  )}
                  noOptionsText='Nenhuma opção selecionada'
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option);
                    if (inputValue !== '' && !isExisting || options.length === 0) {
                      filtered.push("Criar nova palavra-chave +");
                    }
                    return filtered;
                  }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
      <Modal size='sm' open={openModal} onClose={() => setOpenModal(false)}>
        <p>Adicionar palavra chave</p>
        <S.InputGrid item xs={12} md={12} xl={12} mt='15px'>
          <S.Input
            hiddenLabel
            name='name'
            placeholder="Nome"
            fullWidth
            onChange={(e) => setNewSearchTerm(e.target.value)}
            value={newSearchTerm}
          />
        </S.InputGrid>
        <Grid container justifyContent='flex-end' mt='15px'>
          <ButtonCustom
            label='Adicionar'
            onClick={() => addNewSearchTermToFormik(newSearchTerm)}
            sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
          />
        </Grid>
      </Modal>
    </Grid>
  )
}

export default CreateInfluencer