let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

export const login = async (email, password, /* longitude, latitude */) => {
  const response = await fetch(REACT_APP_API_URL + '/auth/login', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      /*       "User-Longitude": longitude,
            "User-Latitude": latitude, */
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
  /* localStorage.setItem("token", response.headers.get('Authentication')); */
  const data = await response.json();
  if (data?.token) localStorage.setItem("token", data?.token);
  return data;
};

export const getInfluencers = async (page, filters) => {
  const token = localStorage.getItem("token");
  const tagsQuery = filters?.userTags?.length > 0 ? `&${filters.userTags.map(tag => `userTags[]=${tag}`).join('&')}` : '';
  const response = await fetch(
    REACT_APP_API_URL 
    + 
    encodeURI(
      `/auth/users?statusOrder=DESC&page=${page}&size=10&type[]=INFLUENCER${tagsQuery}${filters?.name ? 
        `&username=${filters?.name}` : ''}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getUser = async (username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/profile/${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editInfluencer = async (username, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/profile/${username}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
};

export const editUserPhoto = async (edit) => {
  const formData = new FormData();
  Object.entries(edit).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/files`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};

export const createInfluencer = async (userInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/influencer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(userInfo),
  });
  const data = await response.json();
  return data;
};

export const createUser = async (userInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(userInfo),
  });
  const data = await response.json();
  return data;
};

export const editUser = async (username, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/profile/${username}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
};

export const getInfluencerTags = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/auth/users/tags/influencer`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getUserTagsByType = async (types) => {
  const token = localStorage.getItem("token");
  const typesQuery = types?.length > 0 ? `?${types?.map(type => `type[]=${type}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/auth/users/tags${typesQuery}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getAllInfluencers = async (filters) => {
  const activeQuery = () => {
    if (filters?.onlyActive !== undefined) {
      return `&onlyActive=${filters?.onlyActive}`
    }
    return ''
  };
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/auth/users?type[]=INFLUENCER${activeQuery()}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const facebookLogin = async () => {
  const response = await fetch(REACT_APP_API_URL + `/auth/facebook`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  })
  const data = await response.json();
  return data;
}

export const deleteUser = async (username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/${username}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const requestPasswordChange = async (email) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/requestPasswordChange`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      email
    }),
  });
  const data = await response.json();
  return data;
};

export const forgottenPasswordChange = async (id, password) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/passwordChange/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(password),
  });
  const data = await response.json();
  return data;
};

export const changePassword = async (password) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users/passwordChange`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(password),
  });
  const data = await response.json();
  return data;
};

export const listAllUserNames = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/auth/users?onlyNames=true&nameOrder=ASC`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getUsers = async (page, filters) => {
  const token = localStorage.getItem("token");
  const tagsQuery = filters?.userTags?.length > 0 ? `&${filters.userTags.map(tag => `userTags[]=${tag}`).join('&')}` : '';
  const typeQuery = filters?.type?.length > 0 ? `&${filters.type.map(type => `type[]=${type}`).join('&')}` : '';
  const response = await fetch(
    REACT_APP_API_URL 
    + 
    encodeURI(
      `/auth/users?statusOrder=DESC&page=${page}&size=10${typeQuery}${tagsQuery}${filters?.name ? 
        `&username=${filters?.name}` : ''}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getUsersByUserTag = async (userTags) => {
  const token = localStorage.getItem("token");
  const tagsQuery = userTags?.length > 0 ? `?${userTags.map(tag => `userTags[]=${tag}`).join('&')}` : '';
  const response = await fetch(
    REACT_APP_API_URL 
    + 
    encodeURI(
      `/auth/users${tagsQuery}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getMarketingTags = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/auth/users/tags/marketing`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};