import React from 'react'
import { Grid, FormHelperText } from '@mui/material'
import * as S from '../../Projects/ModalContent/style'
import InputAdornment from '@mui/material/InputAdornment';
import { RiCalendarEventFill } from 'react-icons/ri'
import Autocomplete from '@mui/material/Autocomplete';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { createMetrics } from 'utils/requests/other';
import showToast from 'components/Toast/Toast';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import { getAllInfluencers } from 'utils/requests/auth';

const AddMetricsForm = () => {
  moment.locale("pt-br");

  const [influencerList, setInfluencerList] = React.useState();

  const fetchInfluencerList = async () => {
    const data = await getAllInfluencers();
    if (!data?.message) {
      setInfluencerList(data);
    };
  };

  React.useEffect(() => {
    fetchInfluencerList();
  }, []);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      influencer: '',
      registryTakenMs: '',
      instagram: {
        posts: '',
        followers: '',
        reactions: '',
        reach: ''
      },
      tiktok: {
        posts: '',
        followers: '',
        reactions: '',
        reach: ''
      },
      twitter: {
        posts: '',
        followers: '',
        reactions: '',
        reach: ''
      },
      youtube: {
        posts: '',
        followers: '',
        reactions: '',
        reach: ''
      },
      spotify: {
        posts: '',
        followers: '',
        reactions: '',
        reach: ''
      },
    },
    /*     validationSchema: Yup.object({
          title: Yup.string().min(4, 'Nome do projeto deve conter pelo menos 4 caracteres').required('Nome do projeto é obrigatório'),
          business: Yup.string().min(4, 'Nome da empresa deve conter pelo menos 4 caracteres'),
          start: Yup.string().required('Data Inicial é obrigatório'),
          end: Yup.string(),
          projectType: Yup.string().required("Tipo de projeto é obrigatório"),
          currency: Yup.string().required("Moeda corrente é obrigatório"),
          influencers: Yup.array().length(1, "Projeto deve ter pelo menos um influencer vinculado"),
          description: Yup.string().required("Descrição é obrigatório"),
        }), */
    onSubmit: async (values) => {
      const metricsData = {
        influencer: values?.influencer?.id,
        registryTakenMs: values.registryTakenMs ? unixDate(values.registryTakenMs) : '',
        instagram: {
          posts: values.instagram.posts,
          followers: values.instagram.followers,
          reactions: values.instagram.reactions,
          reach: values.instagram.reach
        },
        tiktok: {
          posts: values.tiktok.posts,
          followers: values.tiktok.followers,
          reactions: values.tiktok.reactions,
          reach: values.tiktok.reach
        },
        twitter: {
          posts: values.twitter.posts,
          followers: values.twitter.followers,
          reactions: values.twitter.reactions,
          reach: values.twitter.reach
        },
        youtube: {
          posts: values.youtube.posts,
          followers: values.youtube.followers,
          reactions: values.youtube.reactions,
          reach: values.youtube.reach
        },
        spotify: {
          posts: values.spotify.posts,
          followers: values.spotify.followers,
          reactions: values.spotify.reactions,
          reach: values.spotify.reach
        }
      };
      await createMetrics(metricsData).then((res) => {
        if (!res.message) {
          showToast("Métricas criadas com sucesso!", "success");
        } else {
          showToast("Falha na criação de métricas", "error");
        };
      });
    }
  });


  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr("#registryTakenMs", flatpickrOptions('registryTakenMs', formik.setFieldValue, formik.setFieldTouched));
  return (
    <Formik initialValues={formik.initialValues} /* validationSchema={formik.validationSchema} */ onSubmit={formik.handleSubmit}>
      <Form id='metrics'>
        <Grid container flexDirection='column' gap='20px' marginTop='30px' padding='15px'>
          <p>Instagram</p>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='instagram.posts'
                id='instagram.posts'
                type='text'
                placeholder="Posts"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.instagram.posts}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='instagram.followers'
                id='instagram.followers'
                type='text'
                placeholder="Followers"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.instagram.followers}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='instagram.reactions'
                id='instagram.reactions'
                type='text'
                placeholder="Reactions"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.instagram.reactions}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='instagram.reach'
                id='instagram.reach'
                type='text'
                placeholder="Reach"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.instagram.reach}
              />
            </S.InputGrid>
          </Grid>
          <p>Tiktok</p>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='tiktok.posts'
                id='tiktok.posts'
                type='text'
                placeholder="Posts"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.tiktok.posts}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='tiktok.followers'
                id='tiktok.followers'
                type='text'
                placeholder="Followers"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.tiktok.followers}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='tiktok.reactions'
                id='tiktok.reactions'
                type='text'
                placeholder="Reactions"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.tiktok.reactions}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='tiktok.reach'
                id='tiktok.reach'
                type='text'
                placeholder="Reach"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.tiktok.reach}
              />
            </S.InputGrid>
          </Grid>
          <p>Youtube</p>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='youtube.posts'
                id='youtube.posts'
                type='text'
                placeholder="Posts"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.youtube.posts}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='youtube.followers'
                id='youtube.followers'
                type='text'
                placeholder="Followers"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.youtube.followers}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='youtube.reactions'
                id='youtube.reactions'
                type='text'
                placeholder="Reactions"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.youtube.reactions}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='youtube.reach'
                id='youtube.reach'
                type='text'
                placeholder="Reach"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.youtube.reach}
              />
            </S.InputGrid>
          </Grid>
          <p>Twitter</p>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='twitter.posts'
                id='twitter.posts'
                type='text'
                placeholder="Posts"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.twitter.posts}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='twitter.followers'
                id='twitter.followers'
                type='text'
                placeholder="Followers"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.twitter.followers}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='twitter.reactions'
                id='twitter.reactions'
                type='text'
                placeholder="Reactions"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.twitter.reactions}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='twitter.reach'
                id='twitter.reach'
                type='text'
                placeholder="Reach"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.twitter.reach}
              />
            </S.InputGrid>
          </Grid>
          <p>Spotify</p>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='spotify.posts'
                id='spotify.posts'
                type='text'
                placeholder="Posts"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.spotify.posts}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='spotify.followers'
                id='spotify.followers'
                type='text'
                placeholder="Followers"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.spotify.followers}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='spotify.reactions'
                id='spotify.reactions'
                type='text'
                placeholder="Reactions"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.spotify.reactions}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={5.5}>
              <S.Input
                hiddenLabel
                name='spotify.reach'
                id='spotify.reach'
                type='text'
                placeholder="Reach"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.spotify.reach}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={12} id='registryTakenMs'>
              <S.Input
                name='registryTakenMs'
                hiddenLabel
                type='text'
                placeholder="Data"
                fullWidth
                data-input
                autoComplete='off'
                value={formik.values.registryTakenMs}
                error={Boolean(formik.errors.registryTakenMs)}
                helperText={formik.errors.registryTakenMs}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RiCalendarEventFill />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CloseIcon onClick={() => formik.setFieldValue('registryTakenMs', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                    </InputAdornment>
                  )
                }}
              />
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='space-between'>
            <S.InputGrid item xs={12} md={12} xl={12}>
              <Grid container>
                <Autocomplete
                  id="influencer"
                  name="influencer"
                  disablePortal
                  value={formik.values.influencer ? formik.values.influencer : null}
                  onChange={(e, value) => {
                    formik.setFieldValue('influencer', value)
                  }}
                  options={influencerList ? influencerList[0] : []}
                  sx={[{ width: "100%" }]}
                  renderInput={(params) => <S.Input {...params} placeholder='Influencer' />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
            </S.InputGrid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='SALVAR' sx={{ width: "180px", fontSize: "18px", fontWeight: "500" }} form='metrics' type='submit' />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default AddMetricsForm