import { Card, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import placeholderImg from 'assets/placeholder.png';
import Modal from "components/Modal/Modal";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import TimelineItem from "defaultComponents/Timeline/TimelineItem";
import Transactions from "layouts/billing/components/Transactions";
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import { IoEllipse, IoAdd, IoEllipsisVertical } from 'react-icons/io5';
import { RiPhoneFill, RiMailFill } from "react-icons/ri";
import { useNavigate, useParams } from 'react-router-dom';
import pencil from '../../../../assets/icons/pencil.png';
import * as S from './style';
import { TokenContext } from "context/TokenContext";
import EventDetails from 'pages/Calendar/ModalContent/EventDetails';
import * as T from "components/Toast/style"
import ClientProjects from './ClientProjects';
import { getLead } from 'utils/requests/leads';
import CreateContact from '../ModalContent/CreateContact';
import VuiAvatar from "components/VuiAvatar";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { deleteContact } from 'utils/requests/leads';
import showToast from 'components/Toast/Toast';
import EditClient from './ModalContent/EditClient';
import EditContact from './ModalContent/EditContact';
import PublicationDetails from 'pages/Calendar/ModalContent/PublicationDetails';

const Avatar = ({ img, theme, children, size, border, ...rest }) => (
  <VuiAvatar
    src={img}
    size={size ?? 'xl'}
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      border: border ? `${borderWidth[2]} solid ${rgba('#FC6009', 1)}` : '',
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const Client = () => {
  moment.locale("pt-br")
  const mobile = useMediaQuery("(max-width: 992px)");
  const navigate = useNavigate();
  const params = useParams();
  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [clientInfo, setClientInfo] = React.useState();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openEventDetails, setOpenEventDetails] = React.useState(false);
  const [openPublicationDetails, setOpenPublicationDetails] = React.useState(false);
  const [eventToShow, setEventToShow] = React.useState();
  const [openContactsModal, setOpenContactsModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = React.useState(false);
  const [contactToDelete, setContactToDelete] = React.useState();
  const [chosenContact, setChosenContact] = React.useState();
  const [openEditContactModal, setOpenEditContactModal] = React.useState(false);

  const clientId = params.clientId;

  const fetchClientInfo = async () => {
    const data = await getLead(clientId ?? '');
    if (!data.statusCode) {
      setClientInfo(data);
    }
  };

  React.useEffect(() => {
    fetchClientInfo();
  }, []);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDeleteModal = (contact) => {
    setOpenConfirmDeletionModal(true);
    setContactToDelete(contact);
  };

  const cancelDelete = () => {
    setOpenConfirmDeletionModal(false);
    setContactToDelete();
  };

  const handleDelete = async () => {
    await deleteContact(contactToDelete?.id).then((res) => {
      if (res?.name) {
        fetchClientInfo();
        showToast("Contato deletado com sucesso", "success");
        setOpenConfirmDeletionModal(false);
        return
      }
      showToast("Falha ao apagar contato", "error");
    })
  };

  const closeEditContactModal = () => {
    setOpenEditContactModal(false);
    setChosenContact();
  };

  const handleOpenCalendarDetails = (event) => {
    if(event?.extendedProps?.type === "PUBLICATION") {
      setOpenPublicationDetails(true);
      return
    }
    setOpenEventDetails(true);
  };

  return (
    <DashboardLayout onClick={() => openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null}>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar customTitle={clientInfo?.lead?.businessName} />
      <Grid container spacing='18px'>
        <Grid item xs={12} lg={12} xl={8}>
          <Card>
            {mobile && (role === "ADMINISTRATOR" || role === "H_COMERCIAL" || role === "COMERCIAL") &&
              <Grid container justifyContent='flex-end' gap='10px'>
                <S.EditButtonGrid>
                  <S.EditButton onClick={() => setOpenEditModal(true)}>
                    <img src={pencil} />
                  </S.EditButton>
                </S.EditButtonGrid>
              </Grid>
            }
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <Grid container justifyContent='space-between'>
                <Grid container item lg={1.5} xl={1.5}>
                  <S.InfluencerPhoto>
                    <Avatar img={clientInfo?.lead?.avatar ? clientInfo?.lead?.avatar : placeholderImg} size='xxl' />
                    <S.BranchIndicator>
                      {clientInfo?.lead?.curve}
                    </S.BranchIndicator>
                  </S.InfluencerPhoto>
                </Grid>
                <S.InfluencerTextGrid container flexDirection='column' item lg={9.75} xl={10} /* pl='10px' */>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={6}>
                      <p className='name'>
                        {clientInfo?.lead?.businessName ?? ''}
                      </p>
                    </Grid>
                    {!mobile && (role === "ADMINISTRATOR" || role === "H_COMERCIAL" || role === "COMERCIAL") &&
                      <Grid container justifyContent='flex-end' gap='10px' item xs={12} md={6} xl={6}>
                        <S.EditButtonGrid>
                          <S.EditButton onClick={() => setOpenEditModal(true)}>
                            <img src={pencil} />
                          </S.EditButton>
                        </S.EditButtonGrid>
                      </Grid>
                    }
                  </Grid>
                  <p className='subName'>
                    {clientInfo?.lead?.branch ?? ''}
                  </p>
                  <p className='description'>
                    {clientInfo?.lead?.businessDescription ?? ''}
                  </p>
                  <p className='description'>
                    Criado por: {clientInfo?.lead?.creator?.name ?? ''}
                  </p>
                </S.InfluencerTextGrid>
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12} xl={4}>
          <S.ScheduleList title="Agenda" maxHeight={"335px"}>
            {clientInfo?.calendarEntries?.length === 0 && <p style={{ color: "#fff" }}>Sem informações</p>}
            {clientInfo?.calendarEntries?.map((event, index) => (
              <Grid key={index} onClick={() => { setEventToShow(event); handleOpenCalendarDetails(event); }}>
                <TimelineItem
                  key={event?.id}
                  color="viuzz"
                  icon={<IoEllipse />}
                  title={event?.title}
                  dateTimeStart={event?.start ? (moment(event?.start).format("D MMM HH:mm").toUpperCase()) : ''}
                  dateTimeEnd={event?.end ? (moment(event?.end).format("D MMM HH:mm").toUpperCase()) : ''}
                  badges={event?.extendedProps?.usersRelated?.map(category => category.name)}
                  location={event?.extendedProps?.place}
                />
              </Grid>
            ))}
          </S.ScheduleList>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={8}>
          <ClientProjects
            projects={clientInfo?.projectEntries ? clientInfo?.projectEntries : false}
            routing='/comercial/negocios' />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={4}>
          <Card sx={{ padding: "30px 40px" }}>
            <Grid>
              <S.UpdateButtonGrid>
                <S.ContactButton onClick={() => setOpenContactsModal(true)} data-cy="add-contact">
                  <IoAdd size='22px' color='white' />
                </S.ContactButton>
              </S.UpdateButtonGrid>
              <VuiTypography variant="lg" mb="6px" gutterBottom color='white'>
                Contatos
              </VuiTypography>
              <S.ContactGrid container flexDirection='column' mt='10px' gap='10px' data-cy="contact-container">
                {clientInfo?.lead?.contacts?.length === 0 &&
                  <p>Sem contatos</p>
                }
                {clientInfo?.lead?.contacts.map((contact, index) => (
                  <Grid container key={index} justifyContent='space-between'>
                    <Grid display='flex' item gap='10px' xl={11}>
                      <Grid item>
                        <S.ContactPhoto>
                          <Avatar src={contact.avatar ? contact.avatar : placeholderImg} size='xl' border />
                        </S.ContactPhoto>
                      </Grid>
                      <Grid item display='flex' flexDirection='column' >
                        <p>{contact.name}{contact?.role ? ` - ${contact?.role}` : ''}</p>
                        <Grid container gap='5px' className='minorTextGrid'>
                          <RiPhoneFill color='#FE1E14' />
                          <p>{contact.phone}</p>
                        </Grid>
                        <Grid container gap='5px' className='minorTextGrid'>
                          <RiMailFill color='#FE1E14' />
                          <p>{contact.email}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={1} textAlign='end' onClick={() => toggleOpenMenu(index)} data-cy="contact-menu-toggle">
                      <IoEllipsisVertical size='20px' color='white' style={{ cursor: "pointer" }} />
                    </Grid>
                    {openMenu[index] &&
                      <S.OptionsContainer container justifyContent='flex-end'>
                        <S.OptionsGrid display='flex' flexDirection='column' data-cy='contact-options'>
                          <p
                            onClick={() => {
                              setChosenContact(contact);
                              setOpenEditContactModal(true);
                              toggleOpenMenu(index);
                            }}
                          >
                            Editar
                          </p>
                          <S.Divider />
                          <p onClick={() => { handleDeleteModal(contact); toggleOpenMenu(index); }}>Excluir</p>
                        </S.OptionsGrid>
                      </S.OptionsContainer>
                    }
                  </Grid>
                ))
                }
              </S.ContactGrid>
            </Grid>
          </Card>
        </Grid>
        {role === "ADMINISTRATOR" &&
          <Grid item xs={12} md={12} xl={12}>
            <Transactions
              data={clientInfo ? clientInfo : []}
              hideCredit
              hideRedirect
            />
          </Grid>
        }
      </Grid>
      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <EditClient clientInfo={clientInfo ?? false} refetch={fetchClientInfo} closeModal={() => setOpenEditModal(false)} />
      </Modal>
      <Modal open={openEventDetails} onClose={() => { setOpenEventDetails(false); setEventToShow(); }}>
        <EventDetails event={eventToShow} />
      </Modal>
      <Modal open={openContactsModal} onClose={() => setOpenContactsModal(false)}>
        <CreateContact leadInfo={clientInfo} refetch={fetchClientInfo} closeModal={() => setOpenContactsModal(false)} />
      </Modal>
      <Modal size='sm' open={openConfirmDeletionModal} onClose={() => cancelDelete()}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir {contactToDelete?.name}? Essa ação é irreversível</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom label='Cancelar'
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              />
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label='Confirmar' onClick={() => handleDelete()} />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openEditContactModal} onClose={closeEditContactModal}>
        <EditContact contactInfo={chosenContact} refetch={fetchClientInfo} closeModal={closeEditContactModal} />
      </Modal>
      <Modal open={openPublicationDetails} onClose={() => { setOpenPublicationDetails(false); setEventToShow(); }}>
        <PublicationDetails event={eventToShow} />
      </Modal>
    </DashboardLayout >
  )
}

export default Client