// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";

// Images
import AdobeXD from "defaultComponents/Icons/AdobeXD";
import Atlassian from "defaultComponents/Icons/Atlassian";
import Slack from "defaultComponents/Icons/Slack";
import Spotify from "defaultComponents/Icons/Spotify";
import Jira from "defaultComponents/Icons/Jira";
import Invision from "defaultComponents/Icons/Invision";
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";

export const data = (projects) => {
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="sm"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  return {
    columns: [
      { name: "empresa", align: "left" },
      { name: "influencers", align: "left" },
      { name: "projetos", align: "center" },
      { name: "budget", align: "center" },
      { name: "progresso", align: "left" },
    ],

    rows:
      projects && projects?.map((project) => {
        return {
          empresa: (
            <VuiBox display="flex" alignItems="center" key={project.name}>
              <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                {project?.pipeline?.leadsRelated?.businessName ?? ''}
              </VuiTypography>
            </VuiBox>
          ),
          influencers: (
            <VuiBox display="flex" py={1}>
              {avatars(project.influencers.map((influencer) => [influencer.avatar, influencer.username]))}
            </VuiBox>
          ),
          projetos: (
            <VuiTypography variant="button" color="white" fontWeight="bold">
              {project?.title ?? ''}
            </VuiTypography>
          ),
          budget: (
            <VuiTypography variant="button" color="white" fontWeight="bold">
              R${
                project?.budget
                  ?
                  Number(((project?.budget?.value ?? 0) / 100).toFixed(2)).toLocaleString(
                    "pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )
                  :
                  0
              }
            </VuiTypography>
          ),
          progresso: (
            <VuiBox width="8rem" textAlign="center">
              <VuiTypography color="white" variant="button" fontWeight="bold">
                {project?.progress ?? 0}%
              </VuiTypography>
              <VuiProgress value={project?.progress ?? 0} color="viuzz" label={false} sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
            </VuiBox>
          ),
        }
      })
  };
}
