import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import * as S from "./style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import InputAdornment from "@mui/material/InputAdornment";
import {
  RiCake2Fill,
  RiInstagramLine,
  RiYoutubeFill,
  RiTiktokFill,
  RiTwitterFill,
  RiSpotifyFill,
  RiCameraFill,
} from "react-icons/ri";
import placeholderImg from "assets/placeholder.png";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { editInfluencer, getInfluencerTags } from "utils/requests/auth";
import showToast from "components/Toast/Toast";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptionsInfluencer } from "utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import "moment/locale/pt-br";
import { TokenContext } from "context/TokenContext";
import VuiAvatar from "components/VuiAvatar";
import { useFilePicker } from "react-sage";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Modal from "components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { editUserPhoto } from "utils/requests/auth";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      /*       border: `${borderWidth[2]} solid ${rgba(light.focus, 0.3)}`,
            cursor: "pointer", */
      position: "relative",
      /*       "&:hover, &:focus": {
              zIndex: "10",
            }, */
    })}
  >
    {children}
  </VuiAvatar>
);

const EditInfluencer = ({ userInfo, refetch, closeModal, returnedValues }) => {
  moment.locale("pt-br");

  const navigate = useNavigate();

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [tags, setTags] = React.useState();
  const [postAvatar, setPostAvatar] = React.useState();
  const [previewUrl, setPreviewUrl] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [newSearchTerm, setNewSearchTerm] = React.useState("");
  const [searchTermOptions, setSearchTermOptions] = React.useState([]);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  const filter = createFilterOptions();

  React.useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      }
    };
    getDataUrls();
  }, [imageInput.files]);

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.message) setTags(data);
  };

  const sendUserData = async (values, userPhoto) => {
    if (postAvatar && userPhoto) {
      await editUserPhoto(userPhoto).then((res) => {
        if (!res.message) {
          showToast("Dados alterados com sucesso!", "success");
          refetch(res.username);
          closeModal();
        }
        if (res.message) {
          showToast("Falha ao alterar dados", "error");
        }
      });
    }
    await editInfluencer(userInfo?.username, values).then((res) => {
      if (!res?.message && userInfo?.username !== res?.username) {
        showToast("Dados alterados com sucesso!", "success");
        setTimeout(() => navigate(`/influencers/${res?.username}`), 1000);
        setTimeout(() => refetch(res?.username), 2000);
        closeModal();
        return;
      }
      if (!res.message) {
        showToast("Dados alterados com sucesso!", "success");
        refetch(res.username);
        closeModal();
      }
      if (res.message) {
        showToast("Falha ao alterar dados", "error");
      }
    });
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY").valueOf());
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: userInfo?.name ?? "",
      username: userInfo?.username ?? "",
      birthDate: userInfo?.birthDate ? moment(userInfo?.birthDate).format("DD/MM/YYYY") : "",
      bio: userInfo?.bio ?? "",
      instagram: userInfo?.instagram?.username ?? "",
      youtube: userInfo?.youtube?.username ?? "",
      tiktok: userInfo?.tiktok?.username ?? "",
      twitter: userInfo?.twitter?.username ?? "",
      spotify: userInfo?.spotify?.username ?? "",
      userTags: userInfo?.userTags ?? [],
      searchTerms: returnedValues?.searchTerms ?? [],
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4),
      username: Yup.string().min(4),
      birthDate: Yup.string(),
      bio: Yup.string(),
      instagram: Yup.string(),
      youtube: Yup.string(),
      tiktok: Yup.string(),
      twitter: Yup.string(),
      spotify: Yup.string(),
      userTags: Yup.array(),
      searchTerms: Yup.array(),
    }),
    onSubmit: async (values) => {
      const startInfluencerValues = validation.initialValues;
      /*       const searchTerms = () => {
              if (values.searchTerms?.length === 0) {
                return [];
              };
              if(values.searchTerms !== startInfluencerValues.searchTerms) {
                return values.searchTerms;
              };
              return '';
            }; */
      const edit = {
        bio: values.bio !== startInfluencerValues.bio ? values.bio : "",
        userTags:
          values.userTags !== startInfluencerValues.userTags
            ? values.userTags.map((tag) => tag.id)
            : "",
        name: values.name !== startInfluencerValues.name ? values.name : "",
        username: values.username !== startInfluencerValues.username ? values.username : "",
        instagram:
          values.instagram !== startInfluencerValues.instagram
            ? { username: values.instagram }
            : "",
        spotify:
          values.spotify !== startInfluencerValues.spotify ? { username: values.spotify } : "",
        tiktok: values.tiktok !== startInfluencerValues.tiktok ? { username: values.tiktok } : "",
        twitter:
          values.twitter !== startInfluencerValues.twitter ? { username: values.twitter } : "",
        youtube:
          values.youtube !== startInfluencerValues.youtube ? { username: values.youtube } : "",
        birthDateMs:
          values.birthDate !== startInfluencerValues.birthDate ? unixDate(values.birthDate) : "",
        searchTerms:
          values.searchTerms !== startInfluencerValues.searchTerms ? values.searchTerms : "",
      };

      const avatarChange = {
        file: postAvatar ?? "",
        username: userInfo?.username,
      };

      //Remove os campos não modificados
      Object.keys(edit).forEach((key) => {
        if (edit[key] === "") {
          delete edit[key];
        }
      });

      if (Object.keys(edit).length === 0 && !postAvatar)
        showToast("Sem dados alterados, campos sem alterações", "warning");
      if (Object.keys(edit).length === 0 && postAvatar) {
        await editUserPhoto(avatarChange).then((res) => {
          if (!res.message) {
            showToast("Dados alterados com sucesso!", "success");
            refetch(res.username);
            closeModal();
          }
          if (res.message) {
            showToast("Falha ao alterar dados", "error");
          }
        });
      }
      if (Object.keys(edit).length > 0 && !postAvatar) {
        sendUserData(edit);
      }
      if (Object.keys(edit).length > 0 && postAvatar) {
        sendUserData(edit, avatarChange);
      }
    },
  });

  React.useEffect(() => {
    if (validation?.values?.searchTerms?.includes("Criar nova palavra-chave +")) {
      setOpenModal(true);
      const cleanedArray = validation?.values?.searchTerms?.filter(
        (term) => term !== "Criar nova palavra-chave +"
      );
      validation.setFieldValue("searchTerms", cleanedArray);
    }
  }, [validation?.values?.searchTerms]);

  const addNewSearchTermToFormik = (value) => {
    const searchTermsArray = [...validation?.values?.searchTerms];
    searchTermsArray.push(value);
    validation.setFieldValue("searchTerms", searchTermsArray);
    setSearchTermOptions(searchTermsArray);
    setNewSearchTerm("");
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr(
    "#birthDate",
    flatpickrOptionsInfluencer("birthDate", validation.setFieldValue, validation.setFieldTouched)
  );

  const getPhoto = () => {
    if (previewUrl) {
      return previewUrl;
    }
    if (userInfo?.avatar && !postAvatar) {
      return userInfo?.avatar;
    }
    if (!userInfo?.avatar) {
      return placeholderImg;
    }
  };

  return (
    <>
      <Grid container flexDirection="column">
        <p>Editar perfil</p>
        <Grid container justifyContent="center">
          <S.InfluencerPhoto>
            <div>
              <Avatar img={getPhoto()} />
            </div>
            <S.IndicatorGrid onClick={imageInput.onClick}>
              <S.PhotoIndicator>
                <RiCameraFill size="20px" color="#fff" style={{ alignSelf: "center" }} />
                <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
              </S.PhotoIndicator>
            </S.IndicatorGrid>
          </S.InfluencerPhoto>
        </Grid>
        <form onSubmit={validation.handleSubmit}>
          <Grid container flexDirection="column" gap="20px" mt="20px">
            <Grid container gap="20px" justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="name"
                  placeholder="Nome"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.touched.name && validation.errors.name}
                  helperText={
                    validation.touched.name && validation.errors.name ? "Nome inválido" : false
                  }
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="username"
                  placeholder="Username"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  error={validation.touched.username && validation.errors.username}
                  helperText={
                    validation.touched.username && validation.errors.username
                      ? "Username inválido"
                      : false
                  }
                  InputProps={{
                    readOnly: role !== "ADMINISTRATOR",
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap="20px" justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name="userTags"
                  id="userTags"
                  value={validation.values.userTags ?? []}
                  onChange={(e, value) => {
                    validation.setFieldValue("userTags", value);
                  }}
                  options={tags ? tags : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Tags" />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </S.InputGrid>
              {/*               <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  type='date'
                  name='birthDate'
                  placeholder="Nascimento"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.birthDate || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiCake2Fill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid> */}
              <S.InputGrid item xs={12} md={5.5} xl={5.5} id="birthDate">
                <S.Input
                  name="birthDate"
                  hiddenLabel
                  type="text"
                  placeholder="Data de Nascimento"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={validation.values.birthDate}
                  error={Boolean(validation.errors.birthDate)}
                  helperText={validation.errors.birthDate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCake2Fill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => validation.setFieldValue("birthDate", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                    readOnly: role !== "ADMINISTRATOR",
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap="20px" justifyContent="space-between">
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  multiline
                  name="bio"
                  placeholder="Descrição"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.bio || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="instagram"
                  placeholder="Instagram"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.instagram || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiInstagramLine />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="youtube"
                  placeholder="Youtube"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.youtube || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiYoutubeFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="tiktok"
                  placeholder="TikTok"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.tiktok || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiTiktokFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="twitter"
                  placeholder="Twitter"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.twitter || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiTwitterFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="spotify"
                  placeholder="Spotify"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.spotify || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiSpotifyFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name="searchTerms"
                  id="searchTerms"
                  value={validation.values.searchTerms ?? []}
                  onChange={(e, value) => {
                    validation.setFieldValue("searchTerms", value);
                  }}
                  options={searchTermOptions}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Palavras chave" />}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      {option !== "Criar nova palavra-chave +" && (
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                      )}
                      {option}
                    </li>
                  )}
                  noOptionsText="Nenhuma opção selecionada"
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option);
                    if ((inputValue !== "" && !isExisting) || options.length === 0) {
                      filtered.push("Criar nova palavra-chave +");
                    }
                    return filtered;
                  }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" mt='30px'>
            <ButtonCustom
              label="Salvar"
              type="submit"
              sx={{
                width: "150px",
                height: "40px !important",
                fontSize: "18px",
                fontWeight: "500",
              }}
            />
          </Grid>
        </form>
        <Modal size="sm" open={openModal} onClose={() => setOpenModal(false)}>
          <p>Adicionar palavra chave</p>
          <S.InputGrid item xs={12} md={12} xl={12} mt="15px">
            <S.Input
              hiddenLabel
              name="name"
              placeholder="Nome"
              fullWidth
              onChange={(e) => setNewSearchTerm(e.target.value)}
              value={newSearchTerm}
            />
          </S.InputGrid>
          <Grid container justifyContent="flex-end" mt="15px">
            <ButtonCustom
              label="Adicionar"
              onClick={() => addNewSearchTermToFormik(newSearchTerm)}
              sx={{
                width: "150px",
                height: "40px !important",
                fontSize: "18px",
                fontWeight: "500",
              }}
            />
          </Grid>
        </Modal>
      </Grid>
    </>
  );
};

export default EditInfluencer;
