// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

export const data = (projects) => {
  const avatars = (members) =>
    members.map(([image, name, username]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <Link to={`/influencers/${username}`}>
          <VuiAvatar
            src={image}
            alt="name"
            size="sm"
            sx={{
              border: ({ borders: { borderWidth }, palette: { dark } }) =>
                `${borderWidth[2]} solid ${dark.focus}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: -1.25,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            }}
          />
        </Link>
      </Tooltip>
    ));

  return {
    columns: [
      { name: "projeto", align: "left" },
      { name: "influencers", align: "left" },
      { name: "budget", align: "center" },
      { name: "progresso", align: "center" },
    ],

    rows:
      projects?.map((project) => {
        return {
          projeto: (
            <VuiBox display="flex" alignItems="center">
              <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                {project?.title ?? ""}
              </VuiTypography>
            </VuiBox>
          ),
          influencers: (
            <VuiBox display="flex" py={1}>
              {avatars(project?.influencers?.map((influencer) =>
                [influencer.avatar, influencer.name, influencer.username]) ?? [])}
            </VuiBox>
          ),
          budget: (
            <VuiTypography variant="button" color="white" fontWeight="bold">
              R${Number(((project?.budget?.value ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? ""}
            </VuiTypography>
          ),
          progresso: (
            <Grid container justifyContent='center'>
              <VuiBox width="8rem" textAlign="center" >
                <VuiTypography color="white" variant="button" fontWeight="bold">
                  {project?.progress ?? 0}%
                </VuiTypography>
                <VuiProgress value={project?.progress ?? 0} color="viuzz" label={false} sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
              </VuiBox>
            </Grid>
          ),
        }
      })
  };
}
