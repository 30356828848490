import { useState, useEffect } from 'react';
import * as S from '../style'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { Grid } from "@mui/material"
import Modal from "components/Modal/Modal"
import { getCommercialTags } from 'utils/requests/comercial';
import showToast from 'components/Toast/Toast';
import { RiDeleteBin6Line } from 'react-icons/ri'
import { deleteCommercialTag } from 'utils/requests/comercial';

const TagsModal = ({ cardTags, setTags, refetch, closeModal }) => {
  const [openCreateTagsModal, setOpenCreateTagsModal] = useState(false);
  const [tagText, setTagText] = useState('');
  const [colorPick, setColorPick] = useState('');
  const [tagOptions, setTagOptions] = useState([]);
  const [tagsArr, setTagsArr] = useState(cardTags);
  const [openTagDeletionModal, setOpenTagDeletionModal] = useState(false);

  const colors = ['#7ee2b8', '#e2b203', '#fea362', '#f87168', '#9f8fef', '#0055cc', '#a54800', '#94c748', '#e774bb', '#454f59', '#6cc3e0', '#37471f'];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const fetchTags = async () => {
    const data = await getCommercialTags();
    if (!data.message) {
      setTagOptions(data);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const createTag = async () => {
    if ((!tagText) || (!colorPick)) {
      showToast("Cor ou texto da tag não podem ser vazios!", "error");
      return
    }
    if (tagOptions.some(tag => tag.title === tagText)) {
      showToast("Tag de mesmo nome já existente", "error");
      return
    }
    setTagOptions(prevData => [...prevData, { title: tagText, bgcolor: colorPick }]);
    setTagsArr(prevData => [...prevData, { title: tagText, bgcolor: colorPick }]);
    setOpenCreateTagsModal(false);
    setTagText('');
    setColorPick('');
  };

  const deleteSelectedTags = async () => {
    const excludeTagsWithoutId = tagsArr.filter((tag) => tag.id);
    await deleteCommercialTag(excludeTagsWithoutId).then((res) => {
      if (!res.message) {
        showToast("Tags deletadas com sucesso!", "success");
        setOpenTagDeletionModal(false);
        setTags([]);
        setTagsArr([]);
        fetchTags();
        refetch();
      }
      if (res.message) showToast("Falha ao deletar tags", "error");
    });
  };

  const verifyIfOnlyNewTags = () => {
    const tagsIdCheck = tagsArr?.every(tag => tag?.id === undefined);
    return tagsIdCheck
  };

  return (
    <>
      <Grid container flexDirection='column' mt='10px' gap='20px'>
        <p>Etiquetas</p>
        <S.MultilineInputGrid container item mt={2} pb='10px'>
          <Autocomplete
            disablePortal
            disableCloseOnSelect
            multiple
            value={tagsArr}
            onChange={(e, value) => {
              setTagsArr(value);
            }}
            options={tagOptions ? tagOptions : []}
            sx={{ width: "100%" }}
            renderTags={(value, getTagProps, ownerState) => value.map((tag) => (<S.Tag style={{ backgroundColor: tag.bgcolor }} key={tag?.id}>{tag.title}</S.Tag>))}
            renderInput={(params) => <S.Input {...params} placeholder='Etiquetas' />}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <S.Tag style={{ backgroundColor: option.bgcolor }}>{option.title}</S.Tag>
              </li>
            )}
          />
        </S.MultilineInputGrid>
        <Grid container justifyContent='flex-end' gap='16px'>
          <S.BadgeContainer display='flex' /* alignItems='flex-end' item md={6} */>
            <S.RejectedBadgeGrid
              item
              className={(tagsArr?.length === 0 || verifyIfOnlyNewTags()) ? 'disabled' : ''}
              onClick={() => {(tagsArr?.length === 0 || verifyIfOnlyNewTags()) ? undefined : setOpenTagDeletionModal(true)}}
            >
              <RiDeleteBin6Line size='18px' />
            </S.RejectedBadgeGrid>
          </S.BadgeContainer>
          <ButtonCustom
            onClick={() => setOpenCreateTagsModal(true)}
            sx={{ maxWidth: '150px', height: '30px !important', alignSelf: 'flex-end', marginBottom: '20px' }}
          >
            Criar Nova Etiqueta
          </ButtonCustom>
        </Grid>
        <ButtonCustom
          sx={{ maxWidth: '70px', height: '30px !important', alignSelf: 'flex-end' }}
          onClick={() => { setTags('tags', tagsArr); closeModal(); }}
        >
          Salvar
        </ButtonCustom>
      </Grid>
      <Modal size='sm' open={openCreateTagsModal} onClose={() => setOpenCreateTagsModal(false)}>
        <Grid container flexDirection='column' mt='10px' gap='20px'>
          <p>Título</p>
          <S.SelectInputGrid container item>
            <S.Input
              /* placeholder='Insira um título' */
              name='description'
              id='description'
              value={tagText}
              onChange={(e) => setTagText(e.target.value)}
            />
          </S.SelectInputGrid>
          <Grid>
            <p>Selecionar uma cor</p>
            <S.ColorContainer container gap='10px' mt='10px'>
              {colors.map((color) => (
                <S.ColorPick key={color} onClick={() => setColorPick(color)} className={color === colorPick ? 'activeColor' : ''}>
                  <S.InnerColor style={{ backgroundColor: color }} />
                </S.ColorPick>
              ))
              }
            </S.ColorContainer>
          </Grid>
          <Grid>
            <p>Pré Visualização</p>
            <Grid container>
              <S.Tag style={{ backgroundColor: colorPick }}>{tagText}</S.Tag>
            </Grid>
          </Grid>
          <ButtonCustom
            onClick={() => createTag()}
            sx={{ maxWidth: '70px', height: '30px !important', alignSelf: 'flex-end' }}
            data-cy="save-tag"
          >
            Salvar
          </ButtonCustom>
        </Grid>
      </Modal>
      <Modal size='sm' open={openTagDeletionModal} onClose={() => setOpenTagDeletionModal(false)}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Excluir etiquetas</p>
          <p style={{ fontSize: "18px" }}>Tem certeza que deseja excluir as etiquetas selecionadas?</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom
                label='Cancelar'
                onClick={() => setOpenTagDeletionModal(false)}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              />
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label='Confirmar'
                onClick={deleteSelectedTags}
                sx={{ background: "linear-gradient(180deg, #E55542 0%, #D7007B 154.17%)" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default TagsModal