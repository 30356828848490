import { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { IoAlertCircleOutline, IoEllipsisVertical } from "react-icons/io5";
import * as S from './style'
import moment from 'moment';
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Table from "defaultComponents/Tables/Table";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { BsClockFill } from "react-icons/bs";
import Modal from "components/Modal/Modal";
import { approveWithdraw, rejectWithdraw } from "utils/requests/withdraw";
import Pagination from '@mui/material/Pagination';
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style"
import { getAllWithdrawals } from "utils/requests/withdraw";
import VuiAvatar from "components/VuiAvatar";
import { RiArrowRightSLine } from "react-icons/ri";


const Avatar = ({ img, theme, children, name, ...rest }) => (
  <VuiAvatar
    src={img}
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
      width: '40px',
      height: '40px'
    })}
  >
    {children}
  </VuiAvatar>
)

const GeneralCashout = () => {
  const [openConfirmActualCashoutModal, setOpenConfirmActualCashoutModal] = useState(false);
  const [openConfirmFutureCashoutModal, setOpenConfirmFutureCashoutModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [page, setPage] = useState(1);
  const [financialData, setFinancialData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [chosenEntry, setChosenEntry] = useState();
  const [chosenWithdraw, setChosenWithdraw] = useState();

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const fetchData = async () => {
    await getAllWithdrawals(page - 1, 5).then(res => {
      if (!res?.message) {
        setFinancialData(res);
      };
    });
  };

  const calculatePagination = () => {
    const roundUpArrSize = financialData?.notPendingPleas ? Math.ceil(financialData?.notPendingPleas[1] / 5) : 0;
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (financialData) calculatePagination();
  }, [financialData]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const statusColor = {
    APPROVED: '#5ACC93',
    REJECTED: '#FF1A9D',
    PENDING: '#E86A34'
  }

  const statusTranslation = {
    APPROVED: 'RESGATE APROVADO',
    REJECTED: 'RESGATE RECUSADO',
    PENDING: 'EM PROCESSAMENTO'
  };

  const handleActualApproval = async () => {
    await approveWithdraw(chosenWithdraw?.id).then((res) => {
      if (!res.message) {
        showToast('Saque de saldo atual aprovado com sucesso!', 'success');
        fetchData();
        setOpenConfirmActualCashoutModal(false);
        return
      }
      showToast('Erro na aprovação do saque de saldo atual', 'error');
    })
  };

  const handleFutureApproval = async () => {
    await approveWithdraw(chosenWithdraw?.id).then((res) => {
      if (!res.message) {
        showToast('Saque de saldo futuro aprovado com sucesso!', 'success');
        fetchData();
        setOpenConfirmFutureCashoutModal(false);
        return
      }
      showToast('Erro na aprovação do saque de saldo futuro', 'error');
    })
  };

  const handleFutureRejection = async () => {
    await rejectWithdraw(chosenWithdraw?.id).then((res) => {
      if (!res.message) {
        showToast('Saque de saldo futuro rejeitado com sucesso!', 'success');
        fetchData();
        setOpenConfirmFutureCashoutModal(false);
        return
      }
      showToast('Erro na rejeição do saque de saldo futuro', 'error');
    })
  };

  const handleWithdrawType = (withdraw) => {
    if (withdraw?.type === 'FUTURE_BALANCE') {
      setOpenConfirmFutureCashoutModal(true);
    };
    if (withdraw?.type === 'CURRENT_BALANCE') {
      setOpenConfirmActualCashoutModal(true);
    };
  };

  return (
    <DashboardLayout onClick={() => openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null}>
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container flexDirection='column' gap='15px'>
        <Card sx={{ maxHeight: '580px', overflow: 'auto', paddingTop: '10px', paddingBottom: 0 }}>
          <S.TableGrid container flexDirection='column'>
            <Grid container>
              <BsClockFill color='white' size='22px' style={{ alignSelf: "center", marginRight: "8px" }} />
              <p>Solicitações de Saque</p>
            </Grid>
            <Grid container flexDirection='column'>
              {financialData?.withdrawPleasPending && financialData?.withdrawPleasPending[1] === 0 &&
                <S.EmptyMessageGrid container>
                  <p>Não há solicitações</p>
                </S.EmptyMessageGrid>
              }
              {financialData?.withdrawPleasPending && financialData?.withdrawPleasPending[1] > 0 &&
                <Table
                  columns={[
                    { name: "influencer", align: "left" },
                    { name: "valor", align: "left" },
                    { name: "tipo", align: "left" },
                    { name: "solicitação", align: "left" },
                    { name: "observações", align: "left" },
                  ]}
                  rows={
                    financialData?.withdrawPleasPending && financialData?.withdrawPleasPending[0]?.map((withdraw, index) => {
                      return {
                        influencer: (
                          <Grid container key={index} gap='10px'>
                            <Avatar img={withdraw?.influencerRelated?.avatar} name={withdraw?.influencerRelated?.name} />
                            <p>{withdraw?.influencerRelated?.name}</p>
                          </Grid>
                        ),
                        valor: (
                          <Grid container>
                            <p style={{ color: '#E86A34' }}>R$ {Number((withdraw?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                          </Grid>
                        ),
                        tipo: (
                          <p>{withdraw?.type === 'FUTURE_BALANCE' ? 'Saldo Futuro' : 'Saldo Atual'}</p>
                        ),
                        solicitação: (
                          <p>{withdraw?.createdAt ? moment(withdraw?.createdAt).format('L') : '-'}</p>
                        ),
                        observações: (
                          <Grid container flexDirection='column'>
                            <Grid container justifyContent='space-between'>
                              <S.MiscInfoGrid item xs={8.5} xl={8.5}>
                                <p>{withdraw?.miscInfo ? withdraw?.miscInfo : '-'}</p>
                              </S.MiscInfoGrid>
                              <S.EvaluateWithdrawGrid
                                container
                                item
                                xs={3.5}
                                xl={3.5}
                                onClick={() => { setChosenWithdraw(withdraw); handleWithdrawType(withdraw) }}
                              >
                                <p>Avaliar</p>
                                <RiArrowRightSLine color="#FB8404" size='20px' />
                              </S.EvaluateWithdrawGrid>
                            </Grid>
                          </Grid>
                        )
                      }
                    })}
                />
              }
            </Grid>
          </S.TableGrid>
        </Card>
        <Card sx={{ paddingY: '10px' }}>
          <S.TableGrid container flexDirection='column'>
            <p>Histórico</p>
            <Grid container flexDirection='column'>
              {(financialData?.notPendingPleas && financialData?.notPendingPleas[1] === 0) &&
                <S.EmptyMessageGrid container>
                  <p>Não há solicitações anteriores</p>
                </S.EmptyMessageGrid>
              }
              {financialData?.notPendingPleas && financialData?.notPendingPleas[1] > 0 &&
                <Table
                  columns={[
                    { name: "influencer", align: "left" },
                    { name: "status", align: "left" },
                    { name: "valor", align: "left" },
                    { name: "solicitação", align: "left" },
                    { name: "resposta", align: "left" },
                    { name: "observações", align: "left" },
                  ]}
                  rows={
                    financialData?.notPendingPleas && financialData?.notPendingPleas[0]?.map((entry, index) => {
                      return {
                        influencer: (
                          <Grid container>
                            <p>{entry?.influencerRelated?.name}</p>
                          </Grid>
                        ),
                        status: (
                          <Grid container key={index}>
                            <Grid item md={8} xl={9}>
                              <p>{statusTranslation[entry?.status]}</p>
                            </Grid>
                          </Grid>
                        ),
                        valor: (
                          <Grid container>
                            <p style={{ color: statusColor[entry?.status] }}>R$ {Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                          </Grid>
                        ),
                        solicitação: (
                          <p>{entry?.createdAt ? moment(entry?.createdAt).format('L') : '-'}</p>
                        ),
                        resposta: (
                          <p>{entry?.resolvedAt ? moment(entry?.resolvedAt).format('L') : '-'}</p>
                        ),
                        observações: (
                          <Grid container flexDirection='column'>
                            <Grid container justifyContent='space-between'>
                              <S.MiscInfoGrid item xs={11} xl={11}>
                                <p>{entry?.miscInfo}</p>
                              </S.MiscInfoGrid>
                              <Grid item xs={1} xl={1} onClick={() => toggleOpenMenu(index)}>
                                <IoEllipsisVertical size='20px' style={{ cursor: "pointer", zIndex: "9999" }} />
                              </Grid>
                              {openMenu[index] &&
                                <S.OptionsContainer container justifyContent='flex-end'>
                                  <S.OptionsGrid display='flex' flexDirection='column'>
                                    <p onClick={() => { setOpenDetailsModal(true); setChosenEntry(entry); }}>Ver Detalhes</p>
                                  </S.OptionsGrid>
                                </S.OptionsContainer>
                              }
                            </Grid>
                          </Grid>
                        )
                      }
                    })}
                />
              }
            </Grid>
          </S.TableGrid>
          {pagination?.length > 0 &&
            <S.PaginationGrid container justifyContent='center' sx={{ marginTop: "20px" }}>
              <Pagination count={pagination?.length} page={page} siblingCount={1} boundaryCount={1} onChange={handleChange} />
            </S.PaginationGrid>
          }
        </Card>
      </Grid>
      <Modal size='md' open={openConfirmActualCashoutModal} onClose={() => { setOpenConfirmActualCashoutModal(false); setChosenWithdraw(); }}>
        <S.ModalContainer container flexDirection='column' gap='20px'>
          <Grid container gap='5px'>
            <p className="modalTitle">Autorizar saque de</p>
            <p className="modalTitle bold">saldo atual</p>
          </Grid>
          <p className="cashoutValue">R$ {Number(((chosenWithdraw?.value ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <S.AlertGrid container gap='5px'>
            <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
            <p className='bold'>Lembrete: </p>
            <p>Valor precisa ser creditado na conta em até 48h.</p>
          </S.AlertGrid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom
              label='Autorizar'
              onClick={() => handleActualApproval()}
              sx={{
                width: '150px',
                background: 'linear-gradient(180deg, #5ACC93 0%, #027D3F 155.15%)',
                fontSize: '18px',
                fontWeight: 500,
                height: '40px !important'
              }}
            />
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal size='md' open={openConfirmFutureCashoutModal} onClose={() => { setOpenConfirmFutureCashoutModal(false); setChosenWithdraw() }}>
        <S.ModalContainer container flexDirection='column' gap='20px'>
          <Grid container gap='5px'>
            <p className="modalTitle">Autorizar saque de</p>
            <p className="modalTitle bold">saldo futuro</p>
          </Grid>
          <Grid container flexDirection='column' alignContent='center'>
            <p className="cashoutValue">R$ {Number(((chosenWithdraw?.value ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            <p className="discountWarning">Taxa de 5% (- R$ {Number((((chosenWithdraw?.value ?? 0) * 0.05) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</p>
          </Grid>
          <S.AlertGrid container gap='5px'>
            <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
            <p className='bold'>Lembrete: </p>
            <p>Valor depende de aprovação e caso aprovado será abatido em 5%.</p>
          </S.AlertGrid>
          <Grid container justifyContent='flex-end' gap='15px'>
            <ButtonCustom
              label='Negar'
              onClick={() => handleFutureRejection()}
              sx={{
                width: '150px',
                background: 'linear-gradient(180deg, #D04494 0%, #D7004D 155.15%)',
                fontSize: '18px',
                fontWeight: 500,
                height: '40px !important'
              }}
            />
            <ButtonCustom
              label='Autorizar'
              onClick={() => handleFutureApproval()}
              sx={{
                width: '150px',
                background: 'linear-gradient(180deg, #5ACC93 0%, #027D3F 155.15%)',
                fontSize: '18px',
                fontWeight: 500,
                height: '40px !important'
              }}
            />
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal open={openDetailsModal} onClose={() => { setOpenDetailsModal(false); setChosenEntry(); }}>
        <S.DetailsMainGrid container flexDirection='column' gap='15px'>
          <p className='titleDetails'>Detalhes</p>
          <Grid container gap='20px'>
            <Grid container justifyContent='space-between'>
              <Grid container item flexDirection='column' xs={12} md={5.85} xl={5.85}>
                <p className='subtitleDetails'>Nº do processo</p>
                <p className="valueDetails">{chosenEntry?.processNumber}</p>
              </Grid>
              <Grid container item flexDirection='column' xs={12} md={5.85} xl={5.85}>
                <p className='subtitleDetails'>Status</p>
                <p className="valueDetails">{statusTranslation[chosenEntry?.status]}</p>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid container item flexDirection='column' xs={12} md={5.85} xl={5.85}>
                <p className='subtitleDetails'>Data da Solicitação</p>
                <p className="valueDetails">{chosenEntry?.createdAt ? moment(chosenEntry?.dateInvoiced).format('L') : ''}</p>
              </Grid>
              <Grid container item flexDirection='column' xs={12} md={5.85} xl={5.85}>
                <p className='subtitleDetails'>Data da Resposta</p>
                <p className="valueDetails">{chosenEntry?.resolvedAt ? moment(chosenEntry?.resolvedAt).format('L') : ''}</p>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid container item flexDirection='column' xs={12} md={5.85} xl={5.85}>
                <p className='subtitleDetails'>Valor</p>
                <p className="valueDetails">
                  R$ {Number((chosenEntry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </p>
              </Grid>
              <Grid container item flexDirection='column' xs={12} md={5.85} xl={5.85}>
                <p className='subtitleDetails'>Tipo</p>
                <p className="valueDetails">
                  {chosenEntry?.type === 'FUTURE_BALANCE' ? 'Saldo Futuro' : 'Saldo Atual'}
                </p>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid container item flexDirection='column' xs={12} xl={12}>
                <p className='subtitleDetails'>Observações</p>
                <p className="valueDetails wrap">{chosenEntry?.miscInfo}</p>
              </Grid>
            </Grid>
          </Grid>
          <S.AlertGrid container gap='5px'>
            <IoAlertCircleOutline color='red' style={{ alignSelf: "center" }} />
            <p className='subtitleDetails reminderDetails'>Lembrete:</p>
            <p className='subtitleDetails'>Valor será creditado na conta em até 48h.</p>
          </S.AlertGrid>
        </S.DetailsMainGrid>
      </Modal>
    </DashboardLayout>
  )
}

export default GeneralCashout