export const getColors = () => {
  const colors = [
    '116,79,198', '212,77,92', '79,176,198', '181,255,225', 
    '175,252,65', '254,234,0', '164,66,0', '51,81,69', 
    '142,105,29', '1, 86, 41', '164, 101, 198', '249, 174, 197', 
    '206, 133, 88', '154, 214, 134', '72, 81, 81', '26, 157, 244', 
    '52, 47, 84', '165, 13, 71', '1, 148, 181', '161, 81, 247', 
    '202, 224, 58', '242, 43, 152', '17, 187, 193', '104, 82, 234', 
    '247, 127, 108'
  ];

  return colors;
};