import { useState, useContext, useEffect, useRef } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card, Box, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel } from '@mui/material';
import Board from 'react-trello'
import Modal from 'components/Modal/Modal';
import pencil from 'assets/icons/pencil.png';
import * as S from './style'
import { BsQuestionLg } from "react-icons/bs";
import CardModal from "./CardModal/CardModal";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Tag from 'react-trello/dist/components/Card/Tag'
import moment from 'moment';
import 'moment/locale/pt-br';
import { BsClock } from "react-icons/bs";
import { IoWarningOutline, IoAlertCircleOutline } from "react-icons/io5";
import { createCommercialCard } from "utils/requests/comercial";
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { getSellers } from "utils/requests/other";
import { TokenContext } from "context/TokenContext";
import { RiAddFill } from 'react-icons/ri';
import CreateCardModal from "./CreateCardModal/CreateCardModal";
import { getCommercialKanbanBoard } from "utils/requests/project";
import { useParams, useNavigate } from "react-router-dom";
import { editBriefingProject } from "utils/requests/project";
import { rejectProject } from "utils/requests/project";
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));


const Kanban = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [open, setOpen] = useState(false);
  const [inlineEdit, setInlineEdit] = useState(false);
  const [boardData, setBoardData] = useState([{ lanes: [] }]);
  const [cardId, setCardId] = useState('');
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [chosenSeller, setChosenSeller] = useState('');
  const [openCreateCardModal, setOpenCreateCardModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,

    initialValues: {
      mainReason: '',
      notes: '',
    },
    validationSchema: Yup.object({
      mainReason: Yup.string().required("Motivo é um campo obrigatório"),
      notes: Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      const newRejection = {
        mainReason: values.mainReason ?? '',
        notes: values.notes ?? '',
      };

      Object.keys(newRejection).forEach(key => {
        if (newRejection[key] === '') {
          delete newRejection[key];
        };
      });

      await rejectProject(cardId, newRejection).then((res) => {
        if (!res.message) {
          setOpen(false);
          setOpenConfirmDeletionModal(false);
          showToast("Projeto recusado com sucesso", "success");
          formik.resetForm();
          fetchData();
        } else {
          showToast("Falha ao recusar projeto", "error");
        };
      });
    }
  });

  const handleRadioChange = (event) => {
    formik.setFieldValue('mainReason', event.target.value);
    if (formik.values.mainReason) {
      formik.validateForm();
    };
  };

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      const changedArr = [...data, { name: 'Todos', username: 'todos' }]
      setSellers(changedArr);
    };
  };

  const fetchData = async () => {
    const data = await getCommercialKanbanBoard(chosenSeller?.username !== 'todos' ? [chosenSeller?.username] : []);
    if (!data.message) {
      setBoardData([data[0]?.data]);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  useEffect(() => {
    setChosenSeller(sellers.find(el => el.username === params?.username))
  }, [sellers])

  useEffect(() => {
    if (chosenSeller && chosenSeller?.username !== params?.username) {
      navigate(`/comercial/pipeline/${chosenSeller?.username}`)
    }
    if (chosenSeller) fetchData();
  }, [chosenSeller]);

  const FLAT_TRANSLATION_TABLE = {
    "Add another lane": "+ Adicionar lista",
    "Click to add card": "+ Novo Card",
    "Delete lane": "Deletar lista",
    "button.Add lane": "Adicionar lista",
    "button.Add card": "Novo card",
    "button.Cancel": "Cancelar",
    "placeholder.title": "Título",
    "placeholder.description": "Descrição",
    "placeholder.label": "Label"
  };

  const laneExplanations = {
    "Leads 🔥": 'Empresas que demonstraram interesse no negócio',
    "Relacionamento 🎯": 'Lead sendo preparado para conversão',
    "FUP - Leads 🆙": 'Momento de fornecer informações adicionais e esclarecer dúvidas.',
    "Em Negociação 💰": 'Valores do négocio sendo negociados com o cliente.',
    "Contratos Fechados 🤝": 'Negociação aprovada com prazo paga pagamento e execução do projeto'
  };

  const CustomLaneHeader = ({ label, cards, title, current, target }) => {
    const laneData = boardData[0]?.lanes?.find(lane => lane?.title === title);
    return (
      <div>
        <Grid container flexDirection='column'>
          <header
            style={{
              paddingBottom: 6,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>{title}</div>
            <LightTooltip title={laneExplanations[title]}>
              <div style={{ width: 'fit-content', textAlign: 'right', fontSize: 13 }}>
                <BsQuestionLg style={{ backgroundColor: '#000' }} />
              </div>
            </LightTooltip>
          </header>
          <Grid display='flex' flexDirection='column' justifyContent='center'>
            <S.BadgeGrid container gap='3px'>
              <p className='percentage'>
                R$ {Number((laneData?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2, maximumFractionDigits: 2
                })}
              </p>
            </S.BadgeGrid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const createCard = async (title, description, status, cancel) => {
    const normalizedStatus = String(status).replace(/-/g, "_").toUpperCase();
    if (!title) {
      showToast("Título do card é obrigatório!", "error");
      return
    };
    const cardInfo = {
      title,
      description: description ?? '',
      status: normalizedStatus,
      pipeline: chosenSeller?.id
    };
    await createCommercialCard(cardInfo).then((res) => {
      if (res.id) {
        showToast("Card criado com sucesso!", "success");
        fetchData();
        /*         cancel(); */
      } else {
        showToast("Falha na criação do card", "error");
      };
    });
  };

  const changeCardStatus = async (fromLaneId, newLane, id) => {
    const validMovement = fromLaneId !== newLane ? true : false;
    if (validMovement) {
      const normalizedStatus = String(newLane).replace(/-/g, "_").toUpperCase();
      await editBriefingProject(id, { status: normalizedStatus }).then((res) => {
        if (res?.message === 'Um projeto em andamento não pode voltar para fases de negociação!') {
          showToast(res?.message, "error");
          fetchData();
          return
        };
        if (res.title) {
          fetchData();
        } else {
          showToast("Erro inesperado ao mover card", "error");
          fetchData();
        };
      });
    };
  };

  const NewCardForm = ({ onCancel, laneId }) => {
    const titleRef = useRef(null);
    const descRef = useRef(null);

    const createCardHandler = () => {
      const title = titleRef.current.value;
      const description = descRef.current.value;
      createCard(title, description, laneId, onCancel);
    };

    return (
      <div style={{ background: '#000' }}>
        <div style={{ padding: 5, margin: 5 }}>
          <div>
            <div style={{ marginBottom: 5 }}>
              <S.CardFormInput type="text" ref={titleRef} placeholder="Título" />
            </div>
            <div style={{ marginBottom: 5 }}>
              <S.CardFormInput type="text" ref={descRef} placeholder="Descrição" />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <S.CardFormAddButton onClick={createCardHandler}>Novo Card</S.CardFormAddButton>
            <S.CardFormCancelButton onClick={onCancel}>Cancelar</S.CardFormCancelButton>
          </div>
        </div>
      </div>
    );
  };

  const CustomCard = ({
    onClick,
    title,
    subTitle,
    tagStyle,
    tags,
    onDelete,
    description,
    dueDate
  }) => {
    const clickDelete = e => {
      onDelete()
      e.stopPropagation()
    }

    const actualTime = new Date().getTime();
    const timeComparison = moment(dueDate, 'YYYY-MM-DD HH:mm').valueOf() - actualTime;

    return (
      <S.CardWrapper
        onClick={onClick}
      >
        <S.CardHeader
          style={{
            paddingBottom: 6,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: '#000',
            backgroundColor: '#000'
          }}>
          <S.CardTitle>{title}</S.CardTitle>
          {timeComparison > 86400000 &&
            <S.DueDate>
              <p>{dueDate ? moment(dueDate, 'YYYY-MM-DD HH:mm').format('DD [de] MMM') : "-"}</p>
            </S.DueDate>
          }
          {timeComparison > 0 && timeComparison <= 86400000 &&
            <S.DueDateWarning>
              <BsClock size='12px' />
              <p>{dueDate ? moment(dueDate, 'YYYY-MM-DD HH:mm').format('DD [de] MMM') : "-"}</p>
            </S.DueDateWarning>
          }
          {timeComparison < 0 &&
            <S.DueDateDelayed>
              <IoWarningOutline size='16px' />
              <p>{dueDate ? moment(dueDate, 'YYYY-MM-DD HH:mm').format('DD [de] MMM') : "-"}</p>
            </S.DueDateDelayed>
          }
          {/* {showDeleteButton && <DeleteButton onClick={clickDelete} />} */}
        </S.CardHeader>
        <div style={{ fontSize: 12, color: '#BD3B36' }}>
          <div style={{ color: '#4C4C4C', fontWeight: 'bold', backgroundColor: '#000' }}>{subTitle}</div>
          <S.Detail>
            <p>{description}</p>
          </S.Detail>
          {tags && (
            <S.TagGrid
              style={{
                paddingTop: 6,
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}>
              {tags.map(tag => (
                <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
              ))}
            </S.TagGrid>
          )}
        </div>
      </S.CardWrapper>
    )
  }


  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={role === 'COMERCIAL' ? ['comercial', decodedToken?.username] : ''} />
      <S.MainContainer container spacing='18px'>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card sx={{ width: "100%", paddingTop: "40px", minHeight: "800px" }}>
          {/*           <S.EditButton sx={{ alignSelf: "flex-end" }} onClick={() => setInlineEdit(!inlineEdit)}>
            <img src={pencil} />
          </S.EditButton> */}
          {role !== 'COMERCIAL' &&
            <S.SelectInputGrid item xs={12} md={12} xl={12}>
              <Autocomplete
                disableClearable
                disablePortal
                value={chosenSeller ? chosenSeller : null}
                onChange={(e, value) => setChosenSeller(value)}
                options={sellers ? sellers : []}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => <S.Input {...params} placeholder='Vendedor' />}
                isOptionEqualToValue={(option, value) => option.username === value.username}
              />
            </S.SelectInputGrid>
          }
          {boardData?.lanes?.length === 0 &&
            <Grid mt='20px' pl='10px'>
              <p>{'Sem dados para exibir'}</p>
            </Grid>
          }
          {boardData &&
            <Board
              components={{ LaneHeader: CustomLaneHeader, Card: CustomCard, NewCardForm: NewCardForm }}
              editLaneTitle={inlineEdit}
              editable={true}
              hideCardDeleteIcon
              draggable={inlineEdit}
              canAddLanes={inlineEdit}
              data={boardData ? boardData[0] : []}
              t={key => FLAT_TRANSLATION_TABLE[key]}
              style={{ backgroundColor: 'black', color: '#fff', height: '80vh', overflow: 'auto' }}
              cardStyle={{ backgroundColor: '#0A0A0A', color: '#fff' }}
              laneStyle={{ backgroundColor: '#101010', color: '#fff' }}
              onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => { changeCardStatus(fromLaneId, toLaneId, cardId) }}
              onCardClick={(cardId, metadata, laneId, data) => { setOpen(true); setCardId(cardId) }}
            />
          }
        </Card>
      </S.MainContainer>
      <Modal open={open} onClose={() => { setOpen(false); setCardId('') }} deleteFunc={() => setOpenConfirmDeletionModal(true)}>
        <CardModal
          cardId={cardId}
          refetchBoard={fetchData}
          commercialId={chosenSeller?.id}
          closeModal={() => { setOpen(false); setCardId('') }}
        />
      </Modal>
      <Modal
        size='md'
        open={openConfirmDeletionModal}
        onClose={() => { setOpenConfirmDeletionModal(false); formik.resetForm() }}
      >
        <Grid container flexDirection='column' gap='10px'>
          <p style={{ fontSize: "24px" }}>Confirmar Recusa</p>
          <p style={{ fontSize: "14px", color: '#8F8F8F' }}>Selecione motivo:</p>
          <Formik
            initialValues={formik.initialValues}
            validationSchema={formik.validationSchema}
            onSubmit={formik.handleSubmit}
          >
            <Form>
              <Grid container flexDirection='column' gap='20px'>
                <FormControl>
                  <RadioGroup
                    value={formik.values.mainReason}
                    onChange={handleRadioChange}
                  >
                    <Grid container flexDirection='column' gap='15px'>
                      <FormControlLabel
                        value="Preço elevado / Orçamento insuficiente"
                        control={<Radio />}
                        label="Preço elevado / Orçamento insuficiente"
                      />
                      <FormControlLabel
                        value="Concorrência (Escolheu outra agência)"
                        control={<Radio />}
                        label="Concorrência (Escolheu outra agência)"
                      />
                      <FormControlLabel
                        value="Cliente indeciso"
                        control={<Radio />}
                        label="Cliente indeciso"
                      />
                      <FormControlLabel
                        value="Perda de contato com o cliente"
                        control={<Radio />}
                        label="Perda de contato com o cliente"
                      />
                      <FormControlLabel
                        value="Cancelamento do projeto pelo cliente"
                        control={<Radio />}
                        label="Cancelamento do projeto pelo cliente"
                      />
                      <FormControlLabel
                        value="Outros"
                        control={<Radio />}
                        label="Outros"
                      />
                    </Grid>
                  </RadioGroup>
                  {(Boolean(formik.errors.mainReason)) &&
                    <FormHelperText sx={{ color: "#f44336" }}>
                      {formik.errors.mainReason}
                    </FormHelperText>
                  }
                </FormControl>
                <S.RejectInputGrid item xs={12} md={12} xl={12}>
                  <S.RejectInput
                    id="notes"
                    name='notes'
                    hiddenLabel
                    placeholder="Observações"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                  />
                </S.RejectInputGrid>
                <S.AlertGrid container gap='5px' mt='5px'>
                  <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
                  <p className='bold'>Lembrete: </p>
                  <p>Tem certeza que deseja confirmar a recusa deste projeto?</p>
                </S.AlertGrid>
                <Grid container gap='15px' justifyContent='flex-end'>
                  <Grid item lg={3}>
                    <ButtonCustom
                      label='Confirmar'
                      type='submit'
                      sx={{
                        background: "linear-gradient(180deg, #E55542 0%, #D7007B 154.17%)",
                        fontSize: "16px"
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Modal>
      <Modal open={openCreateCardModal} onClose={() => setOpenCreateCardModal(false)}>
        <CreateCardModal closeModal={() => setOpenCreateCardModal(false)} refetch={fetchData} />
      </Modal>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <S.ModalFab color="primary" onClick={() => setOpenCreateCardModal(true)}>
          <RiAddFill size='30px' />
        </S.ModalFab>
      </Box>
    </DashboardLayout >
  )
}

export default Kanban