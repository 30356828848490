import styled from "styled-components";
import { Grid, TextField } from "@mui/material";

export const MainContainer = styled(Grid)`
  padding: 4px 8px 4px 8px;

  .columnTitle {
    font-size: 18px;
  }

  .dueDate {
    font-size: 13px;
  }
`

export const CardTitleGrid = styled(Grid)`
  padding-bottom: 12px;

  .subTitle {
    font-size: 14px;
  }
`

export const CardDetails = styled(Grid)`
  padding-bottom: 12px;
  font-size: 13px;
`

export const Tag = styled.span`
  min-width: 65px;
  text-align: center;
  padding: 2px 10px;
  border-radius: 3px;
  margin: 2px 5px;
  font-size: 14px;
  color: #fff;
  /* height: 100%; */
`

export const Input = styled(TextField)`
  background-color: transparent;
  border-radius: 10px;
  width: 100%;
  min-height: 40px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const SelectInputGrid = styled(Grid)`
  gap: 20px;
  width: 100%;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 5px !important;
    transform: none !important;
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding-top: 0 !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 5px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    min-height: 45px;
    border-radius: 10px;
    border: 0.4px solid;
    border-color: rgba(255, 255, 255, 0.50) !important;

    &:hover {
      border: 0.4px solid rgba(255, 255, 255, 0.50) !important;
    }
  }
  .MuiAutocomplete-popper {
    background-color: #0E0E0E;
    border: 1px solid;
  }
  .MuiFormHelperText-root{
    margin-top: 5px;
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const MultilineInputGrid = styled(SelectInputGrid)`
  .MuiInputBase-root{
    padding: 0 10px
  }

  .MuiOutlinedInput-notchedOutline {
    min-height: 45px;
    height: 100%;
  }
`

export const TitleInputGrid = styled(MultilineInputGrid)`
  .MuiInputBase-root{
    max-width: 500px;
  }
`

export const ColorContainer = styled(Grid)`
  .activeColor {
    border: 2px #ed1414 solid;
    border-radius: 5px;
  }
`

export const ColorPick = styled.div`
  position: relative;
  border-radius: 3px;
  border: 2px transparent solid;
  width: 54px;
  height: 32px;
`

export const InnerColor = styled.div`
  position: relative;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  cursor: pointer;
`

export const CommentTimestamp = styled.p`
  font-size: 14px;
  align-self: center;
`

export const DeleteText = styled.p`
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    color: #E22B00;
  }
`

export const DueDate = styled.div`
  font-size: 14px;
  padding: 5px 10px;
  background-color: #424242;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 5px;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover{
    background-color: #616161;
  }
`

export const DateAlertFlag = styled.span`
  font-size: 12px;
  padding: 2px 5px;
  background-color: #e2b203;
  color: #172b4d;
  border-radius: 2px;
`

export const DateAlertExpireFlag = styled(DateAlertFlag)`
  background-color: #42221f;
  color: #fd9891;
`

export const Divider = styled.hr`
  border: 1px solid #686868;
`

export const AttachmentDate = styled.p`
  font-size: 14px;
`

export const BadgeGrid = styled(Grid)`
  font-size: 15px;
  padding: 5px 20px;
  border-radius: 15px;
  background: linear-gradient(180deg, #9e5302 0%, #8e110c 155.15%),#FB7E04;
  text-align: center;
`

export const RejectedBadgeGrid = styled(BadgeGrid)`
  background: linear-gradient(180deg, #E55542 0%, #D7007B 154.17%);
  cursor: pointer;
  height: 30px;
  border-radius: 5px;
  padding-left: 0;
  padding-right: 0;
  width: 35px;
`

export const BadgeContainer = styled(Grid)`
  justify-content: flex-end;
  transition: all 150ms ease-in;
  
  :hover {
    transform: scale(1.02);
  }

  .disabled {
    background: linear-gradient(180deg, #a0a0a0 0%, #2e2e2e 154.17%);
    cursor: default;
  }
`