import React from 'react'
import { Grid } from '@mui/material'
import * as S from "./style"
import InputAdornment from '@mui/material/InputAdornment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailIcon from '@mui/icons-material/Mail';
import * as Yup from "yup";
import { useFormik } from "formik";
import Viraliza from '../../assets/viraliza.svg'
import Viuzz from '../../assets/viuzz.svg'
import { useNavigate } from 'react-router-dom';
import { requestPasswordChange } from 'utils/requests/auth';
import showToast from 'components/Toast/Toast';
import * as T from "components/Toast/style"

const ForgottenPassword = () => {
  const navigate = useNavigate();

  /*   const user = localStorage.getItem("dashUser");
  
    React.useEffect(() => {
      if(user) {
        navigate("/")
      };
    }); */

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email").email(),
    }),
    onSubmit: async (values) => {
      await requestPasswordChange(values.email).then((res) => {
        showToast("Solicitação realizada com sucesso, caso a conta exista, verifique seu email em alguns instantes", "success");
        setTimeout(() => navigate('/login'), 3000);
      });
    }
  });

  return (
    <>
      <S.MainContainer container justifyContent='center'>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <S.BgGrid container>
          <Grid container flexDirection='column' justifyContent='space-evenly'>
            <img src={Viraliza} />
            <form
              onSubmit={validation.handleSubmit}
            >
              <Grid container flexDirection='column' sx={{ marginBottom: "100px" }}>
                <S.BoxGrid container flexDirection='column'>
                  <hr className='gradientBar'></hr>
                </S.BoxGrid>
                <S.InputGrid container flexDirection='column'>
                  <S.Input
                    hiddenLabel
                    name='email'
                    type='email'
                    placeholder="Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    /* error={validation.errors.email ? true : false} */
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <MailIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </S.InputGrid>
              </Grid>
              <S.LoginButton
                type='submit'
                disabled={Boolean(validation.errors.email || !validation.values.email)}
              >
                Solicitar recuperação
              </S.LoginButton>
            </form>
            <img src={Viuzz} />
          </Grid>
          {/* <img src={img}></img> */}
        </S.BgGrid>
      </S.MainContainer>
    </>
  )
}

export default ForgottenPassword