import styled from 'styled-components'
import { Grid } from '@mui/material'

export const TextGrid = styled(Grid)`
  color: #fff;

  @media (max-width: 1400px){
    justify-content: center;
  }

  .title {
    font-size: 50px;
    line-height: 1.2;
  }
`

export const MainGrid = styled(Grid)`
  padding: 100px 20px;
  background-color: black;
  border-radius: 15px;
`

export const ImageGrid = styled(Grid)`
  display: flex;

  img {
    width: 100%;

    @media (max-width: 992px){
      margin-left: -5%;
    }
  }

  @media (max-width: 1440px){
    justify-content: center;
  }

`