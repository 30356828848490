import styled from 'styled-components'
import { Button } from '@mui/material'

export const CustomButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 5px !important;
  height: 50px !important;
  color: #FFF !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 14px;
  width: 100%;
  &.Mui-disabled {
    background: rgba(49, 49, 49, 0.65);
    color: #FFFFFF;
    opacity: 0.85;
  }
`