

import React from "react";
import { Card, Grid, Stack } from "@mui/material";
import balance from "assets/images/balanceBG.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { FaEllipsisH } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";
import funds from 'assets/icons/funds.svg'
import * as S from '../Transactions/style'
import moment from 'moment';
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "context/TokenContext";

const CreditBalance = (props) => {

  const navigate = useNavigate();
  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const currentBalance = Number(((props?.data?.monthlyBalance?.currentBalance ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const redirect = {
    ADMINISTRATOR: `/influencers/${props?.cashout}/financeiro/saque`,
    INFLUENCER: `/financeiro/saque`
  }

  return (
    <Card sx={{ padding: "90px 30px 30px 30px", background: "none", backgroundColor: "none" }}>
      <VuiBox display="flex" flexDirection="column">
        <S.CardBox
          mb={props?.cashout ? "10px" : "32px"}
          p="20px"
          display="flex"
          flexDirection="column"
          sx={{ backgroundImage: `url(${balance})`, backgroundSize: "cover", borderRadius: "18px" }}
        >
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
              Saldo positivo atual
            </VuiTypography>
            <FaEllipsisH color="white" size="18px" onClick={() => props?.setState(!props.state)} style={{ cursor: "pointer", zIndex: "99" }} />
            {props?.state &&
              <S.OptionsContainer container justifyContent='flex-end'>
                <S.OptionsGrid display='flex' flexDirection='column'>
                  <p onClick={() => {
                    props.unixTimestamp !== moment(Date.now()).endOf('month').subtract(15, 'days').valueOf()
                      ? props.setUnixTimestamp(moment(Date.now()).endOf('month').subtract(15, 'days').valueOf())
                      : props.getPdf(); props?.setState(!props.state);
                  }}
                  >
                    Download PDF
                  </p>
                </S.OptionsGrid>
              </S.OptionsContainer>
            }
          </VuiBox>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="h2" color="white" fontWeight="bold" mr="auto" sx={{ textWrap: "nowrap" }}>
              R$ {currentBalance ?? 0}
            </VuiTypography>
          </VuiBox>
          <VuiBox display='flex' gap='10px'>
            <p style={{ color: 'white', opacity: "0.7", fontSize: '16px', marginTop: '2px' }}>Saldo futuro:</p>
            <p style={{ color: '#5ACC93', fontSize: '18px' }}> +R$ {Number(((props?.data?.monthlyBalance?.pendingBalance ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          </VuiBox>
        </S.CardBox>
        <Grid container flexDirection='column' gap='10px'>
          {props?.data?.monthlyBalance?.previousMonths?.map((entry, index) => (
            <VuiBox display="flex" justifyContent="space-beetween" alignItems="center" key={entry.month}>
              <Stack direction="row" spacing="10px" mr="auto">
                <VuiBox
                  display="flex"
                  mr="10px"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    /* background: "rgba(34, 41, 78, 0.7)", */
                    borderRadius: "50%",
                    width: "42px",
                    height: "42px",
                  }}
                >
                  <img src={funds} width='42px' height='42px' />
                </VuiBox>
                <VuiBox display="flex" flexDirection="column">
                  <VuiTypography color="white" variant="button" fontWeight="medium">
                    Balanço de {entry.month} de {entry.year}
                  </VuiTypography>
                  {/*                   <VuiTypography color="text" variant="button" fontWeight="medium">
                    5 de Junho de 2023
                  </VuiTypography> */}
                </VuiBox>
              </Stack>
              <S.ValueGrid>
                <VuiTypography variant="button" color="white" fontWeight="bold" sx={{ textWrap: "nowrap" }}>
                  R$ {entry.balance ? Number((entry.balance / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}
                </VuiTypography>
                <IoEllipsisVertical color="white" onClick={() => props?.toggleOpenMenu(index)} style={{ cursor: 'pointer' }} />
                {props?.openMenu[index] &&
                  <S.MinorOptionsContainer container justifyContent='flex-end'>
                    <S.OptionsGrid display='flex' flexDirection='column' onClick={() => { }}>
                      <p
                        onClick={
                          () => {
                            props?.unixTimestamp === moment(`${entry.month}/${entry.year}`, 'MMMM/YYYY').endOf('month').subtract(15, 'days').valueOf()
                              ? props?.setUnixTimestamp(props?.unixTimestamp - 1)
                              : props?.setUnixTimestamp(moment(`${entry.month}/${entry.year}`, 'MMMM/YYYY').endOf('month').subtract(15, 'days').valueOf()); props?.toggleOpenMenu(index)
                          }}
                      >
                        Download PDF
                      </p>
                    </S.OptionsGrid>
                  </S.MinorOptionsContainer>
                }
              </S.ValueGrid>
            </VuiBox>
          ))
          }
          {props?.cashout &&
            <ButtonCustom
              label='Acessar Saques'
              onClick={() => navigate(redirect[role])}
              sx={{ width: "100%", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
            />
          }
        </Grid>
      </VuiBox>
    </Card>
  );
};

export default CreditBalance;
