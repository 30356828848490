import styled from "styled-components"
import { Grid } from "@mui/material"
import TimelineList from "defaultComponents/Timeline/TimelineList";
import TimelineItem from "defaultComponents/Timeline/TimelineItem";

export const RankingGrid = styled(Grid)`
  font-size: 24px;
  color: #FFFFFF;
  gap: 20px;

  img {
    border-radius: 15px;
  }
`

export const NumberGrid = styled(Grid)`
  font-size: 20px;

  .count {
    font-size: 15px;
    padding-left: 5px;
  }
`

export const RankingCard = styled(Grid)`
  height: 100%;
`

export const ScheduleList = styled(TimelineList)`
  padding: 0 22px !important;
  height: 100% !important;
`

export const RankingPhoto = styled(Grid)`
  p {
    text-align: center;
    position: absolute;
    top: 50px;
    left: 65px;
  }
`

export const SocialsGrid = styled(Grid)`
  @media(min-width: 1024px) and (max-width: 1440px) {
    padding-left: 20px !important;
  }
`