import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Route, useLocation, Routes, Navigate } from "react-router-dom";

// Vision UI Dashboard React example components
import Sidenav from "defaultComponents/Sidenav";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Login from "pages/Login/Login";

import UpdatePassword from "pages/UpdatePassword/UpdatePassword";
import NotFound from "pages/404/NotFound";
import Unauthorized from "pages/Unauthorized/Unauthorized";

import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import { TokenContext } from "context/TokenContext";
import ForgottenPasswordChange from "pages/ForgottenPassword/ForgottenPasswordChange";
import ForgottenPassword from "pages/ForgottenPassword/ForgottenPassword";

const InitialRoutes = () => {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { decodedToken } = useContext(TokenContext);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to='/dashboard' replace />}>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/recuperarsenha" element={<ForgottenPassword />} />
      <Route path="/recuperarsenha/:token" element={<ForgottenPasswordChange />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to='/login' replace />} />
    </Routes>
  )
}

export default InitialRoutes