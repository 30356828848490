import styled from 'styled-components'
import { Accordion, Grid, TextField } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

export const MainContainer = styled(Grid)`
/*   .fc-direction-ltr {
    width: 80%;
  } */
`

export const SidePanelGrid = styled(Grid)`
  padding: 10px;
  font-weight: 300;

  .MuiCheckbox-root {
    border: none;
  }

  .MuiCheckbox-root.Mui-checked {
    background-image: none !important;
  }

  .MuiSvgIcon-root {
    height: 25px;
    width: 25px;
  }
`

export const CalendarGrid = styled(Grid)`
  .fc-direction-ltr {
    width: 100%;
    height: 100%;
    @media (min-width: 1680px) {
      height: 85%;
    }
  }
  .fc-next-button {
    background: transparent;
    border-color: transparent;
    padding: 4px;
  }
  .fc-prev-button {
    background: transparent;
    border-color: transparent;
    padding: 4px;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }
  .fc-toolbar-chunk{
    display: flex;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.9375rem;
  }
  .fc-toolbar-title {
    margin-left: 30px !important;
    font-size: 20px;
    padding-top: 5px;
    font-weight: 300;
  }
  .fc .fc-toolbar .fc-button-group .fc-button-primary {
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
  .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
    border-color: rgba(252, 96, 9, 0.5);
    background-color: rgba(252, 96, 9, 0.20);
  }
  .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active {
    border-color: rgba(252, 96, 9, 0.5);
    background-color: rgba(252, 96, 9, 0.8);
  }
  .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
    background-color: transparent;
    padding: 0.375rem 1.27rem;
    color: #F2F2F2;
    border-color: rgba(242, 242, 242, 0.5);
  }
  .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:first-of-type, .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:first-of-type, .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:first-of-type, .fc .fc-toolbar .fc-button-group .fc-listMonth-button:first-of-type {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: -1px;
    border-radius: 10px;
  }
  .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:last-of-type, .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:last-of-type, .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:last-of-type, .fc .fc-toolbar .fc-button-group .fc-listMonth-button:last-of-type, .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:first-of-type, .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:first-of-type, .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:first-of-type, .fc .fc-toolbar .fc-button-group .fc-listMonth-button:first-of-type {
    border-radius: 10px;
  }
  .fc tbody td.fc-col-header-cell, .fc thead th.fc-col-header-cell {
    border-left: 0px;
    border-right: 0px;
  }
  .fc .fc-col-header, .fc .fc-daygrid-body, .fc .fc-scrollgrid-sync-table, .fc .fc-timegrid-body, .fc .fc-timegrid-body table {
    width: 100% !important;
  }
  .fc .fc-col-header .fc-col-header-cell {
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.15px;
    color: rgba(234, 234, 255, 0.87);
  }
  .fc tbody td, .fc thead th {
    border-color: rgba(234, 234, 255, 0.25);
  }
  .fc .fc-scrollgrid {
    border-color: rgba(234, 234, 255, 0.25);
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(252, 96, 9, 0.20);
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(252, 96, 9, 0.20);
  }
  .fc .fc-daygrid-day .fc-daygrid-day-top {
    flex-direction: row;
  }
  .fc .fc-timegrid .fc-timegrid-axis.fc-scrollgrid-shrink .fc-timegrid-axis-cushion {
    font-size: 0.75rem;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    text-align: center;
  }
  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
  }
  .fc .fc-daygrid-event-harness .fc-event {
    font-weight: 500;
    font-size: 0.85rem;
    padding: 0rem 0.25rem;
    cursor: pointer;
  }
  .fc-theme-standard .fc-list-day-cushion {
    background-color: #000;
  }
  .fc .fc-list .fc-list-event:hover td{
    background-color: rgba(252, 96, 9, 0.4);
  }
  .fc-theme-standard .fc-popover {
    background: #000;
    border-radius: 5px;
  }
  .fc .fc-popover-title {
    font-size: 16px;
  }
  .fc .fc-daygrid-event-harness {
    margin-left: 4px;
    margin-right: 4px;
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-end {
    margin-right: 0;
  }
  .fc .fc-popover .fc-popover-body :not(.fc-event-main):not(:last-of-type) {
    margin-bottom: 5px;
  }
  .fc-direction-ltr .fc-daygrid-more-link {
    font-size: 16px;
    text-align: center;
  }
`

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
/*   .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  } */
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const CheckboxGrid = styled(Grid)`
  width: fit-content !important;
  cursor: pointer;
`

export const EventDetailsGrid = styled(Grid)`
  .eventText {
    font-size: 22px;
    overflow-wrap: anywhere;
  }
`

export const CheckboxAccordion = styled(Accordion)`
  && {
    width: 100%;
  }
`

export const CheckboxAccordionSummary = styled(AccordionSummary)`
  && {
    padding: 0;
    font-size: 18px;
    svg {
      color: #fff;
    }
  }
`

export const CheckboxAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
`