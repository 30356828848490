import styled from "styled-components";
import { Button, Grid, TextField, Card } from "@mui/material";
import TimelineList from "defaultComponents/Timeline/TimelineList";
import { Link } from "react-router-dom";

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const Input = styled(TextField)`
  background-color: transparent;
  border-radius: 10px;
  max-width: 275px;
  min-height: 40px;
`

export const ScheduleList = styled(TimelineList)`
  padding: 0 22px !important;
  height: 100% !important;
`

export const GraphMainContainer = styled(Grid)`
  .positive {
    color: #65EFAD;
  }
  .negative {
    color: #D7007B;
  }
  .value {
    font-size: 26px;
    font-weight: 700;
  }
  .percentage {
    font-size: 16px;
    align-self: center;
  }
  .title {
    opacity: 0.75;
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
  }
  .instagram {
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
  }
  .tiktok{
    background: linear-gradient(180deg, #F8DF00 0%, #6A4000 100%);
  }
  .twitter {
    background: linear-gradient(180deg, #00ACE2 0%, #003E52 100%)
  }
  .youtube {
    background: linear-gradient(180deg, #FF0000 0%, #5e4343 100%);
  }
  .spotify {
    background: linear-gradient(180deg, #00D554 0%, #003E52 100%); 
  }
  .emptyBar {
    background: linear-gradient(180deg, #999999 0%, #343434 100%);
  }
`

export const TitleGrid = styled(Grid)`
  margin-top: 30px;
  font-size: 28px;
  font-weight: 500;
`

export const RankingCard = styled(Card)`
  padding: 32px 0 20px 22px !important;
  min-height: 300px;
  height: 100% !important;
`

export const ValuesGrid = styled(Grid)`
  gap: 10px;
`

export const CardGrid = styled(Grid)`
  font-size: 14px;
  overflow: auto;
  .apexcharts-toolbar {
    display: none;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
  }
`

export const SocialGrid = styled(Grid)`
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  text-align: center;
  padding: 5px;
  min-width: 120px;
  cursor: pointer;
`

export const SocialNameGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 400;
`

export const BoardsGrid = styled(Grid)`
  justify-content: end;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`

export const BarContainer = styled(Grid)`
  margin-top: 5px;

  .bar{
    height: 8px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    display: flex;
    div{
      height: 100%;
    }
    .instagram{
      background-color: #FC6009;
    }
    .tiktok{
      background-color: #F8DF00;
    }
    .twitter{
      background-color: #33CCFF;
    }
    .youtube{
      background-color: #FF0000;
    }
    .spotify{
      background-color: #00D554;
    }
  }
    
`

export const ProgressGrid = styled(Grid)`
  border-radius: 10px;
  background: #2B0B03;
  backdrop-filter: blur(2px);
  color: #fff;
  padding: 10px 15px;
  min-width: 250px;

  @media (max-width: 992px){
    width: 100%;
  }

  svg {
    align-self: center;
  }

  .followers {
    font-size: 16px;
  }
`

export const TooltipBase = styled.div`
/*   border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: .15s ease all; */
  top: -70px;
  z-index: 9999;
  color: #fff;
  background: rgba(30, 30, 30, .8);
  border-radius: 5px;
  box-shadow: 2px 2px 6px -5px #999;

  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    top: 60px;
  }

  .tooltip-title {
    font-size: 12px;
    background: rgba(0, 0, 0, .7);
    border-bottom: 1px solid #333;
    padding: 6px;
    margin-bottom: 4px;
  }

  .tooltip-row {
    padding: 0 10px;
    text-align: left;
    justify-content: left;
    align-items: center;
    padding-bottom: 4px;
    display: flex;
    font-size: 14px;
  }

  .tooltip-marker {
    width: 12px;
    height: 12px;
    position: relative;
    top: 0;
    margin-right: 10px;
    border-radius: 50%;
  }
`

export const MainContainer = styled(Grid)`
  .subTitle {
    font-size: 14px;
    color: #9c9a9a;
  }
`

export const GraphGrid = styled(Grid)`
  min-height: 310px;
`

export const BusinessConversionGrid = styled(Grid)`
  min-height: 450px;
`

export const CommercialPipelineGrid = styled(Grid)`
  && {
    max-height: 120px;
    max-width: 300px;
    background-color: #101010;
    align-items: center;
    padding: 20px 0;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 15px;
  }
`

export const UsersPipelineGrid = styled(Grid)`
  && {
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
  }
`

export const RouterLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`