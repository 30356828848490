import styled from 'styled-components'
import { Dialog } from '@mui/material'
import IconButton from '@mui/material/IconButton';

export const ModalBase = styled(Dialog)`
  .MuiPaper-root {
    background-color: #101010;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-radius: 25px;
  }
`

export const FilterModalBase = styled(Dialog)`
  .MuiPaper-root {
    background-color: #101010;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-radius: 25px;
  }
  .MuiDialog-container {
    justify-content: flex-end;
    height: 70%;
  }
`

export const Icon = styled(IconButton)`
  z-index: 9999;
`