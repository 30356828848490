import styled from 'styled-components'
import { Grid } from '@mui/material'

export const MainContainer = styled(Grid)`
  color: #fff;
  padding: 40px 40px;
  font-size: 18px;

  .title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }
  .lastUpdate {
    opacity: 0.8;
    text-align: right;
  }
  .subTitle {
    font-size: 22px;
    font-weight: 700;
  }

  ul {
    list-style: none;
  }
  ol {
    list-style: disc;
    margin-left: 30px;
  }
`