import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Route, useLocation, Routes, Navigate } from "react-router-dom";

// Vision UI Dashboard React example components
import Sidenav from "defaultComponents/Sidenav";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

import { useVisionUIController, setMiniSidenav } from "context";
import Login from "pages/Login/Login";
import Influencers from "pages/Influencers/Influencers";
import Influencer from "pages/Influencers/Influencer/Influencer";
import Metrics from "pages/Influencers/Metrics/Metrics";

import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import Instagram from "pages/Influencers/Metrics/Instagram/Instagram";
import UpdatePassword from "pages/UpdatePassword/UpdatePassword";
import NotFound from "pages/404/NotFound";
import Construction from "pages/Construction/Construction";
import Settings from "pages/SettingsPage/Settings";
import Calendar from "pages/Calendar/Calendar";
import Unauthorized from "pages/Unauthorized/Unauthorized";

import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import ProjectController from "pages/Projects/AddProjectPages/ProjectController";
import { TokenContext } from "context/TokenContext";
import Kanban from "pages/Commercial/Kanban/Kanban";
import Clients from "pages/Commercial/Clients/Clients";
import Client from "pages/Commercial/Clients/Client/Client";
import Commercial from "pages/Commercial/Commercial";
import InfluencerFinance from "pages/Finance/UserViews/InfluencerFinance";
import Marketing from "pages/Marketing/Marketing";
import MarketingDashboard from "pages/Marketing/MarketingDashboard";
import MarketingPipeline from "pages/Marketing/MarketingPipeline";
import MarketingKanban from "pages/Marketing/Kanban/MarketingKanban";
import ProjectListMarketing from "pages/Projects/ProjectList/ProjectListMarketing";
import ProjectListCommercial from "pages/Projects/ProjectList/ProjectListCommercial";
import ProjectListInfluencer from "pages/Projects/ProjectList/ProjectListInfluencer";
import CommercialRejections from "pages/Commercial/CommercialRejections";

const MarketingRoutes = () => {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { decodedToken } = useContext(TokenContext);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to='/dashboard' replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="*" element={
        <>
          <Sidenav
            color={sidenavColor}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <NotFound />
        </>
      } />
      <Route path="/dashboard" key='adminDashboard' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <MarketingDashboard />
          </ProtectedRoute>
        </>
      }
      />
      <Route path="/influencers" key='adminInfluencers' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Influencers />
          </ProtectedRoute>
        </>
      } />
      <Route path="/influencers/:influencer" key='adminInfluencer' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Influencer />
          </ProtectedRoute>
        </>
      } />
      <Route path="/influencers/:influencer/metricas" key='adminMetrics' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Metrics />
          </ProtectedRoute>
        </>
      } />
      <Route path="/influencers/:influencer/metricas/instagram" key='adminInstagram' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Instagram />
          </ProtectedRoute>
        </>
      } />
      <Route path="/influencers/:username/projetos" key='InfProjects' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <ProjectListInfluencer />
          </ProtectedRoute>
        </>
      } />
      <Route path="/marketing" key='marketing' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Marketing />
          </ProtectedRoute>
        </>
      } />
      <Route path="/marketing/projetos" key='adminMarketingProjects' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <ProjectListMarketing />
          </ProtectedRoute>
        </>
      } />
      <Route path="/marketing/projetos/:id" key='adminProjectController' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <ProjectController />
          </ProtectedRoute>
        </>
      } />
      <Route path="/marketing/pipeline" key='adminMarketingPipeline' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <MarketingPipeline />
          </ProtectedRoute>
        </>
      } />
      <Route path="/marketing/pipeline/kanban" key='adminMarketingKanban' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <MarketingKanban />
          </ProtectedRoute>
        </>
      } />
      <Route path="/comercial" key='Commercial' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Commercial />
          </ProtectedRoute>
        </>
      } />
      <Route path="/comercial/negocios" key='ProjectList' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <ProjectListCommercial />
          </ProtectedRoute>
        </>
      } />
            <Route path="/comercial/negocios/recusas" key='adminCommercialBusinesses' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <CommercialRejections />
          </ProtectedRoute>
        </>
      } />
      <Route path="/comercial/clientes" key='CommercialClients' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Clients />
          </ProtectedRoute>
        </>
      } />
      <Route path="/comercial/clientes/:clientId" key='mktCommercialClient' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Client />
          </ProtectedRoute>
        </>
      } />
      <Route path="/nft" key='adminNFT' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Construction page='nft' />
          </ProtectedRoute>
        </>
      } />
      <Route path="/tv" key='adminTV' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Construction page='tv' />
          </ProtectedRoute>
        </>
      } />
      <Route path="/agenda" key='adminCalendar' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Calendar />
          </ProtectedRoute>
        </>
      } />
      <Route path="/configuracoes" key='adminConfig' element={
        <>
          <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Settings />
          </ProtectedRoute>
        </>
      } />
    </Routes>
  )
}

export default MarketingRoutes