import styled from 'styled-components';
import { Grid, TextField, Fab, Button } from '@mui/material';
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import { Link } from 'react-router-dom';

export const FormGrid = styled(Grid)`
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 30px;

  .active {
    opacity: 1 !important;
  }

  .stepActive {
    border-color: rgb(251, 132, 4);
  }

  .briefingTitle {
    font-size: 28px;
    font-weight: 600;
  }

  .briefingDescription {
    font-size: 20px;
    opacity: 0.75;
  }
`

export const FormTitleGrid = styled(Grid)`
  font-size: 18px;
  font-weight: 400;

  .subTitle {
    font-size: 16px;
  }
`

export const BackGrid = styled(Grid)`
  cursor: pointer;
`

export const TabsGrid = styled(Grid)`
  .MuiTabs-flexContainer {
    gap: 5px !important;
  }
`

export const SideTabs = styled(Tab)`
  justify-content: left !important;
  background-color: rgba(239, 241, 246, 0.025) !important;
  border-radius: 5px 5px 15px 5px !important;
  font-size: 14px !important;
`

export const Input = styled(TextField)`
  background-color: transparent;
  /* border: 0.4px solid rgba(255, 255, 255, 0.50) !important; */
  border-radius: 10px;
  width: 100%;
  min-height: 40px;
`

export const InputContainer = styled(Grid)`
  .readOnly {
      .MuiOutlinedInput-notchedOutline {
      height: 45px;
      border-radius: 10px;
      border: 0.4px solid;
      border-color: transparent !important;
    }
  }
  .urlPointer {
    input {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  .MuiAutocomplete-endAdornment {
    top: 5px !important;
    transform: none !important;
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding-top: 0 !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 5px !important;
  }
  .MuiAutocomplete-popper {
    background-color: #0E0E0E;
    border: 1px solid;
  }
  .MuiFormHelperText-root{
    margin-top: 5px;
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const BorderedInputGrid = styled(InputGrid)`
  .MuiOutlinedInput-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 45px;
    border-radius: 10px;
    border: 0.4px solid;
    border-color: rgba(255, 255, 255, 0.50) !important;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: none;
    svg {
      color: #fff;
    }
    .MuiOutlinedInput-input {
      color: #fff;
    }
  }
`

export const MultilineInputGrid = styled(InputGrid)`
  .MuiInputBase-root{
    padding: 0 10px
  }

  .MuiOutlinedInput-notchedOutline {
    min-height: 45px;
    height: 100%;
  }
`

export const DateInputGrid = styled(InputGrid)`
  .flatpickr-confirm {
    color: white;
    cursor: pointer;
  }
`

export const ProjectCard = styled(Card)`
  background: linear-gradient(127.09deg, rgba(0, 0, 0, 0.6) 19.41%, rgba(0, 0, 0, 0.6) 76.65%) !important;
`

export const ImageUploadContainer = styled(Grid)`
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  place-content: center;

  .previewImg{
    max-width: 70%;
    max-height: 70%;
  }

`

export const ImageUploadGrid = styled(Grid)`
  opacity: 0.3;
`

export const DeleteFab = styled(Fab)`
  justify-self: right;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  
  &:hover{
    transform: scale(1.1);
  }
`

export const BadgeGrid = styled(Grid)`
  font-size: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  background: linear-gradient(180deg, #9e5302 0%, #8e110c 155.15%),#FB7E04;
  text-align: center;
  min-width: 100px;
  min-height: 41px;
`

export const TypeBadgeGrid = styled(BadgeGrid)`
  min-height: 41px;
  padding: 7px 20px;
`

export const FinishedProjectBadgeGrid = styled(TypeBadgeGrid)`
  background: linear-gradient(180deg, #e5554282 0%, #d7007b80 154.17%);
`

export const RejectedBadgeGrid = styled(BadgeGrid)`
  background: linear-gradient(180deg, #E55542 0%, #D7007B 154.17%);
  cursor: pointer;
`

export const BadgeContainer = styled(Grid)`
  justify-content: flex-end;
  gap: 10px;
`

export const InfluencerBadge = styled(Tab)`
  min-width: 130px !important;
  background: #FB7E04 !important;
  opacity: 0.7 !important;
  font-size: 14px !important;
  padding: 10px !important;
  border-radius: 20px !important;
  height: 30px !important;
`

export const StyledStep = styled(Grid)`
  width: 20px;
  height: 20px;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(251, 132, 4, 0.3);
  margin-left: 2px;
  margin-right: 2px;
`

export const StepperLabelGrid = styled(Grid)`
  .number {
    font-size: 40px;
    line-height: 1;
    margin-right: 5px;
    opacity: 0.7;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .subTitle {
    font-size: 12px;
  }
  .active {
    opacity: 1;
  }
`

export const RejectedLogGrid = styled(Grid)`
  font-size: 14px;
  .number {
    font-size: 55px;
    line-height: 1;
    opacity: 0.7;
    align-content: center;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    overflow-wrap: anywhere;
  }
  .titleLink {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    overflow-wrap: anywhere;
  }
  .subTitle {
    font-size: 16px;
  }
  .active {
    opacity: 1;
  }
  .author {
    color: #FC6009;
  }
`

export const BorderGrid = styled(Grid)`
  border-radius: 5px;
  border: 0.4px solid rgba(255, 255, 255, 0.30);
  padding: 25px;
  gap: 20px;
`

export const LeftBorderGrid = styled(Grid)`
  border-radius: 5px 0 0 5px;
  border: 0.4px solid rgba(255, 255, 255, 0.30);
`

export const RightBorderGrid = styled(Grid)`
  border-radius: 0 5px 5px 0;
  border: 0.4px solid rgba(255, 255, 255, 0.30);
  padding: 30px 12px;
  display: flex;
  gap: 33px;
`

export const EditButton = styled(Button)`
  && {
    width: 41px;
    min-width: 30px;
    height: 41px;
    border-radius: 6px;
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
    padding: 6px 6px;

    &:hover{
      transform: scale(1.1);
    }
  }
`

export const DoneButton = styled(EditButton)`
  && {
    background: linear-gradient(180deg, #5ACC93 0%, #0E1B05 155.15%);
  }
`

export const RectangleButton = styled(EditButton)`
  width: 50px !important;

  @media (min-width: 1750px) {
    width: 65px !important;
  }
`

export const ApproveButton = styled(RectangleButton)`
  && {
    background: linear-gradient(180deg, #5ACC93 0%, #0E1B05 155.15%);
  }
`

export const RejectButton = styled(RectangleButton)`
  && {
    background: linear-gradient(180deg, #E55542 0%, #D7007B 154.17%);
  }
`

export const RejectButtonSmall = styled(EditButton)`
  background: linear-gradient(180deg, #E55542 0%, #D7007B 154.17%);
`

export const DetailsGrid = styled(Grid)`
  margin-bottom: 20px;
`

export const BoardGrid = styled(Grid)`
  @media (max-width: 992px) {
    justify-content: center;
  }

  p{
    font-size: 15px;
  }
`

export const ProgressGrid = styled(Grid)`
  border-radius: 10px;
  background: #2B0B03;
  backdrop-filter: blur(2px);
  color: #fff;
  padding: 10px 15px;
  min-width: 230px;

  @media (max-width: 992px){
    width: 100%;
  }

  svg {
    align-self: center;
  }

  .followers {
    font-size: 16px;
  }

`

export const BarContainer = styled(Grid)`
  margin-top: 5px;

  .bar{
    height: 8px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    display: flex;
    div{
      height: 100%;
    }
    .instagram{
      background-color: #FC6009;
    }
    .tiktok{
      background-color: #F8DF00;
    }
    .twitter{
      background-color: #33CCFF;
    }
    .youtube{
      background-color: #FF0000;
    }
    .spotify{
      background-color: #00D554;
    }
  }   
`

export const SocialCardsGrid = styled(Grid)`
  justify-content: flex-end;
  gap: 10px;
  
  @media (max-width: 992px){
    justify-content: center;
    margin: 20px 0 !important;
  }

  @media (min-width: 1440px){
    flex-wrap: nowrap !important;
  }

  svg {
    align-self: center;
  }

  .instagram {
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
  }

  .tiktok{
    background: linear-gradient(180deg, #F8DF00 0%, #6A4000 100%);
  }

  .twitter {
    background: linear-gradient(180deg, #00ACE2 0%, #003E52 100%)
  }

  .youtube {
    background: linear-gradient(180deg, #B51E23 0%, #3D0002 100%);
  }

  .spotify {
    background: linear-gradient(180deg, #00D755 0%, #00240D 100%);
  }
`
export const SocialGrid = styled(Grid)`
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  text-align: center;
  padding: 5px;
  min-width: 110px;
  cursor: pointer;
`

export const PageLink = styled(Link)`
  display: contents;
`

export const SocialNameGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 400;
`

export const InfluencerBadgeGrid = styled(Grid)`
  min-width: 130px !important;
  background: #FB7E04 !important;
  opacity: 0.7 !important;
  font-size: 14px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  border-radius: 20px !important;
  height: 30px !important;
  text-align: center;
`

export const ReturnPageGrid = styled(Grid)`
  cursor: pointer;
`

export const InfluencerTabsGrid = styled(Grid)`
  .error {
    color: #E22B00;
    font-size: 14px;
  }
`

export const FinishProjectButton = styled(Button)`
  && {
    border-radius: 5px 5px 15px 5px;
    height: 60px;
    width: 100%;
    background: linear-gradient(180deg, #5ACC93 0%, #0E1B05 155.15%);
    color: #fff;
    font-size: 16px;
    gap: 5px;
    justify-content: start;
    font-weight: 500;
    padding: 10px;
    padding-left: 20px;

    &:hover {
      color: #fff;
      transform: scale(1.02);
    }

    &:focus {
      color: #fff !important;
    }
  }

  svg {
    margin-left: 20px;
  }
`

export const FinishGrid = styled(Grid)`
  .disabledButton {
      opacity: 0.7;
      cursor: default;
      background: linear-gradient(180deg, #818181 0%, #2a2020 155.15%);

      &:hover {
        transform: none;
      }
  }
`

export const AlertGrid = styled(Grid)`
  p {
    color: #8F8F8F;
    font-weight: 400;
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
  }
`