import { useState, createContext } from "react";
import jwt_decode from "jwt-decode";

export const TokenContext = createContext();

export const TokenProvider = (({ children }) => {

  const localToken = localStorage.getItem("token");

  const [userToken, setUserToken] = useState(localToken ?? null);

  const decodedToken = userToken ? jwt_decode(userToken) : null;

  return (
    <TokenContext.Provider value={{ userToken, setUserToken, decodedToken }}>
      {children}
    </TokenContext.Provider>
  )
})