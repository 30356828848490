import React from 'react';

export const SearchBarContext = React.createContext();

export const SearchBarProvider = (({children}) => {

  const [searchQuery, setSearchQuery] = React.useState();

  return (
    <SearchBarContext.Provider value={{ searchQuery, setSearchQuery }}>
      {children}
    </SearchBarContext.Provider>
  )
})