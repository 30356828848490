import styled from 'styled-components'
import { Button, Grid, TextField } from '@mui/material'

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const PaginationGrid = styled(Grid)`
  .MuiPaginationItem-root {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    cursor: pointer;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.4;
    text-align: center;
    text-transform: unset;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-size: 150%!important;
    background-position-x: 25%!important;
    transition: all 150ms ease-in;
    background: transparent;
    color: #a0aec0;
    border-color: #a0aec0;
    border-radius: 10rem;
    padding: 0.6875rem 0.6875rem 0.625rem;
    margin: 0 0.125rem;
    pointer-events: auto;
    font-weight: 400;
    font-size: 0.875rem;
    width: 2.875rem;
    min-width: 2.875rem;
    height: 2.875rem;
    min-height: 2.875rem;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .MuiPaginationItem-root.Mui-selected {
    background: linear-gradient(180deg, #FB8404, #FE1E14) !important;
    border: none;
    color: white;
  }
`

export const LogResultGrid = styled(Grid)`
  overflow: auto;
  flex-direction: row;
  overflow-x: auto;
`

export const InfluencerPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: auto;
    height: 110px;
    margin-top: 5px;
    border: 4px solid #FC6009;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
    }
  }
`

export const LogPhoto = styled(Grid)`
  && {
    padding-top: 0;
  }
`

export const IndicatorGrid = styled(Grid)`
  justify-content: flex-end;
  display: flex;
  margin-top: -28px;
`

export const PhotoIndicator = styled.div`
  color: white;
  width: 30px;
  height: 30px;
  font-size: 18px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 50%;
  text-align: center;
  align-content: center;
  cursor: pointer;
  z-index: 1;

  svg {
    margin-top: 6px;
  }
`

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`