import React from "react";
import { Grid } from "@mui/material";
import * as S from "../style";
import InputAdornment from "@mui/material/InputAdornment";
import { RiCalendarEventFill } from "react-icons/ri";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import moment from "moment";
import "moment/locale/pt-br";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import showToast from "components/Toast/Toast";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from "utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { getAllClients } from "utils/requests/leads";
import { getAllInfluencers } from "utils/requests/auth";
import { createPublication } from "utils/requests/calendar";

const AddPublication = ({ refetch, closeModal }) => {
  moment.locale("pt-br");

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY HH:mm").valueOf());
  };

  const [influencers, setInfluencers] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [businesses, setBusinesses] = React.useState();
  const [validateFields, setValidateFields] = React.useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      title: "",
      start: "",
      end: "",
      extendedProps: {
        socialMedia: [],
        format: "",
        categories: [],
        leadsRelated: [],
        products: "",
        coupons: "",
        tagsAndHashtags: "",
        description: "",
      },
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(4, "Título deve conter pelo menos 4 caracteres")
        .required("Título é obrigatório"),
      start: Yup.string()
        .required("Data Inicial é obrigatório")
        .required("Data de Postagem é obrigatório"),
      end: Yup.string(),
      extendedProps: Yup.object().shape({
        socialMedia: Yup.array(),
        format: Yup.string().required("Formato é obrigatório"),
        categories: Yup.array(),
        leadsRelated: Yup.array(),
        products: Yup.string(),
        coupons: Yup.string(),
        tagsAndHashtags: Yup.string(),
        description: Yup.string(),
      }),
    }),

    onSubmit: async (values) => {
      const publicationData = {
        title: values.title,
        socialMedia: values.extendedProps.socialMedia ? values.extendedProps.socialMedia : "",
        postDateMs: unixDate(values.start),
        format: values.extendedProps.format,
        usersRelated: values.extendedProps.categories
          ? values.extendedProps.categories.map((category) => category.id)
          : "",
        leadsRelated: values.extendedProps.leadsRelated
          ? values.extendedProps.leadsRelated.map((lead) => lead.id)
          : "",
        products: values.extendedProps.products,
        coupons: values.extendedProps.coupons,
        description: values.extendedProps.description,
        tagsAndHashtags: values.extendedProps.tagsAndHashtags,
      };
      Object.keys(publicationData).forEach((key) => {
        if (publicationData[key] === "") {
          delete publicationData[key];
        }
        if (Array.isArray(publicationData[key]) && publicationData[key].length === 0) {
          delete publicationData[key];
        }
      });
      await createPublication(publicationData).then((res) => {
        if (res.id) {
          showToast("Publi criada com sucesso!", "success");
          refetch();
          closeModal();
        } else {
          showToast("Falha na criação de publi", "error");
        }
      });
    },
  });

  const fetchInfluencers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setInfluencers(data[0]);
    }
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    }
  };

  React.useEffect(() => {
    fetchInfluencers();
    fetchBusinesses();
  }, []);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr("#start", flatpickrOptions("start", formik.setFieldValue, formik.setFieldTouched));
  flatpickr("#end", flatpickrOptions("end", formik.setFieldValue, formik.setFieldTouched));

  const socials = ["Instagram", "Facebook", "Twitter", "Youtube", "Spotify", "TikTok"];

  return (
    <>
      <p>Criar Publi</p>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.handleSubmit}
      >
        <Form>
          <Grid container flexDirection="column" gap="20px" marginTop="30px" padding="15px">
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="title"
                  id="title"
                  type="text"
                  placeholder="Título"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  error={Boolean(formik.errors.title)}
                  helperText={formik.errors.title}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.socialMedia"
                  name="extendedProps.socialMedia"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.socialMedia
                      ? formik.values.extendedProps.socialMedia
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.socialMedia", value);
                  }}
                  options={socials}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Redes Sociais" />}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                />
              </S.InputGrid>
              {/*               <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='extendedProps.place'
                  id='extendedProps.place'
                  hiddenLabel
                  type='text'
                  placeholder="Local"
                  fullWidth
                  value={formik.values.extendedProps.place}
                  onChange={formik.handleChange}
                />
              </S.InputGrid> */}
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id="start">
                <S.Input
                  name="start"
                  hiddenLabel
                  type="text"
                  placeholder="Data de Postagem"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={formik.values.start}
                  error={Boolean(formik.errors.start)}
                  helperText={formik.errors.start}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCalendarEventFill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => formik.setFieldValue("start", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.format"
                  id="extendedProps.format"
                  hiddenLabel
                  type="text"
                  placeholder="Formato"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values?.extendedProps?.format}
                  error={Boolean(formik.errors?.extendedProps?.format)}
                  helperText={formik.errors?.extendedProps?.format}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.categories"
                  name="extendedProps.categories"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.categories
                      ? formik.values.extendedProps.categories
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.categories", value);
                  }}
                  options={influencers ? influencers.flat() : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Influencers" />}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.name}
                    </li>
                  )}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.leadsRelated"
                  name="extendedProps.leadsRelated"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.leadsRelated
                      ? formik.values.extendedProps.leadsRelated
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.leadsRelated", value);
                  }}
                  options={businesses && businesses[0] ? businesses[0] : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Empresa" />}
                  getOptionLabel={(option) => option.businessName}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.businessName}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.products"
                  id="extendedProps.products"
                  hiddenLabel
                  type="text"
                  placeholder="Produtos"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.products}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.coupons"
                  id="extendedProps.coupons"
                  hiddenLabel
                  type="text"
                  placeholder="Cupom de Compra"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.coupons}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  name="extendedProps.tagsAndHashtags"
                  id="extendedProps.tagsAndHashtags"
                  hiddenLabel
                  type="text"
                  placeholder="Marcações e Hashtags"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.tagsAndHashtags}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  name="extendedProps.description"
                  id="extendedProps.description"
                  hiddenLabel
                  type="text"
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.description}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <ButtonCustom
                label="CRIAR PUBLI"
                sx={{ width: "180px", fontSize: "18px", fontWeight: "500" }}
                type="submit"
                onClick={() => setValidateFields(true)}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default AddPublication;
