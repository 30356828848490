import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Route, useLocation, Routes, Navigate } from "react-router-dom";

// Vision UI Dashboard React example components
import Sidenav from "defaultComponents/Sidenav";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Login from "pages/Login/Login";
import Influencer from "pages/Influencers/Influencer/Influencer";
import Metrics from "pages/Influencers/Metrics/Metrics";

import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import Instagram from "pages/Influencers/Metrics/Instagram/Instagram";
import UpdatePassword from "pages/UpdatePassword/UpdatePassword";
import NotFound from "pages/404/NotFound";
import Construction from "pages/Construction/Construction";
import Settings from "pages/SettingsPage/Settings";
import Calendar from "pages/Calendar/Calendar";
import Unauthorized from "pages/Unauthorized/Unauthorized";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import ProjectController from "pages/Projects/AddProjectPages/ProjectController";
import { TokenContext } from "context/TokenContext";
import InfluencerBriefingProjectDetails from "pages/Projects/AddProjectPages/UserViews/InfluencerBriefingProjectDetails";
import InfluencerFinance from "pages/Finance/UserViews/InfluencerFinance";
import InfluencerCashout from "pages/Finance/UserViews/InfluencerCashout";
import ProjectListInfluencer from "pages/Projects/ProjectList/ProjectListInfluencer";

const InfluencerRoutes = () => {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { decodedToken } = useContext(TokenContext);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to='/dashboard' replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="*" element={
        <>
          <Sidenav
            color={sidenavColor}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <NotFound />
        </>
      } />
      <Route path="/dashboard" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Influencer influencerUsername={decodedToken?.username} />
          </ProtectedRoute>
        </>
      }
      />
      <Route path="/metricas" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Metrics />
          </ProtectedRoute>
        </>
      } />
      <Route path="/metricas/instagram" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Instagram />
          </ProtectedRoute>
        </>
      } />
      <Route path="/financeiro" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <InfluencerFinance />
          </ProtectedRoute>
        </>
      } />
      <Route path="/financeiro/saque/" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <InfluencerCashout />
          </ProtectedRoute>
        </>
      } />
      <Route path="/projetos" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <ProjectListInfluencer />
          </ProtectedRoute>
        </>
      } />
{/*       <Route path="/projetos/:id" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <ProjectController />
          </ProtectedRoute>
        </>
      } /> */}
      <Route path="/nft" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Construction page='nft' />
          </ProtectedRoute>
        </>
      } />
      <Route path="/tv" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Construction page='tv' />
          </ProtectedRoute>
        </>
      } />
      <Route path="/agenda" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Calendar />
          </ProtectedRoute>
        </>
      } />
      <Route path="/configuracoes" element={
        <>
          <ProtectedRoute allowedRoles={["INFLUENCER"]}>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Settings />
          </ProtectedRoute>
        </>
      } />
    </Routes>
  )
}

export default InfluencerRoutes