import React from 'react'
import * as S from './style'
import moment from 'moment';
import 'moment/locale/pt-br';
import InboundDetails from './Forms/Details/InboundDetails';
import OutboundDetails from './Forms/Details/OutboundDetails';
import TransferDetails from './Forms/Details/TransferDetails';

const Details = ({ entry }) => {
  moment.locale("pt-br")
  return (
    <S.DetailsGrid container flexDirection='column' gap='15px'>
      <p className='title'>Detalhes</p>
      {entry?.type === "ENTRADA" &&
        <InboundDetails entry={entry} />
      }
      {entry?.type === "SAIDA" &&
        <OutboundDetails entry={entry} />
      }
      {entry?.type === "TRANSFERENCIA" &&
        <TransferDetails entry={entry} />
      }
    </S.DetailsGrid>
  )
}

export default Details