import React from 'react'
import * as S from './style'
import VuiTypography from 'components/VuiTypography'
import VuiBox from 'components/VuiBox'
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import placeholderImg from "assets/placeholder.png"
import { Grid } from '@mui/material';
import { abbreviateNumbers } from 'utils/utils';

const InfluencersRanking = ({ influencerList, rankingType, xs, lg, xl }) => {
  return (
    <S.RankingCard item xs={xs} lg={lg} xl={xl} sx={{ height: "auto" }}>
      <Card sx={{ height: "100%" }}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Ranking Influencers
        </VuiTypography>
        {/*         {influencerList?.length === 0 &&
          <p>Sem informações</p>
        } */}
        {influencerList?.length > 0 && rankingType === 'followers' && influencerList?.map((influencer, index) => (
          <VuiBox key={index}>
            <VuiBox display="flex" alignItems="center" mb="10px" mt="20px">
              <S.RankingGrid container gap='10px'>
                <p>{index + 1}.</p>
                <S.RankingPhoto sx={{ marginLeft: index === 0 ? '4px' : 0 }}>
                  <div className='photoContainer'>
                    <img src={influencer?.influencer.avatar ? influencer?.influencer.avatar : placeholderImg} width='100%' height='100%' draggable='false' />
                  </div>
                  {index === 0 && <p className={rankingType === 'followers' ? 'followers' : ''}>👑</p>}
                </S.RankingPhoto>
                <S.NumberGrid flexDirection='column'>
                  <p>{influencer?.influencer?.name ?? ''}</p>
                  <Grid container>
                    {influencer?.monthlyChanges?.up
                      ? <IoArrowUp color="#65EFAD" size="22px" />
                      : <IoArrowDown color="#D7007B" size="22px" />
                    }
                    <p className="count">{abbreviateNumbers(influencer?.monthlyChanges?.changes ?? 0)}</p>
                  </Grid>
                </S.NumberGrid>
              </S.RankingGrid>
            </VuiBox>
          </VuiBox>
        ))
        }
        {rankingType === 'projects' && influencerList?.map((influencer, index) => (
          <VuiBox key={index}>
            <VuiBox display="flex" alignItems="center" mb="10px" mt="20px">
              <S.RankingGrid container justifyContent='space-between'>
                <Grid display='flex' gap='20px'>
                  <p>{index + 1}.</p>
                  <S.RankingPhoto>
                    <div className='photoContainer'>
                      <img src={influencer?.influencer?.avatar ? influencer?.influencer?.avatar : placeholderImg} width="45px" height="45px" draggable='false' />
                    </div>
                    {index === 0 && <p>👑</p>}
                  </S.RankingPhoto>
                  <S.NumberGrid flexDirection='column'>
                    <p>{influencer?.influencer?.name ?? ''}</p>
                    <Grid container gap='5px'>
                      <p className="count">{influencer?.total}</p>
                      <p className='count'>Projetos</p>
                    </Grid>
                  </S.NumberGrid>
                </Grid>
                <Grid display='flex' flexDirection='column' justifyContent='center'>
                  <S.BadgeGrid container className={!influencer?.changes?.up ? 'negativeBadge' : 'positiveBadge'} gap='3px'>
                    <p className='percentage'>
                      {influencer?.changes.value ? !influencer?.changes?.up ? '-' : '+' : ''}{influencer?.changes?.value}
                    </p>
                    <p>este mês</p>
                  </S.BadgeGrid>
                </Grid>
              </S.RankingGrid>
            </VuiBox>
          </VuiBox>
        ))
        }
      </Card>
    </S.RankingCard>
  )
}

InfluencersRanking.defaultProps = {
  influencerList: [],
  rankingType: 'followers',
  xs: 12,
  lg: 6,
  xl: 5
};

InfluencersRanking.propTypes = {
  influencerList: PropTypes.array,
  rankingType: PropTypes.string,
  xs: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number
};

export default InfluencersRanking