import React from 'react'
import * as S from './style'

const ButtonCustom = ({label, children, ...props}) => {
  return (
    <S.CustomButton
      {...props}
    >
      {children}
      {label}
    </S.CustomButton>
  )
}

export default ButtonCustom