import styled from 'styled-components'
import { Button, Grid, TextField } from '@mui/material'
import Fab from '@mui/material/Fab';

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
  padding: 0 !important;
`

export const DateGrid = styled(Grid)`
  justify-content: space-between;

  @media (max-width: 992px) {
    justify-content: center;
    gap: 16px;
  }
`

export const TableGrid = styled(Grid)`
  p {
    color: #fff;
  }
`

export const MainGrid = styled(Grid)`
  .positive {
    color: #65EFAD;
  }
  .negative {
    color: #D7007B;
  }
  .transfer {
    color: #FB8404;
  }
`

export const BottomGrid = styled(Grid)`
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 25px;

  p {
    font-size: 16px;
    align-self: center;
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 50px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const FilterContainer = styled(Grid)`
  position: absolute;
  right: 20px;
  top: 140px;
`

export const FilterGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;
  font-size: 16px;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 50px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const PaginationGrid = styled(Grid)`
  margin-top: 20px;
  .MuiPaginationItem-root {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    cursor: pointer;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.4;
    text-align: center;
    text-transform: unset;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-size: 150%!important;
    background-position-x: 25%!important;
    transition: all 150ms ease-in;
    background: transparent;
    color: #a0aec0;
    border-color: #a0aec0;
    border-radius: 10rem;
    padding: 0.6875rem 0.6875rem 0.625rem;
    margin: 0 0.125rem;
    pointer-events: auto;
    font-weight: 400;
    font-size: 0.875rem;
    width: 2.875rem;
    min-width: 2.875rem;
    height: 2.875rem;
    min-height: 2.875rem;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .MuiPaginationItem-root.Mui-selected {
    background: linear-gradient(180deg, #FB8404, #FE1E14) !important;
    border: none;
    color: white;
  }
`

export const MiscInfoGrid = styled(Grid)`
  padding-left: 15px;
`

export const PieChartGrid = styled(Grid)`
  min-height: 240px;
  margin-top: 30px !important;
  .apexcharts-legend-text{
    color: rgba(255, 255, 255, 0.75) !important;
  }
  .apexcharts-legend-marker{
    border-radius: 3px !important;
  }
`