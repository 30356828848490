let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL;

export const requestWithdraw = async (withdraw) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/withdraw`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(withdraw),
  });
  const data = await response.json();
  return data;
};

export const approveWithdraw = async (id) => {
  const token = localStorage.getItem("token");
  const actualTime = new Date().getTime()
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/withdraw/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      resolvedAtMs: actualTime,
      status: "APPROVED"
    }),
  });
  const data = await response.json();
  return data;
};

export const rejectWithdraw = async (id) => {
  const token = localStorage.getItem("token");
  const actualTime = new Date().getTime()
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/withdraw/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      resolvedAtMs: actualTime,
      status: "REJECTED"
    }),
  });
  const data = await response.json();
  return data;
};

export const getUserWithdrawals = async (username, page, size) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/withdraw/${username}?page=${page}&size=${size}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const getAllWithdrawals = async (page, size) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/withdraw?page=${page}&size=${size}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const reportWithdraw = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/report`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      withdrawPleaId: id
    }),
  });
  const data = await response.json();
  return data;
};