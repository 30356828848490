import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, capitalize } from '@mui/material';
import Card from "@mui/material/Card";
import * as S from './style';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { RiBarChartFill } from "react-icons/ri";
import { BsCurrencyDollar } from "react-icons/bs";
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import BarChart from 'defaultComponents/Charts/BarCharts/BarChart';
import { IoEllipsisVertical } from "react-icons/io5"
import { Link } from 'react-router-dom';
import MonthDatepicker from 'components/Datepicker/MonthDatepicker';
import { TokenContext } from "context/TokenContext";
import { abbreviateNumbers } from 'utils/utils';
import { getProjectCommercialHomeData } from 'utils/requests/project';
import BriefingRanking from './components/BriefingRanking/BriefingRanking';
import SellersRanking from './components/SellersRanking/SellersRanking';
import Autocomplete from '@mui/material/Autocomplete';
import { getSellers } from 'utils/requests/other';

const ProjectsCommercial = () => {

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  /*   const [filterDate, setFilterDate] = React.useState(); */
  const [projectHomeData, setProjectHomeData] = React.useState();
  const [updateChart, setUpdateChart] = React.useState(0);
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions, setChartOptions] = React.useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#FC6009", "#F8DF00", "#33CCFF"],
    },
    colors: ["#FC6009", "#F8DF00", "#33CCFF"],
  });

  const [barChartData, setBarChartData] = React.useState([]);
  const [barChartOptions, setBarChartOptions] = React.useState({
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        barHeight: '85%',
        distributed: true,
        horizontal: true,
        borderRadius: 4,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    stroke: {
      colors: ["transparent"],
      width: 5
    },
    colors: ['#007093', '#00752D', '#A58200', '#00ACE2', '#9D171C', '#6D788D'],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
    },
    /*     labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        }, */
    xaxis: {
      categories: ['Campanhas', 'Santander', 'Palco', 'Eventos', 'Livros', 'Audio Visual'],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    }
  });

  const [funnelChartData, setFunnelChartData] = React.useState([]);
  const [funnelChartOptions, setFunnelChartOptions] = React.useState({
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: '80%',
        isFunnel: true,
        distributed: true
      },
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    colors: [
      '#8D95EB',
      '#B57BED',
      '#CA6CD8',
      '#D863B1',
      '#E55A89',
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
      },
      dropShadow: {
        enabled: true,
      },
      style: {
        fontFamily: 'Plus Jakarta Display", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        fontSize: '14px'
      }
    },
    xaxis: {
      categories: [
        'Leads 🔥',
        'Relacionamento 🎯',
        'FollowUP 🆙',
        'Negociação 💰',
        'Fechado 🤝',
      ],
      labels: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false,
      }
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    }
  });

  const [miniChartData, setMiniChartData] = React.useState([]);
  const [miniChartOptions, setMiniChartOptions] = React.useState({
    chart: {
      toolbar: {
        show: false,
      }
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        formatter: (val) => {
          return Math.floor(val);
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: '#000'
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: false
        }
      },
      xaxis: {
        lines: {
          show: true
        },
      },
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    annotations: {
      points: [
        {
          x: 5,
          y: 500,
          marker: {
            size: 6,
            fillColor: "#000",
            strokeColor: "#FC6009",
            radius: 2
          },
        }
      ]
    },
    fill: {
      type: "solid",
      opacity: 0,
      colors: ["#FC6009", "#F8DF00", "#33CCFF"],
    },
    colors: ["#FC6009", "#F8DF00", "#33CCFF"],
  });

  const [miniChartData2, setMiniChartData2] = React.useState([]);
  const [miniChartOptions2, setMiniChartOptions2] = React.useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        1,
        2,
        3,
        4,
        5,
      ],
      labels: {
        show: true,
        style: {
          colors: ['#000', '#000', '#000', '#000', '#000']
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          show: false
        }
      },
      xaxis: {
        lines: {
          show: true
        }
      },
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "solid",
      opacity: 0,
      colors: ["#F8DF00"],
    },
    colors: ["#F8DF00"],
  });

  const [sellers, setSellers] = React.useState([]);
  const [chosenSeller, setChosenSeller] = React.useState({ name: 'Todos', username: '' });

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      const changedArr = data.toSpliced(0, 0, { name: 'Todos', username: '' });
      setSellers(changedArr);
    };
  };

  const fetchData = async (chosenSeller) => {
    const data = await getProjectCommercialHomeData(chosenSeller?.username);
    if (!data.message) {
      setProjectHomeData(data);
      setChartData(data?.lastMonthProjects?.graphData?.data)
      setChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.lastMonthProjects?.graphData?.labels
        }
      }));
      /*       setBarChartData([{
              data: data?.salesByType?.data
            }])
            setBarChartOptions((chartOptions) => ({
              ...chartOptions,
              xaxis: {
                ...chartOptions.xaxis,
                categories: data?.salesByType?.labels.map((label) => capitalize(label.toLowerCase()))
              }
            })); */
      setFunnelChartData([{
        data: data?.funnelGraph?.data
      }])
      if (data?.totalProjects?.monthlyData?.length > 0) {
        setMiniChartData([{
          data: data?.totalProjects?.monthlyData,
          name: 'Vendas'
        }]);
        setMiniChartOptions((chartOptions) => ({
          ...chartOptions,
          xaxis: {
            ...chartOptions.xaxis,
            categories: data?.totalProjects?.monthlyLabel
          },
          annotations: {
            points: [{
              ...chartOptions.annotations.points[0],
              x: data?.totalProjects?.monthlyLabel[data?.totalProjects?.monthlyLabel?.length - 1],
              y: data?.totalProjects?.monthlyData[data?.totalProjects?.monthlyData?.length - 1]
            }]
          }
        }));
      }
      if (data?.totalInbound?.monthlyData?.length > 0) {
        setMiniChartData2([{
          data: data?.totalInbound?.monthlyData,
          name: 'Vendas'
        }]);
        setMiniChartOptions2((chartOptions) => ({
          ...chartOptions,
          xaxis: {
            ...chartOptions.xaxis,
            categories: data?.totalInbound?.monthlyLabel
          },
        }));
      }
      setUpdateChart(updateChart + 1);
    }
  };

  React.useEffect(() => {
    fetchSellers();
  }, []);

  React.useEffect(() => {
    fetchData(chosenSeller ? chosenSeller : undefined);
  }, [chosenSeller]);

  const navigateObject = {
    H_COMERCIAL: `/comercial/negocios`,
    COMERCIAL: `/comercial/negocios`
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <S.MainContainer container spacing='18px'>
        {/*         {role === "ADMINISTRATOR" || role === "H_COMERCIAL" &&
          <Grid container item>
            <MonthDatepicker setDate={setFilterDate} />
          </Grid>
        } */}
        <Grid item xs={12} lg={12} xl={5}>
          <S.RankingCard>
            <VuiBox height="100%" display="flex" flexDirection="row" justifyContent="space-between">
              <Grid container item xl={7} flexDirection='column' gap='5px'>
                <Grid container gap='5px'>
                  {projectHomeData?.goalPercentage < 60 &&
                    <>
                      <VuiTypography color="white" variant="h3" sx={{ fontSize: "24px" }} fontWeight="bold">
                        Poxa vida,
                      </VuiTypography>
                      <VuiTypography color="white" variant="h3" sx={{ fontSize: "24px" }} fontWeight="bold">
                        {decodedToken?.name}! 🙁
                      </VuiTypography>
                    </>
                  }
                  {projectHomeData?.goalPercentage >= 60 &&
                    <>
                      <VuiTypography color="white" variant="h3" sx={{ fontSize: "24px" }} fontWeight="bold">
                        Parabéns
                      </VuiTypography>
                      <VuiTypography color="white" variant="h3" sx={{ fontSize: "24px" }} fontWeight="bold">
                        {decodedToken?.name}! 🎉
                      </VuiTypography>
                    </>
                  }
                </Grid>
                <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
                  {projectHomeData?.goalPercentage < 60 ? 'Não estamos indo bem, podemos melhorar' : 'Melhores metas do mês'}
                </VuiTypography>
                <VuiTypography color="orange" variant="h3" sx={{ fontSize: "30px" }}>
                  {projectHomeData?.goalValue ?? 0} negócios
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
                  {projectHomeData?.goalPercentage}% da meta 🚀
                </VuiTypography>
                <Grid container>
                  <Link to={navigateObject[role]} rel='noopener noreferrer'>
                    <ButtonCustom label='Ver negócios' sx={{ fontSize: "16px", width: "200px" }} />
                  </Link>
                </Grid>
              </Grid>
              {projectHomeData?.goalPercentage < 60 &&
                <S.LowGoalGrid item xl={5} />
              }
              {projectHomeData?.goalPercentage >= 60 &&
                <S.TrophyGrid item xl={5} />
              }
            </VuiBox>
          </S.RankingCard>
        </Grid>
        <Grid item xs={12} lg={6} xl={3.5}>
          <Card>
            <S.ValuesGrid container>
              <S.RoundedButton>
                <RiBarChartFill color='#fff' size='30px' />
              </S.RoundedButton>
              <Grid display='flex' flexDirection='column'>
                <p className='title'>Total de Vendas</p>
                <Grid container gap='5px'>
                  <p className='value'>{projectHomeData?.totalProjects?.value}</p>
                  <p className={`percentage ${!projectHomeData?.totalProjects?.up ? 'negative' : 'positive'}`}>
                    {!projectHomeData?.totalProjects?.up ? '' : '+'}{projectHomeData?.totalProjects?.percentage}%
                  </p>
                </Grid>
              </Grid>
            </S.ValuesGrid>
            <Grid sx={{ minHeight: "250px" }}>
              <LineChart key={miniChartData} lineChartData={miniChartData} lineChartOptions={miniChartOptions} />
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6} xl={3.5}>
          <Card>
            <S.ValuesGrid container>
              <S.RoundedButton>
                <BsCurrencyDollar color='#fff' size='30px' />
              </S.RoundedButton>
              <Grid display='flex' flexDirection='column'>
                <p className='title'>Total Arrecadado</p>
                <Grid container gap='5px'>
                  <p className='value'>R$ {abbreviateNumbers((projectHomeData?.totalInbound?.value / 100) ?? 0, 1)}</p>
                  <p className={`percentage ${!projectHomeData?.totalInbound?.up ? 'negative' : 'positive'}`}>
                    {!projectHomeData?.totalInbound?.up ? '' : '+'}{projectHomeData?.totalInbound?.percentage}%
                  </p>
                </Grid>
              </Grid>
            </S.ValuesGrid>
            <Grid sx={{ minHeight: "250px" }}>
              <LineChart key={miniChartData2} lineChartData={miniChartData2} lineChartOptions={miniChartOptions2} />
            </Grid>
          </Card>
        </Grid>
        <S.CardGrid item xs={12} lg={6} xl={7} sx={{ minHeight: "400px" }}>
          <Card>
            <Grid container flexDirection='column'>
              <Grid container>
                <Grid item xs={12} md={12} xl={3}>
                  <p className='cardTitle'>Projetos</p>
                  <Grid container gap='2px'>
                    <p className={`percentage ${projectHomeData?.lastMonthProjects?.changeValues?.general?.up ? 'positive' : 'negative'}`}>
                      ({projectHomeData?.lastMonthProjects?.changeValues?.general?.up ? '+' : ''}{projectHomeData?.lastMonthProjects?.changeValues?.general?.value})
                    </p>
                    <p className='subtitle'>no ultimo mês</p>
                  </Grid>
                </Grid>
                <S.BoardsGrid container item xs={12} md={12} xl={9} gap='10px'>
                  <S.SocialGrid className='instagram' item xl={2}>
                    <S.SocialNameGrid container justifyContent='center' gap='2px'>
                      <p>Em andamento</p>
                    </S.SocialNameGrid>
                    <Grid container justifyContent='center'>
                      <p>{projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.value ?? 0}</p>
                      {projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.up
                        ? <IoArrowUp color="#65EFAD" size="22px" />
                        : <IoArrowDown color="#D7007B" size="22px" />
                      }
                    </Grid>
                  </S.SocialGrid>
                  <S.SocialGrid className='tiktok' item xl={2}>
                    <S.SocialNameGrid container justifyContent='center' gap='2px'>
                      <p>Criados</p>
                    </S.SocialNameGrid>
                    <Grid container justifyContent='center'>
                      <p>{projectHomeData?.lastMonthProjects?.changeValues?.created?.value ?? 0}</p>
                      {projectHomeData?.lastMonthProjects?.changeValues?.created?.up
                        ? <IoArrowUp color="#65EFAD" size="22px" />
                        : <IoArrowDown color="#D7007B" size="22px" />
                      }
                    </Grid>
                  </S.SocialGrid>
                  <S.SocialGrid className='twitter' item xl={2}>
                    <S.SocialNameGrid container justifyContent='center' gap='2px'>
                      <p>Finalizados</p>
                    </S.SocialNameGrid>
                    <Grid container justifyContent='center'>
                      <p>{projectHomeData?.lastMonthProjects?.changeValues?.finished?.value ?? 0}</p>
                      {projectHomeData?.lastMonthProjects?.changeValues?.finished?.up
                        ? <IoArrowUp color="#65EFAD" size="22px" />
                        : <IoArrowDown color="#D7007B" size="22px" />
                      }
                    </Grid>
                  </S.SocialGrid>
                </S.BoardsGrid>
              </Grid>
              <Grid sx={{ minHeight: "310px" }}>
                <LineChart key={chartData} lineChartData={chartData} lineChartOptions={chartOptions} />
              </Grid>
            </Grid>
          </Card>
        </S.CardGrid>
        <SellersRanking sellersList={projectHomeData?.membersRanking ?? []} />
        {(role === "H_COMERCIAL") &&
          <>
            <S.CardGrid item xs={12} lg={12} xl={7} sx={{ minHeight: "400px" }}>
              <Card>
                <Grid container flexDirection='column'>
                  <Grid container>
                    <Grid item xl={8}>
                      <p className='cardTitle'>Funil de Vendas</p>
                    </Grid>
                    <S.SelectInputGrid item xs={12} md={12} xl={4}>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        value={chosenSeller ? chosenSeller : null}
                        onChange={(e, value) => setChosenSeller(value)}
                        options={sellers ? sellers : []}
                        sx={{ width: "100%" }}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => <S.Input {...params} placeholder='Vendedor' />}
                        isOptionEqualToValue={(option, value) => option.username === value.username}
                      />
                    </S.SelectInputGrid>
                  </Grid>
                  <Grid sx={{ minHeight: "310px" }}>
                    <BarChart
                      key={updateChart}
                      barChartData={funnelChartData}
                      barChartOptions={funnelChartOptions}
                    />
                  </Grid>
                </Grid>
              </Card>
            </S.CardGrid>
            <BriefingRanking projectList={projectHomeData?.projectsRanking ? projectHomeData?.projectsRanking.slice(0, 5) : []} lg={12} xl={5} />
          </>
        }
      </S.MainContainer>
    </DashboardLayout >
  )
}

export default ProjectsCommercial