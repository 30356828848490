import { useState, useEffect, useContext } from "react";
import * as S from "./style";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Box, Grid, Icon } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import breakpoints from "assets/theme/base/breakpoints";
import {
  RiCurrencyLine,
  RiArrowUpCircleLine,
  RiArrowDownCircleLine,
  RiAddFill,
  RiDownload2Line,
} from "react-icons/ri";
import filter from "assets/icons/filter.svg";
import Datepicker from "components/Datepicker/Datepicker";
import Table from "defaultComponents/Tables/Table";
import Card from "@mui/material/Card";
import { IoEllipsisVertical, IoArrowBack, IoArrowForward } from "react-icons/io5";
import sleep from "assets/icons/zzz.svg";
import VuiButton from "components/VuiButton";
import VuiPagination from "components/VuiPagination";
import Modal from "components/Modal/Modal";
import AddEntry from "./ModalContent/AddEntry";
import EditEntry from "./ModalContent/EditEntry";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import {
  getFinancialData,
  deleteFinancialData,
  getCategories,
  getSubCategories,
} from "utils/requests/financial";
import moment from "moment";
import "moment/locale/pt-br";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Details from "./ModalContent/Details";
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style";
import FilterModal from "components/Modal/FilterModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownloadFinanceData from "./ModalContent/Download/DownloadFinanceData";
import { useDebounce } from "use-debounce";
import { SearchBarContext } from "context/SearchBarContext";
import Pagination from '@mui/material/Pagination';

const Accounting = () => {
  const mobile = useMediaQuery("(max-width: 992px)");

  moment.locale("pt-br");

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [openMenu, setOpenMenu] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [filterValues, setFilterValues] = useState([]);
  const [subFilterValues, setSubFilterValues] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [entryToEdit, setEntryToEdit] = useState();
  const [entryDetails, setEntryDetails] = useState();
  const [deleteId, setDeleteId] = useState();

  const categoryArrObj = {
    TODOS: ["ENTRADA", "SAIDA", "TRANSFERENCIA"],
    ENTRADA: ["ENTRADA"],
    SAIDA: ["SAIDA", "TRANSFERENCIA"],
  };

  const [filtersQuery, setFiltersQuery] = useState({
    type: categoryArrObj.TODOS,
    categories: [],
    subCategories: [],
    description: null,
  });
  const [filterDate, setFilterDate] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState();
  const [openCsvModal, setOpenCsvModal] = useState(false);

  const { searchQuery } = useContext(SearchBarContext);

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filtersQuery, 400);

  const getTableData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD, M, YYYY").valueOf() : "";
    const endDate = filterDate
      ? moment(`${filterDate[1]} 23:59:59`, "DD-M-YYYY HH:mm:ss").valueOf()
      : "";
    const data = await getFinancialData(page - 1, 10, debouncedFilters, startDate, endDate);
    if (!data.message && data !== tableData) {
      setTableData(data);
    }
  };

  useEffect(() => {
    getTableData();
  }, [debouncedFilters, filterDate, page]);

  const getAllCategories = async () => {
    const data = await getCategories(tabValue ? [tabValue] : ["ENTRADA", "SAIDA", "TRANSFERENCIA"]);
    if (!data.message) {
      setAllCategories(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr);
    }
  };

  const getAllSubCategories = async () => {
    const data = await getSubCategories(
      tabValue ? [tabValue] : ["ENTRADA", "SAIDA", "TRANSFERENCIA"]
    );
    if (!data.message) {
      setAllSubCategories(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setSubFilterValues(checkArr);
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(tableData[1] / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  useEffect(() => {
    calculatePagination();
  }, [tableData]);

  useEffect(() => {
    getAllCategories();
    getAllSubCategories();
  }, [tabValue]);

  useEffect(() => {
    setFiltersQuery((prevData) => ({
      ...prevData,
      description: debouncedSearch ? debouncedSearch?.toUpperCase() : null,
    }));
    setPage(1);
  }, [debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const checkArr = allCategories ? Array.from({ length: allCategories.length }, () => false) : [];
  const subCheckArr = allSubCategories
    ? Array.from({ length: allSubCategories.length }, () => false)
    : [];

  useEffect(() => {
    setFilterValues(checkArr);
    setSubFilterValues(subCheckArr);
  }, []);

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );

    setFilterValues(updatedCheckedState);
  };

  const handleSubOnChange = (position) => {
    const updatedCheckedState = subFilterValues.map((item, index) =>
      index === position ? !item : item
    );

    setSubFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues
      ?.map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const result = checkedIndexes?.map((index) => allCategories[index].id);
    result.length !== filtersQuery?.categories?.length
      ? setFiltersQuery((prevData) => ({ ...prevData, categories: result }))
      : null;
    setPage(1);
  };

  const subTagFilter = () => {
    const checkedIndexes = subFilterValues
      ?.map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const result = checkedIndexes?.map((index) => allSubCategories[index].id);
    result.length !== filtersQuery?.subCategories?.length
      ? setFiltersQuery((prevData) => ({ ...prevData, subCategories: result }))
      : null;
    setPage(1);
  };

  useEffect(() => {
    tagFilter();
  }, [filterValues]);

  useEffect(() => {
    subTagFilter();
  }, [subFilterValues]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDeleteModal = (id) => {
    setOpenConfirmDeletionModal(true);
    setDeleteId(id);
  };

  const cancelDelete = () => {
    setOpenConfirmDeletionModal(false);
    setDeleteId("");
  };

  const handleDelete = async () => {
    try {
      await deleteFinancialData(deleteId).then((res) => {
        if (res.type) getTableData();
        showToast("Entrada deletada com sucesso", "success");
        setOpenConfirmDeletionModal(false);
        if (!res.type) showToast("Falha ao apagar entrada", "error");
      });
    } catch {
      console.log("error");
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card
          onClick={() =>
            openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null
          }
        >
          <S.MainGrid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <Grid container item justifyContent="center">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                >
                  <Tab
                    label="TODOS"
                    value=""
                    onClick={() => setFiltersQuery({ type: categoryArrObj.TODOS })}
                    icon={<RiCurrencyLine color="white" size="20px" />}
                  />
                  <Tab
                    label="ENTRADA"
                    value="ENTRADA"
                    onClick={() => setFiltersQuery({ type: categoryArrObj.ENTRADA })}
                    icon={<RiArrowDownCircleLine color="#5ACC93" size="20px" />}
                  />
                  <Tab
                    label="SAIDA"
                    value="SAIDA"
                    onClick={() => setFiltersQuery({ type: categoryArrObj.SAIDA })}
                    icon={<RiArrowUpCircleLine color="#CA0073" size="20px" />}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={12} lg={12} xl={12} justifyContent="space-between">
              <S.DateGrid container item md={6.5} xl={6.5}>
                <Datepicker setDate={setFilterDate} allButton lifetime />
              </S.DateGrid>
              <Grid container item md={5.5} xl={5.5} justifyContent="flex-end" gap="15px">
                <S.EditButton onClick={() => setOpenCsvModal(true)}>
                  <RiDownload2Line size="25px" color="white" />
                </S.EditButton>
                <S.EditButton onClick={() => setOpenFilter(!openFilter)}>
                  <img src={filter} />
                </S.EditButton>
                {/*                 {openFilter &&
                  <S.FilterContainer container justifyContent='flex-end'>
                    <S.FilterGrid display='flex' flexDirection='column'>
                      {arrTest && arrTest.map((cat, index) => (
                        <Grid container key={index}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={filterValues[index]}
                            onChange={() => handleOnChange(index)}
                            name={cat}
                          />
                          <p>{cat}</p>
                        </Grid>
                      ))
                      }
                    </S.FilterGrid>
                  </S.FilterContainer>
                }  */}
              </Grid>
            </Grid>
            <Grid container item sm={12} md={12} lg={12} xl={12}>
              <S.TableGrid container>
                <Table
                  columns={[
                    { name: "descrição", align: "left" },
                    { name: "categoria", align: "left" },
                    { name: "sub-categoria", align: "left" },
                    { name: "valor", align: "left" },
                    { name: "data", align: "left" },
                    { name: "status", align: "left" },
                    { name: "observações", align: "left" },
                  ]}
                  rows={
                    tableData[0] &&
                    tableData[0].map((entry, index) => {
                      return {
                        descrição: (
                          <Grid container key={index}>
                            <Grid item md={3} xl={2}>
                              {entry.type === "SAIDA" && (
                                <RiArrowUpCircleLine
                                  className="negative"
                                  size="20px"
                                  style={{ alignSelf: "center" }}
                                />
                              )}
                              {entry.type === "TRANSFERENCIA" && (
                                <RiArrowUpCircleLine
                                  className="transfer"
                                  size="20px"
                                  style={{ alignSelf: "center" }}
                                />
                              )}
                              {entry.type === "ENTRADA" && (
                                <RiArrowDownCircleLine
                                  className="positive"
                                  size="20px"
                                  style={{ alignSelf: "center" }}
                                />
                              )}
                            </Grid>
                            <Grid item md={8} xl={9}>
                              <p>
                                {entry?.description?.length > (mobile ? 6 : 24)
                                  ? entry?.description?.substring(0, mobile ? 6 : 24) + "..."
                                  : entry?.description}
                              </p>
                            </Grid>
                          </Grid>
                        ),
                        categoria: <p>{entry?.category ? entry?.category?.name : ""}</p>,
                        "sub-categoria": (
                          <p>
                            {entry.subCategories &&
                              entry.subCategories.map((sub) => sub.name).join(", ")}
                          </p>
                        ),
                        valor: (
                          <Grid container>
                            <p
                              className={
                                entry.type === "TRANSFERENCIA"
                                  ? "transfer"
                                  : entry.type === "ENTRADA"
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              R${" "}
                              {Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </Grid>
                        ),
                        data: <p>{moment(entry.dateInvoiced).format("L")}</p>,
                        status: <p>{entry?.businessStatus ?? ""}</p>,
                        observações: (
                          <Grid container flexDirection="column">
                            <Grid container justifyContent="space-between">
                              <Grid item xs={11} xl={11}>
                                <p>
                                  {entry?.miscInfo
                                    ? entry?.miscInfo?.length > 50
                                      ? entry?.miscInfo?.substring(0, 50) + "..."
                                      : entry?.miscInfo
                                    : "-"}
                                </p>
                              </Grid>
                              <Grid item xs={1} xl={1} onClick={() => toggleOpenMenu(index)}>
                                <IoEllipsisVertical
                                  size="20px"
                                  style={{ cursor: "pointer", zIndex: "9999" }}
                                />
                              </Grid>
                            </Grid>
                            {openMenu[index] && (
                              <S.OptionsContainer container justifyContent="flex-end">
                                <S.OptionsGrid display="flex" flexDirection="column">
                                  <p
                                    onClick={() => {
                                      setEntryToEdit(entry);
                                      toggleOpenMenu(index);
                                      setOpenEditModal(true);
                                    }}
                                  >
                                    Editar
                                  </p>
                                  <p
                                    onClick={() => {
                                      handleDeleteModal(entry.id);
                                      toggleOpenMenu(index);
                                    }}
                                  >
                                    Excluir
                                  </p>
                                  <p
                                    onClick={() => {
                                      setEntryDetails(entry);
                                      setOpenDetailsModal(true);
                                    }}
                                  >
                                    Ver detalhes
                                  </p>
                                </S.OptionsGrid>
                              </S.OptionsContainer>
                            )}
                          </Grid>
                        ),
                      };
                    })
                  }
                />
              </S.TableGrid>
              <S.BottomGrid container>
                <Grid display="flex" gap="5px">
                  <VuiButton
                    variant="outlined"
                    color="outbound"
                    sx={{
                      fontWeight: "bold",
                      width: "25px",
                      height: "25px",
                      alignSelf: "center",
                      cursor: "default",
                    }}
                    size="small"
                    iconOnly
                    circular
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{"arrow_upward"}</Icon>
                  </VuiButton>
                  <p>DESPESA</p>
                </Grid>
                <Grid display="flex" gap="5px">
                  <VuiButton
                    variant="outlined"
                    color="transfer"
                    sx={{
                      fontWeight: "bold",
                      width: "25px",
                      height: "25px",
                      alignSelf: "center",
                      cursor: "default",
                    }}
                    size="small"
                    iconOnly
                    circular
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{"arrow_upward"}</Icon>
                  </VuiButton>
                  <p>TRANSFERÊNCIA</p>
                </Grid>
                <Grid display="flex" gap="5px">
                  <VuiButton
                    variant="outlined"
                    color="success"
                    sx={{
                      fontWeight: "bold",
                      width: "25px",
                      height: "25px",
                      alignSelf: "center",
                      cursor: "default",
                    }}
                    size="small"
                    iconOnly
                    circular
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{"arrow_downward"}</Icon>
                  </VuiButton>
                  <p>FATURAMENTO</p>
                </Grid>
                {/*               <Grid display='flex' gap='5px'>
                <VuiButton
                  variant="outlined"
                  color='white'
                  sx={{ fontWeight: "bold", width: "25px", height: "25px", alignSelf: "center", cursor: "default" }}
                  size="small"
                  iconOnly
                  circular
                >
                  <img src={sleep} />
                </VuiButton>
                <p>PENDENTE</p>
              </Grid> */}
              </S.BottomGrid>
            </Grid>
          </S.MainGrid>
        </Card>
        <S.PaginationGrid container justifyContent="center">
          <Pagination
            count={pagination?.length}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange}
          />
        </S.PaginationGrid>
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenModal(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <AddEntry refetch={getTableData} closeModal={() => setOpenModal(false)} />
        </Modal>
        <Modal
          open={openEditModal}
          onClose={() => {
            setOpenEditModal(false);
            setEntryToEdit();
          }}
        >
          <EditEntry
            entry={entryToEdit ? entryToEdit : false}
            refetch={getTableData}
            closeModal={() => setOpenEditModal(false)}
          />
        </Modal>
        <Modal size="sm" open={openConfirmDeletionModal} onClose={() => cancelDelete()}>
          <Grid container flexDirection="column" gap="20px">
            <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
            <p style={{ fontSize: "18px" }}>
              Deseja excluir essa entrada? Essa ação é irreversível
            </p>
            <Grid container gap="15px" justifyContent="center">
              <Grid item lg={3}>
                <ButtonCustom
                  label="Cancelar"
                  onClick={() => cancelDelete()}
                  sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
                ></ButtonCustom>
              </Grid>
              <Grid item lg={3}>
                <ButtonCustom label="Confirmar" onClick={() => handleDelete()}></ButtonCustom>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal open={openDetailsModal} onClose={() => setOpenDetailsModal(false)}>
          <Details entry={entryDetails ? entryDetails : false} />
        </Modal>
        <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
          <p>Filtragem por categoria</p>
          {allCategories &&
            allCategories.map((cat, index) => (
              <Grid container key={index}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={filterValues[index]}
                  onChange={() => handleOnChange(index)}
                  name={cat.name}
                />
                <p style={{ fontSize: "18px" }}>{cat.name}</p>
              </Grid>
            ))}
          <p style={{ marginTop: "15px" }}>Filtragem por sub-categoria</p>
          {allSubCategories &&
            allSubCategories.map((cat, index) => (
              <Grid container key={index}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={subFilterValues[index]}
                  onChange={() => handleSubOnChange(index)}
                  name={cat.name}
                />
                <p style={{ fontSize: "18px" }}>{cat.name}</p>
              </Grid>
            ))}
        </FilterModal>
        <Modal open={openCsvModal} onClose={() => setOpenCsvModal(false)} size="sm">
          <DownloadFinanceData />
        </Modal>
      </DashboardLayout>
    </>
  );
};

export default Accounting;
