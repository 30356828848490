import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import { Card, Grid } from "@mui/material";
import * as S from './style'
import ProjectsRanking from "pages/Projects/components/ProjectsRanking/ProjectsRanking";
import { IoEllipsisVertical, IoArrowDown, IoArrowUp } from "react-icons/io5"
import InfluencersRanking from "layouts/dashboard/components/InfluencersRanking/InfluencersRanking";
import { useState, useEffect, useContext } from "react";
import { getMarketingPageData } from "utils/requests/project";
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import BarChart from "defaultComponents/Charts/BarCharts/BarChart";
import { capitalize } from "utils/utils";
import { TokenContext } from "context/TokenContext";

const Marketing = () => {
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [projectHomeData, setProjectHomeData] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#FC6009", "#F8DF00", "#33CCFF"],
    },
    colors: ["#FC6009", "#F8DF00", "#33CCFF"],
  });
  const [barChartData, setBarChartData] = useState([]);
  const [barChartOptions, setBarChartOptions] = useState({
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        barHeight: '85%',
        distributed: true,
        horizontal: true,
        borderRadius: 4,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    stroke: {
      colors: ["transparent"],
      width: 5
    },
    colors: ['#007093', '#00752D', '#A58200', '#00ACE2', '#9D171C', '#6D788D'],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: (value) => {
        return 'R$ ' + Number((value / 100).toFixed(2)).toLocaleString("pt-BR", {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })
      }
    },
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "10px",
      },
    },
    xaxis: {
      categories: ['Campanhas', 'Santander', 'Palco', 'Eventos', 'Livros', 'Audio Visual'],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
        formatter: (value) => {
          return Number((value / 100).toFixed(2)).toLocaleString("pt-BR", {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          })
        }
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        }
      },
    },
    tooltip: {
      enabled: false,
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        show: false,
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    }
  });

  const fetchData = async () => {
    const data = await getMarketingPageData();
    if (!data.message) {
      setProjectHomeData(data);
      setChartData(data?.lastMonthProjects?.graphData?.data)
      setChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.lastMonthProjects?.graphData?.labels
        }
      }));
      setBarChartData([{
        data: data?.salesByType?.data
      }])
      setBarChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.salesByType?.labels.map((label) => capitalize(label.toLowerCase()))
        }
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container flexDirection='column' gap='16px'>
          <S.GraphMainContainer container spacing='18px'>
            <S.CardGrid item xs={12} lg={6} xl={7} sx={{ minHeight: "400px" }}>
              <Card>
                <Grid container flexDirection='column'>
                  <Grid container>
                    <Grid item xs={12} md={12} xl={3}>
                      <p className='cardTitle'>Projetos</p>
                      <Grid container gap='2px'>
                        <p className={`percentage ${projectHomeData?.lastMonthProjects?.changeValues?.general?.up ? 'positive' : 'negative'}`}>
                          ({projectHomeData?.lastMonthProjects?.changeValues?.general?.up ? '+' : ''}
                          {projectHomeData?.lastMonthProjects?.changeValues?.general?.value})
                          </p>
                        <p className='subtitle'>no ultimo mês</p>
                      </Grid>
                    </Grid>
                    <S.BoardsGrid container item xs={12} md={12} xl={9} gap='10px'>
                      <S.SocialGrid className='instagram' item xl={2}>
                        <S.SocialNameGrid container justifyContent='center' gap='2px'>
                          <p>Em andamento</p>
                        </S.SocialNameGrid>
                        <Grid container justifyContent='center'>
                          <p>{projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.value ?? 0}</p>
                          {projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.up
                            ? <IoArrowUp color="#65EFAD" size="22px" />
                            : <IoArrowDown color="#D7007B" size="22px" />
                          }
                        </Grid>
                      </S.SocialGrid>
                      <S.SocialGrid className='tiktok' item xl={2}>
                        <S.SocialNameGrid container justifyContent='center' gap='2px'>
                          <p>Criados</p>
                        </S.SocialNameGrid>
                        <Grid container justifyContent='center'>
                          <p>{projectHomeData?.lastMonthProjects?.changeValues?.created?.value ?? 0}</p>
                          {projectHomeData?.lastMonthProjects?.changeValues?.created?.up
                            ? <IoArrowUp color="#65EFAD" size="22px" />
                            : <IoArrowDown color="#D7007B" size="22px" />
                          }
                        </Grid>
                      </S.SocialGrid>
                      <S.SocialGrid className='twitter' item xl={2}>
                        <S.SocialNameGrid container justifyContent='center' gap='2px'>
                          <p>Finalizados</p>
                        </S.SocialNameGrid>
                        <Grid container justifyContent='center'>
                          <p>{projectHomeData?.lastMonthProjects?.changeValues?.finished?.value ?? 0}</p>
                          {projectHomeData?.lastMonthProjects?.changeValues?.finished?.up
                            ? <IoArrowUp color="#65EFAD" size="22px" />
                            : <IoArrowDown color="#D7007B" size="22px" />
                          }
                        </Grid>
                      </S.SocialGrid>
                    </S.BoardsGrid>
                  </Grid>
                  <Grid sx={{ minHeight: "310px" }}>
                    <LineChart key={chartData} lineChartData={chartData} lineChartOptions={chartOptions} />
                  </Grid>
                </Grid>
              </Card>
            </S.CardGrid>
            <InfluencersRanking influencerList={projectHomeData?.influencerRanking ?? []} rankingType='projects' />
          </S.GraphMainContainer>
          {(role === "ADMINISTRATOR" || role === "H_MARKETING") &&
            <Grid container spacing="18px">
              <S.CardGrid item xs={12} lg={7} xl={8} sx={{ minHeight: "400px" }}>
                <Card>
                  <Grid container flexDirection='column'>
                    <Grid container>
                      <Grid item xl={12}>
                        <p className='cardTitle'>Vendas por tipo de projeto</p>
                        <Grid container>
                          <p className='subtitle'>
                            Total R$ {projectHomeData?.salesByType?.total
                              ?
                              Number((projectHomeData?.salesByType?.total / 100).toFixed(2)).toLocaleString("pt-BR", {
                                minimumFractionDigits: 2, maximumFractionDigits: 2
                              })
                              :
                              0
                            }
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid sx={{ minHeight: "400px" }}>
                      <BarChart
                        key={barChartData}
                        barChartData={barChartData}
                        barChartOptions={barChartOptions}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </S.CardGrid>
              <ProjectsRanking
                md={12}
                lg={5}
                xl={4}
                projectList={
                  projectHomeData?.projectRanking
                    ?
                    projectHomeData?.projectRanking?.slice(0, 5)
                    :
                    []
                }
              />
            </Grid>
          }
        </Grid>
      </VuiBox>
    </DashboardLayout>
  )
}

export default Marketing