import styled from 'styled-components'
import { Grid, Button } from '@mui/material'
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';

export const DateContainer = styled(Grid)`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: #060201;
  padding: 5px 20px;
  width: 300px !important;

/*   .flatpickr-input{
    position: absolute;
    margin-top: 15px;
    border: none;
    background: black;
    z-index: -3;
  }
 */
  p {
    color: rgb(255, 255, 255);
    opacity: 0.85;
    font-size: 16px;
    font-weight: 400;
  }
`

export const EditButtonGrid = styled(Grid)`
/*   position: absolute;
  right: 0; */
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const UpdateButtonGrid = styled(EditButtonGrid)`
  right: 20px;
  top: 20px;
`

export const ProgressGrid = styled(Grid)`
  border-radius: 10px;
  background: #2B0B03;
  backdrop-filter: blur(2px);
  color: #fff;
  padding: 10px 15px;
  min-width: 250px;

  @media (max-width: 992px){
    width: 100%;
  }

  svg {
    align-self: center;
  }

  .followers {
    font-size: 16px;
  }

`

export const SocialGrid = styled(Grid)`
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  text-align: center;
  padding: 5px;
  min-width: 120px;
  cursor: pointer;
`

export const SocialCardsGrid = styled(Grid)`
  justify-content: flex-end;
  gap: 10px;
  
  @media (max-width: 992px){
    justify-content: center;
    margin: 20px 0 !important;
  }

  @media (min-width: 1440px){
    flex-wrap: nowrap !important;
  }

  svg {
    align-self: center;
  }

  .instagram {
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
  }

  .tiktok{
    background: linear-gradient(180deg, #F8DF00 0%, #6A4000 100%);
  }

  .twitter {
    background: linear-gradient(180deg, #00ACE2 0%, #003E52 100%)
  }

  .youtube {
    background: linear-gradient(180deg, #B51E23 0%, #3D0002 100%);
  }

  .spotify {
    background: linear-gradient(180deg, #00D755 0%, #00240D 100%);
  }
`

export const SocialNameGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 400;
`

export const BarContainer = styled(Grid)`
  margin-top: 5px;

  .bar{
    height: 8px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    display: flex;
    div{
      height: 100%;
    }
    .instagram{
      background-color: #FC6009;
    }
    .tiktok{
      background-color: #F8DF00;
    }
    .twitter{
      background-color: #33CCFF;
    }
    .youtube{
      background-color: #FF0000;
    }
    .spotify{
      background-color: #00D554;
    }
  }
    
`

export const BoardGrid = styled(Grid)`
  @media (max-width: 992px) {
    justify-content: center;
  }
`

export const PageLink = styled(Link)`
  display: contents;
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 100px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`

export const WarningGrid = styled(Grid)`
  margin-top: 40px;
`

export const ConstructionModalGrid = styled(Grid)`
  margin-top: 40px;

  .subtitle {
    color: #8F8F8F;
    font-size: 18px;
  }
  .reminder {
    font-weight: 600;
  }
`