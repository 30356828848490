import styled from "styled-components"
import { Grid } from "@mui/material"

export const RankingGrid = styled(Grid)`
  font-size: 24px;
  color: #FFFFFF;
 /*  gap: 20px; */
`

export const RankingCard = styled(Grid)`
  height: 100%;

  .positiveBadge {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #72E128;
    color: #72E128;
  }
  .negativeBadge {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #E13432;
    color: #E22B00;
  }
`

export const RankingPhoto = styled(Grid)`
  p {
    text-align: center;
    position: absolute;
    top: 55px;
    left: 65px;
  }
  .followers {
    text-align: center;
    position: absolute;
    top: 55px;
    left: 58px;
  }
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: 45px;
    height: 50px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
    }
  }
`

export const NumberGrid = styled(Grid)`
  font-size: 20px;

  .count {
    font-size: 15px;
  }
`

export const BadgeGrid = styled(Grid)`
  font-size: 15px;
  background-color: red;
  padding: 5px 15px;
  border-radius: 15px;

  .percentage {
    font-weight: 700;
  }
`