import React from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiPagination from "components/VuiPagination";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box } from "@mui/material";
import * as S from "./style";
import instagram from "../../assets/icons/socials/instagram.png";
import youtube from "../../assets/icons/socials/youtube.png";
import tiktok from "../../assets/icons/socials/tiktok.png";
import twitter from "../../assets/icons/socials/twitter.png";
import spotify from "../../assets/icons/socials/spotify.png";
import { useNavigate, Link } from "react-router-dom";
import {
  IoArrowUp,
  IoArrowDown,
  IoEllipse,
  IoArrowBack,
  IoArrowForward,
  IoEllipsisVertical,
} from "react-icons/io5";
import { getInfluencers, getInfluencerTags, editInfluencer } from "utils/requests/auth";
import filter from "assets/icons/filter.svg";
import FilterModal from "components/Modal/FilterModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { SearchBarContext } from "context/SearchBarContext";
import { useDebounce } from "use-debounce";
import { abbreviateNumbers } from "utils/utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { RiAddFill } from "react-icons/ri";
import Modal from "components/Modal/Modal";
import CreateInfluencer from "./ModalContent/CreateInfluencer";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";
import { deleteUser } from "utils/requests/auth";
import VuiAvatar from "components/VuiAvatar";
import Pagination from '@mui/material/Pagination';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e0e0e0",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: "15px",
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const Influencers = () => {
  const navigate = useNavigate();

  const [users, setUsers] = React.useState([]);
  const [pagination, setPagination] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [tags, setTags] = React.useState();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState([]);
  const [filters, setFilters] = React.useState({ name: null, userTags: [] });
  const [openCreateInfluencerModal, setOpenCreateInfluencerModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = React.useState(false);
  const [openConfirmStatusChangeModal, setOpenConfirmStatusChangeModal] = React.useState(false);
  const [chosenInfluencer, setChosenInfluencer] = React.useState();

  const { searchQuery } = React.useContext(SearchBarContext);
  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.statusCode) {
      setTags(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr);
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(users[1] / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  const fetchUsers = async () => {
    const data = await getInfluencers(page - 1, filters);
    if (!data.statusCode) {
      setUsers(data);
    }
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  React.useEffect(() => {
    fetchUsers();
  }, [page, debouncedFilters]);

  React.useEffect(() => {
    calculatePagination();
  }, [users]);

  React.useEffect(() => {
    setFilters((prevData) => ({
      ...prevData,
      name: debouncedSearch ? debouncedSearch.toUpperCase() : null,
    }));
    setPage(1);
  }, [debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues
      ?.map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const result = checkedIndexes?.map((index) => tags[index].id);
    result.length !== filters?.userTags?.length
      ? setFilters((prevData) => ({ ...prevData, userTags: result }))
      : null;
    setPage(1);
  };

  React.useEffect(() => {
    tagFilter();
  }, [filterValues]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleInfluencerDelete = (influencerInfo) => {
    setChosenInfluencer(influencerInfo);
    setOpenConfirmDeletionModal(true);
  };

  const cancelDelete = () => {
    setChosenInfluencer();
    setOpenConfirmDeletionModal(false);
  };

  const handleInfluencerStatus = (influencerInfo) => {
    setChosenInfluencer(influencerInfo);
    setOpenConfirmStatusChangeModal(true);
  };

  const cancelStatusChange = () => {
    setChosenInfluencer();
    setOpenConfirmStatusChangeModal(false);
  };

  const userStatusChange = async (bool) => {
    await editInfluencer(chosenInfluencer?.username, { active: bool }).then((res) => {
      if (!res.message) {
        showToast(
          bool ? "Usuário ativado com sucesso" : "Usuário desativado com sucesso!",
          "success"
        );
        fetchUsers();
        cancelStatusChange();
      }
      if (res.message) {
        showToast("Falha ao alterar dados", "error");
      }
    });
  };

  const handleUserDelete = async (username) => {
    await deleteUser(username).then((res) => {
      if (!res.message) {
        showToast("Usuário deletado com sucesso!", "success");
        fetchUsers();
        cancelDelete();
      }
      if (res.message) {
        showToast("Falha ao alterar dados", "error");
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Todos os Influencers
              </VuiTypography>
              <S.EditButton onClick={() => setOpenFilter(true)}>
                <img src={filter} draggable="false" />
              </S.EditButton>
            </VuiBox>
            <VuiBox>
              <Table
                mb={role === "ADMINISTRATOR" ? "40px" : 0}
                columns={[
                  { name: "influencer", align: "left" },
                  { name: "categoria", align: "left" },
                  { name: "alcance", align: "center" },
                  { name: "status", align: "center" },
                  { name: "redes", align: "center" },
                ]}
                rows={
                  users[0] &&
                  users[0].map((user, index) => {
                    return {
                      influencer: (
                        <S.InfluencerGrid
                          container
                          flexDirection="row"
                          onClick={() =>
                            navigate(`/influencers/${user?.username ? user?.username : ""}`)
                          }
                          key={user?.id}
                        >
                          <Grid container item xl={1.5} lg={3} md={2}>
                            <S.RankingPhoto>
                              <Avatar src={user?.avatar ? user?.avatar : ""} />
                            </S.RankingPhoto>
                          </Grid>
                          <S.UserTextGrid
                            container
                            flexDirection="column"
                            item
                            xl={8.5}
                            lg={7}
                            md={8}
                          >
                            <p className="name">{user.name}</p>
                            <p>
                              {user?.bio?.length > 60
                                ? user?.bio?.substring(0, 60) + "..."
                                : user?.bio}
                            </p>
                          </S.UserTextGrid>
                        </S.InfluencerGrid>
                      ),
                      categoria: (
                        <S.ThemeGrid container flexDirection="column">
                          <p className="tags">
                            {user?.userTags?.length > 0
                              ? user?.userTags && user?.userTags?.join(", ")
                              : "-"}
                          </p>
                        </S.ThemeGrid>
                      ),
                      alcance: (
                        <S.ReachGrid container justifyContent="center">
                          {user?.changes?.up && <IoArrowUp color="#65EFAD" size="22px" />}
                          {!user?.changes?.up && <IoArrowDown color="#D7007B" size="22px" />}
                          {/* <LightTooltip title='teste'> */}
                          <p className="count">
                            {user.reach ? abbreviateNumbers(user?.reach ?? 0, 2) : 0}
                          </p>
                          {/* </LightTooltip> */}
                        </S.ReachGrid>
                      ),
                      status: (
                        <S.StatusGrid container justifyContent="center">
                          <IoEllipse color={user?.active ? "#65EFAD" : "#D7007B"} size="12px" />
                          <p className="status">{user?.active ? "ACTIVE" : "INACTIVE"}</p>
                        </S.StatusGrid>
                      ),
                      redes: (
                        <Grid container justifyContent="space-between">
                          <S.SocialsGrid
                            container
                            item
                            xs={9}
                            md={11}
                            xl={11}
                            justifyContent="center"
                          >
                            {user?.instagram?.username ? (
                              <Link
                                to={`https://www.instagram.com/${user?.instagram?.username}`}
                                target="_blank"
                              >
                                <img src={instagram} height="18px" draggable="false" />
                              </Link>
                            ) : (
                              <img
                                src={instagram}
                                height="18px"
                                draggable="false"
                                className="disabled"
                              />
                            )}
                            {user?.youtube?.username ? (
                              <Link
                                to={`https://www.youtube.com/@${user?.youtube?.username}`}
                                target="_blank"
                              >
                                <img src={youtube} height="18px" draggable="false" />
                              </Link>
                            ) : (
                              <img
                                src={youtube}
                                height="18px"
                                draggable="false"
                                className="disabled"
                              />
                            )}
                            {user?.tiktok?.username ? (
                              <Link
                                to={`https://www.tiktok.com/@${user?.tiktok?.username}`}
                                target="_blank"
                                className={user?.instagram?.username ? "active" : "disabled"}
                              >
                                <img src={tiktok} height="18px" draggable="false" />
                              </Link>
                            ) : (
                              <img
                                src={tiktok}
                                height="18px"
                                draggable="false"
                                className="disabled"
                              />
                            )}
                            {user?.twitter?.username ? (
                              <Link
                                to={`https://www.twitter.com/${user?.twitter?.username}`}
                                target="_blank"
                                className={user?.instagram?.username ? "active" : "disabled"}
                              >
                                <img src={twitter} height="18px" draggable="false" />
                              </Link>
                            ) : (
                              <img
                                src={twitter}
                                height="18px"
                                draggable="false"
                                className="disabled"
                              />
                            )}
                            <img
                              src={spotify}
                              height="18px"
                              draggable="false"
                              style={{ filter: "grayscale(1)" }}
                            />
                          </S.SocialsGrid>
                          {role === "ADMINISTRATOR" && (
                            <Grid
                              item
                              xs={3}
                              md={1}
                              xl={1}
                              sx={{ alignSelf: "center" }}
                              onClick={() => toggleOpenMenu(index)}
                            >
                              <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                            </Grid>
                          )}
                          {openMenu[index] && (
                            <S.OptionsContainer container justifyContent="flex-end">
                              <S.OptionsGrid display="flex" flexDirection="column">
                                {user?.active && (
                                  <>
                                    <p onClick={() => handleInfluencerStatus(user)}>Desativar</p>
                                  </>
                                )}
                                {!user?.active && (
                                  <>
                                    <p onClick={() => handleInfluencerStatus(user)}>Ativar</p>
                                  </>
                                )}
                                <S.Divider />
                                <p onClick={() => handleInfluencerDelete(user)}>Excluir</p>
                              </S.OptionsGrid>
                            </S.OptionsContainer>
                          )}
                        </Grid>
                      ),
                    };
                  })
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent="center">
        <S.PaginationGrid container justifyContent="center">
          <Pagination
            count={pagination?.length}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange}
          />
        </S.PaginationGrid>
      </Grid>
      {(role === "ADMINISTRATOR" || role === "H_COMERCIAL") && (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenCreateInfluencerModal(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
      )}
      <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por categoria</p>
        {tags &&
          tags.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={filterValues[index]}
                onChange={() => handleOnChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
      </FilterModal>
      <Modal open={openCreateInfluencerModal} onClose={() => setOpenCreateInfluencerModal(false)}>
        <CreateInfluencer />
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir essa entrada? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => handleUserDelete(chosenInfluencer?.username)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="sm" open={openConfirmStatusChangeModal} onClose={() => cancelStatusChange()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar mudança de status</p>
          <p style={{ fontSize: "18px" }}>
            Deseja {chosenInfluencer?.active ? "DESATIVAR" : "ATIVAR"} este usuário?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelStatusChange()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => userStatusChange(!chosenInfluencer?.active)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default Influencers;
