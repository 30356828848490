import React from 'react'
import * as S from "../style"
import { Grid } from '@mui/material';
import Card from "@mui/material/Card";
import PieChart from 'defaultComponents/Charts/PieCharts/PieChart';
import DoubleBarChart from 'defaultComponents/Charts/BarCharts/DoubleBarChart';
import Table from "defaultComponents/Tables/Table";

const Demographic = () => {
  /*   const [doubleChartData, setDoubleChartData] = React.useState([{
        data: ["44%", "55%", "41%", "64%", "22%"]
      }, {
      data: ["53%", "32%", "33%", "52%", "13%"]
    }])
    const [doubleChartOptions, setDoubleChartOptions] = React.useState({
      chart: {
        type: 'bar',
        height: 430,
        toolbar: {
          show: false
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: "12px",
        },
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        shared: true,
        intersect: false,
        theme: "dark",
      },
      xaxis: {
        categories: ["13-17", "18-24", "25-34", "35-44", "44+"],
      },
    }) */
  const [ageChart, setAgeChart] = React.useState({
    series: [
      {
        name: "Female",
        data: ["44", "55", "41", "64", "22"]
      }, {
        name: "Male",
        data: ["53", "32", "33", "52", "13"]
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 430,
        toolbar: {
          show: false
        },
        labels: ['Female', 'Male'],
      },
      colors: ['#BE4E75', '#733278'],
      grid: {
        show: false,
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: "12px",
        },
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        shared: true,
        intersect: false,
        theme: "dark",
      },
      xaxis: {
        categories: ["13-17", "18-24", "25-34", "35-44", "44+"],
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val) {
            return val + "%";
          }
        }
      }
    }
  });

  const [genderChart, setGenderChart] = React.useState({
    series: [44, 55, 41],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Female', 'Male', 'Outros'],
      colors: ['#BE4E75', '#733278', '#47C094'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  })

  const [countryChart, setCountryChart] = React.useState({
    series: [44, 55, 15, 10, 10, 10, 10, 10],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Brasil', 'Canada', 'Suécia', 'Itália', 'Portugal', 'Austrália', 'India', 'Outros'],
      colors: ['#BE4E75', '#50859D', '#47C094', '#DB972C', '#744C75', '#5BADBF', '#92181C', '#545454'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  })

  return (
    <Grid container spacing='18px'>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid container>
            <Grid item md={6} xl={6}>
              <p>Gênero</p>
              <S.PieChartGrid item xl={10}>
                <PieChart pieChartData={genderChart.series} pieChartOptions={genderChart.options} />
              </S.PieChartGrid>
            </Grid>
            <Grid item md={6} xl={6}>
              <p>Idade</p>
              <S.AgeChartGrid>
                <DoubleBarChart doubleBarChartData={ageChart.series} doubleBarChartOptions={ageChart.options} />
              </S.AgeChartGrid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid container>
            <Grid item md={6} xl={6}>
              <p>Seguidores por País</p>
              <S.PieChartGrid item xl={10}>
                <PieChart pieChartData={countryChart.series} pieChartOptions={countryChart.options} />
              </S.PieChartGrid>
            </Grid>
            <Grid item md={6} xl={6}>
              <p>Seguidores por Cidade</p>
              <Grid>
                <Table
                  columns={[
                    { name: "cidade", align: "left" },
                    { name: "contagem", align: "center" },
                  ]}
                  rows={[
                    {
                      cidade: (
                        <p>Maceió, Alagoas</p>
                      ),
                      contagem: (
                        <p>15%</p>
                      )
                    },
                    {
                      cidade: (
                        <p>Maceió, Alagoas</p>
                      ),
                      contagem: (
                        <p>15%</p>
                      )
                    },
                    {
                      cidade: (
                        <p>Maceió, Alagoas</p>
                      ),
                      contagem: (
                        <p>15%</p>
                      )
                    },
                    {
                      cidade: (
                        <p>Maceió, Alagoas</p>
                      ),
                      contagem: (
                        <p>15%</p>
                      )
                    },
                    {
                      cidade: (
                        <p>Maceió, Alagoas</p>
                      ),
                      contagem: (
                        <p>15%</p>
                      )
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Demographic