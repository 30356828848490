import { useCallback, useState, useEffect, useContext } from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import * as S from './style'
import filter from 'assets/icons/filter.svg'
import MonthDatepicker from 'components/Datepicker/MonthDatepicker';
import { Grid, Card } from '@mui/material';
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import Pagination from '@mui/material/Pagination';
import FilterModal from 'components/Modal/FilterModal';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { getSellers } from "utils/requests/other";
import { useDebounce } from "use-debounce";
import moment from 'moment';
import 'moment/locale/pt-br';
import { SearchBarContext } from 'context/SearchBarContext';
import RejectionsTable from './components/RejectionsTable';
import { getRejectedProjects } from 'utils/requests/project';
import { useNavigate } from 'react-router-dom';

const CommercialRejections = () => {
  moment.locale("pt-br");

  const { searchQuery } = useContext(SearchBarContext);

  const [projects, setProjects] = useState();
  const [filterDate, setFilterDate] = useState();
  const [tabValue, setTabValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [pagination, setPagination] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({ username: [], projectTitle: null });
  const [sellers, setSellers] = useState([]);

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const fetchProjects = useCallback(async (status) => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const data = await getRejectedProjects(
      page - 1,
      size,
      debouncedFilters,
      startDate,
      endDate,
      status ?? []
    );
    if (!data.message) {
      setProjects(data);
    };
  }, [page, debouncedFilters, filterDate]);

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [tabValue]);

  useEffect(() => {
    setFilters((prevData) => ({ ...prevData, projectTitle: debouncedSearch ? debouncedSearch.toUpperCase() : null }));
    setPage(1);
  }, [debouncedSearch]);

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      setSellers(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr)
    };
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(projects[1] / 8);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  useEffect(() => {
    if (projects) calculatePagination();
  }, [projects]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues?.map((bool, index) => bool === true ? index : null).filter((el) => (el !== null));
    const result = checkedIndexes?.map((index) => sellers[index].username);
    result.length !== filters?.username?.length ? setFilters((prevData) => ({ ...prevData, username: result })) : [];
    setPage(1);
  };

  useEffect(() => {
    tagFilter();
  }, [filterValues]);

  return (
    <DashboardLayout>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar />
      <Grid>
        <Card>
          <S.FiltersGrid container item sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
            <Grid container item md={7.5} lg={7.5} xl={6.5}>
              <MonthDatepicker setDate={setFilterDate} allButton />
            </Grid>
            {(role === 'ADMINISTRATOR' || role === 'H_COMERCIAL') &&
              <Grid container item md={4.5} lg={4.5} xl={5.5} justifyContent='flex-end'>
                <S.EditButton>
                  <img src={filter} onClick={() => setOpenFilter(true)} />
                </S.EditButton>
              </Grid>
            }
          </S.FiltersGrid>
          <Grid>
            <RejectionsTable projects={projects ? projects[0] : []} refetch={fetchProjects} />
          </Grid>
          <S.PaginationGrid container justifyContent='center'>
            <Pagination count={pagination?.length} page={page} siblingCount={1} boundaryCount={1} onChange={handleChange} />
          </S.PaginationGrid>
        </Card>
      </Grid>
      {/*       {role !== 'INFLUENCER' &&
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenAddProjectModal(true)} >
            <RiAddFill size='30px' />
          </S.ModalFab>
        </Box>
      } */}
      {/*       <Modal open={openAddProjectModal} onClose={() => setOpenAddProjectModal(false)}>
        <AddProject />
      </Modal> */}
      <FilterModal size='sm' open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por usuário</p>
        {sellers && sellers?.map((cat, index) => (
          <Grid container key={index}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={filterValues[index]}
              onChange={() => handleOnChange(index)}
              name={cat.name}
            />
            <p style={{ fontSize: "18px" }}>{cat.name}</p>
          </Grid>
        ))
        }
      </FilterModal>
    </DashboardLayout>
  )
}

export default CommercialRejections