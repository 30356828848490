

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";
import { Grid } from "@mui/material";
import arrowRightUp from 'assets/icons/arrow-right-up.svg'
import { Link } from "react-router-dom";
import * as S from './style'
import Tooltip from "@mui/material/Tooltip";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import Table from "defaultComponents/Tables/Table";

const avatars = (members) =>
  members.map(([image, name]) => (
    <Tooltip key={name} title={name} placeholder="bottom">
      <VuiAvatar
        src={image}
        alt="name"
        size="sm"
        sx={{
          border: ({ borders: { borderWidth }, palette: { dark } }) =>
            `${borderWidth[2]} solid ${dark.focus}`,
          cursor: "pointer",
          position: "relative",

          "&:not(:first-of-type)": {
            ml: -1.25,
          },

          "&:hover, &:focus": {
            zIndex: "10",
          },
        }}
      />
    </Tooltip>
  ));

const LastProjects = ({ projects, routing, isMarketing }) => {

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <VuiBox mb="auto">
          <Grid>
            <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
              Últimos Projetos
            </VuiTypography>
            <VuiBox display="flex" alignItems="center" lineHeight={0}>
              <BsCheckCircleFill color="green" size="15px" />
              <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
                &nbsp;<strong>0 concluídos</strong> este mês
              </VuiTypography>
            </VuiBox>
            {routing &&
              <VuiBox>
                <S.EditButtonGrid>
                  <Link to={routing}>
                    <S.EditButton>
                      <img src={arrowRightUp} />
                    </S.EditButton>
                  </Link>
                </S.EditButtonGrid>
              </VuiBox>
            }
          </Grid>
        </VuiBox>
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        {(projects?.length === 0 || !projects) && <p style={{ color: "#fff" }}>Sem informações</p>}
        {projects?.length > 0 &&
          <Table
            columns={
              isMarketing
                ?
                [
                  { name: "empresa", align: "left" },
                  { name: "influencers", align: "left" },
                  { name: "projetos", align: "center" },
                  { name: "progresso", align: "left" }
                ]
                :
                [
                  { name: "empresa", align: "left" },
                  { name: "influencers", align: "left" },
                  { name: "projetos", align: "center" },
                  { name: "budget", align: "center" },
                  { name: "progresso", align: "left" }
                ]
            }
            rows={
              projects && projects?.map((project, index) => {
                return {
                  empresa: (
                    <VuiBox display="flex" alignItems="center" key={project.name}>
                      <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                        {project?.pipeline?.leadsRelated?.businessName ?? ''}
                      </VuiTypography>
                    </VuiBox>
                  ),
                  influencers: (
                    <VuiBox display="flex" py={1}>
                      {avatars(project.influencers.map((influencer) => [influencer.avatar, influencer.username]))}
                    </VuiBox>
                  ),
                  projetos: (
                    <VuiTypography variant="button" color="white" fontWeight="bold">
                      {project?.title ?? ''}
                    </VuiTypography>
                  ),
                  budget: (
                    <VuiTypography variant="button" color="white" fontWeight="bold">
                      R${project?.budget ? Number(((project?.budget?.budget ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
                    </VuiTypography>
                  ),
                  progresso: (
                    <VuiBox width="8rem" textAlign="center">
                      <VuiTypography color="white" variant="button" fontWeight="bold">
                        {project?.progress ?? 0}%
                      </VuiTypography>
                      <VuiProgress value={project?.progress ?? 0} color="viuzz" label={false} sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
                    </VuiBox>
                  ),
                }
              },)
            }
          />
        }
      </VuiBox>
    </Card>
  );
}

export default LastProjects;
