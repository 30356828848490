import styled from "styled-components";
import { Grid, TextField, Button } from "@mui/material";

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  /* border: 0.4px solid rgba(255, 255, 255, 0.50) !important; */
  border-radius: 5px;
  width: 100%;
  min-height: 40px;
`

export const AddContactButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 6px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
  padding: 6px 6px !important;

  &:hover{
    transform: scale(1.1) !important;
  }
`

export const IndicatorGrid = styled(Grid)`
  justify-content: flex-end;
  display: flex;
  margin-top: -35px;
`

export const BranchIndicator = styled.div`
  color: white;
  width: 35px;
  height: 35px;
  font-size: 18px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 50%;
  text-align: center;
  align-content: center;
  cursor: pointer;
  z-index: 1;

  svg {
    margin-top: 6px;
  }
`

export const InfluencerPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    width: 80px;
    height: 110px;
    margin-top: 5px;

    img {
      width: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
    }
  }
`

export const AlertGrid = styled(Grid)`
  p {
    color: #8F8F8F;
    font-weight: 400;
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
  }
`