import styled from "styled-components"
import { Grid, Button, TextField } from "@mui/material"
import img from "../../assets/bg.png"

export const BgGrid = styled(Grid)`
  background-image: url(${img}) !important;
  background-repeat: no-repeat;
  /* background-color: rgba(0, 0, 0); */
  height: 100vh;
  /* width: 150vh !important; */
  color: white;
  padding: 0 37%;
  background-size: cover;

  @media(max-width: 992px){
    padding: 0 10%;
  }
`

export const BoxGrid = styled(Grid)`
  .gradientBar {
    border-width: 1px !important;
    margin-bottom: 10px !important;
    width: 100%;
    padding: 0;
    height: 0;
    border-color: #E22B00;
  }

  p {
    margin: 0;
    text-align: center;
  }
`

export const LoginButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 5px !important;
  height: 50px !important;
  color: #FFF !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 14px !important;
  width: 100%;
  &.Mui-disabled {
    background: rgba(49, 49, 49, 0.65);
    color: #FFFFFF;
    opacity: 0.85;
  }
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  & input:disabled {
    -webkit-text-fill-color: #fff;
  }
`

export const MainContainer = styled(Grid)`
  background: #000 !important;
`