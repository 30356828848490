  

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Vision UI Dashboard React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { grey, dark, text } = colors;

export default {
  styleOverrides: {
    label: {
      /* marginTop: `${pxToRem(8)} !important`, */
      fontWeight: fontWeightRegular,
      fontSize: size.md,
      color: "#EAEAFFDE",

      "&.Mui-active": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `#EAEAFFDE`,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `#EAEAFFDE`,
      },
    },
  },
};
