import React from 'react';

import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import { IoHappy } from 'react-icons/io5';
import colors from 'assets/theme/base/colors';
import happy from 'assets/happy.png'
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

const SatisfactionRate = (rating) => {
	const { info, gradients } = colors;
	const { cardContent } = gradients;
	const mobile = useMediaQuery("(max-width: 992px)");

	return (
		<Card sx={{ height: '100%', backdropFilter: "blur(120px)" }}>
			<VuiBox display='flex' flexDirection='column'>
				<VuiTypography variant='lg' color='white' fontWeight='bold' mb='4px'>
					Taxa de Satisfação
				</VuiTypography>
				<VuiTypography variant='button' color='text' fontWeight='regular' mb='20px'>
					Todos os influencers
				</VuiTypography>
				<Grid container justifyContent='center' sx={{ marginTop: "40px" }}>
					<VuiBox sx={{ alignSelf: 'center', justifySelf: 'center', zIndex: '-1' }}>
						<VuiBox sx={{ position: 'relative', display: 'inline-flex' }}>
							<CircularProgress variant='determinate' value={rating?.rating ?? 0} size={170} color='viuzz' sx={{ strokeLinecap: "round" }} />
							<VuiBox
								sx={{
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									position: 'absolute',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
								<VuiBox
									sx={{
										background: linearGradient("#FB8404 0%", "#FE1E14 155.15%", "180"),
										transform: 'translateY(-50%)',
										width: '50px',
										height: '50px',
										borderRadius: '50%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
									{/* <IoHappy size='30px' color='#fff' /> */}
									<img src={happy} />
								</VuiBox>
							</VuiBox>
						</VuiBox>
					</VuiBox>
					<VuiBox
						sx={({ breakpoints }) => ({
							width: '90%',
							padding: '18px 22px',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'row',
							height: mobile ? '60px' : '82px',
							top: mobile ? '300px' : '',
							mx: 'auto',
							borderRadius: '20px',
							background: '#0E0E0E',
							transform: mobile ? 'translateY(-90%)' : 'translateY(-35%)',
							zIndex: '1000',
							position: 'absolute',
							bottom: '0',
						})}>
						<VuiTypography color='text' variant='caption' display='inline-block' fontWeight='regular'>
							0%
						</VuiTypography>
						<VuiBox
							flexDirection='column'
							display='flex'
							justifyContent='center'
							alignItems='center'
							sx={{ minWidth: '80px' }}>
							<VuiTypography color='white' variant='h3'>
								{rating?.rating ?? 0}%
							</VuiTypography>
							<VuiTypography color='text' variant='caption' fontWeight='regular'>
								Baseado em likes
							</VuiTypography>
						</VuiBox>
						<VuiTypography color='text' variant='caption' display='inline-block' fontWeight='regular'>
							100%
						</VuiTypography>
					</VuiBox>
				</Grid>
			</VuiBox>
		</Card>
	);
};

export default SatisfactionRate;
