import styled from 'styled-components'
import { Button, Grid } from '@mui/material'
import Tab from "@mui/material/Tab";
import Fab from '@mui/material/Fab';

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const FiltersGrid = styled(Grid)`
  padding: 0 22px;
`

export const ListFilterTab = styled(Tab)`
  font-size: 14px !important;
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 50px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`

export const PaginationGrid = styled(Grid)`
  .MuiPaginationItem-root {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    cursor: pointer;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.4;
    text-align: center;
    text-transform: unset;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-size: 150%!important;
    background-position-x: 25%!important;
    transition: all 150ms ease-in;
    background: transparent;
    color: #a0aec0;
    border-color: #a0aec0;
    border-radius: 10rem;
    padding: 0.6875rem 0.6875rem 0.625rem;
    margin: 0 0.125rem;
    pointer-events: auto;
    font-weight: 400;
    font-size: 0.875rem;
    width: 2.875rem;
    min-width: 2.875rem;
    height: 2.875rem;
    min-height: 2.875rem;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .MuiPaginationItem-root.Mui-selected {
    background: linear-gradient(180deg, #FB8404, #FE1E14) !important;
    border: none;
    color: white;
  }
`

export const BadgeGrid = styled(Grid)`
  font-size: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  background: linear-gradient(180deg, #9e5302 0%, #8e110c 155.15%),#FB7E04;
  text-align: center;
  min-width: 100px;
  height: 41px;
`

export const RejectedBadgeGrid = styled(BadgeGrid)`
  background: linear-gradient(180deg, #E55542 0%, #D7007B 154.17%);
  cursor: pointer;
`