import React from 'react'
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { changePassword } from 'utils/requests/auth';
import InputAdornment from '@mui/material/InputAdornment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import showToast from 'components/Toast/Toast';
import { Grid } from '@mui/material';
import * as S from '../style'

const ChangePassword = ({ closeModal }) => {
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().min(6, 'Senha atual deve conter pelo menos 6 caracteres').required('Senha atual é obrigatória'),
      newPassword: Yup.string().min(6, 'Senha nova deve conter pelo menos 6 caracteres').required('Senha nova é obrigatória'),
      confirmNewPassword: Yup.string().required('Confirmar nova senha é obrigatório').oneOf([Yup.ref('newPassword')], 'Senha não confere')
    }),
    onSubmit: async (values) => {
      await changePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword }).then((res) => {
        if (!res.message) {
          showToast("Senha alterada com sucesso!", "success");
          closeModal();
        } else {
          showToast("Falha na alteração de senha", "error");
        };
      });
    }
  });

  return (
    <Grid container flexDirection='column'>
      <p>Alterar senha</p>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='15px' mt='15px' mb='20px'>
            <S.InputGrid item xs={12} md={12} xl={12}>
              <S.Input
                hiddenLabel
                name='oldPassword'
                id='oldPassword'
                type={showOldPassword ? 'text' : 'password'}
                placeholder="Digite sua Senha atual"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.oldPassword}
                error={Boolean(formik.errors.oldPassword)}
                helperText={formik.errors.oldPassword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOpenIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {
                        showOldPassword
                          ?
                          <VisibilityIcon onClick={() => setShowOldPassword(false)} sx={{ cursor: 'pointer' }} />
                          :
                          <VisibilityOffIcon onClick={() => setShowOldPassword(true)} sx={{ cursor: 'pointer' }} />
                      }
                    </InputAdornment>
                  )
                }}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={12}>
              <S.Input
                hiddenLabel
                name='newPassword'
                id='newPassword'
                type={showNewPassword ? 'text' : 'password'}
                placeholder="Digite sua Nova senha"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                error={Boolean(formik.errors.newPassword)}
                helperText={formik.errors.newPassword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOpenIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {
                        showNewPassword
                          ?
                          <VisibilityIcon onClick={() => setShowNewPassword(false)} sx={{ cursor: 'pointer' }} />
                          :
                          <VisibilityOffIcon onClick={() => setShowNewPassword(true)} sx={{ cursor: 'pointer' }} />
                      }
                    </InputAdornment>
                  )
                }}
              />
            </S.InputGrid>
            <S.InputGrid item xs={12} md={12} xl={12}>
              <S.Input
                hiddenLabel
                name='confirmNewPassword'
                id='confirmNewPassword'
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirme sua Nova senha"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.confirmNewPassword}
                error={Boolean(formik.errors.confirmNewPassword)}
                helperText={formik.errors.confirmNewPassword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockOpenIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {
                        showConfirmPassword
                          ?
                          <VisibilityIcon onClick={() => setShowConfirmPassword(false)} sx={{ cursor: 'pointer' }} />
                          :
                          <VisibilityOffIcon onClick={() => setShowConfirmPassword(true)} sx={{ cursor: 'pointer' }} />
                      }
                    </InputAdornment>
                  )
                }}
              />
            </S.InputGrid>
          </Grid>
          <ButtonCustom label='Alterar' type='submit' />
        </Form>
      </Formik>
    </Grid>
  )
}

export default ChangePassword