

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import * as S from './style'
import { useNavigate } from "react-router-dom";
import { TokenContext } from "context/TokenContext";
import { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { Grid } from "@mui/material";
import { IoEllipsisVertical } from "react-icons/io5";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Modal from "components/Modal/Modal";
import showToast from "components/Toast/Toast";
import moment from 'moment';
import 'moment/locale/pt-br';
import { editBriefingProject } from "utils/requests/project";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const MarketingProjectsTable = ({ projects, showFinished, routing, refetch, isMarketing }) => {
  moment.locale("pt-br");
  const [openDeleteMenu, setOpenDeleteMenu] = useState([]);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [newProjectType, setNewProjectType] = useState('');

  const avatars = (members) => {
    return (
      members.map(([image, name]) => (
        <Tooltip key={name} title={name} placeholder="bottom">
          <VuiAvatar
            src={image}
            alt="name"
            size="sm"
            sx={{
              border: ({ borders: { borderWidth }, palette: { dark } }) =>
                `${borderWidth[2]} solid ${dark.focus}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: -1.25,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            }}
          />
        </Tooltip>
      ))
    );
  };

  const { decodedToken } = useContext(TokenContext);

  const role = decodedToken?.type;

  const navigate = useNavigate();

  const navigateObject = {
    ADMINISTRATOR: `/marketing/projetos/`,
    INFLUENCER: `/projetos/`,
    H_MARKETING: `/marketing/projetos/`,
    MARKETING: `/marketing/projetos/`
  };

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openDeleteMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenDeleteMenu(updatedMenu);
  };

  const handleActivationModal = (project) => {
    setOpenActivationModal(true);
    setSelectedProject(project);
  };

  const cancelActivation = () => {
    setOpenActivationModal(false);
    setSelectedProject();
  };

  const handleActivation = async () => {
    if (newProjectType) {
      await editBriefingProject(selectedProject?.id, { status: 'ONGOING', type: newProjectType }).then((res) => {
        if (!res?.message) {
          showToast("Projeto iniciado com sucesso!", "success");
          setSelectedProject();
          setTimeout(
            () => navigate(`${navigateObject[role]}${selectedProject?.id}`, { state: { type: selectedProject?.type } }), 2000
          );
          return
        }
        showToast("Falha ao iniciar projeto", "error");
      })
      return
    };
    showToast("Nenhum tipo de projeto selecionado!", "error");
  };

  const handleStatusChange = (event) => {
    setNewProjectType(event.target.value);
  };

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox
        sx={{
          paddingBottom: '10px',
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
          paddingBottom: '40px'
        }}
        onClick={() => openDeleteMenu.includes(true) ? setOpenDeleteMenu(openDeleteMenu.map((bool) => false)) : null}
      >
        <Table
          columns={
            isMarketing
              ?
              [
                { name: "empresa", align: "left" },
                { name: "influencers", align: "left" },
                { name: "tipo", align: "left" },
                { name: "projeto", align: "center" },
                { name: "realização", align: "center" },
              ]
              :
              [
                { name: "empresa", align: "left" },
                { name: "influencers", align: "left" },
                { name: "tipo", align: "left" },
                { name: "projeto", align: "center" },
                { name: "budget", align: "center" },
                { name: "realização", align: "center" },
              ]
          }
          rows={
            projects && projects?.map((project, index) => {
              return {
                empresa: (
                  <VuiBox display="flex" alignItems="center" key={project?.id}>
                    <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                      {project?.leadsRelated?.businessName ?? ""}
                    </VuiTypography>
                  </VuiBox>
                ),
                influencers: (
                  <VuiBox display="flex" py={1}>
                    {avatars(project.influencers.map((influencer) => [influencer.avatar, influencer.name]))}
                  </VuiBox>
                ),
                tipo: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.type ?? ""}
                  </VuiTypography>
                ),
                projeto: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.title ?? ""}
                  </VuiTypography>
                ),
                budget: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    R${project?.budget?.value > 0 ? ((project?.budget?.value / 100).toFixed(2)).toLocaleString("pt-BR") : 0}
                  </VuiTypography>
                ),
                realização: (
                  <S.ProgressGrid container justifyContent='space-around'>
                    <Grid container item xs={8} xl={10} justifyContent='center'>
                      <VuiBox width="8rem" textAlign="center" sx={{ width: "80%" }} >
                        <VuiTypography color="white" variant="button" fontWeight="bold">
                          {project?.progress ? Math.round(Number(project?.progress)) : 0}%
                        </VuiTypography>
                        <VuiProgress value={project?.progress ?? 0} color="viuzz" label={false} sx={{ background: "rgba(96, 96, 96, 0.5)" }} />
                      </VuiBox>
                    </Grid>
                    <Grid container item xs={2} xl={1} onClick={() => toggleOpenMenu(index)}>
                      <IoEllipsisVertical size='20px' style={{ cursor: "pointer" }} />
                    </Grid>
                    {openDeleteMenu[index] &&
                      <S.OptionsContainer container justifyContent='flex-end'>
                        <S.OptionsGrid display='flex' flexDirection='column'>
                          <p onClick={() => navigate(`${navigateObject[role]}${project?.id}`, { state: { type: project?.type } })}>Ver Detalhes</p>
                          {project.status === 'CLOSED_CONTRACT' &&
                            <>
                              <S.Divider />
                              <p onClick={() => handleActivationModal(project)}>Iniciar projeto</p>
                            </>
                          }
                        </S.OptionsGrid>
                      </S.OptionsContainer>
                    }
                  </S.ProgressGrid>
                ),
              }
            },)
          }
        />
      </VuiBox>
      <Modal size='sm' open={openActivationModal} onClose={() => cancelActivation()}>
        <Grid container flexDirection='column' gap='20px'>
          {/* <p style={{ fontSize: "24px" }}>Qual o tipo de projeto que vamos iniciar?</p> */}
          <p style={{ fontSize: "18px" }}>Qual o tipo de projeto que vamos iniciar?</p>
          <Grid container flexDirection='column'>
            <RadioGroup
              value={newProjectType}
              onChange={handleStatusChange}
            >
              <Grid container flexDirection='column'>
                <FormControlLabel value="CAMPANHA" control={<Radio />} label="Campanha" />
                <FormControlLabel value="PALCO" control={<Radio />} label="Palco" />
                <FormControlLabel value="EVENTOS" control={<Radio />} label="Eventos" />
                <FormControlLabel value="AUDIOVISUAL" control={<Radio />} label="Audio Visual" />
                <FormControlLabel value="LIVRO" control={<Radio />} label="Livro" />
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid container gap='15px' justifyContent='flex-end'>
            <ButtonCustom
              label='Iniciar Projeto'
              onClick={() => handleActivation()}
              sx={{
                width: '150px',
                fontSize: '14px',
                height: '40px !important',
                fontWeight: '500'
              }}
            />
          </Grid>
        </Grid>
      </Modal>
    </Card >
  );
}

export default MarketingProjectsTable;
