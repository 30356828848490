

import { useState, useContext } from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import Table from "defaultComponents/Tables/Table";

import { data } from "layouts/dashboard/components/IndividualProjects/data";

import * as S from './style'
import arrowRightUp from 'assets/icons/arrow-right-up.svg'
import { Link } from "react-router-dom";

import { TokenContext } from "context/TokenContext";

const IndividualProjects = ({ projects, showFinished, routing, hideRedirect }) => {
  const { columns, rows } = data(projects ? projects : "");
  const [menu, setMenu] = useState(null);
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  const redirects = {
    ADMINISTRATOR: {
      projects: `/influencers/${routing}/projetos`
    },
    H_COMERCIAL: {
      projects: `/influencers/${routing}/projetos`
    },
    COMERCIAL: {
      projects: `/influencers/${routing}/projetos`
    },
    H_MARKETING: {
      projects: `/influencers/${routing}/projetos`
    },
    MARKETING: {
      projects: `/influencers/${routing}/projetos`
    },
    INFLUENCER: {
      projects: '/projetos'
    }
  };

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <VuiBox mb="auto">
          {routing &&
            <VuiBox>
              <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
                Projetos
              </VuiTypography>
              {!hideRedirect &&
                <S.EditButtonGrid>
                  <Link to={redirects[role]?.projects}>
                    <S.EditButton>
                      <img src={arrowRightUp} />
                    </S.EditButton>
                  </Link>
                </S.EditButtonGrid>
              }
            </VuiBox>
          }
          {showFinished &&
            <VuiBox display="flex" alignItems="center" lineHeight={0}>
              <BsCheckCircleFill color="green" size="15px" />
              <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
                &nbsp;<strong>{projects?.totalCompleted} concluídos</strong> este mês
              </VuiTypography>
            </VuiBox>
          }
        </VuiBox>
        {renderMenu}
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
      </VuiBox>
    </Card>
  );
}

export default IndividualProjects;
