import { useCallback, useState, useEffect, useContext } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import * as S from "./style";
import filter from "assets/icons/filter.svg";
import MonthDatepicker from "components/Datepicker/MonthDatepicker";
import { Grid, Card } from "@mui/material";
import ProjectsTable from "../components/ProjectsTable";
import Tabs from "@mui/material/Tabs";
import { MdFolderCopy } from "react-icons/md";
import { RiFileEditFill, RiTaskFill } from "react-icons/ri";
import breakpoints from "assets/theme/base/breakpoints";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import Pagination from "@mui/material/Pagination";
import FilterModal from "components/Modal/FilterModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { getSellers } from "utils/requests/other";
import { useDebounce } from "use-debounce";
import moment from "moment";
import "moment/locale/pt-br";
import { getProjects } from "utils/requests/project";
import { SearchBarContext } from "context/SearchBarContext";
import { useNavigate } from "react-router-dom";

const ProjectListCommercial = () => {
  moment.locale("pt-br");

  const navigate = useNavigate();

  const { searchQuery } = useContext(SearchBarContext);

  const [projects, setProjects] = useState();
  const [filterDate, setFilterDate] = useState();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [pagination, setPagination] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({ username: [], projectTitle: null });
  const [sellers, setSellers] = useState([]);

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const fetchProjects = useCallback(
    async (status) => {
      const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : "";
      const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : "";
      const data = await getProjects(
        page - 1,
        size,
        debouncedFilters,
        startDate,
        endDate,
        status ?? []
      );
      if (!data.message) {
        setProjects(data);
      }
    },
    [page, debouncedFilters, filterDate]
  );

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [tabValue]);

  useEffect(() => {
    setFilters((prevData) => ({
      ...prevData,
      projectTitle: debouncedSearch ? debouncedSearch.toUpperCase() : null,
    }));
    setPage(1);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!tabValue) {
      fetchProjects();
      return;
    }
    if (tabValue === "NEGOTIATION") {
      fetchProjects(["LEADS", "RELATIONSHIP", "FOLLOW_UP", "NEGOTIATION"]);
      return;
    }
    if (tabValue === "ONGOING") {
      fetchProjects(["CLOSED_CONTRACT", "ONGOING"]);
      return;
    }
    fetchProjects([tabValue]);
  }, [fetchProjects, tabValue]);

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      setSellers(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(projects[1] / 8);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  useEffect(() => {
    if (projects) calculatePagination();
  }, [projects]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues
      ?.map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const result = checkedIndexes?.map((index) => sellers[index].username);
    result.length !== filters?.username?.length
      ? setFilters((prevData) => ({ ...prevData, username: result }))
      : [];
    setPage(1);
  };

  useEffect(() => {
    tagFilter();
  }, [filterValues]);

  return (
    <DashboardLayout>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar />
      <Grid>
        <Grid container item justifyContent="center">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
          >
            <S.ListFilterTab
              label={role === "COMERCIAL" ? "Meus Projetos" : "Todos"}
              value=""
              icon={<MdFolderCopy color="white" size="20px" />}
            />
            <S.ListFilterTab
              label="Em Negociação"
              value="NEGOTIATION"
              icon={<RiFileEditFill color="#5ACC93" size="20px" />}
            />
            <S.ListFilterTab
              label="Em Produção"
              value="ONGOING"
              icon={<RiFileEditFill color="#33CCFF" size="20px" />}
            />
            <S.ListFilterTab
              label="Finalizados"
              value="ENDED"
              icon={<RiTaskFill color="#CA0073" size="20px" />}
            />
          </Tabs>
        </Grid>
        <Card>
          <S.FiltersGrid
            container
            item
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="space-between"
          >
            <Grid container item md={7.5} lg={7.5} xl={6.5}>
              <MonthDatepicker setDate={setFilterDate} allButton />
            </Grid>
            {(role === "ADMINISTRATOR" ||
              role === "H_COMERCIAL" ||
              role === "COMERCIAL" ||
              role === "H_MARKETING") && (
              <Grid container item md={4.5} lg={4.5} xl={5.5} justifyContent="flex-end" gap="12px">
                <S.RejectedBadgeGrid item onClick={() => navigate("/comercial/negocios/recusas")}>
                  <p>Acessar Recusas</p>
                </S.RejectedBadgeGrid>
                {role !== "H_MARKETING" && (
                  <S.EditButton>
                    <img src={filter} onClick={() => setOpenFilter(true)} />
                  </S.EditButton>
                )}
              </Grid>
            )}
          </S.FiltersGrid>
          <Grid>
            <ProjectsTable projects={projects ? projects[0] : []} refetch={fetchProjects} />
          </Grid>
          <S.PaginationGrid container justifyContent="center">
            <Pagination
              count={pagination?.length}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              onChange={handleChange}
            />
          </S.PaginationGrid>
        </Card>
      </Grid>
      {/*       {role !== 'INFLUENCER' &&
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenAddProjectModal(true)} >
            <RiAddFill size='30px' />
          </S.ModalFab>
        </Box>
      } */}
      <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por usuário</p>
        {sellers &&
          sellers?.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={filterValues[index]}
                onChange={() => handleOnChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
      </FilterModal>
    </DashboardLayout>
  );
};

export default ProjectListCommercial;
