import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Route, useLocation, Routes, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Dashboard from "layouts/dashboard";
import Influencer from "pages/Influencers/Influencer/Influencer";

import { TokenContext } from "context/TokenContext";

import AdminRoutes from "routes/AdminRoutes";
import InfluencerRoutes from "routes/InfluencerRoutes";
import InitialRoutes from "routes/InitialRoutes";
import CommercialRoutes from "routes/CommercialRoutes";
import MarketingRoutes from "routes/MarketingRoutes";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { decodedToken } = useContext(TokenContext);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  /*   const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
  
        if (route.route) {
          return <Route exact path={route.route} component={route.component} key={route.key} />;
        }
  
        return null;
      }); */

  const role = decodedToken?.type;

  const routeConfig = {
    ADMINISTRATOR: () => <AdminRoutes />,
    H_COMERCIAL: () => <CommercialRoutes />,
    COMERCIAL: () => <CommercialRoutes />,
    H_MARKETING: () => <MarketingRoutes />,
    MARKETING: () => <MarketingRoutes />,
    STANDARD: () => <Dashboard />,
    TESTER: () => <Dashboard />,
    INFLUENCER: () => <InfluencerRoutes />,
    DEFAULT: () => <InitialRoutes />
  }

  const renderRoutes = role ? routeConfig[role]() : routeConfig["DEFAULT"]();

  return (
    <ThemeProvider theme={theme}>
      {renderRoutes}
      <CssBaseline />
    </ThemeProvider>
  );
}
