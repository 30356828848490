let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

export const getFinancialData = async (page, size, filters, startDate, endDate) => {
  const queryString = filters.type ? `&${filters.type.map(type => `type[]=${type}`).join('&')}` : null;
  const categoriesQuery = filters?.categories?.length > 0 ? `&${filters.categories.map(type => `categories[]=${type}`).join('&')}` : '';
  const subCategoriesQuery = filters?.subCategories?.length > 0 ? `&${filters.subCategories.map(type => `subCategories[]=${type}`).join('&')}` : '';
  const descriptionQuery = filters?.description ? `&description=${filters?.description}` : ''
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + 
    encodeURI(`/dashboard/financial?page=${page ?? 0}&size=${size}${queryString}${categoriesQuery}${subCategoriesQuery}${(startDate && endDate) 
      ? `&startDate=${startDate}&endDate=${endDate}` : ''}${descriptionQuery}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createFinancialInvoice = async (
  entry
) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/invoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(entry),
  });
  const data = await response.json();
  return data;
};

export const createFinancialTransfer = async (transferInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/transfer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(transferInfo),
  });
  const data = await response.json();
  return data;
};

export const editFinancialInvoice = async (id, editEntry) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(editEntry),
  });
  const data = await response.json();
  return data;
};

export const editFinancialTransfer = async (id, editEntry) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(editEntry),
  });
  const data = await response.json();
  return data;
};

export const deleteFinancialData = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getFinancialHomeData = async (startDate, endDate) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + 
    `/dashboard/financial/home${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getFinancialGraph = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/home/graph`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getCategories = async (type) => {
  const queryString = type.map(type => `type[]=${type}`).join('&');
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/dashboard/financial/categories?${type ? queryString : ''}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createCategories = async (name, type, invoice_type) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/financial/categories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      name,
      type,
      invoice_type
    }),
  });
  const data = await response.json();
  return data;
};

export const getSubCategories = async (type) => {
  const queryString = type.map(type => `type[]=${type}`).join('&');
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/dashboard/financial/subcategories?${queryString}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getInfluencerFinancial = async (username, page, size, type, startDate, endDate) => {
  const token = localStorage.getItem("token");
  `&startDate=${startDate}&endDate=${endDate}`
  const queryString = type ? type.map(type => `&balanceType[]=${type}`).join('&') : null;
  const startDateQuery = startDate ? `&startDate=${startDate}` : ''
  const endDateQuery = endDate ? `&endDate=${endDate}` : ''
  const response = await fetch(
    REACT_APP_API_URL 
    + 
    encodeURI(`/dashboard/users/financialData/${username}?page=${page ?? 0}&size=${size}${type ? queryString : ''}${startDateQuery}${endDateQuery}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const getFinancialDownloadData = async (filters) => {
  const queryString = filters.type ? filters.type.map(type => `type[]=${type}`).join('&') : null;
  const startDate = filters.startDate ? `&startDate=${filters.startDate}`  : '';
  const endDate = filters.endDate ? `&endDate=${filters.endDate}`  : '';
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + 
    encodeURI(`/dashboard/financial/downloadInfos?${filters && filters.type ? `&${queryString}` : ''}${startDate}${endDate}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};