let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

/* export const getProjects = async (page, size, username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(
    REACT_APP_API_URL
    +
    `/dashboard/project?${(page >= 0) && size ? `page=${page}&size=${size}` : ''}${username ? `&username=${username}` : ''}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
    });
  const data = await response.json();
  return data;
}; */

export const getSingleProject = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createInternalProject = async (project) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(project),
  });
  const data = await response.json();
  return data;
};

export const editProjectGeneral = async (id, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/general/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
}

export const editProjectBudget = async (id, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/budget/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
}

export const getProjectCommercialHomeData = async (username) => {
  const token = localStorage.getItem("token");
  let queryString = '';
  if (username) {
    queryString = '?username=' + username;
  }
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/home/comercial` + queryString, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectMarketingHomeData = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/home/marketing`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectCreativeRejections = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/${id}/rejections`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const approveProjectCreative = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/creative/${id}/approve`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const rejectProjectCreative = async (id, reason) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/creative/${id}/reject`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reason),
  });
  const data = await response.json();
  return data;
}

export const editProjectCreative = async (id, creative) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/creative/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(creative),
  });
  const data = await response.json();
  return data;
}

export const deleteProject = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createBriefingProject = async (project) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/briefing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(project),
  });
  const data = await response.json();
  return data;
};

export const getCommercialKanbanBoard = async (usernames) => {
  const token = localStorage.getItem("token");
  const usernameQuery = usernames?.length > 0 ? `&${usernames?.map(username => `username[]=${username}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project?returnLanes=COMERCIAL${usernameQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getProjects = async (page, size, filters, startDate, endDate, status, userOnly) => {
  const token = localStorage.getItem("token");
  const pageQuery = page >= 0 ? `&page=${page}` : '';
  const sizeQuery = size >= 0 ? `&size=${size}` : '';
  const dateQuery = startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '';
  const usernameQuery = filters?.username?.length > 0 ? `&${filters?.username?.map(
    username => `username[]=${username}`
  ).join('&')}` : '';
  const typeQuery = status?.length > 0 ? `&${status?.map(
    status => `projectStatus[]=${status}`
  ).join('&')}` : '';
  const userOnlyQuery = userOnly ? `&userOnly=true` : '';
  const titleQuery = filters?.projectTitle ? `&projectTitle=${filters?.projectTitle}` : '';
  const response = await fetch(
    REACT_APP_API_URL +
    encodeURI(`/dashboard/project?${typeQuery}${pageQuery}${sizeQuery}${usernameQuery}${dateQuery}${titleQuery}${userOnlyQuery}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editBriefingProject = async (id, briefing) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/briefing/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(briefing),
  });
  const data = await response.json();
  return data;
}

export const getInfluencerProjects = async (page, size) => {
  const token = localStorage.getItem("token");
  const pageQuery = page >= 0 ? `&page=${page}` : '';
  const sizeQuery = size >= 0 ? `&size=${size}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/influencer?${pageQuery}${sizeQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectsFromLeads = async (selectedLeads, projectStatus) => {
  const token = localStorage.getItem("token");
  const leadsQuery = selectedLeads?.length > 0 ? `?${selectedLeads.map(lead => `leadsRelated[]=${lead}`).join('&')}` : '';
  const statusQuery = projectStatus?.length > 0 ? `&${projectStatus.map(lead => `projectStatus[]=${lead}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/leads${leadsQuery}${statusQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getPipelineBoard = async (team) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline?onlyCards=true${team ? `&team=${team}` : ''}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createPipelineCard = async (card) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(card),
  });
  const data = await response.json();
  return data;
};

export const editPipelineCard = async (id, cardInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(cardInfo),
  });
  const data = await response.json();
  return data;
}

export const getMarketingPageData = async (username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/marketing`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getMarketingProjectTags = async (team) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/dashboard/pipeline/card/tags${team ? `?team=${team}` : ''}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const deleteMarketingCard = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const deleteMarketingTag = async (tags) => {
  const token = localStorage.getItem("token");
  const tagsQuery = tags?.length > 0 ? `?${tags.map(tag => `tags[]=${tag?.id}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/tags${tagsQuery}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getMarketingCardById = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/byId/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editMarketingCard = async (id, card) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(card),
  });
  const data = await response.json();
  return data;
}

export const createMarketingCardComment = async (commentData) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(commentData),
  });
  const data = await response.json();
  return data;
};

export const deleteMarketingCardComment = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/comment/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createMarketingCardAttachment = async (attachmentData) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/attachment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(attachmentData),
  });
  const data = await response.json();
  return data;
};

export const deleteMarketingCardAttachment = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/attachment/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const rejectProject = async (id, reason) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/rejectProject/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reason),
  });
  const data = await response.json();
  return data;
};

export const getRejectedProjects = async (page, size, filters, startDate, endDate, status) => {
  const token = localStorage.getItem("token");
  const pageQuery = page >= 0 ? `&page=${page}` : '';
  const sizeQuery = size >= 0 ? `&size=${size}` : '';
  const dateQuery = startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '';
  const usernameQuery = filters?.username?.length > 0 ? `&${filters?.username?.map(
    username => `username[]=${username}`
  ).join('&')}` : '';
  const titleQuery = filters?.projectTitle ? `&projectTitle=${filters?.projectTitle}` : '';
  const response = await fetch(
    REACT_APP_API_URL +
    encodeURI(`/dashboard/project/rejectedProjects?${pageQuery}${sizeQuery}${usernameQuery}${dateQuery}${titleQuery}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editProjectPipeline = async (id, pipeline) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(pipeline),
  });
  const data = await response.json();
  return data;
}

export const editProjectPhoto = async (edit) => {
  const formData = new FormData();
  Object.entries(edit).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/files`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};