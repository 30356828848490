import React from 'react'
import * as S from "./style"
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { IoCube } from "react-icons/io5";
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import breakpoints from "assets/theme/base/breakpoints";
import { RiHandHeartFill, RiEarthFill, RiImage2Fill, RiMovie2Fill, Ri24HoursFill } from "react-icons/ri";
import MixedChart from "defaultComponents/Charts/MixedCharts/MixedChart";
import BarChart from "defaultComponents/Charts/BarCharts/BarChart";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import { IoArrowUp } from "react-icons/io5";
import filter from 'assets/icons/filter.svg'
import Community from './components/Community';
import Demographic from './components/Demographic';
import Posts from './components/Posts';
import Reels from './components/Reels';
import Stories from './components/Stories';
import { useParams } from 'react-router-dom';

const Instagram = () => {

  //Passar para request no futuro
  const params = useParams();

  const [tabsOrientation, setTabsOrientation] = React.useState("horizontal");
  const [tabValue, setTabValue] = React.useState("community");

  React.useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing='18px'>
        <Grid item xs={12} lg={12} xl={12}>
          <S.EditButton>
            <img src={filter} />
          </S.EditButton>
          <Grid container item /* sx={{ ml: "auto" }} */ justifyContent='center'>
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
            >
              <Tab label="COMUNIDADE" value="community" icon={<RiHandHeartFill color="white" size="16px" />} />
              <Tab label="DEMOGRÁFICO" value="demographic" icon={<RiEarthFill color="white" size="16px" />} />
              <Tab label="POSTS" value="posts" icon={<RiImage2Fill color="white" size="16px" />} />
              <Tab label="REELS" value="reels" icon={<RiMovie2Fill color="white" size="16px" />} />
              <Tab label="STORIES" value="stories" icon={<Ri24HoursFill color="white" size="16px" />} />
            </Tabs>
          </Grid>
        </Grid>
        {tabValue === "community" &&
          <Community />
        }
        {tabValue === "demographic" &&
          <Demographic />
        }
        {tabValue === "posts" &&
          <Posts />
        }
        {tabValue === "reels" &&
          <Reels />
        }
        {tabValue === "stories" &&
          <Stories />
        }
      </Grid>
    </DashboardLayout>
  )
}

export default Instagram