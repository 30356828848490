import React from 'react'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import InboundForm from './Forms/Create/InboundForm';
import OutboundForm from './Forms/Create/OutboundForm';
import TransferForm from './Forms/Create/TransferForm';

const AddEntry = ({ refetch, closeModal }) => {

  moment.locale("pt-br")

  const [formCategory, setFormCategory] = React.useState('SAIDA');

  const handleChange = (event) => {
    setFormCategory(event.target.value);
  };

  return (
    <Grid container>
      <FormControl sx={{ m: 1, minWidth: 180, marginLeft: 0 }}>
        <Select
          value={formCategory}
          onChange={handleChange}
          color='viuzz'
          sx={{
            color: "#fff"
          }}
        >
          <MenuItem value={'SAIDA'}>Nova despesa</MenuItem>
          <MenuItem value={'ENTRADA'}>Nova receita</MenuItem>
          <MenuItem value={'TRANSFERENCIA'}>Transferência</MenuItem>
        </Select>
      </FormControl>
      {formCategory === 'ENTRADA' &&
        <InboundForm refetch={refetch} closeModal={closeModal} />
      }
      {formCategory === 'SAIDA' &&
        <OutboundForm refetch={refetch} closeModal={closeModal} />
      }
      {formCategory === 'TRANSFERENCIA' &&
        <TransferForm refetch={refetch} closeModal={closeModal} />
      }
    </Grid>
  )
}

export default AddEntry