import styled from "styled-components";
import { Grid } from "@mui/material";

export const MainGrid = styled(Grid)`
  .subtitle {
    color: #8F8F8F;
    font-size: 18px;
  }

  .title {
    font-size: 22px;
  }

  .reminder {
    font-weight: 600;
  }
`

export const WarningGrid = styled(Grid)`
  margin-top: 0px;
`