import styled from 'styled-components';
import { Grid, Button, TextField } from '@mui/material';
import Card from "@mui/material/Card";
import trophy from 'assets/trophy.svg'
import Fab from '@mui/material/Fab';
import lowProjectGoal from 'assets/lowProjectGoal.png'

export const MainContainer = styled(Grid)`
  .positive {
    color: #65EFAD;
  }
  .negative {
    color: #D7007B;
  }
  .value {
    font-size: 26px;
    font-weight: 700;
  }
  .percentage {
    font-size: 16px;
    align-self: center;
  }
  .title {
    opacity: 0.75;
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 20px;
    font-weight: 600;
  }
  .instagram {
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
  }

  .tiktok{
    background: linear-gradient(180deg, #F8DF00 0%, #6A4000 100%);
  }

  .twitter {
    background: linear-gradient(180deg, #00ACE2 0%, #003E52 100%)
  }
`
export const TitleGrid = styled(Grid)`
  margin-top: 30px;
  font-size: 28px;
  font-weight: 500;
`

export const RankingCard = styled(Card)`
  padding: 32px 0 20px 22px !important;
  min-height: 300px;
  height: 100% !important;
`

export const TrophyGrid = styled(Grid)`
  background-image: url(${trophy});
  height: 75%;
  width: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  align-self: self-end;
`

export const RoundedButton = styled(Button)`
  width: 75px !important;
  min-width: 30px !important;
  height: 75px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
  cursor: default !important;
  transform: none !important;
`

export const ValuesGrid = styled(Grid)`
  gap: 10px;
`

export const CardGrid = styled(Grid)`
  font-size: 14px;
  overflow: auto;
  .apexcharts-toolbar {
    display: none;
  }
`

export const SocialGrid = styled(Grid)`
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  text-align: center;
  padding: 5px;
  min-width: 120px;
  cursor: pointer;
`

export const SocialNameGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 400;
`

export const BoardsGrid = styled(Grid)`
  justify-content: end;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 100px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`

export const LowGoalGrid = styled(TrophyGrid)`
  background-image: url(${lowProjectGoal});
`

export const Input = styled(TextField)`
  background-color: transparent;
  border-radius: 10px;
  max-width: 275px;
  min-height: 40px;
`

export const SelectInputGrid = styled(Grid)`
  padding-left: 5px;
  gap: 20px;
  width: 100%;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 5px !important;
    transform: none !important;
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding-top: 0 !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 5px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 45px;
    border-radius: 10px;
    border: 0.4px solid;
    border-color: rgba(255, 255, 255, 0.50) !important;

    &:hover {
      border: 0.4px solid rgba(255, 255, 255, 0.50) !important;
    }
  }
  .MuiAutocomplete-popper {
    background-color: #0E0E0E;
    border: 1px solid;
  }
  .MuiFormHelperText-root{
    margin-top: 5px;
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`