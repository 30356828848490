import React from 'react'
import { Grid } from '@mui/material'
import * as S from '../style'
import moment from 'moment';
import 'moment/locale/pt-br';

const EventDetails = ({ event }) => {
  moment.locale("pt-br");

  return (
    <>
      <p>Detalhes do evento</p>
      <S.EventDetailsGrid container flexDirection='column' gap='20px' marginTop='30px' paddingY='15px'>
        <Grid container justifyContent='space-between'>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection='column'>
              <p>Título:</p>
              <p className='eventText'>{event?.title}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection='column'>
              <p>Local:</p>
              <p className='eventText'>{event?.extendedProps?.place}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent='space-between'>
          <S.InputGrid item xs={12} md={5.5} xl={5.5} id='start'>
            <Grid container flexDirection='column'>
              <p>Data Inicial:</p>
              <p className='eventText'>{moment(event?.start).format('DD/MM/YYYY HH:mm')}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5} id='end'>
            <Grid container flexDirection='column'>
              <p>Data Final:</p>
              <p className='eventText'>{moment(event?.end).format('DD/MM/YYYY HH:mm')}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent='space-between'>
          <S.InputGrid item xs={12} md={12} xl={12} id='start'>
            <Grid container flexDirection='column'>
              <p>Empresas:</p>
              <p className='eventText'>{event?.extendedProps?.leadsRelated && event?.extendedProps?.leadsRelated?.map((lead) => (lead?.businessName)).join(", ")}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent='space-between'>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection='column'>
              <p>URL do Evento:</p>
              <p className='eventText'>{event?.extendedProps?.urlRelated}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection='column'>
              <p>Descrição:</p>
              <p className='eventText'>{event?.extendedProps?.description}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent='space-between'>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection='column'>
              <p>Usuários:</p>
              <p className='eventText'>{event?.extendedProps?.usersRelated && event?.extendedProps?.usersRelated.map((category) => (category?.name)).join(", ")}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection='column'>
              <p>Projeto:</p>
              <p className='eventText'>{event?.extendedProps?.project?.title}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
      </S.EventDetailsGrid>
    </>
  )
}

export default EventDetails