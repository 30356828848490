import { useState, useEffect } from 'react';
import flatpickr from "flatpickr";
import moment from 'moment';
import 'moment/locale/pt-br';
import "assets/theme/custom-flatpickr.css";
import { Portuguese } from "flatpickr/dist/l10n/pt"
import calendar from 'assets/icons/calendar.svg';
import * as S from './style';
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

const MonthDatepicker = ({ setDate, allButton, lifetime }) => {
  moment.locale("pt-br")

  const day = moment().date();
  const month = moment().month() + 1;
  const year = new moment().year();
  const initialIsoDate = moment(`${day}-${month > 1 ? month - 1 : 12}-${month > 1 ? year : year - 1}`, "DD-M-YYYY").toISOString();
  const finalIsoDate = moment(`${day}-${month}-${year}`, "DD-M-YYYY").toISOString();

  const validFirstDate = () => {
    if (initialIsoDate === null) {
      return moment(`15-${month > 1 ? month - 1 : 12}-${month > 1 ? year : year - 1}`, "DD-M-YYYY").toISOString();
    };
    return initialIsoDate
  };

  const defaultDates = [`${moment(validFirstDate()).startOf("month").format("DD-MM-YYYY")} 00:00:00`, `${moment(finalIsoDate).endOf("month").format("DD-MM-YYYY")} 23:59:59`];

  const [dates, setDates] = useState(defaultDates);
  const [lifetimeDate, setLifetimeDate] = useState(false);

  const handleLifetime = (fp) => {
    setLifetimeDate(true);
    setDate();
    fp?.clear();
  };

  useEffect(() => {
    if (setDate) setDate(dates);
  }, [dates]);

  useEffect(() => {
    if(lifetime) {
      handleLifetime();
    };
  }, [lifetime]);

  flatpickr(".date", {
    onChange: (selectedDates, dateStr) => {
      if (selectedDates.length === 2 && selectedDates !== dates) {
        /* setDates(selectedDates); */
        setDates([`${moment(selectedDates[0]).startOf("month").format("DD-MM-YYYY")} 00:00:00`, `${moment(selectedDates[1]).endOf("month").format("DD-MM-YYYY")} 23:59:59`]);
        setLifetimeDate(false);
      }
    },
    mode: "range",
    "locale": Portuguese,
    plugins: !allButton ? [
      new monthSelectPlugin({
        shorthand: false,
        dateFormat: "F Y",
        altFormat: "F Y",
        theme: "dark"
      })
    ] : [
      new monthSelectPlugin({
        shorthand: false,
        dateFormat: "F Y",
        altFormat: "F Y",
        theme: "dark"
      }),
      ShortcutButtonsPlugin({
        button: {
          label: 'Todos',
        },
        onClick: (index, fp) => {
          handleLifetime(fp);
        }
      })
    ],
    dateFormat: "F Y",
  })

  const capitalize = (str) => {
    const newString = str.charAt(0).toUpperCase() + str.slice(1)
    return newString;
  };

  const firstDate = dates[0] ? moment(dates[0], "DD-MM-YYYY HH:mm:ss").format("MMMM, YYYY") : "";
  const lastDate = dates[1] ? moment(dates[1], "DD-MM-YYYY HH:mm:ss").format("MMMM, YYYY") : "";

  return (
    <S.DateContainer container display='flex' justifyContent='center' gap='10px' className='date'>
      <img src={calendar} />
      <p style={{ color: "#fff", opacity: "0.85" }}>
        {!lifetimeDate &&
          `${capitalize(firstDate)} - ${capitalize(lastDate)}`
        }
        {lifetimeDate && 'Todos'}
      </p>
    </S.DateContainer>
  )
}

export default MonthDatepicker