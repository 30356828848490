let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

export const getMetrics = async (startDate, endDate) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/metrics?startDate=${startDate}&endDate=${endDate}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getNewsFeed = async (username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/latest_news?username=${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getHomeData = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/home`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getAuditLog = async (page, size, filters) => {
  const token = localStorage.getItem("token");
  const nameQuery = filters?.name?.length > 0 ? `&${filters?.name?.map(type => `username[]=${type}`).join('&')}` : '';
  const textQuery = filters?.text ? `&text=${filters?.text}` : ''
  const response = await fetch(REACT_APP_API_URL + `/dashboard/audit-log?page=${page}&size=${size}${nameQuery}${textQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getPdfData = async (username, dateMs) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/pdf?username=${username}&dateMs=${dateMs}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createMetrics = async (metrics) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/metrics`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(metrics),
  });
  const data = await response.json();
  return data;
};

export const getSellers = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/sellers`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const getMarketingUsers = async (team) => {
  const token = localStorage.getItem("token");
  const teamQuery = team ? `?team=${team}` : ''
  const response = await fetch(REACT_APP_API_URL + `/dashboard/users/marketing${teamQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const getNotifications = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/notifications`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}