import React from 'react'
import * as S from './style'
import VuiTypography from 'components/VuiTypography'
import VuiBox from 'components/VuiBox'
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import placeholderImg from "assets/placeholder.png"
import { Grid } from '@mui/material';
import VuiAvatar from 'components/VuiAvatar';
import { abbreviateName } from 'utils/utils';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
      borderRadius: '8px'
    })}
  >
    {children}
  </VuiAvatar>
)

const SellersRanking = ({ sellersList, xs, lg, xl }) => {
  return (
    <S.RankingCard item xs={xs} lg={lg} xl={xl} sx={{ height: "auto" }}>
      <Card sx={{ height: "100%" }}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Ranking de Vendas
        </VuiTypography>
        {sellersList?.length === 0 &&
          <p>Sem informações</p>
        }
        {sellersList?.map((seller, index) => (
          <VuiBox key={index}>
            <VuiBox display="flex" alignItems="center" mb="10px" mt="20px">
              <S.RankingGrid container justifyContent='space-between'>
                <Grid display='flex' gap='20px'>
                  <p>{index + 1}.</p>
                  <S.RankingPhoto>
                    <div className='photoContainer'>
                      <Avatar img={seller?.member?.avatar ? seller?.member?.avatar : placeholderImg} />
                    </div>
                    {index === 0 && <p>👑</p>}
                  </S.RankingPhoto>
                  <S.NumberGrid flexDirection='column'>
                    <p>
                      {
                        seller?.member?.name?.split(' ').length > 2
                          ?
                          abbreviateName(seller?.member?.name)
                          :
                          seller?.member?.name ?? ''
                      }
                    </p>
                    <Grid container gap='5px'>
                      <p className="count">{seller?.total}</p>
                      <p className='count'>Projetos</p>
                    </Grid>
                  </S.NumberGrid>
                </Grid>
                <Grid display='flex' flexDirection='column' justifyContent='center'>
                  <S.BadgeGrid container className={!seller?.changes?.up ? 'negativeBadge' : 'positiveBadge'} gap='3px'>
                    <p className='percentage'>
                      {seller?.changes.value ? !seller?.changes?.up ? '-' : '+' : ''}{seller?.changes?.value}
                    </p>
                    <p>este mês</p>
                  </S.BadgeGrid>
                </Grid>
              </S.RankingGrid>
            </VuiBox>
          </VuiBox>
        ))
        }
      </Card>
    </S.RankingCard>
  )
}

SellersRanking.defaultProps = {
  influencerList: [],
  xs: 12,
  lg: 6,
  xl: 5
};

SellersRanking.propTypes = {
  influencerList: PropTypes.array,
  xs: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number
};

export default SellersRanking