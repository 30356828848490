import React, { useEffect } from 'react'
import InboundForm from './Forms/Update/InboundForm';
import OutboundForm from './Forms/Update/OutboundForm';
import TransferForm from './Forms/Update/TransferForm';

const EditEntry = ({ refetch, closeModal, entry }) => {

  const [formCategory, setFormCategory] = React.useState('SAIDA');

  useEffect(() => {
    if (entry) {
      setFormCategory(entry.type ? entry.type : '');
    }
  }, []);

  return (
    <>
      {formCategory === 'ENTRADA' &&
        <InboundForm refetch={refetch} closeModal={closeModal} entry={entry} />
      }
      {formCategory === 'SAIDA' &&
        <OutboundForm refetch={refetch} closeModal={closeModal} entry={entry} />
      }
      {formCategory === 'TRANSFERENCIA' &&
        <TransferForm refetch={refetch} closeModal={closeModal} entry={entry} />
      }
    </>
  )
}

export default EditEntry