import React from 'react'
import { useParams } from 'react-router-dom';
import CampaignProjectDetails from './CampaignProjectDetails';
import BriefingProjectDetails from './BriefingProjectDetails';
import { TokenContext } from "context/TokenContext";
import InfluencerBriefingProjectDetails from './UserViews/InfluencerBriefingProjectDetails';
import { getSingleProject } from 'utils/requests/project';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { getAllInfluencers } from 'utils/requests/auth';

const ProjectController = () => {
  const params = useParams();

  const [projectInfo, setProjectInfo] = React.useState();
  const [influencerList, setInfluencerList] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const { decodedToken } = React.useContext(TokenContext);

  const role = decodedToken?.type;

  const fetchProjectData = async () => {
    const data = await getSingleProject(params?.id);
    if (data?.id) {
      setProjectInfo(data);
      setLoading(false);
    };
  };

  const fetchInfluencerList = async () => {
    const data = await getAllInfluencers();
    if (!data?.message) {
      setInfluencerList(data);
    };
  };

  React.useEffect(() => {
    fetchProjectData();
    fetchInfluencerList();
  }, []);

  const projectType = projectInfo?.type;

  const routeConfig = {
    ADMINISTRATOR:
      <>
        {projectType === "BRIEFING" &&
          <BriefingProjectDetails project={projectInfo} influencerList={influencerList} />
        }
        {projectType === "CAMPANHA" &&
          <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
        }
        {projectType === "EVENTOS" &&
          <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
        }
        {projectType === "PALCO" &&
          <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
        }
        {projectType === "AUDIOVISUAL" &&
          <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
        }
        {projectType === "LIVRO" &&
          <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
        }
      </>,
    H_COMERCIAL:
    <>
    {projectType === "BRIEFING" &&
      <BriefingProjectDetails project={projectInfo} influencerList={influencerList} />
    }
    {projectType === "CAMPANHA" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "EVENTOS" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "PALCO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "AUDIOVISUAL" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "LIVRO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
  </>,
    COMERCIAL:
    <>
    {projectType === "BRIEFING" &&
      <BriefingProjectDetails project={projectInfo} influencerList={influencerList} />
    }
    {projectType === "CAMPANHA" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "EVENTOS" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "PALCO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "AUDIOVISUAL" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "LIVRO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
  </>,
    H_MARKETING:
    <>
    {projectType === "BRIEFING" &&
      <BriefingProjectDetails project={projectInfo} influencerList={influencerList} />
    }
    {projectType === "CAMPANHA" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "EVENTOS" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "PALCO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "AUDIOVISUAL" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "LIVRO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
  </>,
    MARKETING:
    <>
    {projectType === "BRIEFING" &&
      <BriefingProjectDetails project={projectInfo} influencerList={influencerList} />
    }
    {projectType === "CAMPANHA" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "EVENTOS" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "PALCO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "AUDIOVISUAL" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
    {projectType === "LIVRO" &&
      <CampaignProjectDetails project={projectInfo} influencerList={influencerList} refetchProject={fetchProjectData} />
    }
  </>,
    STANDARD: <></>,
    TESTER: <></>,
    INFLUENCER:
      <>
        {projectType === "BRIEFING" &&
          <InfluencerBriefingProjectDetails project={projectInfo} />
        }
      </>,
  }

  return (
    <>
      {loading &&
        <LoadingScreen />
      }
      {!loading &&
        routeConfig[role]
      }
    </>
  )
}

export default ProjectController