

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";
import { Grid } from "@mui/material";
import arrowRightUp from 'assets/icons/arrow-right-up.svg'
import { Link } from "react-router-dom";
import * as S from '../IndividualProjects/style'

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard Materail-UI example components
import Table from "defaultComponents/Tables/Table";

// Data
import { data } from "layouts/dashboard/components/Projects/data";

function Projects({ projects, routing }) {
  const { columns, rows } = data(projects ? projects : "");
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <VuiBox mb="auto">
          <Grid>
            <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
              Projetos
            </VuiTypography>
            <VuiBox display="flex" alignItems="center" lineHeight={0}>
              <BsCheckCircleFill color="green" size="15px" />
              <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
                &nbsp;<strong>0 concluídos</strong> este mês
              </VuiTypography>
            </VuiBox>
            {routing &&
              <VuiBox>
                <S.EditButtonGrid>
                  <Link to={routing}>
                    <S.EditButton>
                      <img src={arrowRightUp} />
                    </S.EditButton>
                  </Link>
                </S.EditButtonGrid>
              </VuiBox>
            }
          </Grid>
        </VuiBox>
        {/*         <VuiBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </VuiBox> */}
        {renderMenu}
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        {(projects?.length === 0 || !projects) && <p style={{ color: "#fff" }}>Sem informações</p>}
        {projects?.length > 0 && <Table columns={columns} rows={rows} />}
      </VuiBox>
    </Card>
  );
}

export default Projects;
