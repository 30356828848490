import React, { Component } from "react";
import Chart from "react-apexcharts";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {

      chartData: [],
      chartOptions: {},

      /*       series: [44, 55, 41],
            options: {
              chart: {
                type: 'donut',
              },
              labels: ['Female', 'Male', 'Outros'],
              colors:['#BE4E75', '#733278', '#47C094'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: false
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            }, */
    };
  }


  componentDidMount() {
    const { pieChartData, pieChartOptions } = this.props;

    this.setState({
      chartData: pieChartData,
      chartOptions: pieChartOptions,
    });
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="donut"
        width="100%"
        height="100%"
      />
    );
  }
}

export default PieChart;
