import React from 'react'
import * as S from '../style'
import { Grid } from '@mui/material';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { RiArrowRightLine, RiAddFill, RiDeleteBin6Fill } from "react-icons/ri";
import { IoCheckmarkCircle, IoCheckmarkOutline, IoArrowUp } from "react-icons/io5";
import instagram from 'assets/icons/socials/instagramwhite.svg'
import tiktok from 'assets/icons/socials/tiktokwhite.svg'
import twitter from 'assets/icons/socials/twitterwhite.svg'
import youtube from 'assets/icons/socials/youtubewhite.svg'
import spotify from 'assets/icons/socials/spotifywhite.svg'
import { MdOutlineFileDownload } from "react-icons/md";
import { capitalize } from 'utils/utils';

const MetricsForm = ({ project }) => {

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      projectName: '',
      description: '',
      influencers: [],
      start: '',
      end: '',
      currency: '',
    },
    validationSchema: Yup.object({
      projectName: Yup.string().min(4, 'Título deve conter pelo menos 4 caracteres').required('Nome do projeto é obrigatório'),
      description: Yup.string(),
      influencers: Yup.array(),
      start: Yup.string().min(10, 'Data Inicial deve estar no formato DD/MM/YYYY').required('Data Inicial é obrigatório'),
      end: Yup.string().min(10, 'Data Final deve estar no formato DD/MM/YYYY').required('Data Final é obrigatório'),
      currency: Yup.string(),
    }),
    onSubmit: async (values) => {
    }
  });

  const [instagramUrlArr, setInstagramUrlArr] = React.useState([]);
  const [instagramUrl, setInstagramUrl] = React.useState('');
  const [tiktokUrlArr, setTiktokUrlArr] = React.useState([]);
  const [tiktokUrl, setTiktokUrl] = React.useState('');
  const [twitterUrlArr, setTwitterUrlArr] = React.useState([]);
  const [twitterUrl, setTwitterUrl] = React.useState('');
  const [youtubeUrlArr, setYoutubeUrlArr] = React.useState([]);
  const [youtubeUrl, setYoutubeUrl] = React.useState('');

  const removeByIndex = (array, index) => array.filter((_, i) => i !== index);

  return (
    <S.ProjectCard>
      <Grid container justifyContent='space-between'>
        <S.FormTitleGrid container flexDirection='column' item md={9}>
          <p>Criativos🚧</p>
          <p className='subTitle'>Processo criativo do projeto</p>
        </S.FormTitleGrid>
        <Grid display='flex' flexDirection='column' alignItems='flex-end' item md={3}>
          <S.BadgeGrid item>
            <p>{capitalize(project?.type.toLowerCase())}</p>
          </S.BadgeGrid>
        </Grid>
      </Grid>
      <S.FormGrid container>
        <S.BorderGrid container>
          {instagramUrlArr.map((url, index) => (
            <Grid container key={index}>
              <Grid item lg={2}>
                {index === 0 && <p>Instagram</p>}
              </Grid>
              <Grid container item md={10.5} lg={10}>
                <S.InputGrid item md={10.5} lg={11}>
                  <S.Input
                    name='projectName'
                    id='projectName'
                    placeholder='Insira o link do post'
                    value={url}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </S.InputGrid>
                <Grid container item md={1.5} lg={1} justifyContent='start'>
                  <S.EditButton sx={{ marginLeft: "20px" }} onClick={() => { setInstagramUrlArr(removeByIndex(instagramUrlArr, index)); setInstagramUrl(''); }}>
                    <RiDeleteBin6Fill size='20px' color='#fff' />
                  </S.EditButton>
                </Grid>
              </Grid>
            </Grid>
          ))
          }
          <Grid container>
            <Grid item lg={2}>
              {instagramUrlArr.length === 0 && <p>Instagram</p>}
            </Grid>
            <Grid container item md={10.5} lg={10}>
              <S.InputGrid item md={10.5} lg={11}>
                <S.Input
                  name='projectName'
                  id='projectName'
                  placeholder='Insira o link do post'
                  value={instagramUrl}
                  onChange={(e) => setInstagramUrl(e.target.value)}
                />
              </S.InputGrid>
              <Grid container item md={1.5} lg={1} justifyContent='start'>
                <S.EditButton onClick={() => { setInstagramUrlArr((prevUrl) => [...prevUrl, instagramUrl]); setInstagramUrl(''); }} sx={{ marginLeft: "20px" }}>
                  <RiAddFill size='20px' color='#fff' />
                </S.EditButton>
              </Grid>
            </Grid>
          </Grid>
          {tiktokUrlArr.map((url, index) => (
            <Grid container key={index}>
              <Grid item lg={2}>
                {index === 0 && <p>TikTok</p>}
              </Grid>
              <Grid container item md={10.5} lg={10}>
                <S.InputGrid item md={10.5} lg={11}>
                  <S.Input
                    name='projectName'
                    id='projectName'
                    placeholder='Insira o link do post'
                    value={url}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </S.InputGrid>
                <Grid container item md={1.5} lg={1} justifyContent='start'>
                  <S.EditButton sx={{ marginLeft: "20px" }} onClick={() => { setTiktokUrlArr(removeByIndex(tiktokUrlArr, index)); setTiktokUrl(''); }}>
                    <RiDeleteBin6Fill size='20px' color='#fff' />
                  </S.EditButton>
                </Grid>
              </Grid>
            </Grid>
          ))
          }
          <Grid container>
            <Grid item lg={2}>
              {tiktokUrlArr.length === 0 && <p>TikTok</p>}
            </Grid>
            <Grid container item md={10.5} lg={10}>
              <S.InputGrid item md={10.5} lg={11}>
                <S.Input
                  name='projectName'
                  id='projectName'
                  placeholder='Insira o link do post'
                  value={tiktokUrl}
                  onChange={(e) => setTiktokUrl(e.target.value)}
                />
              </S.InputGrid>
              <Grid container item md={1.5} lg={1} justifyContent='start'>
                <S.EditButton onClick={() => { setTiktokUrlArr((prevUrl) => [...prevUrl, tiktokUrl]); setTiktokUrl(''); }} sx={{ marginLeft: "20px" }}>
                  <RiAddFill size='20px' color='#fff' />
                </S.EditButton>
              </Grid>
            </Grid>
          </Grid>
          {twitterUrlArr.map((url, index) => (
            <Grid container key={index}>
              <Grid item lg={2}>
                {index === 0 && <p>Twitter</p>}
              </Grid>
              <Grid container item md={10.5} lg={10}>
                <S.InputGrid item md={10.5} lg={11}>
                  <S.Input
                    name='projectName'
                    id='projectName'
                    placeholder='Insira o link do post'
                    value={url}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </S.InputGrid>
                <Grid container item md={1.5} lg={1} justifyContent='start'>
                  <S.EditButton sx={{ marginLeft: "20px" }} onClick={() => { setTwitterUrlArr(removeByIndex(twitterUrlArr, index)); setTwitterUrl(''); }}>
                    <RiDeleteBin6Fill size='20px' color='#fff' />
                  </S.EditButton>
                </Grid>
              </Grid>
            </Grid>
          ))
          }
          <Grid container>
            <Grid item lg={2}>
              {twitterUrlArr.length === 0 && <p>Twitter</p>}
            </Grid>
            <Grid container item md={10.5} lg={10}>
              <S.InputGrid item md={10.5} lg={11}>
                <S.Input
                  name='projectName'
                  id='projectName'
                  placeholder='Insira o link do post'
                  value={twitterUrl}
                  onChange={(e) => setTwitterUrl(e.target.value)}
                />
              </S.InputGrid>
              <Grid container item md={1.5} lg={1} justifyContent='start'>
                <S.EditButton onClick={() => { setTwitterUrlArr((prevUrl) => [...prevUrl, twitterUrl]); setTwitterUrl(''); }} sx={{ marginLeft: "20px" }}>
                  <RiAddFill size='20px' color='#fff' />
                </S.EditButton>
              </Grid>
            </Grid>
          </Grid>
          {youtubeUrlArr.map((url, index) => (
            <Grid container key={index}>
              <Grid item lg={2}>
                {index === 0 && <p>Youtube</p>}
              </Grid>
              <Grid container item md={10.5} lg={10}>
                <S.InputGrid item md={10.5} lg={11}>
                  <S.Input
                    name='projectName'
                    id='projectName'
                    placeholder='Insira o link do post'
                    value={url}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </S.InputGrid>
                <Grid container item md={1.5} lg={1} justifyContent='start'>
                  <S.EditButton sx={{ marginLeft: "20px" }} onClick={() => { setYoutubeUrlArr(removeByIndex(youtubeUrlArr, index)); setYoutubeUrl(''); }}>
                    <RiDeleteBin6Fill size='20px' color='#fff' />
                  </S.EditButton>
                </Grid>
              </Grid>
            </Grid>
          ))
          }
          <Grid container>
            <Grid item lg={2}>
              {youtubeUrlArr.length === 0 && <p>Youtube</p>}
            </Grid>
            <Grid container item md={10.5} lg={10}>
              <S.InputGrid item md={10.5} lg={11}>
                <S.Input
                  name='projectName'
                  id='projectName'
                  placeholder='Insira o link do post'
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                />
              </S.InputGrid>
              <Grid container item md={1.5} lg={1} justifyContent='start'>
                <S.EditButton onClick={() => { setYoutubeUrlArr((prevUrl) => [...prevUrl, youtubeUrl]); setYoutubeUrl(''); }} sx={{ marginLeft: "20px" }}>
                  <RiAddFill size='20px' color='#fff' />
                </S.EditButton>
              </Grid>
            </Grid>
          </Grid>
        </S.BorderGrid>
      </S.FormGrid>
      <S.DetailsGrid container>
        <S.BoardGrid container justifyContent='space-between'>
          <S.ProgressGrid item xl={3}>
            <Grid container justifyContent='space-between'>
              <p className='followers'>Impressões</p>
              <Grid item display='flex'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </Grid>
            <S.BarContainer container>
              <div className='bar'>
                <div className='instagram' style={{ width: "40%" }} />
                <div className='tiktok' style={{ width: "20%" }} />
                <div className='twitter' style={{ width: "20%" }} />
                <div className='youtube' style={{ width: `15%` }} />
                <div className='spotify' style={{ width: "5%" }} />
              </div>
            </S.BarContainer>
          </S.ProgressGrid>
          <S.SocialCardsGrid item container xl={7}>
            {/* <S.PageLink> */}
            <S.SocialGrid className='instagram' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={instagram} />
                <p>Instagram</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            {/* </S.PageLink> */}
            <S.SocialGrid className='tiktok' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={tiktok} />
                <p>TikTok</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='twitter' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={twitter} />
                <p>Twitter</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='youtube' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={youtube} />
                <p>Youtube</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='spotify' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={spotify} />
                <p>Spotify</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>5000</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
          </S.SocialCardsGrid>
        </S.BoardGrid>
      </S.DetailsGrid>
      <S.DetailsGrid container>
        <S.BoardGrid container justifyContent='space-between'>
          <S.ProgressGrid item xl={3}>
            <Grid container justifyContent='space-between'>
              <p className='followers'>Alcance</p>
              <Grid item display='flex'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </Grid>
            <S.BarContainer container>
              <div className='bar'>
                <div className='instagram' style={{ width: "40%" }} />
                <div className='tiktok' style={{ width: "20%" }} />
                <div className='twitter' style={{ width: "20%" }} />
                <div className='youtube' style={{ width: `15%` }} />
                <div className='spotify' style={{ width: "5%" }} />
              </div>
            </S.BarContainer>
          </S.ProgressGrid>
          <S.SocialCardsGrid item container xl={7}>
            {/* <S.PageLink> */}
            <S.SocialGrid className='instagram' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={instagram} />
                <p>Instagram</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            {/* </S.PageLink> */}
            <S.SocialGrid className='tiktok' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={tiktok} />
                <p>TikTok</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='twitter' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={twitter} />
                <p>Twitter</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='youtube' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={youtube} />
                <p>Youtube</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='spotify' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={spotify} />
                <p>Spotify</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>5000</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
          </S.SocialCardsGrid>
        </S.BoardGrid>
      </S.DetailsGrid>
      <S.DetailsGrid container>
        <S.BoardGrid container justifyContent='space-between'>
          <S.ProgressGrid item xl={3}>
            <Grid container justifyContent='space-between'>
              <p className='followers'>Interações</p>
              <Grid item display='flex'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </Grid>
            <S.BarContainer container>
              <div className='bar'>
                <div className='instagram' style={{ width: "40%" }} />
                <div className='tiktok' style={{ width: "20%" }} />
                <div className='twitter' style={{ width: "20%" }} />
                <div className='youtube' style={{ width: `15%` }} />
                <div className='spotify' style={{ width: "5%" }} />
              </div>
            </S.BarContainer>
          </S.ProgressGrid>
          <S.SocialCardsGrid item container xl={7}>
            {/* <S.PageLink> */}
            <S.SocialGrid className='instagram' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={instagram} />
                <p>Instagram</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            {/* </S.PageLink> */}
            <S.SocialGrid className='tiktok' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={tiktok} />
                <p>TikTok</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='twitter' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={twitter} />
                <p>Twitter</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='youtube' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={youtube} />
                <p>Youtube</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>43000K</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
            <S.SocialGrid className='spotify' item xl={2}>
              <S.SocialNameGrid container justifyContent='center' gap='2px'>
                <img width='16px' src={spotify} />
                <p>Spotify</p>
              </S.SocialNameGrid>
              <Grid container justifyContent='center'>
                <p>5000</p>
                <IoArrowUp color="#65EFAD" size="22px" />
              </Grid>
            </S.SocialGrid>
          </S.SocialCardsGrid>
        </S.BoardGrid>
      </S.DetailsGrid>
      <Grid container justifyContent='flex-end'>
        <ButtonCustom label='Download PDF' sx={{ width: "230px", fontSize: "18px", fontWeight: "500" }} type='submit'>
          <MdOutlineFileDownload color="#fff" size="25px" />
        </ButtonCustom>
      </Grid>
    </S.ProjectCard>
  )
}

export default MetricsForm