import styled from 'styled-components'
import { Grid } from '@mui/material'

export const ZeGrid = styled(Grid)`
  img {
    margin-top: 150px;
    
    @media (max-width: 992px) {
      width: 100%;
      margin-top: 10px;
    }
  }
`

export const MainGrid = styled(Grid)`
  background-color: black;
  border-radius: 15px;

  @media (max-width: 992px){
      padding-left: 0;
  }
`

export const ImageGrid = styled(Grid)`
  display: flex;

  img {
    height: 90%;

    @media (max-width: 992px){
      width: 100%;
      margin-left: -5px;
    }
  }

  @media (max-width: 1440px){
    justify-content: center;
  }

`