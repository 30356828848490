import styled from "styled-components";
import { Button, Grid, TextField, Fab } from "@mui/material";


export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const MainContainer = styled(Grid)`
 span {
  color: white;
 }

 article {
  border-bottom: none;
 }

 article > header {
  border-bottom: 1px solid #2f2f2f;
 }

 article > div {
  border-top: none;
  color: #9b9999;
 }

 article > div > span {
  min-width: 65px;
  text-align: center;
  padding: 2px 10px;
 }

 span > textarea {
  color: #9b9999; 
 }

 div > textarea {
  color: #9b9999; 
 }

 header > div > button {
  color: #9b9999;
 }

 section > div > a {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 5px;
  color: #fff;
  text-align: center;
  width: 50%;
  font-size: 14px;
  display: none;
 }

/*  div > div > div > section {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
 } */

 .smooth-dnd-container > section:not(:has(div)) {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
 }

 .smooth-dnd-container > section:has(div) {
  background: #101010;
 }

 div > div > div > section > button {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  font-size: 14px;
  font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
  font-weight: 400;
 }

 div > div > div > section > div > button:first-child {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
 }

 div > div > div > section > div > button {
  border-radius: 5px;
 }

 div > div > div > section > div:has(textarea):first-child {
  background: #000;
  border: 1px solid #fff;
  border-radius: 5px;
 }

 section > div > div:has(article) {
  background-color: #101010;
 }

 section > div > div > article {
  background-color: #101010;
 }

 section > div > div > button:not(:last-child) {
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
 }

 section > div > div > button {
  border-radius: 5px;
 }
`

export const CardWrapper = styled.article`
  border-radius: 3px;
  border-bottom: 1px solid #ccc;
  background-color: #000;
  position: relative;
  padding: 10px;
  cursor: pointer;
  max-width: 250px;
  margin-bottom: 7px;
  min-width: 230px;
`

export const MovableCardWrapper = styled(CardWrapper)`
  &:hover {
    background-color: #f0f0f0;
    color: #000;
  }
`

export const Header = styled.header`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Title = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  cursor: ${props => (props.draggable ? 'grab' : `auto`)};
  width: 70%;
  color: #fff;
`

export const CardHeader = styled(Header)`
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
  color: #000;
`

export const CardTitle = styled(Title)`
  font-size: 14px;
  align-self: center;
  text-wrap: wrap;
  padding-right: 5px;
`

export const RightContent = styled.span`
  width: 38%;
  text-align: right;
  padding-right: 10px;
  font-size: 13px;
`

export const CardRightContent = styled(RightContent)`
  font-size: 10px;
`

export const Detail = styled.div`
  font-size: 12px;
  color: #fff;
  white-space: pre-wrap;
`

export const Footer = styled.div`
  border-top: 1px solid #eee;
  padding-top: 6px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`

export const AddButton = styled.button`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 5px;
  color: #fff;
  text-align: center;
  width: 50%;
  font-size: 14px;
  display: block;
  padding: 5px 2px;
  margin-top: 10px;
  position: relative;
  border: 0;
  height: 32px;
`

export const TagGrid = styled.div`
  span {
    min-width: 65px;
    text-align: center;
    padding: 2px 10px;
    border-radius: 3px;
    margin: 2px 3px;
    font-size: 14px;
  }
`

export const CardFormInput = styled.input`
  background-color: #000; 
  /* border: 1px solid #fff; */
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  border: 0;
`

export const CardFormAddButton = styled.button`
  color: #fff;
  transition: background 0.3s ease;
  min-height: 32px;
  padding: 4px 16px;
  vertical-align: top;
  margin-top: 0;
  margin-right: 8px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 5px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
`

export const CardFormCancelButton = styled(CardFormAddButton)`
  background: #999999;
`

export const DueDate = styled.div`
  font-size: 12px;
  padding: 2px 5px;
  background-color: #424242;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 3px;
  color: #fff;

  svg {
    align-self: center;
  }
`

export const DueDateWarning = styled(DueDate)`
  background-color: #f5cd47;
  color: #1d2125;
`

export const DueDateDelayed = styled(DueDate)`
  background-color: #5d1f1a;
  color: #fd9891;
`

export const SelectInputGrid = styled(Grid)`
  padding-left: 5px;
  gap: 20px;
  width: 100%;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 5px !important;
    transform: none !important;
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding-top: 0 !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 5px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 45px;
    border-radius: 10px;
    border: 0.4px solid;
    border-color: rgba(255, 255, 255, 0.50) !important;

    &:hover {
      border: 0.4px solid rgba(255, 255, 255, 0.50) !important;
    }
  }
  .MuiAutocomplete-popper {
    background-color: #0E0E0E;
    border: 1px solid;
  }
  .MuiFormHelperText-root{
    margin-top: 5px;
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const Input = styled(TextField)`
  background-color: transparent;
  border-radius: 10px;
  max-width: 275px;
  min-height: 40px;
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 50px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`