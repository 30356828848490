import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import { Grid } from '@mui/material';
import * as S from "./style"
import img404 from 'assets/404.png'

const NotFound = () => {
  return (
    <DashboardLayout>
      <S.MainGrid container item sm={12} md={12} lg={12} xl={12}>
        <Grid container>
          <S.TextGrid container item xl={6} alignContent='center'>
            <p className='title'>Ops, página não encontrada...</p>
            <p>A URL que você está tentando acessar não foi encontrada nesse servidor... Isso é tudo que sabemos :(</p>
          </S.TextGrid>
          <S.ImageGrid item xl={6}>
            <img src={img404} />
          </S.ImageGrid>
        </Grid>
      </S.MainGrid>
    </DashboardLayout>
  )
}

export default NotFound