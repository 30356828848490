import React, { useEffect } from 'react'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { Grid } from '@mui/material';
import * as S from '../../style'
import Autocomplete from '@mui/material/Autocomplete';
import { RiCalendarEventFill } from 'react-icons/ri'
import { IoClose, IoCheckmark } from 'react-icons/io5'
import InputAdornment from '@mui/material/InputAdornment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'components/Modal/Modal';
import { getCategories, getSubCategories, createCategories, createFinancialInvoice } from 'utils/requests/financial';
import moment from 'moment';
import 'moment/locale/pt-br';
import MaskMoney from 'components/Masks/MaskMoney';
import showToast from 'components/Toast/Toast';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { renderToStaticMarkup } from 'react-dom/server';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { getSellers } from 'utils/requests/other';
import { editFinancialInvoice } from 'utils/requests/financial';
import { getAllClients } from 'utils/requests/leads';
import { sanitizeCurrencyValue } from 'utils/utils';

const InboundForm = ({ refetch, closeModal, entry }) => {
  const [newCategory, setNewCategory] = React.useState();
  const [newCategoryOpen, setNewCategoryOpen] = React.useState(false);
  const [allCategories, setAllCategories] = React.useState();
  const [allSubCategories, setAllSubCategories] = React.useState();
  const [sellers, setSellers] = React.useState();
  const [businesses, setBusinesses] = React.useState();

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      value: entry?.value ? (Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '',
      netValue: entry?.netValue?.value ? (Number((entry?.netValue?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '',
      date: entry?.dateInvoiced ? moment(entry?.dateInvoiced).format('DD/MM/YYYY HH:mm') : '',
      paymentDate: entry?.datePaid ? moment(entry?.datePaid).format('DD/MM/YYYY HH:mm') : '',
      description: entry?.description ?? '',
      bankAccount: entry?.bankAccount ?? '',
      category: entry?.category ? entry?.category : null,
      subCategories: entry?.subCategories?.length > 0 ? entry?.subCategories.map((sub) => (sub)) : null,
      status: entry?.businessStatus ?? '',
      emission: entry?.invoice_bill ?? false,
      seller: entry?.seller ?? '',
      miscInfo: entry?.miscInfo ?? '',
      leadRelated: entry?.leadRelated ? entry?.leadRelated : null
    },

    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,

    validationSchema: Yup.object({
      value: Yup.string().required("Valor de faturamento é um campo obrigatório"),
      netValue: Yup.string().required('Valor de entrada é um campo obrigatório'),
      date: Yup.string().required("Data de fechamento é um campo obrigatório"),
      paymentDate: Yup.string().when('status', {
        is: (value) => value === 'RECEBIDO',
        then: () => Yup.string().required('Data de pagamento é um campo obrigatório'),
        otherwise: () => Yup.string(),
      }),
      description: Yup.string().required('Descrição é um campo obrigatório').min(4, 'Descrição precisa ter pelo menos 4 caracteres'),
      bankAccount: Yup.string(),
      category: Yup.object().required('Categoria é um campo obrigatório'),
      subCategories: Yup.array().notRequired().nullable(),
      status: Yup.string().required('Status é um campo obrigatório'),
      emission: Yup.boolean(),
      seller: Yup.object().shape({
        avatar: Yup.string(),
        name: Yup.string(),
        username: Yup.string(),
        email: Yup.string(),
        type: Yup.string(),
        id: Yup.string(),
      }),
      miscInfo: Yup.string(),
      projectBudgetRelated: Yup.object().shape({
        businessName: Yup.string(),
        id: Yup.string(),
      }),
    }),
    onSubmit: async (values) => {
      const cleanValue = Number(values.value.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, "."));
      const cleanNetValue = Number(values.netValue.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, "."));
      if (cleanNetValue > cleanValue) {
        return showToast("Valor de entrada não pode ser maior que faturamento", "error")
      };
      const unixDate = (date) => {
        return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
      };
      const startInboundValues = formik.initialValues;
      const editInbound = {
        description: values.description !== startInboundValues.description ? values.description : '',
        value: values.value !== startInboundValues.value ? sanitizeCurrencyValue(values.value) : '',
        netValue: values.netValue !== startInboundValues.netValue ? sanitizeCurrencyValue(values.netValue) : '',
        category: values.category.id !== startInboundValues.category.id ? values.category.id : '',
        subCategories: values?.subCategories !== startInboundValues.subCategories ? values?.subCategories?.map(sub => sub.id) : '',
        businessStatus: values.status !== startInboundValues.status ? values.status : '',
        miscInfo: values.miscInfo !== startInboundValues.miscInfo ? values.miscInfo : '',
        dateInvoicedMs: values.date !== startInboundValues.date ? unixDate(values.date) : '',
        datePaidMs: values.paymentDate !== startInboundValues.paymentDate ? unixpaymentDate(values.paymentDate) : '',
        bankAccount: values.bankAccount !== startInboundValues.bankAccount ? values.bankAccount : '',
        seller: values.seller.id !== startInboundValues.seller.id ? values.seller.id : '',
        leadRelated: values?.leadRelated?.id !== startInboundValues?.leadRelated?.id ? values.leadRelated.id : '',
        invoice_bill: values.emission !== startInboundValues.emission ? values.emission : '',
      }
      Object.keys(editInbound).forEach(key => {
        if (editInbound[key] === "" || editInbound[key] === undefined) {
          delete editInbound[key];
        }
      });
      if (Object.keys(editInbound).length > 0) {
        editFinancialInvoice(entry?.id, editInbound).then((res) => {
          if (!res.message) { showToast("Receita editada com sucesso", "success"); refetch(); closeModal() };
          if (res.message) { showToast("Falha na edição de receita", "error") };
        });
      }
      if (Object.keys(editInbound).length === 0) {
        showToast('Sem dados alterados', 'warning');
      };
    }
  });

  const convertSvg = (svg) => {
    const markup = renderToStaticMarkup(svg);
    const encoded = encodeURIComponent(markup);
    const dataUri = `url('data:image/svg+xml;utf8,${encoded}')`;
    return dataUri;
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    height: 40,
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 28 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: convertSvg(<IoCheckmark color='#fff' />),
        left: 12,
      },
      '&:after': {
        backgroundImage: convertSvg(<IoClose color='#fff' />),
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const categoryArrObj = {
    ENTRADA: ["ENTRADA", "TRANSFERENCIA"],
  };

  const getAllCategories = async () => {
    const data = await getCategories(categoryArrObj.ENTRADA);
    if (!data.statusCode) {
      const categoriesArray = data;
      categoriesArray.push({ name: "Criar nova categoria +", id: "Criar nova categoria +" });
      setAllCategories(categoriesArray);
    };
  };

  const getAllSubCategories = async () => {
    const data = await getSubCategories(categoryArrObj.ENTRADA);
    if (!data.statusCode) {
      setAllSubCategories(data);
    };
  };

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      setSellers(data);
    };
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    };
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleNewCategoryClose = () => {
    setNewCategoryOpen(false);
    formik.setFieldValue('category', null);
    setNewCategory('');
  };

  useEffect(() => {
    if (!sellers) {
      fetchSellers();
    };
    if (!businesses) {
      fetchBusinesses();
    };
    getAllCategories();
    getAllSubCategories();
  }, []);

  useEffect(() => {
    if (formik?.values?.category?.name === 'Criar nova categoria +') {
      setNewCategoryOpen(true);
    };
  }, [formik.values.category]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSaveNewCategory = async (name) => {
    await createCategories(name, "MAIN", "ENTRADA").then((res) => {
      if (!res.message) {
        showToast("Categoria criada com sucesso", "success");
        getAllCategories();
        formik.setFieldValue('category', res);
        setNewCategory('');
        setNewCategoryOpen(false)
      };
      if (res.message) {
        showToast("Falha na criação de categoria", "error")
      };
    });
  };

  const handleSwitchChange = (event) => {
    formik.setFieldValue('emission', event.target.checked);
  };

  flatpickr("#date", flatpickrOptions('date', formik.setFieldValue, formik.setFieldTouched));
  flatpickr(".paymentDate", flatpickrOptions('paymentDate', formik.setFieldValue, formik.setFieldTouched));

  return (
    <>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid container flexDirection='column' gap='20px' marginTop='30px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='value'
                    hiddenLabel
                    placeholder={"Valor de Faturamento (R$)"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.value) && formik.touched.value}
                    helperText={formik.touched.value ? formik.errors.value : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='netValue'
                    hiddenLabel
                    placeholder="Valor de Entrada (R$)"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.netValue}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.netValue) && formik.touched.netValue}
                    helperText={formik.touched.netValue ? formik.errors.netValue : false}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} id='date'>
                  <S.Input
                    name='date'
                    hiddenLabel
                    type='text'
                    placeholder="Data de Fechamento"
                    fullWidth
                    value={formik.values.date}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('date', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.date) && formik.touched.date}
                    helperText={formik.touched.date ? formik.errors.date : false}
                  />
                </S.DateInputGrid>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='paymentDate'>
                  <S.Input
                    name='paymentDate'
                    hiddenLabel
                    type='text'
                    placeholder="Data de Pagamento"
                    fullWidth
                    value={formik.values.paymentDate}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('paymentDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.paymentDate) && formik.touched.paymentDate}
                    helperText={formik.touched.paymentDate ? formik.errors.paymentDate : false}
                  />
                </S.DateInputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='description'
                    hiddenLabel
                    type='text'
                    placeholder="Título (descrição)"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={Boolean(formik.errors.description) && formik.touched.description}
                    helperText={formik.touched.description ? formik.errors.description : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='bankAccount'
                    hiddenLabel
                    type='text'
                    placeholder="Conta Bancária"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.bankAccount}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <>
                  <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                    <Autocomplete
                      name='category'
                      disablePortal
                      value={formik.values.category ? formik.values.category : null}
                      onChange={(e, value) => {
                        formik.setFieldTouched('category')
                        formik.setFieldValue('category', value ? value : '')
                      }}
                      options={allCategories ? allCategories : []}
                      sx={{ width: "100%" }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <S.Input {...params} placeholder='Categoria' />}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    {(Boolean(formik.errors.category) && formik.touched.category) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Categoria é um campo obrigatório</FormHelperText>}
                  </S.InputGrid>
                  <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                    <Autocomplete
                      name='subCategories'
                      disablePortal
                      disableCloseOnSelect
                      multiple
                      value={formik.values.subCategories ? formik.values.subCategories : []}
                      onChange={(e, value) => {
                        formik.setFieldValue('subCategories', value)
                      }}
                      id="combo-box-demo"
                      options={allSubCategories ? allSubCategories : []}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <S.Input {...params} placeholder='Sub-Categoria' />}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                    />
                  </S.InputGrid>
                </>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    value={formik.values?.leadRelated ? formik.values?.leadRelated : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('leadRelated', value)
                    }}
                    options={businesses ? businesses[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    name='seller'
                    disablePortal
                    value={formik.values.seller ? formik.values.seller : null}
                    onChange={(e, value) => {
                      formik.setFieldTouched('seller')
                      formik.setFieldValue('seller', value ? value : '')
                    }}
                    options={sellers ? sellers : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <S.Input {...params} placeholder='Vendedor' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    name='miscInfo'
                    type='text'
                    placeholder="Observações"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.miscInfo}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Status:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formik.values.status}
                      onChange={handleStatusChange}
                    >
                      {Boolean(formik.errors.status) && formik.touched.status && <FormHelperText sx={{ color: "#f44336" }}>Status é um campo obrigatório</FormHelperText>}
                      <Grid container gap='30px' sx={{ marginLeft: "20px" }}>
                        <FormControlLabel value="RECEBIDO" control={<Radio />} label="Recebido" />
                        <FormControlLabel value="PENDENTE" control={<Radio />} label="Pendente" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff", marginBottom: "3px" }}>Emissão de Nota Fiscal?</FormLabel>
                    <S.SwitchGrid container gap='10px' justifyContent='center'>
                      <p>Não</p>
                      <CustomSwitch checked={formik.values.emission} onChange={handleSwitchChange} />
                      <p>Sim</p>
                    </S.SwitchGrid>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='Salvar' type='submit' sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }} />
          </Grid>
        </Form>
      </Formik>
      <Modal
        open={newCategoryOpen}
        onClose={handleNewCategoryClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Categoria</p>
          <S.InputGrid item xl={12}>
            <S.Input
              hiddenLabel
              name='newCategory'
              type='text'
              placeholder="Nome da Categoria"
              fullWidth
              onChange={(event) => setNewCategory(event.target.value)}
              value={newCategory}
            />
          </S.InputGrid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='Salvar' onClick={() => onSaveNewCategory(newCategory)} sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }} />
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default InboundForm