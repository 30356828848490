export const lineChartDataMetrics = [
  {
    name: "Instagram",
    data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
  },
  {
    name: "TikTok",
    data: [200, 230, 300, 350, 370, 420, 500, 350, 400, 500, 330, 500],
  },
  {
    name: "Twitter",
    data: [250, 230, 330, 350, 390, 420, 500, 650, 600, 500, 630, 700],
  },
  {
    name: "Youtube",
    data: [200, 280, 300, 350, 470, 420, 500, 350, 400, 300, 330, 400],
  },
  {
    name: "Spotify",
    data: [100, 130, 200, 250, 310, 420, 500, 450, 600, 500, 630, 700],
  },
];
