import styled from 'styled-components'
import { Button, Grid } from '@mui/material'

export const EditButtonGrid = styled(Grid)`
  position: absolute;
  right: 20px;
  top: 20px;
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`