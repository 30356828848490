import styled from 'styled-components'
import { Grid, TextField } from '@mui/material'

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const AlertGrid = styled(Grid)`
  p {
    color: #8F8F8F;
    font-weight: 400;
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
  }
`

export const DetailsGrid = styled(Grid)`
  .title {
    font-size: 26px;
  }
  .subTitle {
    font-size: 24px;
  }
  .wrap {
    text-wrap: wrap;
    overflow-wrap: break-word;
  }
`

export const SwitchGrid = styled(Grid)`
  transform: scale(1.1);
  p {
    font-weight: 200;
    padding-top: 2px;
  }
`

export const DateInputGrid = styled(InputGrid)`
/*   svg {
    margin-bottom: 10px;
  } */
  .flatpickr-confirm {
    color: white;
    cursor: pointer;
  }
`

export const Tag = styled.span`
  min-width: 65px;
  text-align: center;
  padding: 2px 10px;
  border-radius: 3px;
  margin: 2px 5px;
  font-size: 14px;
  color: #fff;
  /* height: 100%; */
`