import styled from "styled-components"
import { Grid } from "@mui/material"

export const RankingGrid = styled(Grid)`
  font-size: 24px;
  color: #FFFFFF;
 /*  gap: 20px; */

  img {
    border-radius: 5px;
    margin-top: 7px;
  }
`

export const RankingCard = styled(Grid)`
  height: 100%;

  .positiveBadge {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #72E128;
    color: #72E128;
  }
  .negativeBadge {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #E13432;
    color: #E22B00;
  }
`

export const RankingPhoto = styled(Grid)`
  p {
    text-align: center;
    position: absolute;
    top: 55px;
    left: 65px;
  }
`

export const NumberGrid = styled(Grid)`
  font-size: 20px;

  .count {
    font-size: 15px;
  }
  .subTitle {
    font-size: 14px;
    opacity: 0.8;
  }
`

export const BadgeGrid = styled(Grid)`
  font-size: 15px;
  padding: 5px 15px;
  border-radius: 5px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), #FB8404;
`

export const LegendGrid = styled(Grid)`
  font-size: 14px;
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.50);
`