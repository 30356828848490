import { useState, useEffect } from 'react'
import * as S from '../style'
import { Grid } from '@mui/material';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { RiArrowRightLine, RiCalendarLine } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import { IoCheckmarkCircle, IoCheckmarkOutline, IoCheckmarkCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import Modal from 'components/Modal/Modal';
import showToast from 'components/Toast/Toast';
import { editProjectCreative, rejectProjectCreative, approveProjectCreative, getProjectCreativeRejections } from 'utils/requests/project';
import moment from 'moment';
import 'moment/locale/pt-br';
import { capitalize } from 'utils/utils';
import { Link } from 'react-router-dom';

const CreativeForm = ({ project, creative, refetch, projectId }) => {

  const lastCreative = creative?.steps[0];

  const [activeStep, setActiveStep] = useState(0);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openRejectLogModal, setOpenRejectLogModal] = useState(false);
  const [openStepDetailsModal, setOpenStepDetailsModal] = useState(false);
  const [stepDetailsNumber, setStepDetailsNumber] = useState();
  const [isApproved, setIsApproved] = useState(false);
  const [rejectedMessage, setRejectedMessage] = useState('');
  const [rejectionLog, setRejectionLog] = useState([]);

  const verifyStep = () => {
    if (lastCreative?.stepName === 'FINAL_FILES') {
      return setActiveStep(5);
    }
    if (lastCreative?.status === 'ACCEPTED') {
      return setActiveStep(4);
    }
    if (lastCreative?.stepName === 'PRODUCTION_FILES') {
      return setActiveStep(3);
    }
    if (lastCreative?.stepName === 'PROJECT_DEFINITION') {
      return setActiveStep(2);
    }
    if (lastCreative?.stepName === 'DESCRIPTION') {
      return setActiveStep(1);
    }
    return setActiveStep(0);
  };

  const creativeSteps = {
    0: "DESCRIPTION",
    1: "PROJECT_DEFINITION",
    2: "PRODUCTION_FILES",
    /*     3: "DETAILS", */
    4: "FINAL_FILES"
  };

  const findContent = (stepName) => {
    const contentArr = creative?.steps?.map(step => {
      if (step?.stepName === stepName) {
        return step?.content
      };
    });
    const filteredContentArr = contentArr.filter(content => content !== undefined);
    return filteredContentArr[0];
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      details: lastCreative?.details ?? '',
      description: creative ? findContent('DESCRIPTION') : '',
      projectDefinition: creative ? findContent('PROJECT_DEFINITION') : '',
      productionFiles: creative ? findContent('PRODUCTION_FILES') : '',
      finalFiles: creative ? findContent('FINAL_FILES') : ''
    },
    validationSchema: Yup.object({
      details: Yup.string().notRequired(),
      description: Yup.string().when('activeStep', {
        is: (value) => activeStep === 0,
        then: () => Yup.string().required('Campo não pode ser vazio'),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
      projectDefinition: Yup.string().when('activeStep', {
        is: (value) => activeStep === 1,
        then: () => Yup.string().required('Campo não pode ser vazio'),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
      productionFiles: Yup.string().when('activeStep', {
        is: (value) => activeStep === 2,
        then: () => Yup.string().required('Campo não pode ser vazio'),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
      finalFiles: Yup.string().when('activeStep', {
        is: (value) => activeStep === 4,
        then: () => Yup.string().required('Campo não pode ser vazio'),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
    }),
    onSubmit: async (values) => {
      const startCreativeValues = formik.initialValues;
      const creativeToEdit = {
        details: values.details !== startCreativeValues.details ? values.details : null,
        description: values.description !== startCreativeValues.description ? values.description : null,
        projectDefinition: values.projectDefinition !== startCreativeValues.projectDefinition ? values.projectDefinition : null,
        productionFiles: values.productionFiles !== startCreativeValues.productionFiles ? values.productionFiles : null,
        finalFiles: values.finalFiles !== startCreativeValues.finalFiles ? values.finalFiles : null
      };
      Object.keys(creativeToEdit).forEach(key => {
        if (creativeToEdit[key] === null) {
          delete creativeToEdit[key];
        };
      });
      const valueToSend = Object.entries(creativeToEdit);
      const mountedObj = {
        content: valueToSend ? valueToSend[0][1] : null,
        stepName: creativeSteps[activeStep]
      };
      if (Object.keys(creativeToEdit).length > 0) await editProjectCreative(project?.id, mountedObj).then((res) => {
        if (res.message) {
          showToast('Falha na edição do criativo', 'error');
        };
        if (!res.message) {
          showToast('Criativo editado com sucesso!', 'success');
          refetch();
          setActiveStep(activeStep + 1)
        };
      });
      if (Object.keys(creativeToEdit).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  const approvedSteps = [
    ['Briefing', 'Descrição'],
    ['Construção do Projeto', 'Definição do Processo'],
    ['Produção do Material', 'Arquivos de Produção'],
    ['Revisão', 'Detalhes a Revisar'],
    ['Entrega', 'Arquivo Final'],
  ];

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const CustomStepIcon = (props) => {
    return (
      <>
        {props.completed &&
          <IoCheckmarkCircle size='24px' color='#FB8404' />
        }
        {!props.completed &&
          <S.StyledStep className={props.active ? 'stepActive' : null} />
        }
      </>
    )
  };

  const handleReject = async () => {
    await rejectProjectCreative(project?.id, { rejectedReason: rejectedMessage }).then((res) => {
      if (res.message) {
        showToast('Falha ao rejeitar criativo', 'error');
      };
      if (!res.message) {
        formik.setFieldValue('description', '');
        formik.setFieldValue('projectDefinition', '');
        formik.setFieldValue('productionFiles', '');
        showToast('Criativo rejeitado com sucesso!', 'success');
        setRejectedMessage('');
        refetch();
      };
    });
  };

  const handleApproval = async () => {
    await approveProjectCreative(project?.id).then((res) => {
      if (res.message) {
        showToast('Falha ao aprovar criativo', 'error');
      };
      if (!res.message) {
        showToast('Criativo aprovado com sucesso!', 'success');
        refetch();
        setIsApproved(true);
      };
    });
  };

  const fetchRejectionLog = async () => {
    const data = await getProjectCreativeRejections(projectId);
    if (!data.message) {
      setRejectionLog(data);
    }
  };

  useEffect(() => {
    if (openRejectLogModal) {
      fetchRejectionLog();
    };
  }, [openRejectLogModal]);

  useEffect(() => {
    verifyStep();
  }, [creative]);

  const handleStepDetailsModal = (step) => {
    setStepDetailsNumber(step);
    setOpenStepDetailsModal(true);
  };

  const filterRejectedSteps = (rejectedSteps) => {
    const stepsArr = rejectedSteps?.map(step => {
      if (step?.stepName === 'PRODUCTION_FILES') {
        return step.content
      };
      return ''
    });
    const filteredStepsArr = stepsArr.filter(step => step !== '');
    return filteredStepsArr;
  };

  const isValidUrl = (str) => {
    const pattern = new RegExp(
      '^(?:(?:(?:https?|ftp):)?\\/\\/)?' +
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
        '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
        '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
        '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
        '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
        '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
        '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
        '(?:' +
          '(?:' +
            '[a-zA-Z0-9\\u00a1-\\uffff]' +
            '[a-zA-Z0-9\\u00a1-\\uffff_-]{0,62}' +
          ')?' +
          '[a-zA-Z0-9\\u00a1-\\uffff]\\.' +
        ')+' +
        '(?:[a-zA-Z\\u00a1-\\uffff]{2,}\\.?)' +
      ')' +
      '(?::\\d{2,5})?' +
      '(?:[/?#][\\w\\-._~:/?#[\\]@!$&\'()*+,;=.%]*)?$',
      'i'
    );
    return pattern.test(str);
  };

  const hasHttpProtocol = (url) => {
    const pattern = /^https?:\/\//;
    return pattern.test(url);
  };

  const LinkInput = ({ str, children }) => {
    if (isValidUrl(str)) {
      return (
        <Link to={hasHttpProtocol(str) ? str : `https://${str}`} target='_blank' key={str}>
          {children}
        </Link>
      )
    };
    return children
  };

  const verifyInputClassname = (step, value) => {
    if (activeStep !== step && isValidUrl(value)) {
      return 'readOnly urlPointer'
    };
    if (activeStep !== step) {
      return 'readOnly'
    };
    return ''
  };

  return (
    <>
      <S.ProjectCard>
        <Grid container justifyContent='space-between'>
          <S.FormTitleGrid container flexDirection='column' item md={6}>
            <p>Criativos</p>
            <p className='subTitle'>Processo criativo do projeto</p>
          </S.FormTitleGrid>
          <S.BadgeContainer display='flex' alignItems='flex-end' item md={6}>
            <S.RejectedBadgeGrid item onClick={() => setOpenRejectLogModal(true)}>
              <p>Acessar Recusas</p>
            </S.RejectedBadgeGrid>
            <S.BadgeGrid item>
              <p>{capitalize(project?.type.toLowerCase())}</p>
            </S.BadgeGrid>
          </S.BadgeContainer>
        </Grid>
        <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
          <Form>
            <S.FormGrid container>
              <S.LeftBorderGrid item md={5}>
                <Stepper /* nonlinear='true' */ activeStep={activeStep} orientation='vertical'>
                  {
                    approvedSteps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={CustomStepIcon} /* onClick={handleStep(index)} */>
                          <S.StepperLabelGrid container>
                            <p className={`number ${(activeStep >= index) ? 'active' : null}`}>{`0${index + 1}`}</p>
                            <Grid display='flex' flexDirection='column'>
                              <p className='title'>{label[0]}</p>
                              <p className='subTitle'>{label[1]}</p>
                            </Grid>
                          </S.StepperLabelGrid>
                        </StepLabel>
                      </Step>
                    ))
                  }
                </Stepper>
              </S.LeftBorderGrid>
              <S.RightBorderGrid item flexDirection='column' md={7}>
                <S.InputContainer container justifyContent='space-between'>
                  <S.BorderedInputGrid
                    item
                    md={10.5}
                    lg={10.5}
                    xl={11}
                    container
                    flexDirection='column'
                    className={verifyInputClassname(0, formik.values.description)}
                  >
                    {activeStep === 0 &&
                      <S.Input
                        name='description'
                        id='description'
                        placeholder='Insira o link do arquivo em PDF'
                        InputProps={{
                          readOnly: activeStep !== 0
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={Boolean(formik.errors.description)}
                        helperText={formik.errors.description}
                      />
                    }
                    {activeStep !== 0 &&
                      <LinkInput str={formik.values.description}>
                        <S.Input
                          name='description'
                          id='description'
                          placeholder='Insira o link do arquivo em PDF'
                          InputProps={{
                            readOnly: activeStep !== 0
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          error={Boolean(formik.errors.description)}
                          helperText={formik.errors.description}
                        />
                      </LinkInput>
                    }
                  </S.BorderedInputGrid>
                  <Grid container item md={1.5} lg={1} justifyContent='flex-end'>
                    {activeStep === 0
                      ?
                      <S.EditButton
                        onClick={() => formik?.values?.description?.length > 0 ? formik.handleSubmit() : formik.validateForm()}
                      >
                        <RiArrowRightLine size='20px' color='#fff' />
                      </S.EditButton>
                      :
                      <S.DoneButton
                        onClick={() => handleStepDetailsModal(1)}
                      >
                        <IoCheckmarkOutline size='20px' color='#fff' />
                      </S.DoneButton>
                    }
                  </Grid>
                </S.InputContainer>
                {activeStep >= 1 &&
                  <S.InputContainer container justifyContent='space-between'>
                    <S.BorderedInputGrid
                      item
                      md={10.5}
                      lg={10.5}
                      xl={11}
                      className={verifyInputClassname(1, formik.values.projectDefinition)}
                    >
                      {activeStep === 1 &&
                        <S.Input
                          name='projectDefinition'
                          id='projectDefinition'
                          placeholder='Sobre a construção do projeto'
                          InputProps={{
                            readOnly: activeStep !== 1
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.projectDefinition}
                          error={Boolean(formik.errors.projectDefinition)}
                          helperText={formik.errors.projectDefinition}
                        />
                      }
                      {activeStep !== 1 &&
                        <LinkInput str={formik.values.projectDefinition}>
                          <S.Input
                            name='projectDefinition'
                            id='projectDefinition'
                            placeholder='Sobre a construção do projeto'
                            InputProps={{
                              readOnly: activeStep !== 1
                            }}
                            onChange={formik.handleChange}
                            value={formik.values.projectDefinition}
                            error={Boolean(formik.errors.projectDefinition)}
                            helperText={formik.errors.projectDefinition}
                          />
                        </LinkInput>
                      }
                    </S.BorderedInputGrid>
                    <Grid container item md={1.5} lg={1} justifyContent='flex-end'>
                      {activeStep === 1
                        ?
                        <S.EditButton
                          onClick={() => formik?.values?.projectDefinition?.length > 0 ? formik.handleSubmit() : formik.validateForm()}
                        >
                          <RiArrowRightLine size='20px' color='#fff' />
                        </S.EditButton>
                        :
                        <S.DoneButton
                          onClick={() => handleStepDetailsModal(2)}
                        >
                          <IoCheckmarkOutline size='20px' color='#fff' />
                        </S.DoneButton>
                      }
                    </Grid>
                  </S.InputContainer>
                }
                {activeStep >= 2 &&
                  <S.InputContainer container justifyContent='space-between'>
                    <S.BorderedInputGrid
                      item md={10.5}
                      lg={10.5} xl={11}
                      className={verifyInputClassname(2, formik.values.productionFiles)}
                    >
                      {activeStep === 2 &&
                        <S.Input
                          name='productionFiles'
                          id='productionFiles'
                          placeholder='Insira o link do drive com os arquivos'
                          InputProps={{
                            readOnly: activeStep !== 2
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.productionFiles}
                          error={Boolean(formik.errors.productionFiles)}
                          helperText={formik.errors.productionFiles}
                        />
                      }
                      {activeStep !== 2 &&
                        <LinkInput str={formik.values.productionFiles}>
                          <S.Input
                            name='productionFiles'
                            id='productionFiles'
                            placeholder='Insira o link do drive com os arquivos'
                            InputProps={{
                              readOnly: activeStep !== 2
                            }}
                            onChange={formik.handleChange}
                            value={formik.values.productionFiles}
                            error={Boolean(formik.errors.productionFiles)}
                            helperText={formik.errors.productionFiles}
                          />
                        </LinkInput>
                      }
                    </S.BorderedInputGrid>
                    <Grid container item md={1.5} lg={1} justifyContent='flex-end'>
                      {activeStep === 2
                        ?
                        <S.EditButton
                          onClick={() => formik?.values?.productionFiles?.length > 0 ? formik.handleSubmit() : formik.validateForm()}
                        >
                          <RiArrowRightLine size='20px' color='#fff' />
                        </S.EditButton>
                        :
                        <S.DoneButton
                          onClick={() => handleStepDetailsModal(3)}
                        >
                          <IoCheckmarkOutline size='20px' color='#fff' />
                        </S.DoneButton>
                      }
                    </Grid>
                  </S.InputContainer>
                }
                {activeStep >= 3 &&
                  <S.InputContainer container justifyContent='space-between'>
                    <S.BorderedInputGrid
                      item
                      md={10.5}
                      lg={activeStep !== 3 ? 10.5 : 9}
                      xl={activeStep !== 3 ? 11 : 9}
                      className={activeStep !== 3 ? 'readOnly' : ''}
                    >
                      <S.Input
                        name='projectName'
                        id='projectName'
                        placeholder='Aprovar ou recusar a produção do material?'
                        value={lastCreative?.status === 'ACCEPTED' ? 'Aprovado' : ''}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </S.BorderedInputGrid>
                    <Grid container item md={1.5} lg={activeStep !== 3 ? 1 : 3} justifyContent='flex-end'>
                      {(activeStep === 3 && !isApproved) &&
                        <Grid container gap='10px' justifyContent='flex-end'>
                          <S.RejectButton onClick={() => setOpenRejectModal(true)}>
                            <IoCloseCircleOutline size='25px' color='#fff' />
                          </S.RejectButton>
                          <S.ApproveButton onClick={handleApproval}>
                            <IoCheckmarkCircleOutline size='25px' color='#fff' />
                          </S.ApproveButton>
                        </Grid>
                      }
                      {(activeStep > 3 || isApproved) &&
                        <S.DoneButton
                          onClick={() => handleStepDetailsModal(4)}
                        >
                          <IoCheckmarkOutline size='20px' color='#fff' />
                        </S.DoneButton>
                      }
                    </Grid>
                  </S.InputContainer>
                }
                {activeStep >= 4 &&
                  <S.InputContainer container justifyContent='space-between'>
                    <S.BorderedInputGrid
                      item
                      md={10.5}
                      lg={10.5}
                      xl={11}
                      className={verifyInputClassname(4, formik.values.finalFiles)}
                    >
                      {activeStep === 4 &&
                        <S.Input
                          name='finalFiles'
                          id='finalFiles'
                          placeholder='Insira o link do drive com o arquivo final'
                          InputProps={{
                            readOnly: activeStep !== 4
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.finalFiles}
                          error={Boolean(formik.errors.finalFiles)}
                          helperText={formik.errors.finalFiles}
                        />
                      }
                      {activeStep !== 4 &&
                        <LinkInput str={formik.values.finalFiles}>
                          <S.Input
                            name='finalFiles'
                            id='finalFiles'
                            placeholder='Insira o link do drive com o arquivo final'
                            InputProps={{
                              readOnly: activeStep !== 4
                            }}
                            onChange={formik.handleChange}
                            value={formik.values.finalFiles}
                            error={Boolean(formik.errors.finalFiles)}
                            helperText={formik.errors.finalFiles}
                          />
                        </LinkInput>
                      }
                    </S.BorderedInputGrid>
                    <Grid container item md={1.5} lg={1} justifyContent='flex-end'>
                      {activeStep === 4
                        ?
                        <S.EditButton
                          onClick={() => formik?.values?.finalFiles?.length > 0 ? formik.handleSubmit() : formik.validateForm()}
                        >
                          <RiArrowRightLine size='20px' color='#fff' />
                        </S.EditButton>
                        :
                        <S.DoneButton
                          onClick={() => handleStepDetailsModal(5)}
                        >
                          <IoCheckmarkOutline size='20px' color='#fff' />
                        </S.DoneButton>
                      }
                    </Grid>
                  </S.InputContainer>
                }
              </S.RightBorderGrid>
            </S.FormGrid>
          </Form>
        </Formik>
      </S.ProjectCard>
      <Modal open={openRejectModal} onClose={() => { setOpenRejectModal(false); setRejectedMessage(''); }} size='md'>
        <Grid container flexDirection='column' gap='20px'>
          <p>Motivo da recusa</p>
          <S.InputGrid item>
            <S.Input
              placeholder='Descreva'
              value={rejectedMessage}
              onChange={(e) => setRejectedMessage(e.target.value)}
              error={!rejectedMessage}
              helperText={!rejectedMessage ? 'Motivo da recusa é obrigatório' : ''}
            />
          </S.InputGrid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom
              label='Salvar'
              disabled={!rejectedMessage}
              onClick={() => { setOpenRejectModal(false); handleReject(); }}
              sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }}
            />
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openStepDetailsModal} onClose={() => { setOpenStepDetailsModal(false); setStepDetailsNumber() }} size='sm'>
        <Grid container flexDirection='column'>
          <Grid container flexDirection='column'>
            <p style={{ fontSize: '22px' }}>{stepDetailsNumber ? approvedSteps[stepDetailsNumber - 1][0] : ''}</p>
            <p style={{ fontSize: '14px' }}>{stepDetailsNumber ? approvedSteps[stepDetailsNumber - 1][1] : ''}</p>
          </Grid>
          <Grid container flexDirection='column' gap='5px' marginTop={3} marginBottom={2}>
            {isValidUrl(creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content) &&
              <Link
                to={
                  hasHttpProtocol(creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content)
                    ?
                    creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content
                    :
                    `https://${creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content}`
                }
                target="_blank"
                style={{ color: '#fff' }}
              >
                <p style={{ overflowWrap: 'anywhere', textDecoration: 'underline' }}>
                  {creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content}
                </p>
              </Link>
            }
            {!isValidUrl(creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content) &&
              <p style={{ overflowWrap: 'anywhere' }}>
                {creative?.steps[creative?.steps?.length - stepDetailsNumber]?.content}
              </p>
            }
            <p>Submetida por: {creative ? creative?.steps[creative?.steps?.length - stepDetailsNumber]?.user?.name : ''}</p>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openRejectLogModal} onClose={() => setOpenRejectLogModal(false)} size='md'>
        <Grid container flexDirection='column' gap='20px'>
          <p>Recusas</p>
          <Grid container flexDirection='column' gap='20px' marginTop={3} marginBottom={6}>
            {rejectionLog.length === 0 &&
              <p>Sem recusas para exibir</p>
            }
            {rejectionLog.length > 0 && rejectionLog.map((rejection, index) => {
              return (
                <S.RejectedLogGrid container justifyContent='space-between' key={index}>
                  <Grid display='flex' item md={1}>
                    <p className={`number`}>{rejectionLog.length < 10 ? `0${rejectionLog.length - index}` : rejectionLog.length - index}</p>
                  </Grid>
                  <Grid display='flex' item flexDirection='column' md={10.5}>
                    {isValidUrl(filterRejectedSteps(rejection?.creative?.steps)[index]) &&
                      <Link
                        to={
                          hasHttpProtocol(filterRejectedSteps(rejection?.creative?.steps)[index])
                            ?
                            filterRejectedSteps(rejection?.creative?.steps)[index]
                            :
                            `https://${filterRejectedSteps(rejection?.creative?.steps)[index]}`
                        }
                        target="_blank"
                        style={{ color: '#fff' }}
                      >
                        <p className='titleLink'>{filterRejectedSteps(rejection?.creative?.steps)[index]}</p>
                      </Link>
                    }
                    {!isValidUrl(filterRejectedSteps(rejection?.creative?.steps)[index]) &&
                      <p className='title'>{filterRejectedSteps(rejection?.creative?.steps)[index]}</p>
                    }
                    <p className='subTitle'>{rejection?.details}</p>
                    <Grid container gap='5px'>
                      <RiCalendarLine color="#a0aec0" size='18px' style={{ alignSelf: "center" }} />
                      <p>{moment(rejection?.createdAt).format('DD [de] MMMM [de] YYYY')}</p>
                      <GoDotFill color="#a0aec0" size='18px' style={{ alignSelf: "center" }} />
                      <p className='author'>Por: {rejection?.user?.name}</p>
                    </Grid>
                  </Grid>
                </S.RejectedLogGrid>
              )
            })}
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default CreativeForm