  

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Vision UI Dashboard React helper functions
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { white, light, gradients } = colors;
const { borderWidth } = borders;
const { md } = boxShadows;

export default {
  defaultProps: {
    disableRipple: true,
  },

  styleOverrides: {
/*     root: {
      width: pxToRem(40),
      height: pxToRem(20),
      margin: `${pxToRem(4)} 0`,
      padding: 0,
      borderRadius: pxToRem(160),
      transition: "transform 250ms ease-in",
    }, */

    switchBase: {
/*       padding: 0,
      top: "50%",
      transform: `translate(${pxToRem(2)}, -50%)`,
      transition: `transform 250ms ease-in-out`, */

      "&.Mui-checked": {
        /* transform: `translate(${pxToRem(22)}, -50%)`, */

        "& + .MuiSwitch-track": {
          backgroundColor: `#5ACC93`,
          /* borderColor: `${rgba(gradients.dark.state, 0.95)} !important`, */
          opacity: 1,
        },
      },

/*       "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      }, */

/*       "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      }, */
    },

    thumb: {
      backgroundColor: white.main,
    },

    track: {
      backgroundColor: '#D7007B',
      opacity: '1'
    },

    checked: {},
  },
};
