import React from 'react'
import { Grid } from '@mui/material'
import * as S from "./style"
import InputAdornment from '@mui/material/InputAdornment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailIcon from '@mui/icons-material/Mail';
import * as Yup from "yup";
import { useFormik } from "formik";
import Viraliza from '../../assets/viraliza.svg'
import Viuzz from '../../assets/viuzz.svg'
import { useNavigate } from 'react-router-dom';

const UpdatePassword = () => {
  const navigate = useNavigate();

/*   const user = localStorage.getItem("dashUser");

  React.useEffect(() => {
    if(user) {
      navigate("/")
    };
  }); */

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password").min(6),
    }),
/*     onSubmit: (values) => {
      handleLogin(values.password).then(() => {
        navigate("/");
      }).catch(() => {
        console.log("Login Fail")
      });
    } */
  })

  return (
    <>
      <S.MainContainer container justifyContent='center'>
        <S.BgGrid container>
          <Grid container flexDirection='column' justifyContent='space-evenly'>
            <img src={Viraliza} />
            <form
              onSubmit={validation.handleSubmit}
            >
              <Grid container flexDirection='column' sx={{ marginBottom: "100px" }}>
                <S.BoxGrid container flexDirection='column'>
                  <p>Olá, User!</p>
                  <hr className='gradientBar'></hr>
                </S.BoxGrid>
                <S.InputGrid container flexDirection='column'>
                  <S.Input
                    hiddenLabel
                    name='email'
                    type='email'
                    placeholder="Login"
/*                     onChange={validation.handleChange}
                    onBlur={validation.handleBlur} */
                    disabled 
                    value={"User"}
                    /* error={validation.errors.email ? true : false} */
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <MailIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <S.Input
                    name='password'
                    type='password'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    hiddenLabel
                    placeholder="Senha"
                    /*                   error={validation.errors.password ? true : false} */
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </S.InputGrid>
              </Grid>
              <S.LoginButton type='submit' disabled={(validation.errors.password || !validation.values.password) ? true : false} /* onClick={() => {navigate("/")}} */>Cadastrar Senha</S.LoginButton>
            </form>
            {/* <button type='submit'>teste</button> */}
            <img src={Viuzz} />
          </Grid>
          {/* <img src={img}></img> */}
        </S.BgGrid>
      </S.MainContainer>
    </>
  )
}

export default UpdatePassword