import React, { Component } from "react";
import Chart from "react-apexcharts";

class DoubleBarChart extends Component {
  constructor(props) {
    super(props);

  this.state = {

    chartData: [],
    chartOptions: {},
          
/*     series: [{
      data: ["44%", "55%", "41%", "64%", "22%"]
    }, {
      data: ["53%", "32%", "33%", "52%", "13%"]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 430,
        toolbar: {
          show: false
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: "12px",
        },
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        shared: true,
        intersect: false,
        theme: "dark",
      },
      xaxis: {
        categories: ["13-17", "18-24", "25-34", "35-44", "44+"],
      },
    }, */
  };
}

    componentDidMount() {
      const { doubleBarChartData, doubleBarChartOptions } = this.props;
  
      this.setState({
        chartData: doubleBarChartData,
        chartOptions: doubleBarChartOptions,
      });
    }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default DoubleBarChart;
