import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import Table from "defaultComponents/Tables/Table";

/* import { data } from "./data/index"; */

import * as S from "./style";
import arrowRightUp from "assets/icons/arrow-right-up.svg";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "context/TokenContext";
import { useContext } from "react";
import { capitalize } from "utils/utils";
import { RiEyeFill } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { Grid } from "@mui/material";
import { IoEllipsisVertical } from "react-icons/io5";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { deleteProject } from "utils/requests/project";
import Modal from "components/Modal/Modal";
import showToast from "components/Toast/Toast";
import moment from "moment";
import "moment/locale/pt-br";

const InfluencerProjectsTable = ({ projects, showFinished, routing, refetch }) => {
  moment.locale("pt-br");
  const [menu, setMenu] = useState(null);
  const [openDeleteMenu, setOpenDeleteMenu] = useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedProject, setSelectedProject] = useState();

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  const avatars = (members) => {
    return members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="sm"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));
  };

  const { decodedToken } = useContext(TokenContext);

  const role = decodedToken?.type;

  const navigate = useNavigate();

  const navigateObject = {
    ADMINISTRATOR: `/projetos/listagem/`,
    INFLUENCER: `/projetos/`,
    H_COMERCIAL: `/comercial/projetos/`,
  };

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openDeleteMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenDeleteMenu(updatedMenu);
  };

  const handleDeleteModal = (id) => {
    setOpenConfirmDeletionModal(true);
    setDeleteId(id);
  };

  const cancelDelete = () => {
    setOpenConfirmDeletionModal(false);
    setDeleteId("");
  };

  const handleDetailsModal = (project) => {
    setOpenDetailsModal(true);
    setSelectedProject(project);
  };

  const closeDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedProject();
  };

  const handleDelete = async () => {
    await deleteProject(deleteId).then((res) => {
      if (res.name) {
        showToast("Projeto deletado com sucesso", "success");
        refetch();
        setOpenConfirmDeletionModal(false);
        return;
      }
      showToast("Falha ao deletar projeto", "error");
    });
  };

  const statusTranslation = {
    LEADS: "Leads 🔥",
    RELATIONSHIP: "Relacionamento 🎯",
    FOLLOW_UP: "FUP - Leads 🆙",
    NEGOTIATION: "Em Negociação 💰",
    CLOSED_CONTRACT: "Contrato Fechado 🤝",
    REJECTED: "Rejeitado",
    ONGOING: "Em andamento",
    ENDED: "Finalizado",
  };

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        {renderMenu}
      </VuiBox>
      <VuiBox
        sx={{
          paddingBottom: "10px",
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
        onClick={() =>
          openDeleteMenu.includes(true)
            ? setOpenDeleteMenu(openDeleteMenu.map((bool) => false))
            : null
        }
      >
        {/* <Table columns={columns} rows={rows} /> */}
        <Table
          columns={[
            { name: "empresa", align: "left" },
            { name: "influencers", align: "left" },
            { name: "projeto", align: "center" },
            { name: "realização", align: "center" },
          ]}
          rows={
            projects &&
            projects?.map((project, index) => {
              return {
                empresa: (
                  <VuiBox display="flex" alignItems="center" key={project?.id}>
                    <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                      {project?.leadsRelated?.businessName ?? ""}
                    </VuiTypography>
                  </VuiBox>
                ),
                influencers: (
                  <VuiBox display="flex" py={1}>
                    {avatars(
                      project.influencers.map((influencer) => [influencer.avatar, influencer.name])
                    )}
                  </VuiBox>
                ),
                projeto: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.title ?? ""}
                  </VuiTypography>
                ),
                realização: (
                  <S.ProgressGrid container justifyContent="space-around">
                    <Grid container item xs={8} xl={10} justifyContent="center">
                      <VuiBox width="8rem" textAlign="center" sx={{ width: "80%" }}>
                        <>
                          <VuiTypography color="white" variant="button" fontWeight="bold">
                            {project?.progress ? Math.round(Number(project?.progress)) : 0}%
                          </VuiTypography>
                          <VuiProgress
                            value={project?.progress ?? 0}
                            color="viuzz"
                            label={false}
                            sx={{ background: "rgba(96, 96, 96, 0.5)" }}
                          />
                        </>
                      </VuiBox>
                    </Grid>
                    {/*                     <Grid container item xs={2} xl={1}>
                      <RiEyeFill size='20px' style={{ cursor: "pointer" }} onClick={() => navigate(`${navigateObject[role]}${project?.id}`, { state: { type: project?.type } })} />
                    </Grid> */}
                    <Grid container item xs={2} xl={1} onClick={() => toggleOpenMenu(index)}>
                      <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                    </Grid>
                    {openDeleteMenu[index] && (
                      <S.OptionsContainer container justifyContent="flex-end">
                        <S.OptionsGrid display="flex" flexDirection="column">
                          <p onClick={() => handleDetailsModal(project)}>Ver Detalhes</p>
                          {/* <p onClick={() => handleDeleteModal(project?.id)}>Excluir</p> */}
                        </S.OptionsGrid>
                      </S.OptionsContainer>
                    )}
                  </S.ProgressGrid>
                ),
              };
            })
          }
        />
      </VuiBox>
      <Modal size="sm" open={openConfirmDeletionModal} onClose={() => cancelDelete()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir essa entrada? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label="Confirmar" onClick={() => handleDelete()}></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="md" open={openDetailsModal} onClose={() => closeDetailsModal()}>
        <Grid container flexDirection="column" gap="5px">
          <p style={{ fontSize: "22px" }}>{selectedProject?.title}</p>
          <p style={{ fontSize: "18px" }}>Status: {statusTranslation[selectedProject?.status]}</p>
        </Grid>
        <Grid container flexDirection="column" gap="20px" mt="10px">
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Empresa:</p>
              <p>{selectedProject?.leadsRelated?.businessName ?? ""}</p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Vendedor:</p>
              <p>{selectedProject?.seller?.name ?? ""}</p>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Fechamento:</p>
              <p>
                {selectedProject?.endDate
                  ? moment(selectedProject?.endDate).format("DD/MM/YYYY HH:mm")
                  : ""}
              </p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Pagamento:</p>
              <p>
                {selectedProject?.budget?.paymentDateExpected
                  ? moment(selectedProject?.budget?.paymentDateExpected).format("DD/MM/YYYY HH:mm")
                  : ""}
              </p>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Influencers:</p>
              <p>
                {selectedProject?.influencers &&
                  selectedProject?.influencers?.map((influencer) => influencer.name).join(", ")}
              </p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Pagamento:</p>
              <p>{selectedProject?.budget?.status}</p>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Descrição:</p>
              <p>{selectedProject?.description}</p>
            </Grid>
            {((role !== "INFLUENCER") && (role !== "MARKETING")) && (
              <Grid item xs={12} md={5.85} xl={5.85}>
                <p>Valor do Negócio:</p>
                <p>
                  {'R$ '}
                  {selectedProject?.budget?.value > 0
                    ? Number((selectedProject?.budget?.value / 100).toFixed(2)).toLocaleString(
                        "pt-BR",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    : '0,00'}
                </p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
    </Card>
  );
};

export default InfluencerProjectsTable;
