import { Button, Grid } from '@mui/material';
import TimelineList from "defaultComponents/Timeline/TimelineList";
import styled from 'styled-components';

export const EditButtonGrid = styled(Grid)`
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const UpdateButtonGrid = styled(EditButtonGrid)`
  position: absolute;
  right: 23px;
  top: 20px;
`

export const CardGrid = styled(Grid)`
  border-radius: 10px;
  background: #2B0B03;
  backdrop-filter: blur(2px);
  color: white;
  min-width: 200px;
  padding: 10px;

  .logo {
    width: 130px;
    align-self: center;
  }

  .postsNumber {
    font-size: 30px;
    font-weight: 700;
  }

  .posts {
    font-size: 14px;
    opacity: 0.5;
    align-self: center;
    margin-left: 5px;
  }
`

export const InnerCardGrid = styled(Grid)`
  border-radius: 5px;
  background: #140501;
  margin: 10px 0;
  font-size: 16px;
  padding: 10px;

  .title {
    opacity: 0.5;
    font-size: 12px;
  }

  .percentage {
    color: #65EFAD;
    font-size: 12px;
    align-self: center;
    margin-left: 5px;
  }
`

export const InnerCardButton = styled(EditButton)`
  width: 35px !important;
  height: 35px !important;
  opacity: 1 !important;
  position: absolute !important;
  right: 20px;
  margin-top: 5px !important;
`

export const ScheduleList = styled(TimelineList)`
`

export const InfluencerTextGrid = styled(Grid)`
  color: #fff;

  .name {
    opacity: 1;
    font-weight: 600;
    font-size: 24px;
  }

  .subName {
    opacity: 0.6;
  }

  .description {
    font-size: 15px;
    opacity: 0.6;
  }

  @media (min-width: 992px){
    margin-left: 10px !important;
  }
`

export const HashtagGrid = styled(Grid)`
  gap: 20px;

  p {
    color: white;
    opacity: 0.75;
    font-size: 14px;
  }
`

export const NewsContainer = styled(Grid)`
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: 992px) {
    gap: 20px;
  }
`

export const NewsGrid = styled(Grid)`
  color: #fff;

  .photo {
    width: 150px;
    height: 40%;

    @media (max-width: 992px) {
      height: 150px;
    }
  }

  .newsLogo {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  .text {
    opacity: 0.5;
    font-size: 16px;
  }

  .date {
    opacity: 0.5;
    font-size: 14px;
  }
`

export const MetricsButton = styled(Button)`
  min-width: 30px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
  color: #fff !important;
  height: 41px !important;
  padding: 0 30px !important;
  gap: 5px;
  font-size: 16px !important;
  font-weight: 400 !important;
`

export const Loader = styled.div`
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #E22B00;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
@keyframes l3 {to{transform: rotate(1turn)}}

  @media(min-width: 1440px) {
    margin-top: 20px;
  }

/* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const InfluencerPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 40px;
    width: 80px;
    height: 110px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
      border-radius: 40px;
    }
  }
`

export const BranchIndicator = styled.div`
  color: white;
  width: 35px;
  height: 35px;
  font-size: 18px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
  border-radius: 50%;
  text-align: center;
  align-content: center;
  position: absolute;
  top: 97px;
  left: 97px;

  @media(max-width: 992px) {
    top: 138px;
  }
`

export const ContactButton = styled(EditButton)`
  width: 25px;
  height: 25px;
  padding: 6px !important;
`

export const ContactPhoto = styled(InfluencerPhoto)`
  .photoContainer {
    width: 60px;
    height: 80px;
    
    img {
      border: 2px solid #FC480D;
    }
  }
`

export const ContactGrid = styled(Grid)`
  svg {
    align-self: center;
  }

  .minorTextGrid {
    font-size: 16px;
    color: #888281;
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 60px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;

  p{
    cursor: pointer;
    font-size: 16px;
  }

  p:hover{
    color: #FC6009;
  }
`

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #2D3748;
`