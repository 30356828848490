import React from 'react'
import * as S from '../style'
import { Grid } from '@mui/material';
import MixedChart from "defaultComponents/Charts/MixedCharts/MixedChart";
import BarChart from "defaultComponents/Charts/BarCharts/BarChart";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import { IoArrowUp } from "react-icons/io5";
import { RiExternalLinkFill } from "react-icons/ri";
import Card from "@mui/material/Card";
import VuiInput from "components/VuiInput";
import Table from "defaultComponents/Tables/Table";

const Posts = () => {
  return (
    <Grid container spacing='18px'>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid display='flex' gap='30px' flexDirection='column'>
            <Grid>
              <Grid container justifyContent='space-between'>
                <Grid item xl={4}>
                  <p style={{ color: "white" }}>Organic Summary</p>
                </Grid>
                <Grid container item xl={8} justifyContent='flex-end'>
                  <S.BlocksGrid container item xl={12}>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <p>43.000K</p>
                      <p className='subTitle'>Engajamento</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <Grid container justifyContent='center'>
                        <p>12</p>
                        <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                      </Grid>
                      <p className='subTitle'>Interações</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <Grid container justifyContent='center'>
                        <p>23</p>
                        <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                      </Grid>
                      <p className='subTitle'>Avg. por Post</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <Grid container justifyContent='center'>
                        <p>467</p>
                        <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                      </Grid>
                      <p className='subTitle'>Impressões</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <p>1</p>
                      <p className='subTitle'>Posts</p>
                    </S.PostsBlock>
                  </S.BlocksGrid>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ minHeight: "300px" }}>
              <MixedChart /* lineChartData={chartData} lineChartOptions={chartOptions} */ />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid display='flex' gap='30px' flexDirection='column'>
            <Grid>
              <Grid container justifyContent='space-between'>
                <Grid item xl={4}>
                  <p style={{ color: "white" }}>Organic Interactions</p>
                </Grid>
                <Grid container item xl={8} justifyContent='flex-end' gap='10px'>
                  <S.BlocksGrid container item xl={12}>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <Grid container justifyContent='center'>
                        <p>23</p>
                        <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                      </Grid>
                      <p className='subTitle'>Likes</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <Grid container justifyContent='center'>
                        <p>467</p>
                        <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                      </Grid>
                      <p className='subTitle'>Comentários</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <Grid container justifyContent='center'>
                        <p>1</p>
                        <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                      </Grid>
                      <p className='subTitle'>Salvos</p>
                    </S.PostsBlock>
                    <S.PostsBlock item xs={1.5} xl={2}>
                      <p>7</p>
                      <p className='subTitle'>Posts</p>
                    </S.PostsBlock>
                  </S.BlocksGrid>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ minHeight: "300px" }}>
              <MixedChart /* lineChartData={chartData} lineChartOptions={chartOptions} */ />
            </Grid>
            <S.BlocksGrid container item xl={12}>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>43.000K</p>
                <p className='subTitle'>Likes</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>12</p>
                <p className='subTitle'>Likes diários</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>23</p>
                <p className='subTitle'>Comentários diários</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>467</p>
                <p className='subTitle'>Comentários por post</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>1</p>
                <p className='subTitle'>Likes por comentário</p>
              </S.InfoBlock>
            </S.BlocksGrid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <p style={{ color: "#fff" }}>Lista de Posts</p>
              </Grid>
              <Grid item xl={2}>
                <VuiInput
                  placeholder="Buscar..."
                  icon={{ component: "search", direction: "left" }}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: {
                      maxWidth: "80px",
                    },
                    [breakpoints.only("sm")]: {
                      maxWidth: "80px",
                    },
                    backgroundColor: "info.main !important",
                  })}
                />
              </Grid>
            </Grid>
            <S.TableGrid>
              <Table
                columns={[
                  { name: "post", align: "left" },
                  { name: "tipo", align: "left" },
                  { name: "data", align: "left" },
                  { name: "impressões", align: "center" },
                  { name: "orgânico", align: "center" },
                  { name: "pago", align: "center" },
                ]}
                rows={[
                  {
                    post: (
                      <Grid container alignItems='center' gap='20px'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" width='40px' height='40px' />
                        <p>redesigned a site for lasik surgeon out of orlando, flori...</p>
                        <RiExternalLinkFill size='20px' />
                      </Grid>
                    ),
                    tipo: (
                      <p>-</p>
                    ),
                    data: (
                      <p>26/02/2023</p>
                    ),
                    impressões: (
                      <p>43535</p>
                    ),
                    orgânico: (
                      <p>54353</p>
                    ),
                    pago: (
                      <p>56454</p>
                    ),
                  },
                  {
                    post: (
                      <Grid container alignItems='center' gap='20px'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" width='40px' height='40px' />
                        <p>redesigned a site for lasik surgeon out of orlando, flori...</p>
                        <RiExternalLinkFill size='20px' />
                      </Grid>
                    ),
                    tipo: (
                      <p>-</p>
                    ),
                    data: (
                      <p>26/02/2023</p>
                    ),
                    impressões: (
                      <p>43535</p>
                    ),
                    orgânico: (
                      <p>54353</p>
                    ),
                    pago: (
                      <p>56454</p>
                    ),
                  },
                  {
                    post: (
                      <Grid container alignItems='center' gap='20px'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" width='40px' height='40px' />
                        <p>redesigned a site for lasik surgeon out of orlando, flori...</p>
                        <RiExternalLinkFill size='20px' />
                      </Grid>
                    ),
                    tipo: (
                      <p>-</p>
                    ),
                    data: (
                      <p>26/02/2023</p>
                    ),
                    impressões: (
                      <p>43535</p>
                    ),
                    orgânico: (
                      <p>54353</p>
                    ),
                    pago: (
                      <p>56454</p>
                    ),
                  },
                  {
                    post: (
                      <Grid container alignItems='center' gap='20px'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" width='40px' height='40px' />
                        <p>redesigned a site for lasik surgeon out of orlando, flori...</p>
                        <RiExternalLinkFill size='20px' />
                      </Grid>
                    ),
                    tipo: (
                      <p>-</p>
                    ),
                    data: (
                      <p>26/02/2023</p>
                    ),
                    impressões: (
                      <p>43535</p>
                    ),
                    orgânico: (
                      <p>54353</p>
                    ),
                    pago: (
                      <p>56454</p>
                    ),
                  },
                ]}
              />
            </S.TableGrid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Posts