import React from "react";
import { createRoot} from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./index.css"

import { VisionUIControllerProvider } from "context";
import { SearchBarProvider } from "context/SearchBarContext";
import { TokenProvider } from "context/TokenContext";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<BrowserRouter>
  <VisionUIControllerProvider>
    <TokenProvider>
      <SearchBarProvider>
        <App />
      </SearchBarProvider>
    </TokenProvider>
  </VisionUIControllerProvider>
</BrowserRouter>)

