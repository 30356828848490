import React from 'react'
import { Grid } from '@mui/material';
import * as S from "./style"
import img404 from 'assets/404.png'
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { useNavigate, useLocation } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("dashUser");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <S.MainGrid container item sm={12} md={12} lg={12} xl={12}>
      <Grid container>
        <S.TextGrid container item xl={6} alignContent='center' paddingLeft='30px'>
          <Grid container>
            <p className='title'>Ops, algo deu errado...</p>
          </Grid>
          {!state?.tokenExpired &&
            <>
              <Grid container>
                <p>Você não tem autorização para acessar esta página :(</p>
              </Grid>
              <ButtonCustom label='Retornar a página inicial' sx={{ fontSize: "16px", width: "500px", marginTop: "30px" }} onClick={() => navigate('/')} />
            </>
          }
          {state?.tokenExpired &&
            <>
              <Grid container>
                <p>Seu token de acesso expirou :(</p>
              </Grid>
              <ButtonCustom label='Retornar a página de login' sx={{ fontSize: "16px", width: "500px", marginTop: "30px" }} onClick={handleLogout} />
            </>
          }
        </S.TextGrid>
        <S.ImageGrid item xl={6}>
          <img src={img404} />
        </S.ImageGrid>
      </Grid>
    </S.MainGrid>
  )
}

export default Unauthorized