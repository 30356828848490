import { useState, useEffect } from 'react';
import flatpickr from "flatpickr";
import moment from 'moment';
import 'moment/locale/pt-br';
import "assets/theme/custom-flatpickr.css";
import { Portuguese } from "flatpickr/dist/l10n/pt"
import calendar from 'assets/icons/calendar.svg';
import * as S from './style';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

const Datepicker = ({ setDate, allButton, lifetime }) => {
  moment.locale("pt-br")

  const day = moment().date();
  const month = moment().month() + 1;
  const year = new moment().year();
  const defaultDates = [`${day}-${month > 1 ? month - 1 : 12}-${month > 1 ? year : year - 1}`, `${day}-${month}-${year}`];

  const [dates, setDates] = useState(defaultDates);
  const [lifetimeDate, setLifetimeDate] = useState(false);

  const handleLifetime = (fp) => {
    setLifetimeDate(true);
    setDate();
    fp?.clear();
  };

  useEffect(() => {
    if (setDate) setDate(dates);
  }, [dates]);

  useEffect(() => {
    if(lifetime) {
      handleLifetime();
    };
  }, [lifetime]);

  flatpickr(".date", {
    onChange: (selectedDates, dateStr) => {
      if (selectedDates.length === 2 && selectedDates !== dates) {
        setDates([selectedDates[0], `${moment(selectedDates[1]).format("DD-MM-YYYY")} 23:59:59`]);
        setLifetimeDate(false);
      }
    },
    plugins: allButton ? [
      ShortcutButtonsPlugin({
        button: {
          label: 'Todos',
        },
        onClick: (index, fp) => {
          handleLifetime(fp);
        }
      })
    ] : [],
    mode: "range",
    maxDate: new Date().fp_incr(365),
    "locale": Portuguese,
    /* dateFormat: "F,Y", */
  })

  const capitalize = (str) => {
    const newString = str.charAt(0).toUpperCase() + str.slice(1)
    return newString;
  };

  const validFirstDate = () => {
    const initialDate = moment(dates[0], "DD-M-YYYY").format("MMMM, YYYY");
    if (initialDate === 'Data inválida') {
      setDates([
        `${moment(`15-${month > 1 ? month - 1 : 12}-${month > 1 ? year : year - 1}`, "DD-MM-YYYY")
        .endOf("month").format("DD-M-YYYY")}`, defaultDates[1]])
      return
    };
    return initialDate
  };

  const firstDate = dates[0] ? validFirstDate() : "";
  const lastDate = dates[1] ? moment(dates[1], "DD-M-YYYY").format("MMMM, YYYY") : "";

  return (
    <S.DateContainer container display='flex' justifyContent='center' gap='10px' className='date'>
      <img src={calendar} />
      <p style={{ color: "#fff", opacity: "0.85" }}>
        {!lifetimeDate &&
          `${firstDate ? capitalize(firstDate) : capitalize(defaultDates[0])} - ${lastDate ? capitalize(lastDate) : capitalize(defaultDates[1])}`
        }
        {lifetimeDate && 'Todos'}
      </p>
    </S.DateContainer>
  )
}

export default Datepicker