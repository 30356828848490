import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { TokenContext } from "context/TokenContext";

export const ProtectedRoute = ({ children, allowedRoles }) => {
  const { decodedToken } = useContext(TokenContext);
  if (decodedToken) {
    const role = decodedToken?.type;
    const tokenExpirationTime = decodedToken?.exp;
    const actualTime = new Date().getTime() / 1000;
    const validToken = tokenExpirationTime > actualTime;
    if (allowedRoles?.includes(role) && validToken) {
      return children
    }
    else if (!validToken) {
      return <Navigate to="/unauthorized" replace state={{ tokenExpired: true }} />
    } else {
      return <Navigate to="/unauthorized" replace />
    };
  };
  return <Navigate to="/login" replace />;
};