import styled from "styled-components";
import VuiBox from "components/VuiBox";
import { Grid } from "@mui/material";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";

export const CardBox = styled(VuiBox)`
  @media (max-width: 992px) {
    text-wrap: nowrap;
  }
`

export const TableGrid = styled(Grid)`
  p {
    color: #fff;
  }
`

export const ValueGrid = styled(Grid)`
  display: flex;
  @media (min-width: 1440px) {
    gap: 20px;
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 75px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;
  font-size: 15px;
  color: #fff;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const MiscInfoGrid = styled(Grid)`
  padding-left: 15px;
`

export const ActiveButton = styled(ButtonCustom)`
  width: 95% !important;
  height: 100% !important;
  border-radius: 12px !important;
  padding: 6px 20px !important; 

  .value {
    font-size: 32px;
  }

  .subTitle {
    font-size: 20px;
    font-weight: 600;
  }

  .info {
    font-size: 14px;
    font-weight: 500;
  }
`

export const DisabledButton = styled(ButtonCustom)`
  width: 95% !important;
  height: 100% !important;
  border-radius: 12px !important;
  border: 0.1px solid #FFFFFF80 !important;
  .subTitle {
    font-size: 20px;
    font-weight: 600;
  }

  .info {
    font-size: 14px;
    font-weight: 500;
  }

  &.Mui-disabled {
    background: #101010;
    color: #343434;
  }
`

export const AlertGrid = styled(Grid)`
  p {
    color: #8F8F8F;
    font-weight: 400;
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
  }
`

export const ModalContainer = styled(Grid)`
  .modalTitle {
    font-size: 22px;
  }

  .cashoutValue {
    font-size: 40px;
    align-self: center;
  }

  .bold {
    font-weight: 700;
  }

  .discountWarning {
    color: #AE2C7A;
    font-size: 14px;
    text-align: end;
    font-weight: 700;
  }
`

export const DetailsMainGrid = styled(Grid)`
  .subtitleDetails {
    color: #fff;
    font-size: 20px;
  }

  .valueDetails {
    color: #fff;
    font-size: 20px;
  }

  .titleDetails {
    font-size: 22px;
  }

  .reminderDetails {
    font-weight: 600;
  }

  .wrap {
    text-wrap: wrap;
    overflow-wrap: break-word;
  }
`

export const PaginationGrid = styled(Grid)`
  margin-top: 20px;
  .MuiPaginationItem-root {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    cursor: pointer;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.4;
    text-align: center;
    text-transform: unset;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-size: 150%!important;
    background-position-x: 25%!important;
    transition: all 150ms ease-in;
    background: transparent;
    color: #a0aec0;
    border-color: #a0aec0;
    border-radius: 10rem;
    padding: 0.6875rem 0.6875rem 0.625rem;
    margin: 0 0.125rem;
    pointer-events: auto;
    font-weight: 400;
    font-size: 0.875rem;
    width: 2.875rem;
    min-width: 2.875rem;
    height: 2.875rem;
    min-height: 2.875rem;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .MuiPaginationItem-root.Mui-selected {
    background: linear-gradient(180deg, #FB8404, #FE1E14) !important;
    border: none;
    color: white;
  }
`