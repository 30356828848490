import { Card, Grid } from "@mui/material";
import { useContext } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ze from "assets/ze.png"
import { TokenContext } from "context/TokenContext";

const WelcomeMark = () => {
  const { decodedToken } = useContext(TokenContext);

  return (
    <Card sx={() => ({
      height: "100%",
      py: "32px",
/*       backgroundImage: `url(${ze})`,
      backgroundSize: "no-repeat",
      backgroundPosition: "50%" */
      padding: "32px 0 0 22px",
      minHeight: "300px"
    })}>
      <VuiBox height="100%" display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item xl={7}>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
            Bem vindo de volta,
          </VuiTypography>
          <VuiTypography color="white" variant="h3" sx={{ fontSize: "24px"}} fontWeight="bold" mb="18px">
            {decodedToken.name ?? ''}
          </VuiTypography>
        </Grid>
        <Grid item xl={5} sx={() => ({
          backgroundImage: `url(${ze})`,
          height: "100%",
          width: "150px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center"
        })} >
        </Grid>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
