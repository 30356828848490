import React from 'react'
import { DialogContent } from '@mui/material'
import * as S from './style'
import CloseIcon from '@mui/icons-material/Close';

const FilterModal = ({ children, contentProps, onClose, size, ...props }) => {
  return (
    <S.FilterModalBase
      maxWidth={size ? size : "md"}
      fullWidth
      onClose={onClose}
      {...props}
    >
      {onClose &&
        <S.Icon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#F2F2F2",
          }}
        >
          <CloseIcon />
        </S.Icon>
      }
      <DialogContent sx={contentProps}>
        {children}
      </DialogContent>
    </S.FilterModalBase>
  )
}

export default FilterModal