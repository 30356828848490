import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import Tabs from "@mui/material/Tabs";
import InputAdornment from '@mui/material/InputAdornment';
import { RiArrowLeftLine, RiCalendarEventFill } from 'react-icons/ri';
import * as S from '../style'
import moment from 'moment';
import 'moment/locale/pt-br';
import placeholderBanner from 'assets/placeholderBanner.svg'
import { useNavigate } from 'react-router-dom';

const InfluencerBriefingProjectDetails = ({ project }) => {

  moment.locale("pt-br")

  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState("community");

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar customTitle={project?.name} />
      <Grid container spacing={3}>
        <Grid item md={3} xl={3}>
          <S.ProjectCard sx={{ width: "100%" }}>
            <S.ReturnPageGrid container gap='10px' onClick={() => navigate('/projetos')}>
              <RiArrowLeftLine size='20px' style={{ alignSelf: "center" }} />
              <p>Voltar</p>
            </S.ReturnPageGrid>
            <S.TabsGrid>
              <Tabs
                orientation='vertical'
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                TabIndicatorProps={{ style: { borderRadius: "5px 5px 15px 5px", backgroundColor: "rgba(239, 241, 246, 0.10)", backdropFilter: "blur(15px)" } }}
              >
                <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Geral" value="community" />
              </Tabs>
            </S.TabsGrid>
          </S.ProjectCard>
        </Grid>
        <Grid item md={9} xl={9}>
          <S.ProjectCard>
            <Grid container justifyContent='space-between'>
              <S.FormTitleGrid flexDirection='column' item md={9}>
                <p>Informações gerais</p>
                <p className='subTitle'>Gerenciar informações básicas do projeto</p>
              </S.FormTitleGrid>
              <Grid display='flex' flexDirection='column' alignItems='flex-end' item md={3}>
                <S.BadgeGrid item>
                  <p>Briefing</p>
                </S.BadgeGrid>
              </Grid>
            </Grid>
            <S.FormGrid container flexDirection='column' gap='30px'>
              <Grid container>
                <img src={placeholderBanner} width='100%' height='280px' />
              </Grid>
              <Grid container flexDirection='column' gap='5px'>
                <p className='briefingTitle'>{project?.name}</p>
                <p className='briefingDescription'>{project?.description}</p>
              </Grid>
              <Grid container>
                <Grid item md={2.5} xl={2.5}>
                  <p>Influencers</p>
                </Grid>
                <Grid container item md={9} xl={9} gap='5px'>
                  {project?.influencers.map((influencer) => (
                    <S.InfluencerBadgeGrid className='active'>{influencer.name}</S.InfluencerBadgeGrid>
                  ))}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2.5} xl={2.5}>
                  <p>Datas</p>
                </Grid>
                <Grid item md={9} xl={9}>
                  <Grid container justifyContent='space-between'>
                    <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='startDate'>
                      <S.Input
                        hiddenLabel
                        type='text'
                        placeholder="Data Inicial"
                        fullWidth
                        value={project?.startDate ? moment(project?.startDate).format('DD/MM/YYYY HH:mm') : ''}
                        data-input
                        autoComplete='off'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <RiCalendarEventFill />
                            </InputAdornment>
                          ),
                          readOnly: true
                        }}
                      />
                    </S.DateInputGrid>
                    <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='endDate'>
                      <S.Input
                        hiddenLabel
                        type='text'
                        placeholder="Data Final"
                        fullWidth
                        value={project?.endDate ? moment(project?.endDate).format('DD/MM/YYYY HH:mm') : ''}
                        data-input
                        autoComplete='off'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <RiCalendarEventFill />
                            </InputAdornment>
                          ),
                          readOnly: true
                        }}
                      />
                    </S.DateInputGrid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2.5} xl={2.5}>
                  <p>Moeda Corrente</p>
                </Grid>
                <Grid item md={9} xl={9}>
                  <S.InputGrid>
                    <S.Input
                      placeholder='BRL'
                      name='currency'
                      id='currency'
                      value={project?.currency}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </S.InputGrid>
                </Grid>
              </Grid>
            </S.FormGrid>
          </S.ProjectCard>
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default InfluencerBriefingProjectDetails