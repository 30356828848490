let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

export const getCommercialBoard = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/${id}/cards`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getCommercialCard = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createCommercialCard = async (cardInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/card`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(cardInfo),
  });
  const data = await response.json();
  return data;
};

export const getCommercialTags = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/tags`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const deleteCommercialTag = async (tags) => {
  const token = localStorage.getItem("token");
  const tagsQuery = tags?.length > 0 ? `?${tags.map(tag => `tags[]=${tag?.id}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/tags${tagsQuery}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createCommercialTag = async (tagInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/project/tags`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(tagInfo),
  });
  const data = await response.json();
  return data;
};

export const getCommercialMembers = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/${id}/members`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createCommercialCardComment = async (commentData) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(commentData),
  });
  const data = await response.json();
  return data;
};

export const deleteCommercialCardComment = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/comment/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createCommercialCardAttachment = async (attachmentData) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/attachment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(attachmentData),
  });
  const data = await response.json();
  return data;
};

export const deleteCommercialCardAttachment = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/attachment/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getUserCommercial = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/related`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editProjectCommercial = async (id, commercialInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(commercialInfo),
  });
  const data = await response.json();
  return data;
}

export const getSellerBoard = async (username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/pipeline/cards/${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};