import styled from 'styled-components'
import { Button, Grid } from '@mui/material'

export const EditButtonGrid = styled(Grid)`
  position: absolute;
  right: 20px;
  top: 20px;
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const ProgressGrid = styled(Grid)`
  svg {
    &:hover {
      color: #E22B00;
    }
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 50px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #2D3748;
`