import { useState, useEffect } from "react";
import { Grid, Autocomplete, InputAdornment, FormHelperText } from "@mui/material";
import placeholder from "assets/placeholder.png";
import { Formik, Form, useFormik } from "formik";
import * as S from "./style";
import * as Yup from "yup";
import { RiCake2Fill, RiCameraFill } from "react-icons/ri";
import { useFilePicker } from "react-sage";
import showToast from "components/Toast/Toast";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import VuiAvatar from "components/VuiAvatar";
import CloseIcon from "@mui/icons-material/Close";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptionsInfluencer } from "utils/utils";
import { editUser } from "utils/requests/auth";
import moment from "moment";
import "moment/locale/pt-br";
import { editUserPhoto } from "utils/requests/auth";
import { getMarketingTags } from "utils/requests/auth";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const EditUser = ({ userInfo, refetch, closeModal }) => {
  moment.locale("pt-br");

  const [postAvatar, setPostAvatar] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [validateFields, setValidateFields] = useState(false);
  const [triggerFlatpickr, setTriggerFlatpickr] = useState(false);
  const [marketingTags, setMarketingTags] = useState([]);

  const fetchMarketingTags = async () => {
    const data = await getMarketingTags();
    if (!data.message) {
      setMarketingTags(data);
    }
  };

  useEffect(() => {
    setTriggerFlatpickr(true);
    fetchMarketingTags();
  }, []);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  const userTypes = [
    {
      name: "Administrador",
      id: "ADMINISTRATOR",
    },
    {
      name: "Comercial",
      id: "COMERCIAL",
    },
    {
      name: "Head Comercial",
      id: "H_COMERCIAL",
    },
    {
      name: "Marketing",
      id: "MARKETING",
    },
    {
      name: "Head de Marketing",
      id: "H_MARKETING",
    },
  ];

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      }
    };
    getDataUrls();
  }, [imageInput.files]);

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY").valueOf());
  };

  const userTeamTranslation = {
    DESIGNER: "Designer",
    FILMMAKER: "Videomaker",
    "SOCIAL MEDIA": "Social Media",
    PRODUCER: "Produção",
  };

  const formik = useFormik({
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      name: userInfo?.name ?? "",
      username: userInfo?.username ?? "",
      birthDate: userInfo?.birthDate ? moment(userInfo?.birthDate).format("DD/MM/YYYY") : "",
      bio: userInfo?.bio ?? "",
      type: userInfo?.type ? userTypes.find((type) => type.id === userInfo?.type) : null,
      userTags: userInfo?.userTags?.length > 0 ? userInfo?.userTags?.map(tag => { return { name: tag, translatedName: userTeamTranslation[tag] } }) : [],
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4).required("Nome é um campo obrigatório"),
      username: Yup.string().min(4).required("Username é um campo obrigatório"),
      birthDate: Yup.string(),
      bio: Yup.string(),
      type: Yup.object().required("Tipo é obrigatório"),
      userTags: Yup.array().optional(),
    }),
    onSubmit: async (values) => {
      const startUserValues = formik.initialValues;

      const editUserValues = {
        name: values.name !== startUserValues.name ? values.name : "",
        username: values.username !== startUserValues.username ? values.username : "",
        birthDateMs:
          values.birthDate !== startUserValues.birthDate ? unixDate(values.birthDate) : "",
        bio: values.bio !== startUserValues.bio ? values.bio : "",
        type: values?.type?.id !== startUserValues?.type?.id ? values.type : "",
        userTags:
          values.userTags !== startUserValues.userTags ? values.userTags.map((tag) => tag.id) : "",
      };

      Object.keys(editUserValues).forEach((key) => {
        if (editUserValues[key] === "") {
          delete editUserValues[key];
        }
      });

      Object.keys(editUserValues).forEach((key) => {
        if (Array.isArray(editUserValues[key]) && editUserValues[key].length === 0) {
          delete editUserValues[key];
        }
      });

      const avatarChange = {
        file: postAvatar ?? "",
        username: userInfo?.username,
      };

      if (Object.keys(editUserValues).length === 0 && !postAvatar) {
        showToast("Sem dados alterados, campos sem alterações", "warning");
      }
      if (Object.keys(editUserValues).length === 0 && postAvatar) {
        await editUserPhoto(avatarChange).then((res) => {
          if (!res.message) {
            showToast("Usuário editado com sucesso!", "success");
            refetch();
            closeModal();
          }
          if (res.message) {
            showToast("Falha ao alterar dados", "error");
          }
        });
      }
      if (Object.keys(editUserValues).length > 0 && !postAvatar) {
        await editUser(userInfo?.username, editUserValues).then((res) => {
          if (!res?.message) {
            showToast("Usuário editado com sucesso!", "success");
            refetch();
            closeModal();
          }
          if (res?.message) {
            showToast(res.message, "error");
          }
        });
      }
      if (Object.keys(editUserValues).length > 0 && postAvatar) {
        await editUser(userInfo?.username, editUserValues).then((res) => {
          if (!res?.message) {
            editUserPhoto(avatarChange).then((res) => {
              if (!res.message) {
                showToast("Usuário editado com sucesso!", "success");
                refetch();
                closeModal();
              }
              if (res.message) {
                showToast("Falha ao alterar dados, contate o suporte", "error");
              }
            });
          }
          if (res?.message) {
            showToast(res.message, "error");
          }
        });
      }
    },
  });

  flatpickr(
    "#birthDate",
    flatpickrOptionsInfluencer("birthDate", formik.setFieldValue, formik.setFieldTouched)
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getPhoto = () => {
    if (previewUrl) {
      return previewUrl;
    }
    if (userInfo?.avatar && !postAvatar) {
      return userInfo?.avatar;
    }
    if (!userInfo?.avatar) {
      return placeholder;
    }
  };

  return (
    <Grid container flexDirection="column">
      <p>Editar Perfil</p>
      <Grid container justifyContent="center">
        <S.InfluencerPhoto>
          <div>
            <Avatar img={getPhoto()} />
          </div>
          <S.IndicatorGrid onClick={imageInput.onClick}>
            <S.BranchIndicator>
              <RiCameraFill size="20px" color="#fff" style={{ alignSelf: "center" }} />
            </S.BranchIndicator>
            <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
          </S.IndicatorGrid>
        </S.InfluencerPhoto>
      </Grid>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.handleSubmit}
      >
        <Form>
          <Grid container flexDirection="column" gap="20px" mt="15px">
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="name"
                  placeholder="Nome"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  error={formik.errors.name}
                  helperText={formik.errors.name ? "Nome inválido" : false}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="username"
                  placeholder="Username"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username || ""}
                  error={formik.errors.username}
                  helperText={formik.errors.username ? "Username inválido" : false}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="type"
                  disablePortal
                  disableClearable
                  readOnly
                  value={formik.values?.type ? formik.values?.type : null}
                  onChange={(e, value) => {
                    formik.setFieldValue("type", value);
                  }}
                  options={userTypes}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <S.Input {...params} error={formik.errors.type} placeholder="Tipo" />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                {Boolean(formik.errors.type) && (
                  <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                    Tipo é um campo obrigatório
                  </FormHelperText>
                )}
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id="birthDate">
                <S.Input
                  name="birthDate"
                  hiddenLabel
                  type="text"
                  placeholder="Data de Nascimento"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={formik.values.birthDate}
                  error={Boolean(formik.errors.birthDate)}
                  helperText={formik.errors.birthDate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCake2Fill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => formik.setFieldValue("birthDate", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              {formik?.values?.type?.id === "MARKETING" && (
                <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                  <Autocomplete
                    disablePortal
                    disableCloseOnSelect
                    multiple
                    name="userTags"
                    id="userTags"
                    value={formik.values.userTags ?? []}
                    onChange={(e, value) => {
                      formik.setFieldValue("userTags", value);
                    }}
                    options={marketingTags ?? []}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <S.Input {...params} placeholder="Time" />}
                    getOptionLabel={(option) => option.translatedName}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.translatedName}
                      </li>
                    )}
                  />
                </S.InputGrid>
              )}
              <S.InputGrid
                item
                xs={12}
                md={formik?.values?.type?.id === "MARKETING" ? 5.85 : 12}
                xl={formik?.values?.type?.id === "MARKETING" ? 5.85 : 12}
              >
                <S.Input
                  hiddenLabel
                  name="bio"
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bio || ""}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" mt="15px">
            <ButtonCustom
              label="Salvar"
              type="submit"
              sx={{
                width: "150px",
                height: "40px !important",
                fontSize: "18px",
                fontWeight: "500",
              }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
};

export default EditUser;
