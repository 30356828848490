import React from 'react'
import * as S from './style'
import VuiTypography from 'components/VuiTypography'
import VuiBox from 'components/VuiBox'
import { IoArrowUp } from "react-icons/io5";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import placeholderImg from "assets/placeholder.png"
import { Grid } from '@mui/material';
import { abbreviateNumbers } from 'utils/utils';

const ProjectsRanking = ({ projectList, xs, lg, xl }) => {
  return (
    <S.RankingCard item xs={xs} lg={lg} xl={xl} sx={{ height: "auto" }}>
      <Card sx={{ height: "100%", padding: "22px 32px" }}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Ranking de Projetos
        </VuiTypography>
        {projectList?.length === 0 &&
          <p>Sem informações</p>
        }
        {projectList?.length > 0 &&
          <S.LegendGrid container justifyContent='space-between'>
            <p>NOME</p>
            <p>SALDO</p>
          </S.LegendGrid>
        }
        {projectList?.map((project, index) => (
          <VuiBox key={index}>
            <VuiBox display="flex" alignItems="center" mb="10px" mt="20px">
              <S.RankingGrid container justifyContent='space-between'>
                <Grid display='flex' gap='20px'>
                  <S.RankingPhoto>
                    <img src={project.photo ? project.photo : placeholderImg} width="50px" height="45px" draggable='false' />
                  </S.RankingPhoto>
                  <S.NumberGrid flexDirection='column'>
                    <p>{project?.title?.length > 25 ? project?.title.substring(0, 25) + "..." : project?.title}</p>
                    <Grid container gap='5px'>
                      <p className="subTitle">{project?.type}</p>
                    </Grid>
                  </S.NumberGrid>
                </Grid>
                <Grid display='flex' flexDirection='column' justifyContent='center'>
                  <S.BadgeGrid container>
                    <p>R$ {abbreviateNumbers((project?.value / 100) ?? 0, 2)}</p>
                  </S.BadgeGrid>
                </Grid>
              </S.RankingGrid>
            </VuiBox>
          </VuiBox>
        ))
        }
      </Card>
    </S.RankingCard>
  )
}

ProjectsRanking.defaultProps = {
  projectList: [],
  xs: 12,
  lg: 6,
  xl: 5
};

ProjectsRanking.propTypes = {
  projectList: PropTypes.array,
  xs: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default ProjectsRanking