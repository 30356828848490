import React from 'react'
import { DialogContent } from '@mui/material'
import * as S from './style'
import CloseIcon from '@mui/icons-material/Close';
import { RiDeleteBin6Line } from 'react-icons/ri'

const Modal = ({ children, contentProps, onClose, size, deleteFunc, ...props }) => {
  return (
    <S.ModalBase
      maxWidth={size ? size : "md"}
      fullWidth
      onClose={onClose}
      {...props}
    >
      {onClose &&
        <>
          {deleteFunc &&
            <S.Icon
              aria-label="close"
              onClick={deleteFunc}
              sx={{
                position: 'absolute',
                right: 48,
                top: 8,
                color: "#F2F2F2",
              }}
            >
              <RiDeleteBin6Line />
            </S.Icon>
          }
          <S.Icon
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "#F2F2F2",
            }}
          >
            <CloseIcon />
          </S.Icon>
        </>
      }
      <DialogContent sx={contentProps}>
        {children}
      </DialogContent>
    </S.ModalBase>
  )
}

export default Modal