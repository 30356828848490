import React, { useEffect } from 'react'
import * as S from './style'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import { Grid } from '@mui/material';
import Datepicker from 'components/Datepicker/Datepicker';
import MonthDatepicker from 'components/Datepicker/MonthDatepicker';
import filter from 'assets/icons/filter.svg'
import { RiWalletFill, RiExchangeDollarFill, RiHandCoinFill, RiArrowRightUpLine, RiArrowUpCircleLine, RiArrowDownCircleLine } from 'react-icons/ri'
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import Table from "defaultComponents/Tables/Table";
import { Link } from 'react-router-dom';
import { getFinancialHomeData, getFinancialGraph } from 'utils/requests/financial';
import { getFinancialData } from 'utils/requests/financial';
import moment from 'moment';
import 'moment/locale/pt-br';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BsQuestionLg } from "react-icons/bs";
import LightTooltip from 'components/Tooltip/LightTooltip';

const Finance = () => {
  const mobile = useMediaQuery("(max-width: 992px)");

  moment.locale("pt-br")

  const formatNumbers = (val) => {
    return Number((val).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  };

  const [filterDate, setFilterDate] = React.useState();
  const [chartData, setChartData] = React.useState([
    /*     {
          data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
          name: "Entrada",
        },
        {
          data: [200, 230, 300, 350, 370, 420, 550, 350, 400, 500, 330, 550],
          name: "Saída",
        }, */
  ]);
  const [chartOptions, setChartOptions] = React.useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return "R$ " + formatNumbers(val);
        },
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#65EFAD", "#D7007B"],
    },
    colors: ["#65EFAD", "#D7007B"],
  });
  const [tableData, setTableData] = React.useState([]);
  const [homeData, setHomeData] = React.useState({});
  const [graphData, setGraphData] = React.useState({});

  const fetchTableData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const data = await getFinancialData(0, 5, { type: ["ENTRADA", "SAIDA", "TRANSFERENCIA"] }, startDate, endDate);
    if (!data.statusCode) {
      setTableData(data);
    };
  };

  const fetchHomeData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    if (startDate && endDate) {
      const data = await getFinancialHomeData(startDate, endDate);
      if (!data.statusCode) {
        setHomeData(data);
      }
    };
  };

  const fetchGraphData = async () => {
    const data = await getFinancialGraph();
    if (!data.statusCode) {
      setGraphData(data);
      setChartData(data?.graphData?.info);
      setChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.graphData?.months
        }
      }));
    };
  };

  React.useEffect(() => {
    fetchGraphData();
  }, []);

  React.useEffect(() => {
    fetchTableData();
    fetchHomeData();
  }, [filterDate]);

  const QuestionTooltip = ({ message }) => (
    <LightTooltip title={message}>
      <div style={{ width: 'fit-content', textAlign: 'right', fontSize: '16px' }}>
        <BsQuestionLg style={{ backgroundColor: '#1e1e1e', borderRadius: '5px' }} />
      </div>
    </LightTooltip>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <S.MainContainer container spacing='18px'>
        <Grid container item sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
          <S.DateGrid container item md={7.5} lg={7.5} xl={6.5}>
            <S.HiddenGrid>
              <MonthDatepicker setDate={setFilterDate} allButton />
            </S.HiddenGrid>
            <p style={{ color: "#fff" }}>Dashboard Financeira</p>
          </S.DateGrid>
          <Grid container item md={4.5} lg={4.5} xl={5.5} justifyContent='flex-end'>
            {/*             <S.EditButton>
              <img src={filter} />
            </S.EditButton> */}
          </Grid>
        </Grid>
        <Grid container item sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
          <Grid item>
            <Card>
              <S.BlockGrid container justifyContent='space-between'>
                <Grid>
                  <Grid container gap='10px'>
                    <p className='title'>Faturamento</p>
                    <QuestionTooltip message="Todo valor negociado no mês vigente, mesmo não recebido." />
                  </Grid>
                  <Grid container>
                    <p className='value'>R$ {homeData?.totalData && homeData?.totalData?.inbound.value !== 0 ? Number((homeData?.totalData?.inbound?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</p>
                    <p className={`percentage ${homeData?.totalData?.inbound?.up ? "positive" : "negative"}`}>{`${homeData?.totalData?.inbound?.up ? "+" : ""}${homeData?.totalData?.inbound?.percentual}%`}</p>
                  </Grid>
                </Grid>
                <Grid>
                  <S.IconBlock>
                    <RiWalletFill color='#fff' size='30px' />
                  </S.IconBlock>
                </Grid>
              </S.BlockGrid>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <S.BlockGrid container justifyContent='space-between'>
                <Grid>
                  <Grid container gap='10px'>
                    <p className='title'>Total de Entrada</p>
                    <QuestionTooltip message="Todo valor dado como recebido no mês." />
                  </Grid>
                  <Grid container>
                    <p className='value'>R$ {homeData?.totalData && homeData?.totalData?.netValue.value !== 0 ? Number((homeData?.totalData?.netValue.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</p>
                    <p className={`percentage ${homeData?.totalData?.netValue?.up ? "positive" : "negative"}`}>{`${homeData?.totalData?.netValue?.up ? "+" : ""}${homeData?.totalData?.netValue?.percentual}%`}</p>
                  </Grid>
                </Grid>
                <Grid>
                  <S.IconBlock>
                    <RiHandCoinFill color='#fff' size='30px' />
                  </S.IconBlock>
                </Grid>
              </S.BlockGrid>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <S.BlockGrid container justifyContent='space-between'>
                <Grid>
                  <Grid container gap='10px'>
                    <p className='title'>Total Gasto (Saídas)</p>
                    <QuestionTooltip message="Saídas e transferências do mês." />
                  </Grid>
                  <Grid container>
                    <p className='value'>R$ {homeData?.totalData && homeData?.totalData?.outbound?.value !== 0 ? Number((homeData?.totalData?.outbound?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</p>
                    <p className={`percentage ${homeData?.totalData?.outbound?.up ? "negative" : "positive"}`}>{`${homeData?.totalData?.outbound?.up ? "+" : ""}${homeData?.totalData?.outbound?.percentual}%`}</p>
                  </Grid>
                </Grid>
                <Grid>
                  <S.IconBlock>
                    <RiExchangeDollarFill color='#fff' size='30px' />
                  </S.IconBlock>
                </Grid>
              </S.BlockGrid>
            </Card>
          </Grid>
        </Grid>
        <S.CardGrid item sm={12} md={12} lg={12} xl={12} sx={{ minHeight: "400px" }}>
          <Card>
            <Grid>
              <p className='cardTitle'>Fluxo de Caixa (Entrada e Saída)</p>
              <Grid container gap='2px'>
                <p className={`percentage ${graphData?.lastMonthChanges?.up ? "positive" : "negative"}`}>{`${graphData?.lastMonthChanges?.up ? "+" : "-"}${graphData?.lastMonthChanges?.percentual}%`}</p>
                <p className='subtitle'>na última semana</p>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12} sx={{ minHeight: "310px" }}>
              <LineChart key={chartData} lineChartData={chartData} lineChartOptions={chartOptions} />
            </Grid>
          </Card>
        </S.CardGrid>
        <S.CardGrid item sm={12} md={12} lg={12} xl={12}>
          <Card>
            <Grid item sm={12} md={12} lg={12} xl={12} alignItems='stretch'>
              <Grid container justifyContent='space-between'>
                <Grid>
                  <p className='cardTitle'>Histórico</p>
                </Grid>
                <Grid>
                  <Link to={'/financeiro/contas'}>
                    <S.HistoricButton>
                      <RiArrowRightUpLine color='#fff' size='24px' />
                    </S.HistoricButton>
                  </Link>
                </Grid>
              </Grid>
              <S.TableGrid>
                <Table
                  columns={[
                    { name: "descrição", align: "left" },
                    { name: "categoria", align: "left" },
                    { name: "sub-categoria", align: "left" },
                    { name: "valor", align: "left" },
                    { name: "data", align: "left" },
                    { name: "status", align: "left" },
                    { name: "observações", align: "center" },
                  ]}
                  rows={
                    tableData[0] && tableData[0].map((entry, index) => {
                      return {
                        descrição: (
                          <Grid container gap='5px' key={index}>
                            {entry?.type === 'SAIDA'/*  && entry.category !== 'transferencia' */ &&
                              <RiArrowUpCircleLine className='negative' size='20px' style={{ alignSelf: "center" }} />
                            }
                            {
                              entry?.type === 'TRANSFERENCIA' &&
                              <RiArrowUpCircleLine className='transfer' size='20px' style={{ alignSelf: "center" }} />
                            }
                            {
                              entry?.type === 'ENTRADA' &&
                              <RiArrowDownCircleLine className='positive' size='20px' style={{ alignSelf: "center" }} />
                            }
                            <p>{entry?.description?.length > (mobile ? 5 : 12) ? entry?.description?.substring(0, (mobile ? 5 : 12)) + "..." : entry?.description}</p>
                          </Grid>
                        ),
                        categoria: (
                          <p>{entry?.category ? entry?.category?.name : ""}</p>
                        ),
                        "sub-categoria": (
                          <p>{entry?.subCategories && entry?.subCategories.map((sub) => (sub.name)).join(", ")}</p>
                        ),
                        valor: (
                          <Grid container>
                            <p className={entry?.type === 'TRANSFERENCIA' ? 'transfer' : (entry?.type === 'ENTRADA' ? 'positive' : 'negative')}>R$ {Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                          </Grid>
                        ),
                        data: (
                          <p>{moment(entry?.dateInvoiced).format('L')}</p>
                        ),
                        status: (
                          <p>{entry?.businessStatus ?? ""}</p>
                        ),
                        observações: (
                          <Grid container flexDirection='column'>
                            <Grid container justifyContent='space-between'>
                              <Grid item xl={12}>
                                <p>{entry?.miscInfo ? (entry?.miscInfo?.length > (mobile ? 25 : 50) ? entry?.miscInfo?.substring(0, (mobile ? 25 : 50)) + "..." : entry?.miscInfo) : "-"}</p>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      }
                    })}
                />
              </S.TableGrid>
            </Grid>
          </Card>
        </S.CardGrid>
      </S.MainContainer>
    </DashboardLayout>
  )
}

export default Finance