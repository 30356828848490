import styled from 'styled-components'
import { Grid } from '@mui/material'

export const DateContainer = styled(Grid)`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: #060201;
  padding: 5px 20px;
  width: fit-content !important;

  p {
    color: rgb(255, 255, 255);
    opacity: 0.85;
    font-size: 16px;
    font-weight: 400;
  }
`