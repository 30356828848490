import React from 'react'
import * as S from '../style'
import { Grid } from '@mui/material';
import MixedChart from "defaultComponents/Charts/MixedCharts/MixedChart";
import BarChart from "defaultComponents/Charts/BarCharts/BarChart";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import { IoArrowUp } from "react-icons/io5";
import Card from "@mui/material/Card";

const Community = () => {
/*   const [chartData, setChartData] = React.useState({
    series: [{
      name: 'Website Blog',
      type: 'column',
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    }, {
      name: 'Social Media',
      type: 'line',
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }]
  }
  ); */

/*   const [chartOptions, setChartOptions] = React.useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "#A0AEC0",
          fontSize: "10px",
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#A0AEC0",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "#56577A",
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "solid",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0.5,
        stops: [],
      },
      colors: ["#01B574", "#0075ff"],
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: "8px",
      },
    },
    colors: ["#01B574", "#0075ff"],
  }); */

  return (
    <Grid container spacing='18px'>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid display='flex' gap='30px' flexDirection='column'>
            <Grid>
              <Grid container justifyContent='space-between'>
                <Grid item xl={6}>
                  <p style={{ color: "white" }}>Growth</p>
                </Grid>
                <Grid container item xl={6} justifyContent='flex-end' gap='10px'>
                  <S.InfoBlock item xs={2} xl={3} sx={{ backgroundColor: "#83848A" }}>
                    <Grid container justifyContent='center'>
                      <p>23.000K</p>
                      <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                    </Grid>
                    <p className='subTitle'>Seguidores</p>
                  </S.InfoBlock>
                  <S.InfoBlock item xs={2} xl={2} sx={{ backgroundColor: "#FC6009" }}>
                    <Grid container justifyContent='center'>
                      <p>50</p>
                      <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
                    </Grid>
                    <p className='subTitle'>Posts</p>
                  </S.InfoBlock>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ minHeight: "300px" }}>
              <MixedChart /* lineChartData={chartData} lineChartOptions={chartOptions} */ />
            </Grid>
            <Grid container item xl={12} justifyContent='space-evenly'>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>43.000K</p>
                <p className='subTitle'>Seguidores</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>12</p>
                <p className='subTitle'>Seguidores diários</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>23</p>
                <p className='subTitle'>Seguidores por Post</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>467</p>
                <p className='subTitle'>Seguindo</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>1</p>
                <p className='subTitle'>Posts Diários</p>
              </S.InfoBlock>
              <S.InfoBlock item xs={2} xl={1.5}>
                <p>7</p>
                <p className='subTitle'>Posts por semana</p>
              </S.InfoBlock>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <Grid container justifyContent='space-between'>
            <p style={{ color: "#fff" }}>Novos Seguidores</p>
            <S.InfoBlock item xs={2} xl={2}>
              <Grid container justifyContent='center'>
                <p>43.000K</p>
                <IoArrowUp color="#65EFAD" size="16px" style={{ alignSelf: "center" }} />
              </Grid>
              <p className='subTitle'>Novos Seguidores</p>
            </S.InfoBlock>
          </Grid>
          <Grid sx={{ minHeight: "300px" }}>
            <BarChart
              barChartData={barChartDataDashboard}
              barChartOptions={barChartOptionsDashboard}
            />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Community