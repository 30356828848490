import { useState, useContext } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import * as S from "./style";
import arrowRightUp from "assets/icons/arrow-right-up.svg";
import { capitalize } from "utils/utils";
import { RiEyeFill } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";
import { Grid } from "@mui/material";
import { IoEllipsisVertical } from "react-icons/io5";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Modal from "components/Modal/Modal";
import showToast from "components/Toast/Toast";
import moment from "moment";
import "moment/locale/pt-br";
import { editBriefingProject } from "utils/requests/project";
import { TokenContext } from "context/TokenContext";

const ProjectsTable = ({ projects, showFinished, routing, refetch }) => {
  moment.locale("pt-br");

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [openMenu, setOpenMenu] = useState([]);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openPaymentApprovalModal, setOpenPaymentApprovalModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState();

  const avatars = (members) => {
    return members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="sm"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));
  };

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handlePaymentApprovalModal = (project) => {
    setSelectedProject(project);
    setOpenPaymentApprovalModal(true);
  };

  const cancelPaymentApproval = () => {
    setOpenPaymentApprovalModal(false);
    setSelectedProject();
  };

  const handleDetailsModal = (project) => {
    setOpenDetailsModal(true);
    setSelectedProject(project);
  };

  const closeDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedProject();
  };

  const handlePaymentApproval = async () => {
    await editBriefingProject(selectedProject?.id, { budget: { status: "PAGO" } }).then((res) => {
      if (!res.message) {
        showToast("Pagamento aprovado com sucesso", "success");
        refetch();
        setOpenPaymentApprovalModal(false);
        return;
      }
      showToast("Falha ao aprovar pagamento", "error");
    });
  };

  const statusTranslation = {
    LEADS: "Leads 🔥",
    RELATIONSHIP: "Relacionamento 🎯",
    FOLLOW_UP: "FUP - Leads 🆙",
    NEGOTIATION: "Em Negociação 💰",
    CLOSED_CONTRACT: "Contrato Fechado 🤝",
    REJECTED: "Rejeitado",
    ONGOING: "Em andamento",
    ENDED: "Finalizado",
  };

  return (
    <Card
      sx={{
        height: "100% !important",
        paddingBottom: "40px",
      }}
    >
      <VuiBox
        sx={{
          paddingBottom: "10px",
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
        onClick={() =>
          openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null
        }
      >
        <Table
          columns={[
            { name: "empresa", align: "left" },
            { name: "influencers", align: "left" },
            { name: "vendedor", align: "left" },
            { name: "projeto", align: "center" },
            { name: "budget", align: "center" },
            { name: "realização", align: "center" },
          ]}
          rows={
            projects &&
            projects?.map((project, index) => {
              return {
                empresa: (
                  <VuiBox display="flex" alignItems="center" key={project?.id}>
                    <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                      {project?.leadsRelated?.businessName ?? ""}
                    </VuiTypography>
                  </VuiBox>
                ),
                influencers: (
                  <VuiBox display="flex" py={1}>
                    {avatars(
                      project.influencers.map((influencer) => [influencer.avatar, influencer.name])
                    )}
                  </VuiBox>
                ),
                vendedor: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.seller?.name ?? ""}
                  </VuiTypography>
                ),
                projeto: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    {project?.title ?? ""}
                  </VuiTypography>
                ),
                budget: (
                  <VuiTypography variant="button" color="white" fontWeight="bold">
                    R$
                    {project?.budget?.value > 0
                      ? Number((project?.budget?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </VuiTypography>
                ),
                realização: (
                  <S.ProgressGrid container justifyContent="space-around">
                    <Grid container item xs={8} xl={10} justifyContent="center">
                      <VuiBox width="8rem" textAlign="center" sx={{ width: "80%" }}>
                        <>
                          <VuiTypography color="white" variant="button" fontWeight="bold">
                            {project?.progress ? Math.round(Number(project?.progress)) : 0}%
                          </VuiTypography>
                          <VuiProgress
                            value={project?.progress ?? 0}
                            color="viuzz"
                            label={false}
                            sx={{ background: "rgba(96, 96, 96, 0.5)" }}
                          />
                        </>
                      </VuiBox>
                    </Grid>
                    {/*                     <Grid container item xs={2} xl={1}>
                      <RiEyeFill size='20px' style={{ cursor: "pointer" }} onClick={() => navigate(`${navigateObject[role]}${project?.id}`, { state: { type: project?.type } })} />
                    </Grid> */}
                    <Grid container item xs={2} xl={1} onClick={() => toggleOpenMenu(index)}>
                      <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                    </Grid>
                    {openMenu[index] && (
                      <S.OptionsContainer container justifyContent="flex-end">
                        <S.OptionsGrid display="flex" flexDirection="column">
                          <p onClick={() => handleDetailsModal(project)}>Ver Detalhes</p>
                          {(project?.status === "CLOSED_CONTRACT" ||
                            project?.status === "ONGOING") &&
                            project?.budget?.status === "PENDENTE" && (
                              <>
                                {(role === "ADMINISTRATOR" ||
                                  role === "H_COMERCIAL" ||
                                  role === "COMERCIAL") && (
                                  <>
                                    <S.Divider />
                                    <p onClick={() => handlePaymentApprovalModal(project)}>
                                      Aprovar Pagamento
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                        </S.OptionsGrid>
                      </S.OptionsContainer>
                    )}
                  </S.ProgressGrid>
                ),
              };
            })
          }
        />
      </VuiBox>
      <Modal size="md" open={openDetailsModal} onClose={() => closeDetailsModal()}>
        <Grid container flexDirection="column" gap="5px">
          <p style={{ fontSize: "22px" }}>{selectedProject?.title}</p>
          <p style={{ fontSize: "18px" }}>Status: {statusTranslation[selectedProject?.status]}</p>
        </Grid>
        <Grid container flexDirection="column" gap="20px" mt="10px">
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Empresa:</p>
              <p>{selectedProject?.leadsRelated?.businessName ?? ""}</p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Valor do Negócio:</p>
              <p>
                R${" "}
                {selectedProject?.budget?.value > 0
                  ? Number((selectedProject?.budget?.value / 100).toFixed(2)).toLocaleString(
                      "pt-BR",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )
                  : 0}
              </p>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Fechamento:</p>
              <p>
                {selectedProject?.budget?.dealCloseDate
                  ? moment(selectedProject?.budget?.dealCloseDate).format("DD/MM/YYYY HH:mm")
                  : ""}
              </p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Pagamento:</p>
              <p>
                {selectedProject?.budget?.paymentDate
                  ? moment(selectedProject?.budget?.paymentDate).format("DD/MM/YYYY HH:mm")
                  : ""}
              </p>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Influencers:</p>
              <p>
                {selectedProject?.influencers &&
                  selectedProject?.influencers?.map((influencer) => influencer.name).join(", ")}
              </p>
            </Grid>
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Status de Pagamento:</p>
              <p>
                {selectedProject?.budget?.status
                  ? capitalize(selectedProject?.budget?.status?.toLowerCase())
                  : ""}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <p>Descrição:</p>
            <p>{selectedProject?.description}</p>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5.85} xl={5.85}>
              <p>Data de Criação:</p>
              <p>
                {selectedProject?.createdAt
                  ? moment(selectedProject?.createdAt).format("DD/MM/YYYY HH:mm")
                  : ""}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="sm" open={openPaymentApprovalModal} onClose={() => cancelPaymentApproval()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Aprovar pagamento</p>
          <p style={{ fontSize: "18px" }}>
            Deseja aprovar o pagamento do projeto <strong>{selectedProject?.title}</strong>?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelPaymentApproval()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              />
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label="Confirmar" onClick={() => handlePaymentApproval()} />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Card>
  );
};

export default ProjectsTable;
