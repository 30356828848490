import styled from 'styled-components'
import { Grid } from '@mui/material'

export const TextGrid = styled(Grid)`
  padding-top: 30px;
  padding-bottom: 80px;

  .username {
    font-weight: 700;
    font-size: 26px;
  }

  .description {
    font-weight: 100;
  }

  .terms {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const MainGrid = styled(Grid)`
  justify-content: flex-end;

  a {
    color: white;
  }

  @media(max-width: 992px) {
    justify-content: center !important;
  }
`