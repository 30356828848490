import { Grid } from '@mui/material'
import moment from 'moment';
import 'moment/locale/pt-br';

const InboundDetails = ({ entry }) => {
  moment.locale("pt-br");

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Valor de Faturamento (R$):</p>
          <p>{entry?.value ? Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Valor de Entrada (R$):</p>
          <p>{entry?.netValue?.value ? Number((entry?.netValue?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Data de fechamento:</p>
          <p>{entry?.dateInvoiced ? moment(entry?.dateInvoiced).format('L') : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Data de pagamento:</p>
          <p>{entry?.datePaid ? moment(entry?.datePaid).format('L') : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Descrição:</p>
          <p>{entry?.description ? entry?.description : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85} sx={{ overflowWrap: 'break-word' }}>
          <p className='subTitle'>Conta Bancária:</p>
          <p>{entry?.bankAccount ? entry?.bankAccount : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Categoria:</p>
          <p>{entry?.category ? entry?.category.name : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Sub-Categoria:</p>
          <p>{entry?.subCategories && entry?.subCategories.map((sub) => (sub.name)).join(", ")}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Empresa:</p>
          {entry?.leadRelated ? entry?.leadRelated?.businessName : ''}
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Vendedor:</p>
          {entry?.seller ? entry?.seller?.name : ''}
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Status:</p>
          <p>{entry?.businessStatus ? entry?.businessStatus : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Emissão de nota fiscal:</p>
          <p>{entry?.invoice_bill ? "Sim" : 'Não'}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={10} xl={10}>
          <p className='subTitle'>Observações:</p>
          <p className='wrap'>{entry?.miscInfo ? entry?.miscInfo : ''}</p>
        </Grid>
      </Grid>
    </>
  )
}

export default InboundDetails