import React from "react";
import { Grid } from "@mui/material";
import * as S from "../style";
import moment from "moment";
import "moment/locale/pt-br";

const PublicationDetails = ({ event }) => {
  moment.locale("pt-br");

  return (
    <>
      <p>Detalhes da publi</p>
      <S.EventDetailsGrid container flexDirection="column" gap="20px" paddingY="15px">
        <Grid container justifyContent="space-between">
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Título:</p>
              <p className="eventText">{event?.title}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Redes Sociais:</p>
              <p className="eventText">
                {event?.extendedProps?.socialMedia &&
                  event?.extendedProps?.socialMedia.map((socialMedia) => socialMedia).join(", ")}
              </p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent="space-between">
          <S.InputGrid item xs={12} md={5.5} xl={5.5} id="start">
            <Grid container flexDirection="column">
              <p>Data de postagem:</p>
              <p className="eventText">{moment(event?.start).format("DD/MM/YYYY HH:mm")}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5} id="end">
            <Grid container flexDirection="column">
              <p>Formato:</p>
              <p className="eventText">{event?.extendedProps?.format}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent="space-between">
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Influencers:</p>
              <p className="eventText">
                {event?.extendedProps?.usersRelated &&
                  event?.extendedProps?.usersRelated.map((category) => category?.name).join(", ")}
              </p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5} id="start">
            <Grid container flexDirection="column">
              <p>Empresas:</p>
              <p className="eventText">
                {event?.extendedProps?.leadsRelated &&
                  event?.extendedProps?.leadsRelated.map((lead) => lead?.businessName).join(", ")}
              </p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent="space-between">
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Produtos:</p>
              <p className="eventText">{event?.extendedProps?.products}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Cupom de compra:</p>
              <p className="eventText">{event?.extendedProps?.coupons}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
        <Grid container justifyContent="space-between">
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Marcações e Hashtags:</p>
              <p className="eventText">{event?.extendedProps?.tagsAndHashtags}</p>
            </Grid>
          </S.InputGrid>
          <S.InputGrid item xs={12} md={5.5} xl={5.5}>
            <Grid container flexDirection="column">
              <p>Descrição:</p>
              <p className="eventText">{event?.extendedProps?.description}</p>
            </Grid>
          </S.InputGrid>
        </Grid>
      </S.EventDetailsGrid>
    </>
  );
};

export default PublicationDetails;
