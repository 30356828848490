import styled from "styled-components";
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const DateInputGrid = styled(InputGrid)`
  .flatpickr-confirm {
    color: white;
    cursor: pointer;
  }
`

export const CheckboxGrid = styled(Grid)`
  width: fit-content !important;
  cursor: pointer;
`

export const DownloadLoadingButton = styled(LoadingButton)`
  border-radius: 5px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%) !important;
  height: 40px !important;
  width: 150px !important;
  opacity: 1 !important;

  span {
    color: #fff !important;
  }
`