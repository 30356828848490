import { useState, useEffect, useContext } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card } from '@mui/material';
import VuiAvatar from "components/VuiAvatar";
import * as S from './style'
import { getSellers } from "utils/requests/other";
import zePlaceholder from 'assets/ze.png'
import { TokenContext } from "context/TokenContext";
import { useNavigate } from "react-router-dom";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      cursor: "pointer",
      position: "relative",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
);

const MarketingPipeline = () => {
  const navigate = useNavigate();
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [sellers, setSellers] = useState([]);

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      const changedNameArray = [
        { ...data[0], name: 'Você' },
        ...data.slice(1)
      ]
      setSellers(changedNameArray);
    };
  };

  useEffect(() => {
    if(role === 'MARKETING') {
      navigate('/marketing/pipeline/kanban');
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container item xs={12} md={12} lg={12} xl={12}>
        <Card sx={{ width: '100%', minHeight: '780px' }}>
          <Grid container flexDirection='column' py='15px' px='30px'>
            <Grid container flexDirection='column'>
              <p>Todos</p>
              <Grid container gap='20px'>
                <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                  <S.RouterLink to={`/marketing/pipeline/kanban`}>
                    <S.CommercialPipelineGrid display='flex' flexDirection='column'>
                      <Avatar>All</Avatar>
                      <p>Geral</p>
                    </S.CommercialPipelineGrid>
                  </S.RouterLink>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                  <S.RouterLink to={`/marketing/pipeline/kanban?team=DESIGNER`}>
                    <S.CommercialPipelineGrid display='flex' flexDirection='column'>
                      <Avatar>DS</Avatar>
                      <p>Designers</p>
                    </S.CommercialPipelineGrid>
                  </S.RouterLink>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                  <S.RouterLink to={`/marketing/pipeline/kanban?team=FILMMAKER`}>
                    <S.CommercialPipelineGrid display='flex' flexDirection='column'>
                      <Avatar>VM</Avatar>
                      <p>Videomakers</p>
                    </S.CommercialPipelineGrid>
                  </S.RouterLink>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                  <S.RouterLink to={`/marketing/pipeline/kanban?team=SOCIAL_MEDIA`}>
                    <S.CommercialPipelineGrid display='flex' flexDirection='column'>
                      <Avatar>SM</Avatar>
                      <p>Social Media</p>
                    </S.CommercialPipelineGrid>
                  </S.RouterLink>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                  <S.RouterLink to={`/marketing/pipeline/kanban?team=PRODUCER`}>
                    <S.CommercialPipelineGrid display='flex' flexDirection='column'>
                      <Avatar>PR</Avatar>
                      <p>Produção</p>
                    </S.CommercialPipelineGrid>
                  </S.RouterLink>
                </Grid>
              </Grid>
            </Grid>
            {/*             <Grid container flexDirection='column' mt='50px'>
              <p>Funil por usuário</p>
              <Grid container>
                {sellers.map((seller) => (
                  <Grid container item flexDirection='column' xs={6} md={4} lg={3} xl={2} key={seller?.username}>
                    <S.RouterLink to={`/comercial/pipeline/${seller.username}`}>
                      <S.UsersPipelineGrid container item flexDirection='column'>
                        <Avatar size='xxl' img={seller?.avatar ? seller?.avatar : zePlaceholder} />
                        <p>{seller?.name}</p>
                      </S.UsersPipelineGrid>
                    </S.RouterLink>
                  </Grid>
                ))
                }
              </Grid>
            </Grid> */}
          </Grid>
        </Card>
      </Grid >
    </DashboardLayout >
  )
}

export default MarketingPipeline