import React, { useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { Grid } from '@mui/material';
import * as S from '../../style'
import Autocomplete from '@mui/material/Autocomplete';
import { RiCalendarEventFill } from 'react-icons/ri'
import { IoAlertCircleOutline } from 'react-icons/io5'
import InputAdornment from '@mui/material/InputAdornment';
import Modal from 'components/Modal/Modal';
import { editFinancialTransfer, getCategories, getSubCategories, createCategories } from 'utils/requests/financial';
import moment from 'moment';
import 'moment/locale/pt-br';
import MaskMoney from 'components/Masks/MaskMoney';
import showToast from 'components/Toast/Toast';
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { getAllClients } from 'utils/requests/leads';
import { sanitizeCurrencyValue } from 'utils/utils';

const TransferForm = ({ refetch, closeModal, entry }) => {
  moment.locale("pt-br")

  const [newCategory, setNewCategory] = React.useState();
  const [newCategoryOpen, setNewCategoryOpen] = React.useState(false);
  const [allCategories, setAllCategories] = React.useState();
  const [allSubCategories, setAllSubCategories] = React.useState();
  const [businesses, setBusinesses] = React.useState();

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      value: entry?.value ? (Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '',
      date: entry?.dateInvoiced ? moment(entry?.dateInvoiced).format('DD/MM/YYYY HH:mm') : '',
      paymentForeseenDate: entry?.datePaymentExpected ? moment(entry?.datePaymentExpected).format('DD/MM/YYYY HH:mm') : '',
      description: entry?.description ? entry?.description : '',
      bankAccount: entry?.bankAccount ? entry?.bankAccount : '',
      category: entry?.category ? entry?.category : null,
      subCategories: entry?.subCategories?.length > 0 ? entry?.subCategories.map((sub) => (sub)) : null,
      status: entry?.businessStatus ? entry?.businessStatus : '',
      influencerStatus: entry?.userStatus ? entry?.userStatus : '',
      miscInfo: entry?.miscInfo ? entry?.miscInfo : '',
      influencer: entry?.subCategories?.length > 0 ? entry?.subCategories[0] : null,
      leadRelated: entry?.leadRelated ? entry?.leadRelated : '',
      brand: entry?.brand ? entry?.brand : ''
    },

    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,

    validationSchema: Yup.object({
      value: Yup.string().required("Valor é um campo obrigatório"),
      date: Yup.string().required("Data é um campo obrigatório"),
      paymentForeseenDate: Yup.string().required("Previsão de Pagamento é um campo obrigatório"),
      description: Yup.string().min(4, 'Descrição precisa ter pelo menos 4 caracteres'),
      bankAccount: Yup.string(),
      category: Yup.object().notRequired().nullable(),
      subCategories: Yup.array().notRequired().nullable(),
      status: Yup.string().required('Status é um campo obrigatório'),
      influencerStatus: Yup.string().required('Status do Influenciador é um campo obrigatório'),
      miscInfo: Yup.string(),
      influencer: Yup.object().required('Influencer é um campo obrigatório'),
      brand: Yup.string()
    }),
    onSubmit: async (values) => {
      const startTransferValues = formik.initialValues;
      const cleanValue = Number(values.value.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, "."));
      const unixDate = (date) => {
        return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
      };
      const editTransfer = {
        description: values.description !== startTransferValues.description ? values.description : null,
        value: startTransferValues.value !== values.value ? sanitizeCurrencyValue(values.value) : '',
        subCategories: values.influencer.id !== startTransferValues.influencer.id ? [values.influencer.id] : '',
        businessStatus: values.status !== startTransferValues.status ? values.status : '',
        userStatus: values.influencerStatus !== startTransferValues.influencerStatus ? values.influencerStatus : '',
        miscInfo: values.miscInfo !== startTransferValues.miscInfo ? values.miscInfo : '',
        dateInvoicedMs: values.date !== startTransferValues.date ? unixDate(values.date) : '',
        leadRelated: values.leadRelated.id !== startTransferValues.leadRelated.id ? values.leadRelated.id : '',
        brand: values.brand !== startTransferValues.brand ? values.brand : '',
        datePaymentExpectedMs: values.paymentForeseenDate !== startTransferValues.paymentForeseenDate ? unixDate(values.paymentForeseenDate) : '',
        bankAccount: values.bankAccount !== startTransferValues.bankAccount ? values.bankAccount : '',
        type: 'TRANSFERENCIA'
      }
      Object.keys(editTransfer).forEach(key => {
        if (editTransfer[key] === "" || editTransfer[key] === null) {
          delete editTransfer[key];
        }
      });
      if (Object.keys(editTransfer).length > 0) {
        editFinancialTransfer(entry?.id, editTransfer).then((res) => {
          if (!res.message) { showToast("Entrada editada com sucesso", "success"); refetch(); closeModal(); };
          if (res.message) { showToast("Falha na edição de entrada", "error") };
        });
      };
      if (Object.keys(editTransfer).length === 0) {
        showToast('Sem dados alterados', 'warning');
      };
    }
  });

  const getAllCategories = async () => {
    const data = await getCategories(["TRANSFERENCIA"]);
    if (!data.statusCode) {
      const categoriesArray = data;
      categoriesArray.push({ name: "Criar nova categoria +", id: "Criar nova categoria +" });
      setAllCategories(categoriesArray);
    };
  };

  const getAllSubCategories = async () => {
    const data = await getSubCategories(["TRANSFERENCIA"]);
    if (!data.statusCode) {
      setAllSubCategories(data);
    };
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    };
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleInfluencerStatusChange = (event) => {
    formik.setFieldValue('influencerStatus', event.target.value);
  };
  const handleNewCategoryClose = () => {
    setNewCategoryOpen(false);
    formik.setFieldValue('category', null);
  };

  useEffect(() => {
    fetchBusinesses();
    getAllCategories();
    getAllSubCategories();
  }, []);

  useEffect(() => {
    if (formik?.values?.category?.name === 'Criar nova categoria +') {
      setNewCategoryOpen(true);
    };
  }, [formik.values.category]);

  const onSaveNewCategory = async (name) => {
    await createCategories(name, "MAIN", "TRANSFERENCIA").then((res) => {
      if (!res.message) {
        showToast("Categoria criada com sucesso", "success");
        getAllSubCategories();
        setNewCategoryOpen(false)
      };
      if (res.message) {
        showToast("Falha na criação de categoria", "error")
      };
    });
  };

  flatpickr("#date", flatpickrOptions('date', formik.setFieldValue, formik.setFieldTouched));
  flatpickr(".paymentForeseenDate", flatpickrOptions('paymentForeseenDate', formik.setFieldValue, formik.setFieldTouched));

  return (
    <>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid container flexDirection='column' gap='20px' marginTop='30px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='value'
                    hiddenLabel
                    placeholder={"Valor(R$)"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.value) && formik.touched.value}
                    helperText={formik.touched.value ? formik.errors.value : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    value={formik.values.influencer ? formik.values.influencer : null}
                    onChange={(e, value) => {
                      formik.setFieldTouched('influencer')
                      formik.setFieldValue('influencer', value ? value : '')
                    }}
                    options={allSubCategories ? allSubCategories : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <S.Input {...params} error={Boolean(formik.errors.influencer)} placeholder='Selecione o Influencer' />}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                  />
                  {(Boolean(formik.errors.influencer) && formik.touched.influencer) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Influencer é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='description'
                    hiddenLabel
                    type='text'
                    placeholder="Descrição"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={Boolean(formik.errors.description) && formik.touched.description}
                    helperText={formik.touched.description ? formik.errors.description : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='bankAccount'
                    hiddenLabel
                    type='text'
                    placeholder="Conta Bancária"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.bankAccount}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} id='date'>
                  <S.Input
                    name='date'
                    hiddenLabel
                    type='text'
                    placeholder="Data de Fechamento"
                    fullWidth
                    value={formik.values.date}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('date', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.date) && formik.touched.date}
                    helperText={formik.touched.date ? formik.errors.date : false}
                  />
                </S.DateInputGrid>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='paymentForeseenDate'>
                  <S.Input
                    name='paymentForeseenDate'
                    hiddenLabel
                    type='text'
                    placeholder="Previsão de pagamento"
                    fullWidth
                    value={formik.values.paymentForeseenDate}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('paymentForeseenDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.paymentForeseenDate) && formik.touched.paymentForeseenDate}
                    helperText={formik.touched.paymentForeseenDate ? formik.errors.paymentForeseenDate : false}
                  />
                </S.DateInputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    value={formik.values?.leadRelated ? formik.values?.leadRelated : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('leadRelated', value)
                    }}
                    options={businesses ? businesses[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='brand'
                    hiddenLabel
                    type='text'
                    placeholder="Marca (Opcional)"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.brand}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    name='miscInfo'
                    type='text'
                    placeholder="Observações"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.miscInfo}
                  />
                </S.InputGrid>
              </Grid>
              <S.AlertGrid container gap='5px'>
                <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
                <p className='bold'>Lembrete: </p>
                <p>Esta informação ficará visível no saldo do mês do influencer.</p>
              </S.AlertGrid>
              <Grid container justifyContent='space-between'>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Status da empresa:</FormLabel>
                    <RadioGroup
                      row
                      value={formik.values.status}
                      onChange={handleStatusChange}
                    >
                      {Boolean(formik.errors.status) && formik.touched.status &&
                        <FormHelperText sx={{ color: "#f44336" }}>Status é um campo obrigatório</FormHelperText>
                      }
                      <Grid container gap='30px' sx={{ marginLeft: "20px" }}>
                        <FormControlLabel value="RECEBIDO" control={<Radio />} label="Recebido" />
                        <FormControlLabel value="PENDENTE" control={<Radio />} label="Pendente" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Status do influenciador:</FormLabel>
                    <RadioGroup
                      row
                      value={formik.values.influencerStatus}
                      onChange={handleInfluencerStatusChange}
                    >
                      {Boolean(formik.errors.influencerStatus) && formik.touched.influencerStatus &&
                        <FormHelperText sx={{ color: "#f44336" }}>Status do Influenciador é um campo obrigatório</FormHelperText>
                      }
                      <Grid container gap='30px' sx={{ marginLeft: "20px" }}>
                        <FormControlLabel value="PAGO" control={<Radio />} label="Pago" />
                        <FormControlLabel value="PENDENTE" control={<Radio />} label="Pendente" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='Salvar' type='submit' sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }} />
          </Grid>
        </Form>
      </Formik>
      <Modal
        open={newCategoryOpen}
        onClose={handleNewCategoryClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Categoria</p>
          <S.InputGrid item xl={12}>
            <S.Input
              hiddenLabel
              name='newCategory'
              type='text'
              placeholder="Nome da Categoria"
              fullWidth
              onChange={(event) => setNewCategory(event.target.value)}
              value={newCategory}
            />
          </S.InputGrid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='Salvar' onClick={() => onSaveNewCategory(newCategory)} sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }} />
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default TransferForm