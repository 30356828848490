import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import arrowRightUp from 'assets/icons/arrow-right-up.svg'

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import Table from "defaultComponents/Tables/Table";

import { data } from "pages/Commercial/Clients/Client/ClientProjects/data";

import * as S from "./style";

const ClientProjects = ({ projects, showFinished, routing }) => {
  const { columns, rows } = data(projects ? projects : []);

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <VuiBox mb="auto">
          <Grid>
            <VuiBox>
              <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
                Projetos
              </VuiTypography>
            </VuiBox>
            {showFinished && (
              <VuiBox display="flex" alignItems="center" lineHeight={0}>
                <BsCheckCircleFill color="green" size="15px" />
                <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
                  &nbsp;<strong>{projects?.totalCompleted} concluídos</strong> este mês
                </VuiTypography>
              </VuiBox>
            )}
            {routing && (
              <VuiBox>
                <S.EditButtonGrid>
                  <Link to={routing}>
                    <S.EditButton>
                      <img src={arrowRightUp} />
                    </S.EditButton>
                  </Link>
                </S.EditButtonGrid>
              </VuiBox>
            )}
          </Grid>
        </VuiBox>
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
      </VuiBox>
    </Card>
  );
};

export default ClientProjects;
