import styled from 'styled-components'
import { Grid, Button } from '@mui/material'
import VuiBox from "components/VuiBox";

export const DividerGrid = styled(Grid)`
  justify-content: center;
  padding: 90px 30px 30px 30px;

  span {
    width: 1px;
    height: 100%;
    background: rgb(255, 255, 255);
    display: block;
    opacity: 0.15;
  }
`

export const EditButtonGrid = styled(Grid)`
  position: absolute;
  right: 0;
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const UpdateButtonGrid = styled(EditButtonGrid)`
  right: 20px;
  top: 20px;
  z-index: 1;
`

export const PerformanceGrid = styled(Grid)`
  font-size: 14px;
  gap: 3px;

  .positive {
    color: #65EFAD;
  }
  .negative {
    color: #D7007B;
  }
  .date {
    color: #fff;
    opacity: 0.5;
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 75px;
`

export const MinorOptionsContainer = styled(Grid)`
  position: absolute;
  right: 50px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;
  font-size: 15px;
  color: #fff;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const CardBox = styled(VuiBox)`
  @media (max-width: 992px) {
    text-wrap: nowrap;
  }
`

export const ValueGrid = styled(Grid)`
  display: flex;
  @media (min-width: 1440px) {
    gap: 20px;
  }
`