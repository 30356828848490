import { useState, useEffect } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card } from '@mui/material';
import VuiAvatar from "components/VuiAvatar";
import * as S from './style'
import { getSellers } from "utils/requests/other";
import zePlaceholder from 'assets/ze.png'

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      cursor: "pointer",
      position: "relative",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
);

const Pipeline = () => {
  const [sellers, setSellers] = useState([]);

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      const changedNameArray = [
        { ...data[0], name: 'Você' },
        ...data.slice(1)
      ]
      setSellers(changedNameArray);
    };
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container item xs={12} md={12} lg={12} xl={12}>
        <Card sx={{ width: '100%', minHeight: '780px' }}>
          <Grid container flexDirection='column' py='15px' px='30px'>
            <Grid container flexDirection='column'>
              <p>Todos</p>
              <S.RouterLink to={`/comercial/pipeline/todos`}>
                <S.CommercialPipelineGrid display='flex' flexDirection='column'>
                  <Avatar>All</Avatar>
                  <p>Pipeline Comercial</p>
                </S.CommercialPipelineGrid>
              </S.RouterLink>
            </Grid>
            <Grid container flexDirection='column' mt='50px'>
              <p>Funil por usuário</p>
              <Grid container>
                {sellers.map((seller) => (
                  <Grid container item flexDirection='column' xs={6} md={4} lg={3} xl={2} key={seller?.username}>
                    <S.RouterLink to={`/comercial/pipeline/${seller.username}`}>
                      <S.UsersPipelineGrid container item flexDirection='column'>
                        <Avatar size='xxl' img={seller?.avatar ? seller?.avatar : zePlaceholder} />
                        <p>{seller?.name}</p>
                      </S.UsersPipelineGrid>
                    </S.RouterLink>
                  </Grid>
                ))
                }
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid >
    </DashboardLayout >
  )
}

export default Pipeline