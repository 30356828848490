import React from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, FormHelperText } from '@mui/material'
import * as S from './style'
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import InputAdornment from '@mui/material/InputAdornment';
import { RiInstagramLine, RiYoutubeFill, RiTiktokFill, RiTwitterFill, RiSpotifyFill, RiCameraFill } from "react-icons/ri"
import placeholderImg from 'assets/placeholder.png'
import Autocomplete from '@mui/material/Autocomplete';
import showToast from 'components/Toast/Toast';
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import moment from 'moment';
import 'moment/locale/pt-br';
import { TokenContext } from "context/TokenContext";
import VuiAvatar from "components/VuiAvatar";
import { editLead } from 'utils/requests/leads';
import { useFilePicker } from 'react-sage';
import { editLeadPhoto } from 'utils/requests/leads';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const EditClient = ({ clientInfo, refetch, closeModal }) => {
  moment.locale("pt-br");

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [postAvatar, setPostAvatar] = React.useState()
  const [previewUrl, setPreviewUrl] = React.useState();

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  React.useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const sendClientData = async (values, clientPhoto) => {
    if (postAvatar && clientPhoto) {
      await editLeadPhoto(clientPhoto).then((res) => {
        if (!res.message) {
          showToast("Dados alterados com sucesso!", "success");
          refetch();
          closeModal();
        };
        if (res.message) {
          showToast("Falha ao alterar dados", "error")
        };
      });
    }
    await editLead(clientInfo?.lead?.id, values).then((res) => {
      if (!res.message) {
        showToast("Dados alterados com sucesso!", "success");
        refetch();
        closeModal();
      };
      if (res.message) {
        showToast("Falha ao alterar dados", "error")
      };
    });
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      businessName: clientInfo?.lead?.businessName ?? '',
      businessDescription: clientInfo?.lead?.businessDescription ?? '',
      branch: clientInfo?.lead?.branch ?? '',
      curve: clientInfo?.lead?.curve ?? '',
      instagram: clientInfo?.lead?.instagram?.username ?? '',
      youtube: clientInfo?.lead?.youtube?.username ?? '',
      tiktok: clientInfo?.lead?.tiktok?.username ?? '',
      spotify: clientInfo?.lead?.spotify?.username ?? '',
      twitter: clientInfo?.lead?.twitter?.username ?? '',
    },
    validationSchema: Yup.object({
      businessName: Yup.string().required('Nome da empresa é obrigatório'),
      businessDescription: Yup.string().required('Descrição é obrigatório'),
      branch: Yup.string().required('Ramo é obrigatório'),
      curve: Yup.string().required('Curva é obrigatório'),
      instagram: Yup.string(),
      youtube: Yup.string(),
      tiktok: Yup.string(),
      spotify: Yup.string(),
      twitter: Yup.string(),
    }),
    onSubmit: async (values) => {
      const startClientValues = formik.initialValues;
      const edit = {
        businessName: values.businessName !== startClientValues.businessName ? values.businessName : null,
        businessDescription: values.businessDescription !== startClientValues.businessDescription ? values.businessDescription : null,
        branch: values.branch !== startClientValues.branch ? values.branch : null,
        curve: values.curve !== startClientValues.curve ? values.curve : null,
        instagram: values.instagram !== startClientValues.instagram
          ? JSON.stringify({ username: values.instagram }) : null,
        spotify: values.spotify !== startClientValues.spotify
          ? JSON.stringify({ username: values.spotify }) : null,
        tiktok: values.tiktok !== startClientValues.tiktok
          ? JSON.stringify({ username: values.tiktok }) : null,
        twitter: values.twitter !== startClientValues.twitter
          ? JSON.stringify({ username: values.twitter }) : null,
        youtube: values.youtube !== startClientValues.youtube
          ? JSON.stringify({ username: values.youtube }) : null,
      };
      //Remove os campos não modificados
      Object.keys(edit).forEach(key => {
        if (edit[key] === null) {
          delete edit[key];
        }
      });

      const avatarChange = {
        file: postAvatar ?? '',
        id: clientInfo?.lead?.id,
        type: 'MarketingLeads'
      };

      if (Object.keys(edit).length === 0 && !postAvatar) showToast('Sem dados alterados, campos sem alterações', 'warning');
      if (Object.keys(edit).length === 0 && postAvatar) {
        await editLeadPhoto(avatarChange).then((res) => {
          if (!res.message) {
            showToast("Dados alterados com sucesso!", "success");
            refetch();
            closeModal();
          };
          if (res.message) {
            showToast("Falha ao alterar dados", "error")
          };
        });
      };
      if ((Object.keys(edit).length > 0) && !postAvatar) {
        sendClientData(edit);
      };
      if ((Object.keys(edit).length > 0) && postAvatar) {
        sendClientData(edit, avatarChange);
      };
    }
  })

  const branches = [
    'Agência',
    'Bebidas',
    'Instituição Financeira',
    'Alimentícias',
    'Supermercado',
    'Time',
    'Limpeza',
    'Calçados',
    'Politicos',
    'Farmacias',
    'Saude',
    'AudioVisual',
    'Instituição de Ensino',
    'Transporte',
    'Cosméticos',
    'Entretenimento',
    'Aplicativos',
    'Automotivo',
    'Outros'
  ];

  const curves = ['A', 'B', 'C'];

  flatpickr("#birthDate", flatpickrOptions('birthDate', formik.setFieldValue, formik.setFieldTouched));

  const initialUserAvatar = clientInfo?.lead?.avatar ? clientInfo?.lead?.avatar : placeholderImg;

  return (
    <>
      <Grid container flexDirection='column'>
        <p>Editar perfil</p>
        <Grid container justifyContent='center'>
          <S.InfluencerPhoto>
            <div>
              <Avatar img={previewUrl ?? initialUserAvatar} />
            </div>
            <S.IndicatorGrid container onClick={imageInput.onClick}>
              <S.BranchIndicator>
                <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
                <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
              </S.BranchIndicator>
            </S.IndicatorGrid>
            {/*           <div className='photoContainer'>
            <img src={clientInfo?.avatar ? clientInfo?.avatar : placeholderImg} alt='Foto do usuário' draggable='false' />
          </div> */}
          </S.InfluencerPhoto>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container flexDirection='column' gap='15px' mt='20px'>
            <S.InputGrid container item xs={12} md={12} xl={12}>
              <S.Input
                name='businessName'
                id='businessName'
                placeholder='Nome do empresa'
                onChange={formik.handleChange}
                value={formik.values.businessName}
                error={Boolean(formik.errors.businessName)}
                helperText={formik.errors.businessName}
                fullWidth
              />
            </S.InputGrid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  name='branch'
                  disablePortal
                  value={formik.values.branch ? formik.values.branch : null}
                  onChange={(e, value) => {
                    formik.setFieldTouched('branch')
                    formik.setFieldValue('branch', value ? value : '')
                  }}
                  options={branches ? branches : []}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) =>
                    <S.Input
                      {...params}
                      placeholder='Ramo'
                      error={Boolean(formik.errors.branch)}
                      helperText={formik.errors.branch}
                    />
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  name='curve'
                  disablePortal
                  value={formik.values.curve ? formik.values.curve : null}
                  onChange={(e, value) => {
                    formik.setFieldTouched('curve')
                    formik.setFieldValue('curve', value ? value : '')
                  }}
                  options={curves ? curves : []}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) =>
                    <S.Input
                      {...params}
                      placeholder='Curva'
                      error={Boolean(formik.errors.curve)}
                      helperText={formik.errors.curve}
                    />
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                />
                {(Boolean(formik.errors.curve) && formik.touched.curve) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Curva é um campo obrigatório</FormHelperText>}
              </S.InputGrid>
            </Grid>
            <S.InputGrid>
              <S.Input
                name='businessDescription'
                id='businessDescription'
                placeholder='Descrição'
                onChange={formik.handleChange}
                value={formik.values.businessDescription}
                error={Boolean(formik.errors.businessDescription)}
                helperText={formik.errors.businessDescription}
              />
            </S.InputGrid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='instagram'
                  hiddenLabel
                  type='text'
                  placeholder="Instagram"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.instagram}
                  error={Boolean(formik.errors.instagram)}
                  helperText={formik.errors.instagram}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiInstagramLine />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='youtube'
                  hiddenLabel
                  type='text'
                  placeholder="Youtube"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.youtube}
                  error={Boolean(formik.errors.youtube)}
                  helperText={formik.errors.youtube}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiYoutubeFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='tiktok'
                  hiddenLabel
                  type='text'
                  placeholder="TikTok"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.tiktok}
                  error={Boolean(formik.errors.tiktok)}
                  helperText={formik.errors.tiktok}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiTiktokFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='spotify'
                  hiddenLabel
                  type='text'
                  placeholder="Spotify"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.spotify}
                  error={Boolean(formik.errors.spotify)}
                  helperText={formik.errors.spotify}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiSpotifyFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='twitter'
                  hiddenLabel
                  type='text'
                  placeholder="Twitter"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.twitter}
                  error={Boolean(formik.errors.twitter)}
                  helperText={formik.errors.twitter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiTwitterFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='Salvar' type='submit' sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }} />
          </Grid>
        </form>
      </Grid>
    </>
  )
}

export default EditClient