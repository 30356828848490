import React from "react";
import { Grid } from "@mui/material";
import * as S from "../style";
import InputAdornment from "@mui/material/InputAdornment";
import { RiCalendarEventFill } from "react-icons/ri";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import moment from "moment";
import "moment/locale/pt-br";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { createEvent, getCalendarCategories } from "utils/requests/calendar";
import showToast from "components/Toast/Toast";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from "utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { getProjectsFromLeads } from "utils/requests/project";
import { getAllClients } from "utils/requests/leads";

const AddEvent = ({ refetch, closeModal, token }) => {
  moment.locale("pt-br");

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY HH:mm").valueOf());
  };

  const [categories, setCategories] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [businesses, setBusinesses] = React.useState();
  const [validateFields, setValidateFields] = React.useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      title: "",
      start: "",
      end: "",
      extendedProps: {
        eventUrl: "",
        categories: [],
        project: "",
        description: "",
        place: "",
        leadsRelated: [],
      },
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(4, "Título deve conter pelo menos 4 caracteres")
        .required("Título é obrigatório"),
      start: Yup.string()
        .required("Data Inicial é obrigatório")
        .test("start-date-test", "Data inicial não pode ser maior que a final", (value) => {
          if (!formik.values.end || !value) {
            return true;
          }
          if (formik.errors.end) {
            return true;
          }
          return unixDate(value) <= unixDate(formik.values.end);
        })
        .test("start-same-date-test", "Data inicial não pode ser igual a final", (value) => {
          if (formik.values.end === value || !value) {
            return false;
          }
          return true;
        }),
      end: Yup.string()
        .test("end-date-test", "Data final não pode ser menor que a inicial", (value) => {
          if (!formik.values.start || !value) {
            return true;
          }
          if (formik.errors.start) {
            return true;
          }
          return unixDate(value) >= unixDate(formik.values.start);
        })
        .test("end-same-date-test", "Data final não pode ser igual a inicial", (value) => {
          if (formik.values.start === value || !value) {
            return false;
          }
          return true;
        }),

      extendedProps: Yup.object().shape({
        eventUrl: Yup.string(),
        categories: Yup.array(),
        project: Yup.object(),
        description: Yup.string(),
        place: Yup.string(),
        leadsRelated: Yup.array(),
      }),
    }),
    onSubmit: async (values) => {
      const eventData = {
        title: values.title,
        startDateMs: unixDate(values.start),
        endDateMs: values.end ? unixDate(values.end) : unixDate(values.start) + 3600000,
        description: values.extendedProps.description,
        place: values.extendedProps.place,
        urlRelated: values.extendedProps.eventUrl,
        usersRelated: values.extendedProps.categories
          ? values.extendedProps.categories.map((category) => category.id)
          : "",
        project: values.extendedProps.project.id,
        leadsRelated: values.extendedProps.leadsRelated
          ? values.extendedProps.leadsRelated.map((lead) => lead.id)
          : "",
      };
      Object.keys(eventData).forEach((key) => {
        if (eventData[key] === "") {
          delete eventData[key];
        }
        if (Array.isArray(eventData[key]) && eventData[key].length === 0) {
          delete eventData[key];
        }
      });
      await createEvent(eventData).then((res) => {
        if (res.id) {
          showToast("Evento criado com sucesso!", "success");
          refetch();
          closeModal();
        } else {
          showToast("Falha na criação do evento", "error");
        }
      });
    },
  });

  const fetchCategories = async () => {
    const data = await getCalendarCategories();
    if (!data.message) {
      const entries = Object.entries(data).map((entry) => entry[1]);
      setCategories(entries.flat());
      const loggedUserCategory = entries?.flat()?.find((category) => category?.id === token.id);
      if (loggedUserCategory) {
        formik.setFieldValue("extendedProps.categories", [loggedUserCategory]);
      }
      if (!loggedUserCategory) {
        showToast("Usuário atual não encontrado na agenda, contate o suporte.", "error");
      }
    }
  };

  const fetchProjects = async () => {
    const selectedLeads = formik.values.extendedProps.leadsRelated.map((lead) => lead.id);
    if (formik?.values?.extendedProps?.leadsRelated?.length > 0) {
      const data = await getProjectsFromLeads(selectedLeads);
      if (!data.message) {
        setProjects(data);
      }
    } else {
      setProjects([]);
    }
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    }
  };

  React.useEffect(() => {
    fetchCategories();
    fetchBusinesses();
  }, []);

  React.useEffect(() => {
    fetchProjects();
  }, [formik.values.extendedProps.leadsRelated]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr("#start", flatpickrOptions("start", formik.setFieldValue, formik.setFieldTouched));
  flatpickr("#end", flatpickrOptions("end", formik.setFieldValue, formik.setFieldTouched));

  return (
    <>
      <p>Criar Evento</p>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.handleSubmit}
      >
        <Form>
          <Grid container flexDirection="column" gap="20px" marginTop="30px" padding="15px">
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name="title"
                  id="title"
                  type="text"
                  placeholder="Título"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  error={Boolean(formik.errors.title)}
                  helperText={formik.errors.title}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.place"
                  id="extendedProps.place"
                  hiddenLabel
                  type="text"
                  placeholder="Local"
                  fullWidth
                  value={formik.values.extendedProps.place}
                  onChange={formik.handleChange}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id="start">
                <S.Input
                  name="start"
                  hiddenLabel
                  type="text"
                  placeholder="Data Inicial"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={formik.values.start}
                  error={Boolean(formik.errors.start)}
                  helperText={formik.errors.start}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCalendarEventFill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => formik.setFieldValue("start", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id="end">
                <S.Input
                  name="end"
                  hiddenLabel
                  type="text"
                  placeholder="Data Final"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={formik.values.end}
                  error={Boolean(formik.errors.end)}
                  helperText={formik.errors.end}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCalendarEventFill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => formik.setFieldValue("end", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name="extendedProps.eventUrl"
                  id="extendedProps.eventUrl"
                  hiddenLabel
                  type="text"
                  placeholder="URL do Evento"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.eventUrl}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.leadsRelated"
                  name="extendedProps.leadsRelated"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.leadsRelated
                      ? formik.values.extendedProps.leadsRelated
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.leadsRelated", value);
                  }}
                  options={businesses && businesses[0] ? businesses[0] : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Empresa" />}
                  getOptionLabel={(option) => option.businessName}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.businessName}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="extendedProps.categories"
                  name="extendedProps.categories"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={
                    formik.values.extendedProps.categories
                      ? formik.values.extendedProps.categories
                      : []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.categories", value);
                  }}
                  options={categories ? categories.flat() : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Usuários" />}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.name}
                    </li>
                  )}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  disablePortal
                  value={
                    formik.values?.extendedProps?.project
                      ? formik.values?.extendedProps?.project
                      : null
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("extendedProps.project", value);
                  }}
                  id="combo-box-demo"
                  options={projects && projects[0] ? projects[0] : []}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => <S.Input {...params} placeholder="Projeto" />}
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  name="extendedProps.description"
                  id="extendedProps.description"
                  hiddenLabel
                  type="text"
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.extendedProps.description}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <ButtonCustom
                label="CRIAR EVENTO"
                sx={{ width: "180px", fontSize: "18px", fontWeight: "500" }}
                type="submit"
                onClick={() => setValidateFields(true)}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default AddEvent;
